import { Button, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';

import ButtonComponent from 'src/components/ButtonComponent';
import TextInput from 'src/components/InputFiled';
import SelectOption from 'src/components/Selection';
import NumberFormatCustom from 'src/containers/Services/components/CreateService/FormatNumber';
import { optionStatus, textFieldSurcharge } from '../../constants';
import Places from '../Places';
import { IFormikValueType, ICitiesID } from '../../interface';
import { validate } from '../../constants';

import { useStyles } from '../FormCreateSurcharge/styles';

type IFormUpdateSurcharge = {
  listCities: [];
  onClose: () => void;
  handleUpdateSurcharge: (data) => void;
  oldDataSurcharge: IFormikValueType | undefined;
};

export const FormUpdateSurcharge = ({
  listCities,
  onClose,
  handleUpdateSurcharge,
  oldDataSurcharge,
}: IFormUpdateSurcharge) => {
  const classes = useStyles();

  const defaultValueCity: ICitiesID = {
    id: oldDataSurcharge?.city_id,
    name: oldDataSurcharge?.city_name,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: oldDataSurcharge?.title,
      description: oldDataSurcharge?.description,
      amount: oldDataSurcharge?.amount,
      address: oldDataSurcharge?.address,
      city: oldDataSurcharge?.city_id,
      status: oldDataSurcharge?.status,
      id: oldDataSurcharge?.id,
    },
    validationSchema: validate,
    onSubmit: (values) => {
      handleUpdateSurcharge(values);
    },
  });

  const handleSelected = (data) => {
    formik.setFieldValue('address', data.full_address);
  };

  const handleSelectedCity = (_, newValue) => {
    formik.setFieldValue('city', newValue?.id);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        {textFieldSurcharge(formik.values).map((item: any) => (
          <Grid item xs={12}>
            <div key={item.name} className={classes.formContent}>
              <TextInput
                value={item.value}
                onChange={formik.handleChange}
                label={item.label}
                type={item.type}
                name={item.name}
                variant="outlined"
                required
                error={
                  formik.touched[item.name] && Boolean(formik.errors[item.name])
                }
                helperText={
                  formik.touched[item.name] && formik.errors[item.name]
                }
                InputLabelProps={{
                  shrink: true,
                  inputComponent: NumberFormatCustom as any,
                }}
              />
            </div>
          </Grid>
        ))}

        <Grid item xs={8}>
          <div className={classes.formContent}>
            <Places handleSelected={handleSelected} defaultValue={oldDataSurcharge}/>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.formContent}>
            <Autocomplete
              id="city"
              options={listCities}
              onChange={handleSelectedCity}
              className={classes.autocompleteContainer}
              getOptionLabel={(option: any) => option?.name}
              renderInput={(params) => (
                <TextInput {...params} label="City" variant="outlined" />
              )}
              defaultValue={defaultValueCity}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.formContent}>
            <SelectOption
              style={{ width: '100%' }}
              label="Status"
              options={optionStatus}
              name="status"
              onChangeOption={formik.handleChange}
              value={formik.values.status}
            />
          </div>
        </Grid>
      </Grid>

      <div className={classes.actionSubmit}>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonComponent status="approve">Submit</ButtonComponent>
      </div>
    </form>
  );
};
