import { call, put, takeLatest } from 'redux-saga/effects';

import * as types from '../constants';
import * as actionTypes from '../actions';
import { getBookingsService, getCalenderBookingsService } from '../services';

function* getBookingsSaga(action) {
  try {
    const response = yield getBookingsService(action.payload);
    if (response) {
      yield put(actionTypes.getBookingsSuccess(response.data.bookings));
    }
  } catch (error) {
    const message = { error };
    yield put(actionTypes.getBookingsFail(message.error.graphQLErrors[0].code));
  }
}

function* getCalenderBookingsSaga(action) {
  try {
    const {
      data: { bookings },
    } = yield call(getCalenderBookingsService, action.payload);
    yield put(actionTypes.getCalenderBookingsSuccess(bookings.data));
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.getCalenderBookingsFail(message.error.graphQLErrors[0].code)
    );
  }
}

export default function* BookingsSaga() {
  yield takeLatest(types.GET_BOOKINGS_REQUEST, getBookingsSaga);
  yield takeLatest(types.GET_CALENDER_BOOKING_REQUEST, getCalenderBookingsSaga);
}
