import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import StarIcon from '@material-ui/icons/Star';

import { objClasses, objStatus } from 'src/containers/User/contants';
import { ISelector } from 'src/reducers';

import { useStyles } from './styles';

interface ICalenderBookings {
  handleChangeTime: (from: string, to: string) => void;
}

function CalenderBookings({ handleChangeTime }: ICalenderBookings) {
  const classes = useStyles();
  const calenderBookings = useSelector(
    (s: ISelector) => s.bookings?.calenderBookings
  );

  const dataBooking = calenderBookings.map((obj) => ({
    ...obj,
    groupId: obj.id,
    title: obj.service?.name,
    start: obj.booking_time,
  }));

  const renderEventContent = (eventInfo) => {
    return (
      <div
        className={classes.schedule}
        style={{
          backgroundColor: `${
            objClasses[eventInfo.event.extendedProps.status]
          }`,
        }}
      >
        <Link
          to={`/booking/${eventInfo.event._def.publicId}`}
          className={classes.viewDetail}
        >
          <div className={classes.textViewDetail}>
            <span style={{ fontSize: '12px', fontWeight: 'bold' , display: 'flex', alignItems: 'center'}}>
              {eventInfo.event._def.extendedProps.type === 'manual_select' && (
                <StarIcon style={{ color: '#ffd400' }} fontSize="small" />
              )}
              ({eventInfo.event._def.publicId}) - 
              {moment(eventInfo.event._def.extendedProps.booking_time).format(
                'LT'
              )}
            </span>
            <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
              {eventInfo.event.title}
            </span>
          </div>
          <div
            className={classes.textStatus}
            style={{ fontSize: 10, fontWeight: 'bold' }}
          >
            {objStatus[eventInfo.event.extendedProps.status]}
          </div>
        </Link>
      </div>
    );
  };
  return (
    <FullCalendar
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridDay',
      }}
      editable={true}
      datesSet={(arg) => {
        handleChangeTime(
          moment(arg.start).format('YYYY-MM-DD'),
          moment(arg.end).format('YYYY-MM-DD')
        );
      }}
      selectable={true}
      selectMirror={true}
      dayMaxEvents={true}
      plugins={[dayGridPlugin, timeGridPlugin]}
      eventContent={renderEventContent}
      events={dataBooking}
    />
  );
}

export default CalenderBookings;
