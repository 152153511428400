import { useInjectReducer } from 'src/utils/injectReducer';
import { useInjectSaga } from 'src/utils/injectSaga';
import TableRequest from './component/TableRequest';
import reducer from './store/reducers';
import saga from './store/sagas';

function ServiceRequest() {
  useInjectReducer({ key: 'serviceRequest', reducer });
  useInjectSaga({ key: 'serviceRequest', saga });

  return <TableRequest />;
}

export default ServiceRequest;
