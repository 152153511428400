import produce from 'immer';
import { IReducerBooking } from 'src/containers/User/interface';
import * as types from '../constants';

export const initialState: IReducerBooking = {
  isRequesting: false,
  bookingDetail: {},
  services: [],
  isUpdate: false,
  isDelete: false,
  isUpdateRecipient: false,
  cancelBooking: {},
  partnerReceive: [],
  partnerSkip: [],
  isResend: false,
  isResendNotAvailableWork: false,
  messageConversation: [],
  dataConversation: {},
  pageNumberResend: null,
};

export type bookingState = typeof initialState;

const BookingReducer = (state = initialState, { type, payload }: any) =>
  produce(state, (draft) => {
    switch (type) {
      case types.GET_BOOKING_REQUEST:
        draft.isRequesting = true;
        break;
      case types.GET_BOOKING_SUCCESS:
        draft.isRequesting = false;
        draft.bookingDetail = payload;
        break;
      case types.GET_BOOKING_FAIL:
        draft.isRequesting = false;
        break;
      case types.UPDATE_STATUS_BOOKING_REQUEST:
        draft.isRequesting = true;
        break;
      case types.UPDATE_STATUS_BOOKING_SUCCESS:
        draft.isRequesting = false;
        break;
      case types.UPDATE_STATUS_BOOKING_FAIL:
        draft.isRequesting = false;
        break;
      case types.UPDATE_BOOKING_REQUEST:
        draft.isUpdate = true;
        break;
      case types.UPDATE_BOOKING_SUCCESS:
        draft.isUpdate = false;
        break;
      case types.UPDATE_BOOKING_FAIL:
        draft.isUpdate = false;
        break;
        case types.ASSIGN_PARTNER_SUCCESS:
          draft.isUpdateRecipient = true;
          break;
        case types.ASSIGN_PARTNER_FAIL:
          draft.isUpdateRecipient = true;
          break;
      case types.GET_SERVICE_SUCCESS:
        draft.services = payload;
        break;
      case types.GET_SERVICE_FAIL:
        break;
      case types.DELETE_BOOKING_REQUEST:
        draft.isDelete = true;
        break;
      case types.DELETE_BOOKING_SUCCESS:
        draft.isDelete = false;
        break;
      case types.DELETE_BOOKING_FAIL:
        draft.isDelete = false;
        break;
      case types.FETCH_CANCEL_BOOKING_SUCCESS:
        draft.cancelBooking = payload;
        break;
      case types.FETCH_CANCEL_BOOKING_FAIL:
        break;
      case types.FETCH_PARTNER_RECEIVE_NOTIFICATION_REQUEST:
        draft.isRequesting = true;
        break;
      case types.FETCH_PARTNER_RECEIVE_NOTIFICATION_SUCCESS:
        draft.isRequesting = false;
        draft.partnerReceive = payload;
        break;
      case types.FETCH_PARTNER_RECEIVE_NOTIFICATION_FAIL:
        draft.isRequesting = false;
        break;
      case types.FETCH_PARTNER_SKIP_NOTIFICATION_REQUEST:
        draft.isRequesting = true;
        break;
      case types.FETCH_PARTNER_SKIP_NOTIFICATION_SUCCESS:
        draft.partnerSkip = payload;
        draft.isRequesting = false;
        break;
      case types.FETCH_PARTNER_SKIP_NOTIFICATION_FAIL:
        draft.isRequesting = false;
        break;
      case types.RESEND_NOTIFICATION_REQUEST:
        draft.isResend = true;
        break;
      case types.RESEND_NOTIFICATION_SUCCESS:
        draft.isResend = false;
        break;
      case types.RESEND_NOTIFICATION_FAIL:
        draft.isResend = false;
        break;
      case types.RESEND_NOTIFICATION_NOT_AVAILABLE_WORK_REQUEST:
        draft.isResendNotAvailableWork = true;
        break;
      case types.RESEND_NOTIFICATION_NOT_AVAILABLE_WORK_SUCCESS:
        draft.isResendNotAvailableWork = false;
        break;
      case types.RESEND_NOTIFICATION_NOT_AVAILABLE_WORK_FAIL:
        draft.isResendNotAvailableWork = false;
        break;
      case types.GET_CONVERSATION_BOOKING_REQUEST:
        break;
      case types.GET_CONVERSATION_BOOKING_SUCCESS:
        const newData = [
          ...state.messageConversation,
          ...payload.getMessage.messages,
        ];
        draft.messageConversation = newData.filter(
          (item, index, self) =>
            item.conversation_id === +payload.payload.conversationId &&
            index === self.findIndex((t) => t.id === item.id)
        );
        draft.dataConversation = payload.getMessage;
        break;
      case types.GET_CONVERSATION_BOOKING_FAIL:
        break;
      case types.GET_PAGE_NUMBER_SUCCESS:
        draft.pageNumberResend = payload;
        break;
      case types.RESEND_NOTIFICATION_PAGE_SUCCESS:
        break;
      case types.RESEND_NOTIFICATION_PAGE_FAIL:
        break;
      default:
        break;
    }
  });

export default BookingReducer;
