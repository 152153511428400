import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  talenMain__item: {
    position: 'relative',
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  item: {
    color: 'rgba(0, 0, 0, 0.54)',
    '&-main': {
      display: 'flex',
      paddingBottom: '5px',
      '&__left': {
        width: '100%',
        paddingRight: theme.spacing(1),
        fontWeight: 500,
        fontSize: 10,
      },
      '&__right': {
        display: 'block',
        textAlign: 'start',
        fontWeight: 500,
        fontSize: 10,
      },
    },
  },
  noReview: {
    padding: theme.spacing(2, 0),
  },
}));
export { useStyles };
