import { gql } from '@apollo/client';

import { client } from 'src';

export const getApiListPartner = async (data) => {
  const { page, rowsPerPage, filter } = data;

  return await client.query({
    query: gql`
      query {
        getPartners(page: ${page} , per_page: ${rowsPerPage} , filter : {
          username:"${filter.username}"
          phone:"${filter.phone}"
          email:"${filter.email}"
          serviceId:${filter.serviceId}
        }) {
          data {
            id
            role_id
            user_id
            role_name
            name
            email
            gender
            city_name
            date_of_birth
            online_status
            available_work_status
            status
            phone
            avatar
            accountRevenue
            accountBalance
            block_until
            services {
              name
            }
            created_at
            updated_at
          }
          page
          next_page
          per_page
          total
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};
