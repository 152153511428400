function IconImage(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <clipPath id="bc14i5f67a">
          <path fill="#fff" d="M0 0H24V24H0z" transform="translate(-0.458)" />
        </clipPath>
      </defs>
      <g clipPath="url(#bc14i5f67a)" transform="translate(0.458)">
        <g>
          <g>
            <path
              fill="rgba(0,0,0,0.54)"
              d="M18.9 17.1V4.5a1.805 1.805 0 0 0-1.8-1.8H4.5a1.805 1.805 0 0 0-1.8 1.8v12.6a1.805 1.805 0 0 0 1.8 1.8h12.6a1.805 1.805 0 0 0 1.8-1.8zM7.651 12.151L9.9 14.86l3.15-4.059 4.05 5.4H4.5z"
              transform="translate(1.251 1.199)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconImage;
