import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as TransactionAction from '../store/actions';

interface ISearch {
  date: string;
}

function HistoryTransactionHook(id: string) {
  const dispatch = useDispatch();

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [search, setSearch] = useState<ISearch>({
    date: '',
  });

  const handleSearch = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleFilter = () => {
    const params = {
      id: id,
      page: page,
      rowsPerPage: rowsPerPage,
      filter: {
        date: search.date,
      },
    };
    dispatch(TransactionAction.getHistoryTransactionRequest(params));
  };

  const handleClearFilter = () => {
    const params = {
      id: id,
      page: 1,
      rowsPerPage: 10,
      filter: {
        date: '',
      },
    };
    setSearch({ date: '' });
    dispatch(TransactionAction.getHistoryTransactionRequest(params));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return {
    page,
    rowsPerPage,
    search,
    handleSearch,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilter,
    handleClearFilter,
  };
}

export default HistoryTransactionHook;
