import * as types from '../constants';
export const initialState = {
  isRequesting: false,
  listStaff: [],
  total: 0,
  isAdd: false,
};

const StaffReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_STAFF_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case types.GET_STAFF_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        listStaff: action.payload.data.data,
        total: action.payload.data.total,
      };
    case types.GET_STAFF_FAIL:
      return {
        ...state,
        isRequesting: false,
        listStaff: [],
      };

    case types.UPDATE_STAFF_REQUEST:
      return {
        ...state,
        isAdd: false,
      };

    case types.UPDATE_STAFF_SUCCESS:
      return {
        ...state,
        isAdd: true,
      };
    case types.UPDATE_STAFF_FAIL:
      return {
        ...state,
        isAdd: true,
      };

    case types.ADD_STAFF_REQUEST:
      return {
        ...state,
        isAdd: false,
      };

    case types.ADD_STAFF_SUCCESS:
      return {
        ...state,
        isAdd: true,
      };
    case types.ADD_STAFF_FAIL:
      return {
        ...state,
        isAdd: true,
      };

    case types.DELETE_STAFF_REQUEST:
      return {
        ...state,
        isAdd: false,
      };

    case types.DELETE_STAFF_SUCCESS:
      return {
        ...state,
        isAdd: true,
      };
    case types.DELETE_STAFF_FAIL:
      return {
        ...state,
        isAdd: true,
      };
    default:
      return state;
  }
};

export default StaffReducer;
