import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as WithdrawAction from 'src/containers/WithDraw_Partner/store/actions';
import reducer from 'src/containers/WithDraw_Partner/store/reducers';
import saga from 'src/containers/WithDraw_Partner/store/sagas';
import { useInjectReducer } from 'src/utils/injectReducer';
import { useInjectSaga } from 'src/utils/injectSaga';
import useFetchWithdraw from './hooks/useFetchWithdraw';
import { IWithDraw } from './interfaces';
import {
  makeSelectIsRequest,
  makeSelectTotal,
  makeSelectWithDraw,
} from './store/selectors';
import WithDrawView from './views/index';

const WithDraw = (props: IWithDraw) => {
  const dispatch = useDispatch();
  useInjectReducer({ key: 'withdraw', reducer });
  useInjectSaga({ key: 'withdraw', saga });

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useFetchWithdraw();

  useEffect(() => {
    const params = {
      page: page,
      rowsPerPage: rowsPerPage,
    };
    dispatch(WithdrawAction.getWithdrawRequest(params));
  }, [dispatch, page, rowsPerPage]);

  return (
    <WithDrawView
      {...props}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  listWithdraw: makeSelectWithDraw(),
  isRequesting: makeSelectIsRequest(),
  total: makeSelectTotal(),
});

export default connect(mapStateToProps)(WithDraw);
