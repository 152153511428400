import { useParams } from 'react-router';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInjectReducer } from 'src/utils/injectReducer';
import { useInjectSaga } from 'src/utils/injectSaga';
import BookingView from './views';
import reducer from './store/reducers';
import saga from './store/sagas';

import reducerPartners from 'src/containers/ListPartner/store/reducers';
import sagaPartners from 'src/containers/ListPartner/store/sagas';

import * as BookingActions from './store/actions';
import * as PartnersActions from 'src/containers/ListPartner/store/actions';
import { ParamTypes } from './interface';
import { ISearchPartner } from '../ListPartner/interface';
import { debounce } from 'lodash';
import { ISelector } from 'src/reducers';

function BookingContainer() {
  useInjectReducer({ key: 'booking', reducer });
  useInjectSaga({ key: 'booking', saga });

  useInjectReducer({ key: 'partners', reducer: reducerPartners });
  useInjectSaga({ key: 'partners', saga: sagaPartners });

  const bookingDetail = useSelector((s: ISelector) => s.booking?.bookingDetail);

  const { id } = useParams<ParamTypes>();

  const [dataDebounce, setDataDebounce] = useState();

  const isUpdateRecipient = useSelector(
    (s: ISelector) => s.booking?.isUpdateRecipient
  );

  const dispatch = useDispatch();

  const debounceDropDown = useCallback(
    debounce((nextValue) => setDataDebounce(nextValue), 200),
    []
  );

  const [search, setSearch] = useState<ISearchPartner>({
    email: '',
    username: '',
    phone: '',
    serviceId: 0,
  });

  const handleSearch = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      ...search,
      [name]: value,
    });
    debounceDropDown(value);
  };

  const handleResendNotification = () => {
    dispatch(BookingActions.resendNotificationRequest(id));
  };

  const handleResendNotificationAvailableWork = () => {
    dispatch(BookingActions.resendNotificationNotAvailableWorkRequest(id));
  };

  const handleSendNotificationPage = (page) => () => {
    dispatch(BookingActions.resendNotificationPageRequest({booking_id: bookingDetail?.id , page}))
  }

  useEffect(() => {
    if (bookingDetail.service_id) {
      const params = {
        page: 1,
        rowsPerPage: 20,
        filter: {
          username: search.username,
          phone: search.phone,
          email: search.email,
          serviceId: bookingDetail?.service_id,
        },
      };
      dispatch(PartnersActions.getListPartnersRequestAction(params));
    }
  }, [dispatch, dataDebounce, isUpdateRecipient, bookingDetail.service_id]);

  useEffect(() => {
    dispatch(BookingActions.getServiceRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(BookingActions.getBookingRequest(id));
    dispatch(BookingActions.fetchBookingCancelRequest(id));
    dispatch(BookingActions.fetchPartnerReceiveNotificationRequest(id));
    dispatch(BookingActions.fetchPartnerSkipNotificationRequest(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (bookingDetail?.service_id) {
      dispatch(
        BookingActions.getPageNumberResendRequest({
          service_id: bookingDetail?.service_id,
          lat: bookingDetail?.location?.coordinates[1],
          long: bookingDetail?.location?.coordinates[0],
          request_profile_id: bookingDetail?.request_profile_id,
        })
      );
    }
  }, [dispatch, bookingDetail?.service_id, bookingDetail?.location?.coordinates, bookingDetail?.request_profile_id]);

  useEffect(() => {
    if (bookingDetail.conversations?.id) {
      dispatch(
        BookingActions.getConversationRequest({
          conversationId: bookingDetail.conversations.id,
          page: 1,
        })
      );
    }
  }, [bookingDetail.conversations?.id]);

  return (
    <BookingView
      search={search}
      handleSearch={handleSearch}
      handleResendNotification={handleResendNotification}
      handleResendNotificationAvailableWork={
        handleResendNotificationAvailableWork
      }
      handleSendNotificationPage={handleSendNotificationPage}
    />
  );
}

export default BookingContainer;
