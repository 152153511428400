import { call, put, takeLatest } from 'redux-saga/effects';

import * as types from '../constants';
import * as actions from '../actions';
import { getServicePartner } from '../services';
import {
  addScheduleInterviewService,
  updateProviderServicer,
} from 'src/containers/ListServiceRequest/store/services';
import { showSnackbar } from 'src/containers/Snackbar/store/actions';
import { closeModalSchedule } from 'src/containers/ListServiceRequest/store/actions';

export interface ActionPayload {
  payload: any;
  type: string;
}

function* getServiceRequestPartnerSaga(action: ActionPayload) {
  try {
    const {
      data: { getServiceRequest },
    } = yield call(getServicePartner, action.payload);
    yield put(actions.serviceRequestPartnerSuccess(getServiceRequest));
  } catch (error) {
    const message = { error };
    yield put(actions.serviceRequestPartnerFail(message.error.graphQLErrors[0].code));
  }
}

function* updateServiceRequestSaga(action: ActionPayload) {
  try {
    const { service_id, status, id, url_images } = action.payload.data;
    yield updateProviderServicer({ service_id, status, id, url_images });
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
    yield put(actions.updateServiceSuccess(action.payload.data));
  } catch (error: any) {
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Updated Failed',
      })
    );
    const message = { error };
    yield put(actions.updateServiceFail(message.error.graphQLErrors[0].code));
  }
}

function* addScheduleServiceSaga(action: ActionPayload) {
  try {
    yield call(addScheduleInterviewService, action.payload.data);
    yield put(actions.addScheduleInterviewPartnerSuccess(action.payload.data));
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
    yield put(closeModalSchedule());
  } catch (error: any) {
    const message = { error };
    yield put(
      actions.addScheduleInterviewPartnerFail(
        message.error.graphQLErrors[0].code
      )
    );
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Updated Failed',
      })
    );
    yield put(closeModalSchedule());
  }
}

export default function* ServiceRequestSaga() {
  yield takeLatest(types.SERVICE_PARTNER_REQUEST, getServiceRequestPartnerSaga);
  yield takeLatest(
    types.UPDATE_SERVICE_PARTNER_REQUEST,
    updateServiceRequestSaga
  );
  yield takeLatest(
    types.ADD_SCHEDULE_SERVICE_PARTNER_REQUEST,
    addScheduleServiceSaga
  );
}
