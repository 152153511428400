import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import EventIcon from '@material-ui/icons/Event';
import { Skeleton } from '@material-ui/lab';

import ButtonComponent from 'src/components/ButtonComponent';
import MuiTable from 'src/components/MuiTable';
import * as ServiceRequestAction from 'src/containers/ServiceRequest/store/actions';
import { ISelector } from 'src/reducers';
import ToggleComponent from 'src/components/ToggleComponent';
import { IParamHandle } from 'src/containers/ListServiceRequest/interface';
import DialogComponent from 'src/components/DialogComponent';
import Certificate from 'src/containers/ListServiceRequest/components/ChildrenComponent/Certificate';
import Interview from 'src/containers/ListServiceRequest/components/ChildrenComponent/Interview';
import InterviewDetail from 'src/containers/ListServiceRequest/components/ChildrenComponent/InterviewDetail';
import ModalUploadImages from 'src/containers/ListServiceRequest/components/TableView/ModalUploadImages';
import useLoadingButton from 'src/containers/ListServiceRequest/hook/useLoadingButton';
import { COLUMNS, objStatus, StatusRequest } from '../../contants';
import ServiceRequestHook from '../../hooks';
import { getSocialNetworkAction } from 'src/containers/ListServiceRequest/store/actions';

import { useStyles } from './styles';

function TableRequest() {
  const classes = useStyles();
  const objClasses = {
    0: classes.wait,
    1: classes.accepted,
    2: classes.cancel,
  };
  const dispatch = useDispatch();

  const getProfileRequest = (id: string) => {
    dispatch(getSocialNetworkAction(id));
  };

  const { isScheduleDetail, ScheduleDetail, isSchedule, isOpenModal } =
    useSelector((s: ISelector) => s.serviceProvider);

  const { serviceRequests, isRequesting, isUpdate } = useSelector(
    (s: ISelector) => s.serviceRequest
  );
  const { dataLoading, handleChangeLoading, isLoading } =
    useLoadingButton(isUpdate);

  const {
    handleCloseModalCertificate,
    handleGetDetail,
    handleOpenModalUpload,
    handleOpenSchedule,
    handleOpenScheduleDetail,
    handleCloseModalSchedule,
    handleHideModalScheduleDetail,
    detail,
  } = ServiceRequestHook();

  const handEdit =
    ({ id, status, service_id, url_images }: IParamHandle) =>
      () => {
        dispatch(
          ServiceRequestAction.updateServiceRequest({
            service_id,
            status,
            id,
            url_images,
          })
        );
        handleChangeLoading({ id, status });
      };

  const viewSchedule = (item) => {
    return (
      <div className={classes.schedule} onClick={handleOpenSchedule(item)}>
        <EventIcon fontSize="small" />
        <span>schedule </span>
      </div>
    );
  };

  const viewScheduleDetail = (item) => {
    return (
      <div
        className={classes.schedule}
        onClick={handleOpenScheduleDetail(item.id)}
      >
        <EventIcon fontSize="small" />
        <span>Detail </span>
      </div>
    );
  };

  const row = (item) => {
    return {
      name: (
        <div>
          <p>{item.services?.name || ''}</p>
        </div>
      ),
      status: (
        <div>
          <p className={`${classes.textStatus} ${objClasses[item.status]}`}>
            {objStatus[item.status] || ''}
          </p>
        </div>
      ),
      created_at: (
        <div>
          <p>{moment(item?.created_at).format('YYYY-MM-DD') || ''}</p>
        </div>
      ),
      author: (
        <div>
          <p>{item.admin?.name || ''}</p>
        </div>
      ),
      images: (
        <div>
          <div className={classes.bntAction}>
            <ButtonComponent
              status="origin"
              handleAction={handleGetDetail(item)}
            >
              Certificate
            </ButtonComponent>
            <ButtonComponent
              handleAction={handleOpenModalUpload(item)}
              status="origin"
            >
              Proof
            </ButtonComponent>
          </div>
        </div>
      ),
      schedule: (
        <div>
          {item.status === 0
            ? viewSchedule(item)
            : item.status === 3
              ? viewScheduleDetail(item)
              : item.status === 2
                ? viewSchedule(item)
                : ''}
        </div>
      ),
      action: (
        <div>
          <div className={classes.bntAction}>
            <ButtonComponent
              handleAction={handEdit({
                id: item.id,
                status: 'APPROVED',
                service_id: item.service_id,
                url_images: item.images?.map((item) => item.url),
              })}
              status="approve"
              disable={isLoading || item.status === StatusRequest.APPROVED}
              isLoading={
                dataLoading.id === item.id && dataLoading.status === 'APPROVED'
              }
            >
              Approve
            </ButtonComponent>
            <ButtonComponent
              handleAction={handEdit({
                id: item.id,
                status: 'DECLINED',
                service_id: item.service_id,
                url_images: item.images?.map((item) => item.url),
              })}
              status="cancel"
              disable={isLoading || item.status === StatusRequest.DECLINED}
              isLoading={
                dataLoading.id === item.id && dataLoading.status === 'DECLINED'
              }
            >
              Decline
            </ButtonComponent>
          </div>
        </div>
      ),
    };
  };

  const skeletonRowData = <Skeleton variant="rect" animation="wave" />;

  const rowError = {
    name: <div>{skeletonRowData}</div>,
    status: <div>{skeletonRowData}</div>,
    created_at: <div>{skeletonRowData}</div>,
    author: <div>{skeletonRowData}</div>,
    images: <div>{skeletonRowData}</div>,
    schedule: <div>{skeletonRowData}</div>,
    action: <div>{skeletonRowData}</div>,
  };

  const renderRow = isRequesting
    ? new Array(10).fill(0).map((i) => rowError)
    : serviceRequests?.map(row);

  return (
    <ToggleComponent title="Service Request">
      <ModalUploadImages title="Upload Images" detail={detail} />
      <DialogComponent
        title="Certificate"
        open={isOpenModal}
        onClose={handleCloseModalCertificate}
      >
        <Certificate detail={detail} getProfileRequest={getProfileRequest} />
      </DialogComponent>

      <DialogComponent
        title="Schedule Interview"
        open={isSchedule}
        onClose={handleCloseModalSchedule}
      >
        <Interview
          detail={detail}
          action={ServiceRequestAction.addScheduleInterviewPartnerRequest}
        />
      </DialogComponent>

      <DialogComponent
        title="Schedule Interview Detail"
        open={isScheduleDetail}
        onClose={handleHideModalScheduleDetail}
      >
        <InterviewDetail ScheduleDetail={ScheduleDetail} />
      </DialogComponent>
      <MuiTable headers={COLUMNS} rows={renderRow} />
    </ToggleComponent>
  );
}

export default TableRequest;
