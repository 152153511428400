import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { loginRequest } from './store/actions';
import { useInjectSaga } from 'src/utils/injectSaga';
import { useInjectReducer } from 'src/utils/injectReducer';
import saga from './store/sagas';
import reducer from './store/reducer';
import TextInput from 'src/components/InputFiled';
import ButtonComponent from 'src/components/ButtonComponent';
import cookie from 'src/utils/cookie';
import {
  Root,
  Content,
  useStyles,
  Form,
  Field,
  Error,
  Circles,
} from './styles';

interface Props {
  [data: string]: any;
}

export interface IInfoAdmin {
  id: number | string;
  name: string;
  avatar: string;
  gender: string;
  date_of_birth: string;
}

export interface IAuthReduce {
  authenticated: boolean;
  message_error: string;
  isLoading: boolean;
  adminInfo: IInfoAdmin;
}

function Login(props: Props) {
  const classes = useStyles();

  useInjectSaga({ key: 'auth', saga });
  useInjectReducer({ key: 'auth', reducer });

  const { auth = {}, loginRequest } = props;
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  // const auth = useSelector((state) => state.auth);
  const history = useHistory();

  const onLogin = (e: any) => {
    e.preventDefault();
    loginRequest({ username: username, password: password });
  };

  const onSetPassword = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);
  const onSetEmail = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUsername(e.target.value);

  useEffect(() => {
    if (auth.authenticated) {
      history.push('/partners');
    }
  }, [auth.authenticated, history, auth.loading]);

  useEffect(() => {
    cookie.removeCookie('token');
    cookie.removeCookie('refreshToken');
  }, []);

  return (
    <Root>
      <Content>
        <div className={classes.card}>
          <h3>Log In</h3>
          <Form onSubmit={onLogin}>
            <Field>
              <TextInput
                variant="outlined"
                label="Username"
                value={username}
                onChange={onSetEmail}
              />
            </Field>
            <Field>
              <TextInput
                variant="outlined"
                type={'password'}
                label="Password"
                value={password}
                onChange={onSetPassword}
              />
            </Field>
            {auth.message_error?.message && (
              <Error>{auth.message_error?.message}</Error>
            )}
            <div>
              <ButtonComponent
                isLoading={auth.isLoading}
                handleAction={onLogin}
              >
                Login
              </ButtonComponent>
              {auth.loading && <CircularProgress size={20} color="primary" />}
            </div>
          </Form>
        </div>
      </Content>
      <Circles>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </Circles>
    </Root>
  );
}

const mapStateToProps = (store: any) => {
  const { auth } = store;
  return {
    auth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      loginRequest: loginRequest,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
