import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '10px',
    '& a': {
      textDecoration: 'none',
      color: '#3e50b4',
    },
  },
  select: {
    paddingLeft: '5px',
    '& .MuiSelect-select.MuiSelect-select': {
      fontSize: '12px',
      '&:focus': {
        background: 'none',
      },
    },
  },
  dialogPaper: {
    width: '800px',
    height: '800px',
    maxWidth: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
    },
  },
  dialogContent: {
    width: '400px',
    height: '800px',

    maxWidth: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
    },
  },
  dialog: {},
  pageContent: {
    display: 'flex',
  },
  contentImage: {
    height: 600,
  },
  navigate: {
    padding: '10px',
    marginRight: '20px',
    '& span': {
      fontSize: '12px',
      marginRight: '20px',
    },
  },
  page: {
    fontSize: '12px',
    paddingRight: '5px',
  },
  container: {
    maxHeight: 440,
  },
  danger: {
    color: '#f94839',
  },
  active: {
    color: '#4caf50',
  },
  boxTable: {
    border: '1px none',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
  online: {
    color: '#4CAF50',
  },
  check: {
    color: '#4CAF50',
  },
  cancel: {
    color: '#ff3838',
  },
  done: {
    color: '#2dccff',
  },
  wait: {
    color: '#3788d8',
  },
  accepted: {
    color: '#56f000	',
  },
  processing: {
    color: '#ffb302',
  },
  textStatus: {
    fontSize: 10,
    fontWeight: 600,
  },
  bntAction: {
    display: 'flex',
    '& >div': {
      margin: theme.spacing(0, 1),
    },
  },
  imageListItem: {
    height: '100% !important',
  },
  imgItem: {
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  imgList: {
    height: '100% !important',
  },
  input: {
    display: 'none',
  },
  images: {
    position: 'relative',
  },
  img: {
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  close: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  inputImage: {
    '& input[type="file"]': {
      display: 'none !important',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  textFile: {
    fontSize: 12,
    paddingLeft: theme.spacing(2),
  },
  schedule: {
    display: 'flex',
    background: '#04a0de',
    padding: '8px 15px',
    width: 100,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
    '& >span': {
      marginLeft: theme.spacing(1),
      textTransform: 'capitalize',
      color: '#ffff',
    },
    '& >svg': {
      color: '#ffff',
    },
  },
  scheduled: {
    display: 'flex',
    width: 160,
    alignItems: 'center',
    '& >span': {
      marginLeft: theme.spacing(1),
    },
  },
}));
export { useStyles };
