import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  viewDetail: {
    textDecoration: 'none',
    color: '#ffff',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: 4,
    '& p': {
      marginTop: 2,
      marginBottom: 2,
      fontSize: 14,
    },
  },
  view: {
    '& svg': {
      color: '#ffff',
    },
  },
  textStatus: {
    fontSize: 12,
  },
  textViewDetail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      paddingRight: 8,
    },
  },
  schedule: {
    width: '100%',
    height: 60,
    borderRadius: 4,
    overflow: 'hidden',
    padding: 0,
    zIndex: 100,
    scrolbarWidth: 'none',
    msOverflowStyle: 'none',
    '&:hover': {
      overflow: 'auto',
    },

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));
export { useStyles };
