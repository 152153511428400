import * as types from '../constants';

export const initialState = {
  isRequesting: false,
  listUser: [],
  infoPage: {},
  isBlock: true,
};

const usersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_LIST_USER_REQUEST:
      return {
        ...state,
        listUser: [],
        isRequesting: true,
      };
    case types.GET_LIST_USER_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        listUser: action.payload.listData,
        infoPage: action.info,
      };
    case types.GET_LIST_USER_FAIL:
      return {
        ...state,
        isRequesting: false,
        listUser: [],
      };
    case types.BLOCK_USER_REQUEST:
      return {
        ...state,
        isBlock: true,
      };
    case types.BLOCK_USER_SUCCESS:
      return {
        ...state,
        isBlock: false,
      };

    default:
      return state;
  }
};

export default usersReducer;
