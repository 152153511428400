import { MenuItem } from '@material-ui/core';

import TextInput from 'src/components/InputFiled';

import { SelectForm, useStyles } from './styles';

export default function SelectOption(props) {
  const { options, label, value, onChangeOption, name, ...rest } = props;
  const classes = useStyles();
  return (
    <SelectForm variant="outlined" {...rest}>
      <TextInput
        name={name}
        select
        variant="outlined"
        label={label}
        value={value}
        onChange={onChangeOption}
        className={classes.menuItem}
        InputLabelProps={{
          shrink: true,
        }}
        {...rest}
      >
        {options?.map((item, index) => (
          <MenuItem value={item.id} key={index} className={classes.menuItem}>
            {item.name || item.payment_name}
          </MenuItem>
        ))}
      </TextInput>
    </SelectForm>
  );
}
