import produce from 'immer';
import { ActionType } from 'src/containers/App/interfaces';
import { ITransactionReducer } from '../../interface';
import * as types from '../constants';

export const initialState: ITransactionReducer = {
  listTransactions: [],
  total: 0,
  isRequesting: false,
};

const TransactionReducer = (
  state = initialState,
  { type, payload }: ActionType
) =>
  produce(state, (draft: ITransactionReducer) => {
    switch (type) {
      case types.GET_HISTORY_TRANSACTION_REQUEST:
        draft.isRequesting = true;
        break;
      case types.GET_HISTORY_TRANSACTION_SUCCESS:
        draft.isRequesting = false;
        draft.listTransactions = payload.data.data;
        break;
      case types.GET_HISTORY_TRANSACTION_FAIL:
        draft.isRequesting = false;
        break;
      default:
        break;
    }
  });

export default TransactionReducer;
