import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 12,
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 600,
  },

  action: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    fontSize: 16,
    padding: theme.spacing(1),
    '& > div:first-child': {
      '& >svg': {},
    },
    '& > div:nth-child(2)': {
      marginLeft: theme.spacing(1),
      fontWeight: 500,
      fontSize: 14,
    },
  },
  animation: {
    display: 'flex !important',
    padding: '0 16px !important',
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'none',
    },
    '& .MuiAccordionSummary-content': {
      display: 'block !important',
    },
    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
    },
  },
  detail: {
    display: 'block',
    padding: theme.spacing(0, 2),
    minHeight: 150
  },

  accordion: {
    borderRadius: '2px !important',
    marginBottom: theme.spacing(2),
  },
  active: {
    background: '#f5f6fc',
    fontWeight: 500,
    color: '#3e50b4',
    borderBottom: '2px solid #3e50b4',
    // padding: theme.spacing(1, 0),
  },
  iconAction: {
    display: 'flex',
    alignItems: 'center',
    '& >svg': {
      width: 20,
    },
  },
  toggleAction: {
    display: 'flex',
    alignItems: 'center',
  },
}));
export { useStyles };
