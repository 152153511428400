import styled from 'styled-components';
import {
  createStyles,
  makeStyles,
  TableCell,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  main: {
    minWidth: '100px',
  },
  Calender: {
    width: '100%',
    position: 'relative',
    paddingBottom: theme.spacing(2),
    '& >div>div': {
      fontSize: '12px',
      alignItems: 'center',
    },
    '& .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr': {
      '& >div>button': {
        color: 'black',
        background: '#ffff',
        marginLeft: '0px',
      },
      '& >div>div>button': {
        color: 'black',
        border: 'none',
        background: '#ffff',
      },
      '& >.fc-button-primary': {
        color: 'black',
        border: 'none',
        background: '#ffff',
      },
    },
  },
  tableContent: {
    marginTop: '10px',
  },
  item: {
    color: 'rgba(0, 0, 0, 0.54)',
    '&-main': {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '5px',
      '&__left': {
        width: '30%',
        textAlign: 'start',
        paddingRight: '10px',
        fontWeight: '500',
        fontSize: '12px',
        color: '#b0b0b0',
      },
      '&__right': {
        width: '70%',
        textAlign: 'start',
        fontWeight: '500',
        fontSize: '12px',
        wordWrap: 'break-word',
      },
    },
  },
  talenMain__item: {
    position: 'relative',
    marginTop: '5px',
    paddingLeft: '5px',
  },
  notify: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  notifyMain: {
    display: 'flex',
    marginLeft: '10px',
    fontSize: '12px',
  },
  circle: {
    width: '20px',
    height: '20px',
    borderRadius: '100%',
    marginLeft: '5px',
  },
  status: {},
  cancel: {
    backgroundColor: '#ff3838',
  },
  done: {
    backgroundColor: '#2dccff',
  },
  wait: {
    backgroundColor: '#3788d8',
  },
  accepted: {
    backgroundColor: '#56f000	',
  },
  processing: {
    backgroundColor: '#ffb302',
  },
  dialogContent: {
    '& >div': {
      width: '100% !important',
    },
  },
  wrapper: {
    position: 'relative',
    backgroundColor: '#3e50b4' , 
    padding: '4px 0px',
    fontWeight: 'normal',
    fontSize: 12,
  },
  deleteIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  contentInfo: {
    textAlign: 'center',
    textDecoration: 'none',
    color: '#ffff'
  }
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  margin: 0px;
  width: 100%;
  color: white;
  & p {
    margin: 0;
  }
`;

export { useStyles, StyledTableCell, StyledTableRow, IconWrapper };
