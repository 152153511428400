import { createSelector } from 'reselect';

import { initialState } from '../reducers';

const selectGlobal = (state: any) => state.withdraw || initialState;

const makeSelectWithDraw = () =>
  createSelector(selectGlobal, (state) => state.listWithdraw);

const makeSelectIsRequest = () =>
  createSelector(selectGlobal, (state) => state.isRequesting);

const makeSelectTotal = () =>
  createSelector(selectGlobal, (state) => state.total);

export { makeSelectWithDraw, makeSelectIsRequest, makeSelectTotal };
