import { Avatar, TablePagination } from '@material-ui/core';
import { Brightness1 as Brightness1Icon } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ButtonComponent from 'src/components/ButtonComponent';
import DialogComponent from 'src/components/DialogComponent';
import MuiTable from 'src/components/MuiTable';
import { ISelector } from 'src/reducers';
import { COLUMNS } from '../../contants';
import useBlockUser from '../../hooks/useBlockUser';
import { ITableUser } from '../../interface';
import BlockUser from '../BlockUser';
import { useStyles } from './styles';

function TableUser({
  page,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleChangePage,
}: ITableUser) {
  const classes = useStyles();

  const listUser = useSelector((s: ISelector) => s.users?.listUser || []);
  const infoPage = useSelector((s: ISelector) => s.users?.infoPage || {});
  const isRequesting = useSelector((s: ISelector) => s.partners?.isRequesting);

  const {
    userProfile,
    handleDeleteProfile,
    handleCloseDelete,
    handleBlockUser,
    handleChangeUnitBlock,
    untilBlock,
    messageError,
  } = useBlockUser();

  const row = (item) => {
    return {
      name: (
        <div>
          <Link to={`user/${item.id}`} className={classes.rowName}>
            <Avatar alt={item.name} src={item.avatar} />
            <div>{item.name || ''}</div>
          </Link>
        </div>
      ),
      role_id: (
        <div>
          <p>{item.role_id || ''}</p>
        </div>
      ),
      user_id: (
        <div>
          <p>{item.user_id || ''}</p>
        </div>
      ),
      role_name: (
        <div>
          <Link to={`user/${item.id}`}>{item.role_name || ''}</Link>
        </div>
      ),
      email: (
        <div>
          <Link to={`user/${item.id}`}>{item.email || ''}</Link>
        </div>
      ),
      city: (
        <div>
          <Link to={`user/${item.id}`}>{item.city_name || ''}</Link>
        </div>
      ),
      phone: (
        <div>
          <Link to={`user/${item.id}`}>{item.phone || ''}</Link>
        </div>
      ),
      date_of_birth: (
        <div>
          {item.date_of_birth && (
            <p>{moment(item.date_of_birth).format('YYYY-MM-DD') || ''}</p>
          )}
        </div>
      ),
      available_work_status: (
        <div>
          <p>{item.available_work_status ? 'Yes' : 'No' || ''}</p>
        </div>
      ),
      online_status: item.online_status ? (
        <div>
          <Brightness1Icon fontSize="small" className={classes.online} />
        </div>
      ) : (
        <div className={classes.online}>
          <p>Offline</p>
        </div>
      ),
      status: (
        <div>
          <p>
            {item.status === 1 ? 'Active' : item.status === 1 ? 'UnActive' : ''}
          </p>
        </div>
      ),
      block_until: (
        <div>
          <p>
            {item.block_until ? moment(item.block_until).format('LLLL') : ''}
          </p>
        </div>
      ),
      deleted_at: (
        <div>
          {item.deleted_at && (
            <p>{moment(item.deleted_at).format('YYYY-MM-DD') || ''}</p>
          )}
        </div>
      ),
      created_at: (
        <div>
          <p>{moment(item.created_at).format('YYYY-MM-DD') || ''}</p>
        </div>
      ),
      updated_at: (
        <div>
          <p>{moment(item.updated_at).format('YYYY-MM-DD') || ''}</p>
        </div>
      ),
      action: (
        <div>
          <ButtonComponent handleAction={() => handleDeleteProfile(item)}>
            Block
          </ButtonComponent>
        </div>
      ),
    };
  };

  const skeletonRowData = <Skeleton variant="rect" animation="wave" />;

  const rowError = {
    role_id: <div>{skeletonRowData}</div>,
    user_id: <div>{skeletonRowData}</div>,
    role_name: <div>{skeletonRowData}</div>,
    name: <div>{skeletonRowData}</div>,
    email: <div>{skeletonRowData}</div>,
    city: <div>{skeletonRowData}</div>,
    phone: <div>{skeletonRowData}</div>,
    date_of_birth: <div>{skeletonRowData}</div>,
    available_work_status: <div>{skeletonRowData}</div>,
    online_status: <div>{skeletonRowData}</div>,
    status: <div>{skeletonRowData}</div>,
    deleted_at: <div>{skeletonRowData}</div>,
    block_until: <div>{skeletonRowData}</div>,
    created_at: <div>{skeletonRowData}</div>,
    updated_at: <div>{skeletonRowData}</div>,
    action: <div>{skeletonRowData}</div>,
  };

  const renderRow = isRequesting
    ? new Array(10).fill(0).map((i) => rowError)
    : listUser.map(row);

  const pagination = (
    <div>
      <TablePagination
        className={classes.container}
        component="div"
        count={infoPage?.totalUsers || 0}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.boxTable}>
        <MuiTable headers={COLUMNS} rows={renderRow} pagination={pagination} />
      </div>
      <DialogComponent
        title={`Block User ${userProfile.data?.name!}`}
        open={userProfile.isOpen}
        onClose={handleCloseDelete}
      >
        <BlockUser
          data={userProfile.data}
          untilBlock={untilBlock}
          handleChangeUnitBlock={handleChangeUnitBlock}
          handleBlockUser={handleBlockUser}
          onClose={handleCloseDelete}
          messageError={messageError}
        />
      </DialogComponent>
    </div>
  );
}

export default TableUser;
