import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ISelector } from 'src/reducers';
import {
  createSurchargeRequest,
  getListCitiesRequestAction,
  getListSurchargeRequest,
  deleteSurchargeRequest,
  updateSurchargeRequest,
} from '../store/actions';
import {
  showModal,
  hideModal,
  showModalUpdate,
  hideModalUpdate,
} from 'src/containers/App/store/actions';
import { IFormikValueType } from '../interface';

const useViewSurcharge = (isFresh?: boolean) => {
  const dispatch = useDispatch();
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [idDelete, setIdDelete] = useState('');
  const [oldDataSurcharge, setOldDataSurcharge] = useState<IFormikValueType>();

  const isModalDelete = useSelector((s: ISelector) => s.global?.isModal);

  const isModalUpdate = useSelector((s: ISelector) => s.global?.isUpdate);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleToggleFormCreate = () => setIsOpenCreate((open) => !open);

  const handleCreateSurcharge = (data) => {
    dispatch(
      createSurchargeRequest({
        ...data,
        callback: () => handleToggleFormCreate(),
      })
    );
  };

  const handleOpenModalDeleteSurcharge = (id: string) => {
    dispatch(showModal());
    setIdDelete(id);
  };

  const handleOpenModalDelete = () => dispatch(showModal());
  const handleCloseModalDelete = () => dispatch(hideModal());

  const handleDeleteSurcharge = () =>
    dispatch(deleteSurchargeRequest(idDelete));

  const handleOpenModalUpdate = (data: IFormikValueType) => {
    dispatch(showModalUpdate());
    setOldDataSurcharge(data);
  };

  const handleCloseModalUpdate = () => dispatch(hideModalUpdate());

  const handleUpdateSurcharge = (data) =>
    dispatch(updateSurchargeRequest(data));

  useEffect(() => {
    dispatch(getListCitiesRequestAction());
  }, [dispatch]);

  useEffect(() => {
    const data = {
      page: page,
      rowsPerPage: rowsPerPage,
    };
    dispatch(getListSurchargeRequest(data));
  }, [dispatch, page, rowsPerPage, isFresh]);

  return {
    isOpenCreate,
    handleToggleFormCreate,
    handleCreateSurcharge,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    isModalDelete,
    handleDeleteSurcharge,
    handleOpenModalDeleteSurcharge,
    handleCloseModalDelete,
    handleOpenModalDelete,
    isModalUpdate,
    handleOpenModalUpdate,
    handleCloseModalUpdate,
    oldDataSurcharge,
    handleUpdateSurcharge,
  };
};

export default useViewSurcharge;
