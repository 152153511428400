import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  main: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
  },
  header: {
    '&__map ': {
      width: '100%',
      height: '500px',
    },
    '&__bottom': {
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 10px',
      '&-main': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  left: {
    textAlign: 'left',
    maxWidth: '100px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
  },
  box: {
    padding: '20px',
  },
  boxContent: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '5px',
    width: '100%',
    fontSize: '12px',
  },
  boxTitle: {
    width: '20%',
    textAlign: 'end',
    paddingRight: '10px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  boxValue: {
    width: '60%',
    textAlign: 'start',
  },
  btnMore: {
    marginTop: '20px',
    width: '100%',
    fontSize: '12px',
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  list: {
    margin: '10px',
    background: 'rgba(30, 116, 209, 0.05)',
    boxShadow: '1px 1px 8px 0 rgb(0 0 0 / 10%)',
  },
  mainHistory: {
    fontSize: '12px',
    alignItems: 'flex-start',
  },
  historyTitle: {
    fontWeight: 'bold',
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
  },
  historyDate: {
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
  },
  historyStatus: {
    margin: '0px',
  },
  date: {
    paddingLeft: '10px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  historyContent: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px',
  },
  historyRecipient: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px',
  },
  historyTimeBooking: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px',
  },
  icon__log: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#3e50b4',
    '& :hover': {
      cursor: 'pointer',
    },
    '& svg': {},
  },
  titleMain: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    margin: '10px 10px 0 10px',
  },
  box__icon: {
    width: '28px',
    height: '28px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginRight: '5px',
  },
  itemIcon: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#3e50b4',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  icon__redLight: {
    backgroundColor: '#f94839',
  },
  icon__greenLight: {
    backgroundColor: '#168449',
  },
  icon__blueLight: {
    backgroundColor: '#3e50b4',
  },
  icon: {
    minWidth: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageListItem: {
    height: '100% !important',
    '& .MuiImageListItem-item': {
      height: 350,
    },
    '& >div': {
      marginBottom: theme.spacing(2),
    },
  },
  imageList: {
    margin: '8px 0px !important',
  },
  images: {
    height: 350,
    width: '100%',
    objectFit: 'cover',
  },
  input: {
    display: 'none',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    '& >span': {
      boxShadow: 'none',
      border: '1px solid #a8a8a8',
      borderRadius: 2,
    },
  },
  textPhoto: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: theme.spacing(2),
  },
  btnUpload: {},
  boxBottom: {
    border: 'solid 1px #a8a8a8',
    height: 40,
    color: '#4caf50',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 15,
    fontWeight: 500
  },
  boxBlack: {
    color: '#1a1a1a'
  },
  gridImages: {
    padding: theme.spacing(2,0)
  }
}));

export { useStyles };
