import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
  },
  search: {
    display: 'flex',
    width: '100%',
    '&__box': {
      width: '100%',
      display: 'flex',
    },
  },
  box: {
    marginRight: '10px',
    marginBottom: '10px',
  },
  filter: {
    display: 'flex',
    justifyContent: 'end',
    flexWrap: 'wrap',
    marginRight: '10px',
    marginLeft: theme.spacing(3),
    '& >div>div>div>p': {
      display: 'none',
    },
    boxSizing: 'border-box',
  },
  formItem: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  btn: {
    width: '150px',
    marginRight: '10px',
    '& button': {
      width: '100%',
      height: 35,
    },
  },
  btnReset: {
    boxSizing: 'border-box',
    padding: '8px 10px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '12px',
  },
  btnApply: {
    boxSizing: 'border-box',
    padding: '8px 10px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '12px',
  },
  btnColor: {
    background: '#FC4A46',
    color: '#ffff',
  },
  gridLeft: {
    display: 'flex',
  },
}));
export { useStyles };
