import React, { useState } from 'react';
import {
  CardContent,
  Grid,
  Paper,
  Tabs,
  Tab,
  Typography,
  Box,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LaunchIcon from '@material-ui/icons/Launch';

import Button from 'src/components/Button';
import TextInput from 'src/components/InputFiled';
import ToggleComponent from 'src/components/ToggleComponent';
import * as BookingAction from 'src/containers/Booking/store/actions';
import { ISelector } from 'src/reducers';
import { IInfoBookingRecipient } from '../../interface';
import PartnerReceive from '../PartnerReceive';

import { useStyles } from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
}

interface LinkTabProps {
  label?: string;
  href?: string;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, className } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={className}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

function InfoBookingRecipient({
  infoRecipient,
  search,
  handleSearch,
}: IInfoBookingRecipient) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const listPartners = useSelector(
    (s: ISelector) => s.partners?.listPartners || []
  );
  const bookingDetail = useSelector((s: ISelector) => s.booking?.bookingDetail);
  const partnerReceive = useSelector(
    (s: ISelector) => s.booking?.partnerReceive
  );
  const partnerSkip = useSelector((s: ISelector) => s.booking?.partnerSkip);
  const isRequesting = useSelector((s: ISelector) => s.booking?.isRequesting);

  const [selectTab, setSelectTab] = React.useState(0);
  const [selectedOptions, setSelectedOptions] = useState<any>();
  const handleChangeTest = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSelectTab(newValue);
  };

  const handleChange = (_, value) => setSelectedOptions(value);

  const handleUpdateBooking = () => {
    dispatch(
      BookingAction.assignPartnerPageRequest({
        booking_id: bookingDetail.id,
        profile_id: selectedOptions.id,
      })
    );
  };

  const EditComponent = () => (
    <div className={classes.editBooking}>
      <Autocomplete
        className={classes.autoComplete}
        options={listPartners}
        onChange={handleChange}
        getOptionLabel={(option) => option.name}
        renderOption={(option) => (
          <span>
            {option.name} ({option.id})
          </span>
        )}
        renderInput={(params) => (
          <TextInput
            {...params}
            label="Assign to"
            type="text"
            value={search?.username || ''}
            variant="outlined"
            onChange={handleSearch}
            name="username"
          />
        )}
      />
      <Button onClick={handleUpdateBooking}>Assign</Button>
    </div>
  );

  const InfoComponent = () => (
    <CardContent>
      <Link
        to={`/partner/${bookingDetail.recipient_profile_id}`}
        className={classes.view}
      >
        <span>View more</span>
        <LaunchIcon fontSize="small" />
      </Link>

      <Grid container spacing={3}>
        {infoRecipient.map((item: any, index: number) => (
          <Grid item md={4} xs={12} key={index}>
            <TextInput
              label={item.title}
              type="text"
              value={item.content}
              variant="outlined"
            />
          </Grid>
        ))}
      </Grid>
    </CardContent>
  );

  return (
    <ToggleComponent title="Recipient">
      {!bookingDetail.recipientData ? EditComponent() : InfoComponent()}

      {(bookingDetail?.status === 1 || bookingDetail?.status === 0) && (
        <>
          <Paper square>
            <Tabs
              value={selectTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChangeTest}
              aria-label="disabled tabs example"
              className={classes.tabs}
            >
              <Tab
                className={classes.tab}
                label={`Receive (${partnerReceive.length})`}
              />
              <Tab
                className={classes.tab}
                label={`Skip (${partnerSkip.length})`}
              />
            </Tabs>
          </Paper>

          <TabPanel className={classes.tabPanel} value={selectTab} index={0}>
            <PartnerReceive isRequesting={isRequesting} list={partnerReceive} />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={selectTab} index={1}>
            <PartnerReceive isRequesting={isRequesting} list={partnerSkip} />
          </TabPanel>
        </>
      )}
    </ToggleComponent>
  );
}
export default InfoBookingRecipient;
