export const GET_PAYMENT_METHOD_REQUEST = 'instalent/GET_PAYMENT_METHOD_REQUEST';
export const GET_PAYMENT_METHOD_SUCCESS = 'instalent/GET_PAYMENT_METHOD_SUCCESS';
export const GET_PAYMENT_METHOD_FAIL = 'instalent/GET_PAYMENT_METHOD_FAIL';

export const GET_ALL_SERVICE_REQUEST = 'instalent/GET_ALL_SERVICE_REQUEST';
export const GET_ALL_SERVICE_SUCCESS = 'instalent/GET_ALL_SERVICE_SUCCESS';
export const GET_ALL_SERVICE_FAIL = 'instalent/GET_ALL_SERVICE_FAIL';

export const GET_LIST_USER_REQUEST = 'instalent/GET_LIST_USER_REQUEST';
export const GET_LIST_USER_SUCCESS = 'instalent/GET_LIST_USER_SUCCESS';
export const GET_LIST_USER_FAIL = 'instalent/GET_LIST_USER_FAIL';

export const DELETE_RESTRICTABLE_STATE = 'instalent/DELETE_RESTRICTABLE_STATE';

export const ADD_COUPON_REQUEST = 'instalent/ADD_COUPON_REQUEST';
export const ADD_COUPON_SUCCESS = 'instalent/ADD_COUPON_SUCCESS';
export const ADD_COUPON_FAIL = 'instalent/ADD_COUPON_FAIL';

export const GET_ALL_COUPON_REQUEST = 'instalent/GET_ALL_COUPON_REQUEST';
export const GET_ALL_COUPON_SUCCESS = 'instalent/GET_ALL_COUPON_SUCCESS';
export const GET_ALL_COUPON_FAIL = 'instalent/GET_ALL_COUPON_FAIL';

export const DELETE_COUPON_REQUEST = 'instalent/DELETE_COUPON_REQUEST';
export const DELETE_COUPON_SUCCESS = 'instalent/DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAIL = 'instalent/DELETE_COUPON_FAIL';

export const UPDATE_COUPON_REQUEST = 'instalent/UPDATE_COUPON_REQUEST';
export const UPDATE_COUPON_SUCCESS = 'instalent/UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_FAIL = 'instalent/UPDATE_COUPON_FAIL';
