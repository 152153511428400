import { useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import S3 from 'react-aws-s3';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import * as ServiceRequestAction from '../../store/actions';
import { config } from 'src/definitions/app';
import { ISelector } from 'src/reducers';
import { IDetailProfile } from '../../interface';

import { useStyles } from './styles';

interface IModalUploadImages {
  title: string;
  detail: IDetailProfile | any;
}

function ModalUploadImages({ title, detail }: IModalUploadImages) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isAddProof = useSelector(
    (s: ISelector) => s.serviceProvider?.isAddProof
  );

  const fileInput = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [images, setImages] = useState<Array<string>>([]);

  const [validateImages, setValidateImages] = useState<string>('');

  const handleClick = (event) => {
    event.preventDefault();
    let newArr: any = fileInput.current.files;
    if (newArr.length > 3) {
      setValidateImages('The number of images should be less than 4');
    } else {
      for (let i = 0; i < newArr.length; i++) {
        let file = newArr[i];
        let newFileName = file.name.replace(/\..+$/, '');
        const ReactS3Client = new S3(config);
        ReactS3Client.uploadFile(file, newFileName).then((data) => {
          if (data.status === 204) {
            setImages((images) => [...images, data.location]);
          }
        });
      }
    }
  };

  const handleUpload = () => {
    dispatch(
      ServiceRequestAction.addProofRequest({
        profile_id: detail.profile_id,
        urlImages: images,
        service_provider_id: detail.id,
      })
    );
  };

  const handleClose = () => dispatch(ServiceRequestAction.closeModalAdd());

  const deleteFile = (e) =>
    setImages(images.filter((item, index) => index !== e));

  return (
    <Dialog
      open={isAddProof || false}
      onClose={handleClose}
      classes={{ paper: classes.dialogContent }}
    >
      <div>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.contentImage}>
          <label>
            <input
              ref={fileInput}
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              onChange={handleClick}
              multiple
              type="file"
            />
            <label className={classes.label} htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
                component="span"
              >
                Choose Files
              </Button>
              <div className={classes.textFile}>
                {images.length > 0 && `${images.length} files `}
              </div>
            </label>
            <br />

            <div>{validateImages}</div>
          </label>
          <br />
          <div>
            {images.map((item, index) => (
              <div className={classes.images} key={index}>
                <img className={classes.img} src={item} alt="" />
                <div
                  className={classes.close}
                  onClick={() => deleteFile(index)}
                >
                  <CloseIcon />
                </div>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleUpload}
            type="submit"
            variant="contained"
            color="primary"
          >
            Upload
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ModalUploadImages;
