export const IconCollapse = ({ width, height }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 16 16'
  >
    <path
      fill='rgba(0,0,0,0.87)'
      d='M10.722 20.5v-1.778H7.531l4-4-1.253-1.253-4 4v-3.191H4.5V20.5h6.222m4-8.969l4-4v3.191H20.5V4.5h-6.222v1.778h3.191l-4 4z'
      transform='translate(-4.5 -4.5)'
    />
  </svg>
);

export const IconExpand = ({ width = '10px', height = '10px', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 13 13'
    {...props}
  >
    <path
      id='arrow-collapse'
      fill='rgba(0,0,0,0.87)'
      d='M16.63 4.635l-3.289 3.289V5.3h-1.462v5.117H17V8.955h-2.629l3.289-3.289-1.03-1.031M5.3 11.879v1.462h2.624L4.635 16.63l1.031 1.03 3.289-3.289V17h1.462v-5.121z'
      transform='translate(-4.635 -4.635)'
    />
  </svg>
);

export const IconPen = ({ width = '10px', height = '10px', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 17.876 17.876'
    {...props}
  >
    <path
      fill='currentColor'
      d='M17.386 4.964l-1.61 1.609a.42.42 0 0 1-.593 0L11.307 2.7a.42.42 0 0 1 0-.593L12.916.495a1.679 1.679 0 0 1 2.371 0l2.1 2.1a1.673 1.673 0 0 1-.001 2.369zM9.924 3.487L.755 12.656.015 16.9a.839.839 0 0 0 .971.971l4.242-.744L14.4 7.956a.42.42 0 0 0 0-.593l-3.879-3.876a.424.424 0 0 0-.6 0zm-5.59 8.383a.486.486 0 0 1 0-.691L9.711 5.8a.489.489 0 0 1 .691.691L5.025 11.87a.486.486 0 0 1-.691 0zm-1.26 2.937H4.75v1.267l-2.25.395-1.088-1.086.394-2.252h1.268z'
      transform='translate(-0.002 -0.005)'
    />
  </svg>
);

export const IconClose = ({ width = '10px', height = '10px', ...props }) => (
  <svg
    enableBackground='new 0 0 413.348 413.348'
    width={width}
    height={height}
    viewBox='0 0 413.348 413.348'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill='currentColor'
      d='m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z'
    />
  </svg>
);
