import { IListServiceView } from 'src/containers/Services/interface';
import TableStaff from '../components/TableStaff';

import { useStyles } from './styles';

function StaffUserView(props: IListServiceView) {
  const classes = useStyles();
  return (
    <div className={classes.deviceContainer}>
      <TableStaff {...props} />
    </div>
  );
}
export default StaffUserView;
