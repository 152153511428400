import { useStyles } from "./styles";

interface Props {
  [data: string]: any;
}

export default function HomeView(props: Props) {
  const classes = useStyles();

  return <div className={classes.deviceContainer}></div>;
}
