import { AuthenticatedRoute } from '../components/routes';
import WithDrawPartner from '../containers/WithDraw_Partner';
const BookingRoutes = [
  {
    path: '/withdraw',
    title: 'WithDraw',
    component: WithDrawPartner,
    route: AuthenticatedRoute,
    roles: [],
    permission: [],
    exact: true,
  },
];

export default BookingRoutes;
