import { gql } from '@apollo/client';
import moment from 'moment';

import { client } from 'src';

export const getApiUsers = async (data) => {
  const { page, rowsPerPage, filter } = data;

  const users = await client.query({
    query: gql`
      query {
        users(page: ${page} , per_page: ${rowsPerPage}, filter : { name: "${filter.name}" , email: "${filter.email}", phone : "${filter.phone}" , role_id: 2})
        {
          data {
            role_id
            user_id
            avatar
            id
            role_name
            name
            city_name
            email
            gender
            date_of_birth
            online_status
            available_work_status
            status
            phone
            created_at
            updated_at
            deleted_at
            block_until
          }
          page
          next_page
          per_page,
          totalUsers
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
  return users;
};

export const blockUserService = async ({ profileId, block_until }) => {
  return await client.mutate({
    mutation: gql`
      mutation {
        blockUser(profileId: ${profileId}, block_until: "${moment(block_until)
      .utc()
      .toISOString()}")
      }
    `,
  });
};
