import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useStyles } from './styles';

interface IDialogMui {
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  children: React.ReactNode;
  title: string;
}

function DialogComponent({ title, open, onClose, children }: IDialogMui) {
  const classes = useStyles();

  return (
    <Dialog
      open={open || false}
      onClose={onClose}
      classes={{ paper: classes.dialogContent }}
    >
      <DialogTitle className={classes.title}>{title} </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default DialogComponent;
