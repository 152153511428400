export const GET_LIST_SERVICE_PROVIDER_REQUEST =
  'instalent/GET_LIST_SERVICE_PROVIDER_REQUEST';
export const GET_LIST_SERVICE_PROVIDER_SUCCESS =
  'instalent/GET_LIST_SERVICE_PROVIDER_SUCCESS';
export const GET_LIST_SERVICE_PROVIDER_FAIL =
  'instalent/ GET_LIST_SERVICE_PROVIDER_FAIL';

export const GET_SCHEDULE_INTERVIEW_REQUEST =
  'instalent/GET_SCHEDULE_INTERVIEW_REQUEST';
export const GET_SCHEDULE_INTERVIEW_SUCCESS =
  'instalent/GET_SCHEDULE_INTERVIEW_SUCCESS';
export const GET_SCHEDULE_INTERVIEW_FAIL =
  'instalent/ GET_LIST_SERVICE_PROVIDER_FAIL';

export const UPDATE_PROVIDER_REQUEST = 'instalent/UPDATE_PROVIDER_REQUEST';
export const UPDATE_PROVIDER_SUCCESS = 'instalent/UPDATE_PROVIDER_SUCCESS';
export const UPDATE_PROVIDER_FAIL = 'instalent/ UPDATE_PROVIDER_FAIL';

export const ADD_PROOF_REQUEST = 'instalent/ADD_PROOF_REQUEST';
export const ADD_PROOF_SUCCESS = 'instalent/ADD_PROOF_SUCCESS';
export const ADD_PROOF_FAIL = 'instalent/ ADD_PROOF_FAIL';

export const ADD_SCHEDULE_REQUEST = 'instalent/ADD_SCHEDULE_REQUEST';
export const ADD_SCHEDULE_SUCCESS = 'instalent/ADD_SCHEDULE_SUCCESS';
export const ADD_SCHEDULE_FAIL = 'instalent/ADD_SCHEDULE_FAIL';

export const CLOSE_MODAL_ADD_PROOF = 'CLOSE_MODAL_ADD_PROOF';
export const OPEN_MODAL_ADD_PROOF = 'OPEN_MODAL_ADD_PROOF';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';

export const CLOSE_MODAL_SCHEDULE = 'CLOSE_MODAL_SCHEDULE';
export const OPEN_MODAL_SCHEDULE = 'OPEN_MODAL_SCHEDULE';

export const SHOW_MODAL_SCHEDULE_DETAIL = 'SHOW_MODAL_SCHEDULE_DETAIL';
export const HIDE_MODAL_SCHEDULE_DETAIL = 'HIDE_MODAL_SCHEDULE_DETAIL';

export const GET_SOCIAL_NETWORK_REQUEST =
  'instalent/GET_SOCIAL_NETWORK_REQUEST';
export const GET_SOCIAL_NETWORK_SUCCESS =
  'instalent/GET_SOCIAL_NETWORK_SUCCESS';
export const GET_SOCIAL_NETWORK_FAIL = 'instalent/ GET_SOCIAL_NETWORK_FAIL';

export const UPDATE_STATUS_REVIEW_SOCIAL_NETWORK_REQUEST =
  'instalent/UPDATE_STATUS_REVIEW_SOCIAL_NETWORK_REQUEST';
export const UPDATE_STATUS_REVIEW_SOCIAL_NETWORK_SUCCESS =
  'instalent/UPDATE_STATUS_REVIEW_SOCIAL_NETWORK_SUCCESS';
export const UPDATE_STATUS_REVIEW_SOCIAL_NETWORK_FAIL =
  'instalent/ UPDATE_STATUS_REVIEW_SOCIAL_NETWORK_FAIL';
