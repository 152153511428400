import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardContent: {},
  cardHeader: {
    paddingLeft: 0,
  },
  main: {},
  cancel: {
    color: '#ff3838',
  },
  done: {
    color: '#2dccff',
  },
  wait: {
    color: '#3788d8',
  },
  accepted: {
    color: '#56f000	',
  },
  processing: {
    color: '#ffb302',
  },
  formGroup: {
    display: 'block',
    textAlign: 'center',
  },
  input: {},
  status: {},
  statusLeft: {
    display: 'flex',
    alignItems: 'center',
    '& >button': {
      marginTop: theme.spacing(2),
    },
  },
  save: {
    width: 200,
    background: '#3e50b4',
    borderRadius: '2px !important',
    color: '#ffff',
  },
  btn: {
    width: 75,
    borderRadius: '2px !important',
    background: '#ffff',
  },
  select: {
    width: '100%',
    height: 35,
    borderRadius: '2px !important',
    '& .MuiSelect-root': {
      fontSize: 14,
      padding: theme.spacing(2, 1.75),
    },
    '& $notchedOutline': {
      borderColor: 'green',
    },
    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor: 'initial',
      },
    },
  },
  formControl: {
    width: '100%',
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#999999',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {},
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {},
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black',
    },
  },
  btnSave: {
    display: 'flex',
    justifyContent: 'end',
  },
  ss: {},
}));

export { useStyles };
