import NewPartnerContainer from 'src/containers/NewPartners';
import { AuthenticatedRoute } from '../components/routes';

const listNewPartnerRoutes = [
  {
    path: '/new-partners',
    title: 'New Partners',
    component: NewPartnerContainer,
    route: AuthenticatedRoute,
    roles: [],
    permission: [],
    exact: true,
  },
];


export default listNewPartnerRoutes;