import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  deviceContainer: {
    width: '100%',
    marginTop: theme.spacing(0.5),
  },
  tabPanel: {
    '& div' : {
      padding: '2px 0px',
    }
  }
}));

export { useStyles };
