import { Button, TablePagination } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ButtonComponent from 'src/components/ButtonComponent';
import DialogComponent from 'src/components/DialogComponent';
import MuiTable from 'src/components/MuiTable';
import { ISelector } from 'src/reducers';
import {
  COLUMNS,
  COLUMNS_PROCESSING,
  COLUMNS_WAITING,
  STATUS_SERVICE_REQUEST,
} from '../../contants';
import useLoadingButton from '../../hook/useLoadingButton';
import {
  IColumn,
  IDetailProfile,
  IParamHandle,
  ITableUser,
} from '../../interface';
import * as ServiceRequestAction from '../../store/actions';
import Certificate from '../ChildrenComponent/Certificate';
import Interview from '../ChildrenComponent/Interview';
import InterviewDetail from '../ChildrenComponent/InterviewDetail';
import { MenuActionServiceRequest } from '../MenuActionServiceRequest';
import ModalUploadImages from './ModalUploadImages';

import { useStyles } from './styles';

function TableView({
  page,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleChangePage,
  handleEditListProvider,
}: // rowSelecteds,
  // handleSelectAllClick,
  // handleClick,
  ITableUser) {
  const {
    listServiceProvider,
    isScheduleDetail,
    ScheduleDetail,
    isSchedule,
    isOpenModal,
    isRequesting,
    isAction,
  } = useSelector((s: ISelector) => s.serviceProvider);
  const { dataLoading, handleChangeLoading, isLoading } =
    useLoadingButton(isAction);
  const classes = useStyles();
  const objStatus = {
    0: 'WAITING_FOR_PROCESS',
    1: 'APPROVED',
    2: 'DECLINED',
    3: 'PROCESSING',
  };
  const objClasses = {
    0: classes.wait,
    1: classes.accepted,
    2: classes.cancel,
  };

  const dispatch = useDispatch();

  const [detail, setDetail] = useState<IDetailProfile | any>({});
  const [dataAction, setDataAction] = useState<IParamHandle>({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    { id, status, service_id, url_images }: IParamHandle
  ) => {
    setAnchorEl(event.currentTarget);
    setDataAction({ id, status, service_id, url_images });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModalCertificate = () =>
    dispatch(ServiceRequestAction.closeModal());

  const handleApprovedServiceRequest = () => {
    handleEditListProvider({ ...dataAction, status: 'APPROVED' });
    dataAction &&
      handleChangeLoading({ id: dataAction.id, status: dataAction?.status });
    handleClose();
  };

  const handleDeclinedServiceRequest = () => {
    handleEditListProvider({ ...dataAction, status: 'DECLINED' });
    handleChangeLoading({ id: dataAction?.id, status: dataAction?.status });
    handleClose();
  };

  const infoPage = useSelector((s: ISelector) => s.serviceProvider?.infoPage);

  const handleGetDetail = (data) => () => {
    setDetail(data);
    dispatch(ServiceRequestAction.openModal());
  };

  const handleOpenSchedule = (data) => () => {
    setDetail(data);
    dispatch(ServiceRequestAction.openModalSchedule());
  };

  const handleOpenScheduleDetail = (id) => () => {
    dispatch(ServiceRequestAction.getScheduleInterviewAction(id));
    dispatch(ServiceRequestAction.showModalScheduleDetail());
  };

  const handleCloseModalSchedule = () =>
    dispatch(ServiceRequestAction.closeModalSchedule());
  const handleHideModalScheduleDetail = () =>
    dispatch(ServiceRequestAction.hideModalScheduleDetail());

  const getProfileRequest = (id: string) => {
    dispatch(ServiceRequestAction.getSocialNetworkAction(id));
  };

  const handleOpenModalUpload = (item) => () => {
    dispatch(ServiceRequestAction.openModalAdd());
    setDetail(item);
  };

  const row = (item) => {
    return {
      // id: item.id,
      id: (
        <div>
          <p>{item.id || ''}</p>
        </div>
      ),
      partner: (
        <div>
          <Link to={`/partner/${item.profile_id}`}>
            {item.profile?.name || ''}
          </Link>
        </div>
      ),
      name: (
        <div>
          <p>{item.services?.name || ''}</p>
        </div>
      ),
      status: (
        <div>
          <p className={`${classes.textStatus} ${objClasses[item.status]}`}>
            {objStatus[item.status] || ''}
          </p>
        </div>
      ),
      city: (
        <div>
          <p>{item.profile?.city_name || ''}</p>
        </div>
      ),
      created_at: (
        <div>
          <p>{moment(item.created_at).format('YYYY-MM-DD') || ''}</p>
        </div>
      ),
      updated_at: (
        <div>
          <p>{moment(item.updated_at).format('YYYY-MM-DD') || ''}</p>
        </div>
      ),
      author: (
        <div>
          <p>{item.admin?.name || ''}</p>
        </div>
      ),
      images: (
        <div>
          <div className={classes.bntAction}>
            <ButtonComponent
              status="origin"
              handleAction={handleGetDetail(item)}
            >
              Certificate
            </ButtonComponent>
            <ButtonComponent
              handleAction={handleOpenModalUpload(item)}
              status="origin"
            >
              Proof
            </ButtonComponent>
          </div>
        </div>
      ),
      action: (
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(value) =>
              handleClick(value, {
                id: item.id,
                service_id: item.service_id,
                url_images: item.images?.map((item) => item.url),
              })
            }
          >
            <MoreVertIcon />
          </Button>

          <MenuActionServiceRequest
            handleActionApprove={handleApprovedServiceRequest}
            handleActionDecline={handleDeclinedServiceRequest}
            item={item}
            isLoading={isLoading}
            dataLoading={dataLoading}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          />
        </div>
      ),
    };
  };

  const rowProcessing = (item) => {
    return {
      // id: item.id,
      id: (
        <div>
          <p>{item.id || ''}</p>
        </div>
      ),
      partner: (
        <div>
          <Link to={`/partner/${item.profile_id}`}>
            {item.profile?.name || ''}
          </Link>
        </div>
      ),
      name: (
        <div>
          <p>{item.services?.name || ''}</p>
        </div>
      ),
      status: (
        <div>
          <p className={`${classes.textStatus} ${objClasses[item.status]}`}>
            {objStatus[item.status] || ''}
          </p>
        </div>
      ),
      city: (
        <div>
          <p>{item.profile?.city_name || ''}</p>
        </div>
      ),
      created_at: (
        <div>
          <p>{moment(item.created_at).format('YYYY-MM-DD') || ''}</p>
        </div>
      ),
      updated_at: (
        <div>
          <p>{moment(item.updated_at).format('YYYY-MM-DD') || ''}</p>
        </div>
      ),
      author: (
        <div>
          <p>{item.admin?.name || ''}</p>
        </div>
      ),
      images: (
        <div>
          <div className={classes.bntAction}>
            <ButtonComponent
              handleAction={handleOpenModalUpload(item)}
              status="origin"
            >
              Proof
            </ButtonComponent>
            <ButtonComponent
              status="origin"
              handleAction={handleGetDetail(item)}
            >
              Certificate
            </ButtonComponent>
          </div>
        </div>
      ),
      schedule: (
        <div>
          <div
            className={classes.schedule}
            onClick={handleOpenScheduleDetail(item.id)}
          >
            <EventIcon fontSize="small" />
            <span>Detail </span>
          </div>
        </div>
      ),
      action: (
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(value) =>
              handleClick(value, {
                id: item.id,
                service_id: item.service_id,
                url_images: item.images?.map((item) => item.url),
              })
            }
          >
            <MoreVertIcon />
          </Button>

          <MenuActionServiceRequest
            handleActionApprove={handleApprovedServiceRequest}
            handleActionDecline={handleDeclinedServiceRequest}
            item={item}
            isLoading={isLoading}
            dataLoading={dataLoading}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          />
        </div>
      ),
    };
  };

  const rowWaiting = (item) => {
    return {
      id: (
        <div>
          <p>{item.id || ''}</p>
        </div>
      ),
      partner: (
        <div>
          <Link to={`/partner/${item.profile_id}`}>
            {item.profile?.name || ''}
          </Link>
        </div>
      ),
      name: (
        <div>
          <p>{item.services?.name || ''}</p>
        </div>
      ),
      status: (
        <div>
          <p className={`${classes.textStatus} ${objClasses[item.status]}`}>
            {objStatus[item.status] || ''}
          </p>
        </div>
      ),
      city: (
        <div>
          <p>{item.profile?.city_name || ''}</p>
        </div>
      ),
      created_at: (
        <div>
          <p>{moment(item.created_at).format('YYYY-MM-DD') || ''}</p>
        </div>
      ),
      updated_at: (
        <div>
          <p>{moment(item.updated_at).format('YYYY-MM-DD') || ''}</p>
        </div>
      ),
      images: (
        <div>
          <div className={classes.bntAction}>
            <ButtonComponent
              status="origin"
              handleAction={handleGetDetail(item)}
            >
              Certificate
            </ButtonComponent>
            <ButtonComponent
              handleAction={handleOpenModalUpload(item)}
              status="origin"
            >
              Proof
            </ButtonComponent>
          </div>
        </div>
      ),
      schedule: (
        <div>
          <div className={classes.schedule} onClick={handleOpenSchedule(item)}>
            <EventIcon fontSize="small" />
            <span>schedule </span>
          </div>
        </div>
      ),
      action: (
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(value) =>
              handleClick(value, {
                id: item.id,
                service_id: item.service_id,
                url_images: item.images?.map((item) => item.url),
              })
            }
          >
            <MoreVertIcon />
          </Button>

          <MenuActionServiceRequest
            handleActionApprove={handleApprovedServiceRequest}
            handleActionDecline={handleDeclinedServiceRequest}
            item={item}
            isLoading={isLoading}
            dataLoading={dataLoading}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          />
        </div>
      ),
    };
  };

  const skeletonRowData = (
    <div>
      <Skeleton variant="rect" animation="wave" />
    </div>
  );

  const formatData = (keys) => {
    const obj = {};
    keys.forEach((key) => (obj[key] = skeletonRowData));
    return obj;
  };

  const rowErrorWaiting = formatData([
    'id',
    'partner',
    'name',
    'status',
    'city',
    'created_at',
    'updated_at',
    'images',
    'schedule',
    'action',
  ]);

  const rowError = formatData([
    'id',
    'partner',
    'name',
    'status',
    'city',
    'created_at',
    'updated_at',
    'author',
    'images',
    'action',
  ]);

  const rowErrorProcessing = formatData([
    'id',
    'partner',
    'name',
    'status',
    'city',
    'created_at',
    'updated_at',
    'images',
    'schedule',
    'action',
  ]);

  const statusCase = listServiceProvider && +listServiceProvider[0]?.status;

  let rowTable: Array<any> = listServiceProvider?.map(row);

  let columnHeader: Array<IColumn> = COLUMNS;

  let waitingRow = rowError;

  switch (statusCase) {
    case STATUS_SERVICE_REQUEST.WAITING_FOR_PROCESS:
      rowTable = listServiceProvider?.map(rowWaiting);
      columnHeader = COLUMNS_WAITING;
      waitingRow = rowErrorWaiting;
      break;
    case STATUS_SERVICE_REQUEST.APPROVED:
      rowTable = listServiceProvider?.map(row);
      columnHeader = COLUMNS;
      waitingRow = rowError;
      break;
    case STATUS_SERVICE_REQUEST.DECLINED:
      rowTable = listServiceProvider?.map(rowWaiting);
      columnHeader = COLUMNS_WAITING;
      waitingRow = rowErrorWaiting;
      break;
    case STATUS_SERVICE_REQUEST.PROCESSING:
      rowTable = listServiceProvider?.map(rowProcessing);
      columnHeader = COLUMNS_PROCESSING;
      waitingRow = rowErrorProcessing;
      break;
    default:
      break;
  }

  const renderRow = isRequesting
    ? new Array(10).fill(0).map((i) => waitingRow)
    : rowTable;

  const pagination = (
    <div>
      <TablePagination
        className={classes.container}
        component="div"
        count={infoPage?.total || 0}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );

  return (
    <div className={classes.root}>
      <ModalUploadImages title="Upload Images" detail={detail} />
      <DialogComponent
        title="Certificate"
        open={isOpenModal}
        onClose={handleCloseModalCertificate}
      >
        <Certificate getProfileRequest={getProfileRequest} detail={detail} />
      </DialogComponent>

      <DialogComponent
        title="Schedule Interview"
        open={isSchedule}
        onClose={handleCloseModalSchedule}
      >
        <Interview
          detail={detail}
          action={ServiceRequestAction.addScheduleInterviewRequest}
        />
      </DialogComponent>

      <DialogComponent
        title="Schedule Interview Detail"
        open={isScheduleDetail}
        onClose={handleHideModalScheduleDetail}
      >
        <InterviewDetail ScheduleDetail={ScheduleDetail} />
      </DialogComponent>

      <div className={classes.boxTable}>
        <MuiTable
          // onChecked={handleClick}
          // rowSelecteds={rowSelecteds}
          // onSelectedAll={handleSelectAllClick}
          // isCheckBox
          headers={columnHeader}
          rows={renderRow}
          pagination={pagination}
        />
      </div>
    </div>
  );
}

export default TableView;
