import { gql } from '@apollo/client';
import { client } from 'src';

export const getServiceAreasService = async (data) => {
  return await client.query({
    query: gql`
    query {
      serviceArea(page: ${data.page}, per_page: ${data.rowsPerPage}) {
        data {
          id
          service_id
          services {
            name
          }
          city_id
          city_name
          price
        }
        total
      }
    }
    `,
    fetchPolicy: 'network-only',
  });
};

export const deleteServiceAreasService = async (id) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      deleteServiceAreaById (id: ${id})
    }
    `,
  });
};

export const getCitiesID = async () => {
  const data = {
    take: 85,
    skip: 0,
  };
  return await client.query({
    query: gql`
      query {
        cities(take: ${data.take}, skip: ${data.skip}) {
          id
          name
        }
      }
    `,
  });
};

export const createServiceAreaService = async (data) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      createServiceArea( data: {
        service_id: ${data.service_id}, city_id: ${data.city_id},price: ${data.price}
      }) 
    }
    `,
  });
};

export const updateServiceAreaService = async (params) => {
  return await client.mutate({
    mutation: gql`
      mutation {
        updateServiceAreaById(
          id: ${params.id}
          data: { service_id: ${params.serviceValue}, city_id: ${params.cityValue.id}, price: ${params.price} }
        )
      }
    `,
  });
};
