import * as Yup from 'yup';

export const COLUMNS = [
  {
    title: 'ID',
    field: 'id',
  },
  {
    title: 'Name Service',
    field: 'service',
  },
  {
    title: 'City Name',
    field: 'city_name',
  },
  {
    title: 'Price (vnd)',
    field: 'price',
  },
  {
    title: 'Action',
    field: 'action',
  },
];

export const validateCreate = Yup.object().shape({
  price: Yup.string().required('Required'),
  city_id: Yup.string().required('Required'),
  service_id: Yup.string().required('Required'),
});

export const validateUpdate = Yup.object().shape({
  price: Yup.string().required('Required'),
  cityValue: Yup.object().required('Required'),
  serviceValue: Yup.string().required('Required'),
});