import { IParamAddService } from '../../interfaces';
import * as types from '../constants';

export const getPaymentMethodRequest = () => {
  return {
    type: types.GET_PAYMENT_METHOD_REQUEST,
  };
};

export const getPaymentMethodSuccess = (data: any) => {
  return {
    type: types.GET_PAYMENT_METHOD_SUCCESS,
    payload: { data },
  };
};

export const getPaymentMethodFailed = (error: any) => {
  return {
    type: types.GET_PAYMENT_METHOD_FAIL,
    payload: error,
  };
};

export const getAllServiceRequest = () => {
  return {
    type: types.GET_ALL_SERVICE_REQUEST,
  };
};

export const getAllServiceSuccess = (data: any) => {
  return {
    type: types.GET_ALL_SERVICE_SUCCESS,
    payload: { data },
  };
};

export const getAllServiceFailed = (error: any) => {
  return {
    type: types.GET_ALL_SERVICE_FAIL,
    payload: error,
  };
};

export const getListUserRequest = (data) => {
  return {
    type: types.GET_LIST_USER_REQUEST,
    payload: { data },
  };
};

export const getListUserSuccess = (data: any) => {
  return {
    type: types.GET_LIST_USER_SUCCESS,
    payload: { data },
  };
};

export const getListUserFailed = (error: any) => {
  return {
    type: types.GET_LIST_USER_FAIL,
    payload: error,
  };
};

export const deleteStateRestrict = () => {
  return {
    type: types.DELETE_RESTRICTABLE_STATE,
  };
};

export const addCouponRequest = (data: IParamAddService) => {
  return {
    type: types.ADD_COUPON_REQUEST,
    payload: data,
  };
};

export const addCouponSuccess = () => {
  return {
    type: types.ADD_COUPON_SUCCESS,
  };
};

export const addCouponFailed = (error: any) => {
  return {
    type: types.ADD_COUPON_FAIL,
    payload: error,
  };
};

export const getListCouponRequest = (data) => {
  return {
    type: types.GET_ALL_COUPON_REQUEST,
    payload: { data },
  };
};

export const getListCouponSuccess = (data: any) => {
  return {
    type: types.GET_ALL_COUPON_SUCCESS,
    payload: { data },
  };
};

export const getListCouponFailed = (error: any) => {
  return {
    type: types.GET_ALL_COUPON_FAIL,
    payload: error,
  };
};

export const deleteCouponRequest = (data: any) => {
  return {
    type: types.DELETE_COUPON_REQUEST,
    payload: data,
  };
};

export const deleteCouponSuccess = (data) => {
  return {
    type: types.DELETE_COUPON_SUCCESS,
    payload: data,
  };
};

export const deleteCouponFailed = (error: any) => {
  return {
    type: types.DELETE_COUPON_FAIL,
    payload: error,
  };
};

export const updateCouponRequest = (data: any) => {
  return {
    type: types.UPDATE_COUPON_REQUEST,
    payload: data,
  };
};

export const updateCouponSuccess = (data) => {
  return {
    type: types.UPDATE_COUPON_SUCCESS,
    payload: data,
  };
};

export const updateCouponFailed = (error: any) => {
  return {
    type: types.UPDATE_COUPON_FAIL,
    payload: error,
  };
};
