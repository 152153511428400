import { Fragment } from 'react';
import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import propTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';

import {
  TableSortLabelStyle,
  TableHeadStyle,
  useStyles,
  TableCellStyle,
  TableFooterStyle,
  CheckBoxStyle,
} from './styles';

interface Headers {
  title: string;
  field: string;
}
interface Props {
  headers: Headers[];
  [data: string]: any;
}
interface RowProps {
  [data: string]: any;
}

export default function MuiTable(props: Props) {
  const {
    headers,
    rows,
    detailPanel,
    onRowClick,
    pagination,
    selectedId,
    isCheckBox,
    onSelectedAll,
    rowSelecteds = [],
    onChecked,
    isLoading,
    tableClass = '',
    rowSum,
    order,
    orderBy,
    onRequestSort,
    isSorting = false,
    isBorder = false,
    borderRight = false,
    isPointer = false,
    className,
  } = props;
  const classes = useStyles();

  const themeMode = localStorage.getItem('instalent-them-mode');


  const onSelectedAllClick = (e: any) => {
    // e.preventDefault();
    e.stopPropagation();
    !isLoading && onSelectedAll(e);
  };

  const createSortHandler = (property: string | number) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <Paper
      className={clsx(classes.paper, className, { [classes.border]: isBorder })}
    >
      <TableContainer
        className={`${classes.container} ${isBorder && classes.borderBottom}`}
      >
        <Table
          aria-label="collapsible table"
          stickyHeader={true}
          className={tableClass}
        >
          <TableHeadStyle>
            <TableRow className={clsx(classes.tableRow, [{
              tableRowDark: themeMode === 'dark',
              tableRowLight: themeMode === 'light'
            }])}>
              {isCheckBox && (
                <TableCell padding="checkbox" className={classes.checkbox}>
                  <CheckBoxStyle
                    checked={rowSelecteds.length === rows.length}
                    onClick={onSelectedAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                    size="small"
                  />
                </TableCell>
              )}
              {headers &&
                headers.map((col: Headers, index: number) => (
                  <TableCellStyle
                    key={index}
                    // width={col.width}
                    sortDirection={orderBy === col.field ? order : false}
                    className={borderRight ? classes.borderRight : ''}
                  >
                    {isSorting ? (
                      <div className={classes.label}>
                        <TableSortLabelStyle
                          active={orderBy === col.field}
                          direction={orderBy === col.field ? order : 'asc'}
                          onClick={createSortHandler(col.field)}
                          className={classes.arrowIcon}
                        >
                          {col.title}
                        </TableSortLabelStyle>{' '}
                      </div>
                    ) : (
                      <>{col.title}</>
                    )}
                  </TableCellStyle>
                ))}
            </TableRow>
          </TableHeadStyle>
          <TableBody>
            {rows && rows.length > 0 ? (
              rows.map((row: any, index: number) => (
                <Row
                  row={row}
                  headers={headers}
                  detailPanel={detailPanel}
                  onRowClick={onRowClick}
                  isActive={row.id === selectedId}
                  key={index}
                  isCheckBox={isCheckBox}
                  rowSelecteds={rowSelecteds}
                  onChecked={onChecked}
                  isLoading={isLoading}
                  selectedId={selectedId}
                  borderRight={borderRight}
                  isPointer={isPointer}
                />
              ))
            ) : (
              <>
                <TableRow>
                  <TableCell colSpan={15} className={classes.empty}>
                    No result available
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
          <TableFooterStyle>
            <TableRow>{rowSum && rowSum.props.children}</TableRow>
          </TableFooterStyle>
        </Table>
      </TableContainer>
      <div className={classes.pagination}>
        {pagination && pagination.props.children}
      </div>
    </Paper>
  );
}

function Row(props: RowProps) {
  const classes = useStyles();
  const {
    headers,
    row,
    detailPanel,
    onRowClick,
    isActive,
    isCheckBox,
    rowSelecteds,
    onChecked,
    isLoading,
    borderRight,
    isPointer,
  } = props;
  const themeMode = localStorage.getItem('instalent-them-mode');

  const handleRowClick = (event: any) => {
    onRowClick && !isLoading && onRowClick({ event, row });
  };

  const onCheckRow = (e: any) => {
    e.stopPropagation();
    onChecked(row.id);
  };

  return (
    <Fragment>
      <TableRow
        onClick={handleRowClick}
        className={`${classes.rowTable} ${themeMode === 'dark' ? classes.rowDark : classes.rowLight} ${row.rowStatus && row.rowStatus} ${
          row.isRowBorderLeft && classes.rowSelected
        } ${isPointer && classes.cursorPointer} ${
           themeMode === 'dark' && classes.light
        }`}
      >
        {isCheckBox && (
          <TableCell
            padding="checkbox"
            className={`${classes.checkbox} ${classes.fixedCheckbox}`}
          >
            {isLoading ? (
              <Skeleton
                variant="rect"
                animation="wave"
                width={14}
                height={14}
                style={{ marginLeft: 5 }}
              />
            ) : (
              <CheckBoxStyle
                checked={rowSelecteds.includes(row.id)}
                onClick={onCheckRow}
                size="small"
              />
            )}
          </TableCell>
        )}
        {headers.map((col: any, index: number) => (
          <TableCellStyle
            align="left"
            key={index}
            className={clsx({
              [classes.borderRight]: borderRight,
              [classes.dark]:
                row.rowStatus !== 'rejected' && themeMode === 'dark',
            })}
          >
            {row[col.field].props.children}
          </TableCellStyle>
        ))}
      </TableRow>
      {detailPanel && (
        <TableRow>
          <TableCellStyle
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={12}
          >
            <Collapse
              in={isActive}
              timeout="auto"
              unmountOnExit
              disableStrictModeCompat={true}
            >
              <Box margin={1}>{detailPanel.props.children}</Box>
            </Collapse>
          </TableCellStyle>
        </TableRow>
      )}
    </Fragment>
  );
}

MuiTable.propTypes = {
  headers: propTypes.array,
  rows: propTypes.array,
  detailPanel: propTypes.object,
  onRowClick: propTypes.func,
  isCheckBox: propTypes.bool,
  pagination: propTypes.object,
  selectedId: propTypes.oneOfType([propTypes.number, propTypes.string]),
  onSelectedAll: propTypes.func,
  orderBy: propTypes.string,
  onRequestSort: propTypes.func,
  order: propTypes.string,
  isSorting: propTypes.bool,
  isBorder: propTypes.bool,
  borderRight: propTypes.bool,
  isPointer: propTypes.bool,
};
