export const DISCOUNT_UNIT = [
  {
    name: 'Percentage',
    id: 'percentage',
  },
  {
    name: 'Currency',
    id: 'currency',
  },
];

export const DISCOUNT_UNIT_EDIT = [
  {
    name: 'Percentage',
    id: 'percentage',
    value: 1,
  },
  {
    name: 'Currency',
    id: 'currency',
    value: 0,
  },
];

export const PAYMENT_METHOD = [
  {
    name: 'Cash',
    id: 'cash',
  },
  {
    name: 'Wallet',
    id: 'wallet',
  },
  {
    name: 'Bank',
    id: 'bank',
  },
  {
    name: 'All Payment Method',
    id: 'all',
  },
];

export const RESTRICTABLE_TYPE = [
  {
    name: 'SERVICE',
    id: 'service',
  },
  {
    name: 'PROFILE',
    id: 'profile',
  },
  {
    name: 'PAYMENT METHOD',
    id: 'payment_method',
  },
  {
    name: 'NONE',
    id: 'none',
  },
  {
    name: 'NEW_USER',
    id: 'new_user',
  },
];

export const couponData = (value: any) => [
  {
    label: 'Valid Before',
    name: 'valid_before',
    type: 'datetime-local',
    value: value.valid_before,
  },
  {
    label: 'Quantity',
    name: 'minimum_order_value',
    type: 'number',
    value: value.minimum_order_value,
  },
];

export const COLUM_NAME = [
  {
    title: 'ID',
    field: 'id',
  },
  {
    title: 'Coupon Code',
    field: 'coupon_code',
  },
  {
    title: 'Discount Value',
    field: 'discount_value',
  },
  {
    title: 'Discount Unit',
    field: 'discount_unit',
  },
  {
    title: 'Expired Day',
    field: 'valid_before',
  },
  {
    title: 'Restrictable Type',
    field: 'restrictable_type',
  },
  {
    title: 'Service',
    field: 'service',
  },
  {
    title: 'Amount',
    field: 'minimum_order_value',
  },
  {
    title: 'Maximum Discount Amount',
    field: 'maximum_discount_amount',
  },
  {
    title: 'Created at',
    field: 'create_at',
  },
  {
    title: 'Updated at',
    field: 'updated_at',
  },
  {
    title: 'Action',
    field: 'action',
  },
];
