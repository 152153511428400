import { isArray, isEmpty } from 'lodash';
import moment from 'moment';

import { IUserInfo, IUser } from '../interface';

export const profileUser = (userDetail: IUser) => {
  return [
    { title: 'Name', content: userDetail.name || '' },
    { title: 'Email', content: userDetail.email || '' },
    { title: 'Gender', content: userDetail.gender || '' },
    { title: 'Phone', content: `${userDetail.phone || ''}` },
    { title: 'Day of Birth', content: userDetail.date_of_birth || '' },
    {
      title: 'Status',
      content: userDetail?.status === 1 ? 'Active' : 'UnActive',
    },
    {
      title: 'Created at',
      content: userDetail.created_at
        ? moment(userDetail.created_at).format('DD-MM-YYYY')
        : '',
    },
    {
      title: 'Updated at',
      content: userDetail.updated_at
        ? moment(userDetail.updated_at).format('DD-MM-YYYY')
        : '',
    },
  ];
};


export const ROLES = { ADMIN: 1, USER: 2, PARTNER: 3 };

export const getProfileUser = (data: Array<IUserInfo>) => {
  return (data && data.find((item) => item.role_id === ROLES.USER)) || {};
};

export const objStatusBooking = {
  0: 'CANCELED',
  1: 'WAITING TO BE PROCESSED',
  2: 'DONE',
  3: 'ACCEPTED',
  4: 'PROCESSING',
};

export const memorizeUsers = (data: Array<any>) => {
  if (isEmpty(data) || !isArray(data))
    return {
      users: {},
      userIds: [],
    };

  return data.reduce(
    (result, item) => {
      result.users[item.id] = item;
      result.userIds.push(item.id);
      return result;
    },
    {
      users: {},
      userIds: [],
    }
  );
};

export const objClasses = {
  0: '#9c3e3c',
  1: '#3788d8',
  2: '#2dccff',
  3: '#48792c	',
  4: '#ffb302',
};

export const objStatus = {
  0: 'CANCELED',
  1: 'WAITING',
  2: 'DONE',
  3: 'ACCEPTED',
  4: 'PROCESSING',
};

export const notify = [
  {
    title: 'CANCELED',
    status: 'cancel',
  },
  {
    title: 'WAITING',
    status: 'wait',
  },
  {
    title: 'DONE',
    status: 'done',
  },
  {
    title: 'ACCEPTED',
    status: 'accepted',
  },
  {
    title: 'PROCESSING',
    status: 'processing',
  },
];
