import { IGetTransaction } from '../../interface';
import * as types from '../constants';

export const getHistoryTransactionRequest = function (params: IGetTransaction) {
  return {
    type: types.GET_HISTORY_TRANSACTION_REQUEST,
    payload: params,
  };
};

export const getHistoryTransactionSuccess = (data) => {
  return {
    type: types.GET_HISTORY_TRANSACTION_SUCCESS,
    payload: { data },
  };
};

export const getHistoryTransactionFail = (error) => {
  return {
    type: types.GET_HISTORY_TRANSACTION_FAIL,
    payload: error,
  };
};
