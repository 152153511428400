export const COLUMNS = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Email',
    field: 'email',
  },
  {
    title: 'Phone',
    field: 'phone',
  },
  {
    title: 'City',
    field: 'city',
  },
  {
    title: 'Available Work Status',
    field: 'available_work_status',
  },
  {
    title: 'Block until',
    field: 'block_until',
  },
  {
    title: 'Online Status',
    field: 'online_status',
  },
  {
    title: 'Account Balance',
    field: 'accountBalance',
  },
  {
    title: 'Account Revenue',
    field: 'accountRevenue',
  },
  {
    title: 'Service',
    field: 'service',
  },
  {
    title: 'Action',
    field: 'action',
  },
];

export const dataFieldPartner = (data) => [
  {
    name: 'email',
    label: 'Email',
    value: data.email,
  },
  {
    name: 'username',
    label: 'Name',
    value: data.username,
  },

  {
    name: 'phone',
    label: 'Phone',
    value: data.phone,
  },
];
