import {
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useFormik } from 'formik';
import moment from 'moment';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';

import ToggleComponent from 'src/components/ToggleComponent';
import TextInput from 'src/components/InputFiled';
import MapLocation from 'src/components/MapLocation';
import { ParamTypes } from '../../interface';
import {
  infoDetailDescription,
  statusBooking,
  newStatusBooking,
  statusConvert,
  bookingCancel,
} from '../../contants';
import * as BookingAction from '../../store/actions';
import ButtonComponent from 'src/components/ButtonComponent';
import { ISelector } from 'src/reducers';

import { useStyles } from './styles';

const validate = Yup.object().shape({
  qty: Yup.number().required('Required'),
  booking_time: Yup.string().required('Required'),
});

function InfoBookingDetail() {
  const classes = useStyles();
  const { id } = useParams<ParamTypes>();
  const { cancelBooking, bookingDetail, services, isUpdate, partnerReceive } =
    useSelector((s: ISelector) => s.booking);

  const dispatch = useDispatch();

  const pathData = [
    {
      latitude: +bookingDetail?.recipientData?.location?.coordinates[1] || 0,
      longitude: +bookingDetail?.recipientData?.location?.coordinates[0] || 0,
    },
    {
      latitude: +bookingDetail?.location?.coordinates[1] || 0,
      longitude: +bookingDetail?.location?.coordinates[0] || 0,
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      service_id: bookingDetail?.service_id || 0,
      booking_time: bookingDetail?.booking_time || '',
      qty: bookingDetail?.qty || '',
      request_profile_id: bookingDetail?.request_profile_id || '',
      recipient_profile_id: bookingDetail?.recipient_profile_id || '',
      full_address: bookingDetail?.full_address || '',
    },
    validationSchema: validate,
    onSubmit: (values) => {
      dispatch(BookingAction.updateBookingRequest({ id, data: values }));
    },
  });

  const formStatus = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: bookingDetail?.status || 0,
    },
    onSubmit: (values) => {
      if (values.status !== bookingDetail?.status) {
        dispatch(
          BookingAction.updateStatusBookingRequest({
            id,
            status: statusConvert(values.status),
          })
        );
      }
    },
  });

  const positionPartner = {
    lat: +bookingDetail?.location?.coordinates[1] || 0,
    lng: +bookingDetail?.location?.coordinates[0] || 0,
  };

  return (
    <ToggleComponent title="Booking Detail">
      <CardContent className={classes.cardContent}>
        <CardHeader
          className={classes.cardHeader}
          avatar={<NotificationsActiveIcon />}
          title="Partner received notifications"
          subheader={partnerReceive.length}
        />
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <div className={classes.status}>
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel>Service</InputLabel>
                  <Select
                    value={formik.values.service_id}
                    onChange={formik.handleChange}
                    label="Service"
                    className={classes.select}
                    name="service_id"
                  >
                    {services?.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextInput
                label="Booking Time"
                value={moment(formik.values.booking_time).format(
                  'YYYY-MM-DDThh:mm'
                )}
                name="booking_time"
                variant="outlined"
                onChange={formik.handleChange}
                type="datetime-local"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextInput
                label="Quantity"
                type="text"
                value={formik.values.qty}
                name="qty"
                variant="outlined"
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item md={12} xs={12} className={classes.btnSave}>
              <ButtonComponent isLoading={isUpdate}>Save</ButtonComponent>
            </Grid>
          </Grid>
        </form>

        <form onSubmit={formStatus.handleSubmit} className={classes.status}>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <FormControl className={classes.formControl} variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  value={formStatus.values.status}
                  onChange={formStatus.handleChange}
                  label="Status"
                  className={classes.select}
                  name="status"
                >
                  {formStatus.values.status === 1
                    ? statusBooking.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    : newStatusBooking.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <ButtonComponent>Update Status</ButtonComponent>
            </Grid>
          </Grid>
        </form>
        {bookingDetail && bookingDetail?.status === 0 && (
          <Grid container spacing={3}>
            {bookingCancel(cancelBooking).map((item: any, index: number) => (
              <Grid item md={4} xs={12} key={index}>
                <TextInput
                  label={item.title}
                  value={item.content}
                  name={item.name}
                  variant="outlined"
                />
              </Grid>
            ))}
          </Grid>
        )}

        <br />
        <br />
        <br />
        <Grid container spacing={3}>
          {infoDetailDescription(bookingDetail).map(
            (item: any, index: number) => (
              <Grid item md={4} xs={12} key={index}>
                <TextInput
                  label={item.title}
                  value={item.content}
                  name={item.name}
                  variant="outlined"
                />
              </Grid>
            )
          )}
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <MapLocation
              icon={<LocationOnOutlinedIcon />}
              path={pathData}
              positionPartner={positionPartner}
              bookingDetail={bookingDetail}
              partnerReceive={partnerReceive}
            />
          </Grid>
        </Grid>
      </CardContent>
    </ToggleComponent>
  );
}
export default InfoBookingDetail;
