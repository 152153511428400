// Dependencies
import { StylesProvider } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import { compose } from "redux";
import { connect } from "react-redux";
import { Fragment } from "react";
import { createStructuredSelector } from "reselect";

import { useInjectReducer } from 'src/utils/injectReducer';
import snackBarReducer from 'src/containers/Snackbar/store/reducers';
import routes from "../../routes";
import { makeSelectLoading } from "./store/selectors";
import Snackbar from "../Snackbar";

// App component
function App() {

  useInjectReducer({ key: 'snack', reducer: snackBarReducer });

  return (
    <Fragment>
      <Helmet
        titleTemplate="%s | Instalent BackOffice"
        defaultTitle="Dashboard | Instalent BackOffice"
      ></Helmet>
      <StylesProvider injectFirst>{routes}</StylesProvider>
      <Snackbar />
    </Fragment>
  );
}

const mapStateToProps = createStructuredSelector({
  isLoading: makeSelectLoading(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(App);
