export const COLUMS = [
  {
    title: 'ID',
    field: 'id',
  },
  {
    title: 'Partner',
    field: 'partner',
  },
  {
    title: 'Amount',
    field: 'amount',
  },
  {
    title: 'Status',
    field: 'status',
  },
  {
    title: 'Bank Account Verified',
    field: 'verified',
  },
  {
    title: 'Action',
    field: 'action',
  },
];

export const statusWithdraw = {
  open: 'OPEN',
  inprocessing: 'INPROCESSING',
  approved: 'APPROVED',
  rejected: 'REJECTED',
};

export const statusData = [
  {
    label: 'OPEN',
    value: 'OPEN',
  },
  {
    label: 'INPROCESSING',
    value: 'INPROCESSING',
  },
  {
    label: 'APPROVED',
    value: 'APPROVED',
  },
  {
    label: 'REJECTED',
    value: 'REJECTED',
  },
];
