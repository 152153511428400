import { makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLoadScript } from '@react-google-maps/api';
import TextInput from 'src/components/InputFiled';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

export interface IPropPlace {
  handleSelected: any;
  defaultValue?: any;
}

export default function Places({ handleSelected, defaultValue }: IPropPlace) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_KEY_MAP || '',
    libraries: ['places'],
  });

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <PlacesAutocomplete
      handleSelected={handleSelected}
      defaultValue={defaultValue}
    />
  );
}

const PlacesAutocomplete = ({ handleSelected, defaultValue }) => {
  const {
    value,
    setValue,
    suggestions: { data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const classes = useStyles();

  const defaultValueCity: any = {
    description: defaultValue?.address,
  };

  const handleSelect = async (_, data) => {
    const address = data?.description;

    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    handleSelected({
      location: { lat, lng },
      full_address: address,
    });
  };

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <Autocomplete
      options={data}
      autoHighlight
      className={classes.autocompleteContainer}
      onChange={handleSelect}
      getOptionLabel={(option: any) => option?.description}
      renderOption={(option) => <span>{option.description}</span>}
      defaultValue={defaultValueCity}
      renderInput={(params) => (
        <TextInput
          {...params}
          label="Choose address"
          variant="outlined"
          required
          value={value}
          onChange={handleChange}
          fullWidth
          inputProps={{
            ...params.inputProps,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};

const useStyles = makeStyles(() => ({
  autocompleteContainer: {
    '& >div>div>div>input': {
      padding: '0px !important',
    },
  },
}));
