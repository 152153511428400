/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';

import { useInjectReducer } from 'src/utils/injectReducer';
import ListPartnerView from './views';
import { useInjectSaga } from 'src/utils/injectSaga';
import saga from '../ListPartner/store/sagas';
import reducer from './store/reducers';
import sagaBooking from '../Booking/store/sagas';
import reducerBooking from '../Booking/store/reducers';
import * as PartnersAction from './store/actions/index';
import * as BookingActions from '../Booking/store/actions';
import { IParams, ISearchPartner } from './interface';
import useQueryLocation from 'src/hooks/useQueryLocation';
import sagaUser from '../ListUsersContainer/store/sagas';
import reducerUser from '../ListUsersContainer/store/reducers';
import { ISelector } from 'src/reducers';

function ListPartnerContainer() {
  useInjectReducer({ key: 'partners', reducer });
  useInjectSaga({ key: 'partners', saga });
  useInjectReducer({ key: 'booking', reducer: reducerBooking });
  useInjectSaga({ key: 'booking', saga: sagaBooking });
  useInjectReducer({ key: 'users', reducer: reducerUser });
  useInjectSaga({ key: 'users', saga: sagaUser });

  const isBlock = useSelector((s: ISelector) => s.users?.isBlock);

  const location = useLocation();
  const history = useHistory();
  let query = useQueryLocation();
  let params = useRef<IParams>();

  const dispatch = useDispatch();

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [search, setSearch] = useState<ISearchPartner>({
    email: '',
    username: '',
    phone: '',
    serviceId: 0,
  });

  const handleSearch = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const onResetFilter = () => {
    setSearch({
      email: '',
      username: '',
      phone: '',
      serviceId: 0,
    });
    params.current = {
      page: 1,
      rowsPerPage: 10,
      filter: {
        email: '',
        username: '',
        phone: '',
        serviceId: 0,
      },
    };
    history.push(`/partners`);
    dispatch(PartnersAction.getListPartnersRequestAction(params.current));
  };

  const handleSubmitFilter = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    params.current = {
      rowsPerPage: rowsPerPage,
      page: page,
      filter: {
        username: search.username,
        phone: search.phone,
        email: search.email,
        serviceId: search.serviceId,
      },
    };
    dispatch(PartnersAction.getListPartnersRequestAction(params.current));
    const paramsString = queryString.stringify(params.current.filter);

    history.push(`/partners?${paramsString}`);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };

  useEffect(() => {
    if (location.search) {
      const username = query.get('username');
      const phone = query.get('phone');
      const email = query.get('email');
      const serviceId = query.get('serviceId');

      params.current = {
        page: page,
        rowsPerPage: rowsPerPage,
        filter: {
          username: username || search.username,
          phone: phone || search.phone,
          email: email || search.email,
          serviceId: serviceId || search.serviceId,
        },
      };
    } else {
      params.current = {
        page: page,
        rowsPerPage: rowsPerPage,
        filter: {
          username: search.username,
          phone: search.phone,
          email: search.email,
          serviceId: search.serviceId,
        },
      };
    }

    dispatch(PartnersAction.getListPartnersRequestAction(params.current));
  }, [dispatch, rowsPerPage, page, isBlock]);

  useEffect(() => {
    dispatch(BookingActions.getServiceRequest());
  }, [dispatch]);

  return (
    <ListPartnerView
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      search={search}
      onResetFilter={onResetFilter}
      handleSubmitFilter={handleSubmitFilter}
      handleSearch={handleSearch}
    />
  );
}

export default ListPartnerContainer;
