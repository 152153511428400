import * as types from "../constants";

export const generateThingNameRequest = (data: any) => {
  return {
    type: types.GENERATE_THING_NAME_REQUEST,
    payload: { data },
  };
};

export const generateThingNameSuccess = (data: any) => {
  return {
    type: types.GENERATE_THING_NAME_SUCCESS,
    payload: { data },
  };
};

export const generateThingNameFail = (data: any) => {
  return {
    type: types.GENERATE_THING_NAME_FAIL,
    payload: { data },
  };
};
