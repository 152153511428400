export const GET_LIST_SURCHARGE_REQUEST =
  'instalent/GET_LIST_SURCHARGE_REQUEST';
export const GET_LIST_SURCHARGE_SUCCESS =
  'instalent/GET_LIST_SURCHARGE_SUCCESS';
export const GET_LIST_SURCHARGE_FAIL = 'instalent/GET_LIST_SURCHARGE_FAIL';

export const CREATE_SURCHARGE_REQUEST = 'instalent/CREATE_SURCHARGE_REQUEST';
export const CREATE_SURCHARGE_SUCCESS = 'instalent/CREATE_SURCHARGE_SUCCESS';
export const CREATE_SURCHARGE_FAIL = 'instalent/CREATE_SURCHARGE_FAIL';

export const UPDATE_SURCHARGE_REQUEST = 'instalent/UPDATE_SURCHARGE_REQUEST';
export const UPDATE_SURCHARGE_SUCCESS = 'instalent/UPDATE_SURCHARGE_SUCCESS';
export const UPDATE_SURCHARGE_FAIL = 'instalent/UPDATE_SURCHARGE_FAIL';

export const DELETE_SURCHARGE_REQUEST = 'instalent/DELETE_SURCHARGE_REQUEST';
export const DELETE_SURCHARGE_SUCCESS = 'instalent/DELETE_SURCHARGE_SUCCESS';
export const DELETE_SURCHARGE_FAIL = 'instalent/DELETE_SURCHARGE_FAIL';

export const GET_CITIES_REQUEST = 'instalent/GET_CITIES_REQUEST';
export const GET_CITIES_SUCCESS = 'instalent/GET_CITIES_SUCCESS';
export const GET_CITIES_FAIL = 'instalent/GET_LIST_SURCHARGE_FAIL';
