import { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import { useSelector } from 'react-redux';
import { Rating } from '@material-ui/lab';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { ISelector } from 'src/reducers';
import ToggleComponent from 'src/components/ToggleComponent';
import ConfirmDialog from './ConfirmDialog';
// import DialogSchedule from './DialogSchedule';
import { objClasses } from 'src/containers/User/contants';
import { IConfirmDialog } from 'src/containers/User/interface';
import { objStatus } from 'src/containers/ServiceRequest/contants';

import { useStyles, IconWrapper } from './styles';

function Schedule() {
  const classes = useStyles();

  const historyBooking = useSelector(
    (s: ISelector) => s.user?.historyBooking || []
  );
  // const historyBookingData = useSelector(
  //   (s: ISelector) => s.user?.historyBookingData || []
  // );
  // const isModal = useSelector((s: ISelector) => s.global.isModal);

  // const dispatch = useDispatch();

  // const [detail, setDetail] = useState<IDetail | any>({});
  const [confirmDialog, setConfirmDialog] = useState<IConfirmDialog>({
    isOpen: false,
    onConfirm: '',
  });

  const dataBooking = historyBooking.map((obj) => ({
    ...obj,
    date: obj.booking_time,
    title: obj.service?.name,
    backgroundColor: objClasses[obj.status],
  }));

  // const handleClose = () => dispatch(AppAction.hideModal());

  const renderEventContent = (eventInfo) => {
    // const handleDateSelect = (selectInfo) => () => {
    //   dispatch(AppAction.showModal());
    //   const id = selectInfo.event._def.publicId;
    //   setDetail(historyBookingData[id]);
    // };
    return (
      <IconWrapper
        className={classes.wrapper}
        style={{
          backgroundColor: `${
            objClasses[eventInfo.event.extendedProps.status]
          }`,
        }}
      >
        <Link
          to={`/booking/${eventInfo.event._def.publicId}`}
          // onClick={handleDateSelect(eventInfo)}
          className={classes.contentInfo}
        >
          <p>
            {moment(eventInfo.event._def.extendedProps.booking_time).format(
              'LT'
            )}
          </p>
          <p>{eventInfo.event.title}</p>
          <div>
            {eventInfo.event._def.extendedProps.review.length > 0 && (
              <Rating
                size="small"
                readOnly
                value={eventInfo.event._def.extendedProps?.review[0].start}
              />
            )}
          </div>
          <div
            style={{ fontSize: 10, fontWeight: 'bold' }}
          >
            {objStatus[eventInfo.event.extendedProps.status]}
          </div>
        </Link>
      </IconWrapper>
    );
  };

  return (
    <ToggleComponent title="Schedule Partner">
      <div className={classes.Calender}>
        {/* <DialogSchedule
          open={isModal}
          handleClose={handleClose}
          detail={detail}
        /> */}
        <FullCalendar
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'prevYear nextYear',
          }}
          // editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          plugins={[dayGridPlugin, interactionPlugin]}
          eventContent={renderEventContent}
          events={dataBooking}
        />
        {/* {renderSidebar()} */}
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </ToggleComponent>
  );
}

export default Schedule;
