import moment from 'moment';
import { useSelector } from 'react-redux';
import MuiTable from 'src/components/MuiTable';
import ToggleComponent from 'src/components/ToggleComponent';
import { IBankAccount } from 'src/containers/User/interface';
import { ISelector } from 'src/reducers';

export const COLUMS = [
  {
    title: 'ID',
    field: 'id',
  },
  {
    title: 'Bank Account',
    field: 'bank_account',
  },
  {
    title: 'Status',
    field: 'status',
  },
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Full name',
    field: 'fullname',
  },
  {
    title: 'Created at',
    field: 'created_at',
  },
  {
    title: 'Updated at',
    field: 'updated_at',
  },
];

const BankAccount = () => {
  const { bank_account } = useSelector((s: ISelector) => s.user?.userDetail);

  const row = (item: IBankAccount) => {
    return {
      id: (
        <div>
          <p>{item?.id}</p>
        </div>
      ),
      bank_account: (
        <div>
          <p>{item?.bank_account}</p>
        </div>
      ),
      status: (
        <div>
          <p>{item?.status ? 'True' : 'False'}</p>
        </div>
      ),
      name: (
        <div>
          <p>{item?.payment_unit?.name}</p>
        </div>
      ),
      fullname: (
        <div>
          <p>{item?.payment_unit?.fullname}</p>
        </div>
      ),
      created_at: (
        <div>
          <p>{moment(item?.created_at).format('YYYY-MM-DD') || ''}</p>
        </div>
      ),
      updated_at: (
        <div>
          <p>{moment(item?.updated_at).format('YYYY-MM-DD') || ''}</p>
        </div>
      ),
    };
  };

  const renderRow = bank_account?.map(row);

  return (
    <ToggleComponent title="Bank Account">
      <MuiTable headers={COLUMS} rows={renderRow} />
    </ToggleComponent>
  );
};

export default BankAccount;
