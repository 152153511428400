import {
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import {
  IDataSchedule,
  IDetailProfile,
  IScheduleDetail,
} from '../../interface';
import { dataSchedule } from '../../contants';

import { useStyles } from './styles';

const validate = Yup.object().shape({
  type: Yup.number().required('Required'),
  schedule: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
});

interface IModalInterview {
  detail: IDetailProfile;
  action: (data: IScheduleDetail | IScheduleDetail) => void;
}

function Interview({ detail, action }: IModalInterview) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: 0,
      schedule: '',
      description: '',
      address: '',
    },
    validationSchema: validate,
    onSubmit: (values) => {
      dispatch(
        action({
          id: detail.id,
          service_id: detail.services.id,
          service_providers_id: detail.id,
          address: values.address,
          schedule: values.schedule,
          type: values.type,
          description: values.description,
        })
      );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.formContent}>
        <FormControl className={classes.formControl}>
          <InputLabel>Interview</InputLabel>
          <Select
            native
            value={formik.values.type}
            onChange={formik.handleChange}
            label='Interview'
            name='type'
            inputProps={{
              name: 'type',
            }}
            error={formik.touched.type && Boolean(formik.errors.type)}
          >
            <option aria-label='None' value='' />
            <option value={1}>Online</option>
            <option value={0}>Offline</option>
          </Select>
        </FormControl>
      </div>
      {dataSchedule(formik.values).map((item: IDataSchedule) => (
        <div key={item.name} className={classes.formContent}>
          <TextField
            className={classes.textField}
            value={
              item.type === 'datetime-local'
                ? moment(item.value).format('YYYY-MM-DDThh:mm')
                : item.value
            }
            inputProps={{
              min: new Date().toISOString().slice(0, 16),
            }}
            onChange={formik.handleChange}
            label={item.label}
            type={item.type}
            name={item.name}
            error={
              formik.touched[item.name] && Boolean(formik.errors[item.name])
            }
            helperText={formik.touched[item.name] && formik.errors[item.name]}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      ))}
      <div className={classes.actionSubmit}>
        <Button type='submit' variant='contained' color='primary'>
          Submit
        </Button>
      </div>
    </form>
  );
}

export default Interview;
