import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import { TablePagination } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';

import { COLUMS } from '../contants';
import MuiTable from 'src/components/MuiTable';
import { ITableBookings } from '../../interface';
import * as AppAction from 'src/containers/App/store/actions';
import * as BookingAction from 'src/containers/Booking/store/actions';
import ButtonComponent from 'src/components/ButtonComponent';
import DialogMui from 'src/components/DialogMui';
import { ISelector } from 'src/reducers';

import { useStyles } from './styles';

function TableBookings({
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}: ITableBookings) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isModal = useSelector((s: ISelector) => s.global?.isModal);

  const listBookings = useSelector((s: ISelector) => s.bookings?.listBookings);
  const info = useSelector((s: ISelector) => s.bookings?.info);
  const isRequesting = useSelector((s: ISelector) => s.bookings?.isRequesting);

  const objClasses = {
    0: classes.cancel,
    1: classes.wait,
    2: classes.done,
    3: classes.accepted,
    4: classes.processing,
  };

  const objStatus = {
    0: 'CANCELED',
    1: 'WAITING TO BE PROCESSED',
    2: 'DONE',
    3: 'ACCEPTED',
    4: 'PROCESSING',
  };
  const [id, setId] = useState<string>('');
  const handleDeleteBooking = () =>
    dispatch(BookingAction.deleteBookingRequest(id));
  const handleCloseModal = () => dispatch(AppAction.hideModal());

  const handleOpenModal = (e) => () => {
    setId(e);
    dispatch(AppAction.showModal());
  };

  const row = (item) => {
    return {
      id: (
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link to={`booking/${item.id}`}>{item.id}</Link>
            {item.type === 'manual_select' && (
              <StarIcon style={{ color: '#ffd400' }} />
            )}
          </div>
        </div>
      ),
      userRequest: (
        <div>
          <Link to={`user/${item?.request_profile_id}`}>
            {item.userRequest?.name}
          </Link>
        </div>
      ),
      recipientData: (
        <div>
          <Link to={`partner/${item?.recipient_profile_id}`}>
            {item.recipientData?.name}
          </Link>
        </div>
      ),
      service: (
        <div>
          <p>{item.service?.name}</p>
        </div>
      ),
      cityData: (
        <div>
          <p>{item.cityData?.name}</p>
        </div>
      ),
      full_address: (
        <div>
          <p>{item.full_address}</p>
        </div>
      ),
      booking_time: (
        <div>
          <p>{moment(item.booking_time).format('DD-MM-YYYY HH:mm:ss')}</p>
        </div>
      ),
      schedule: (
        <div>
          <p>{item.schedule ? 'Yes' : 'No'}</p>
        </div>
      ),
      status: (
        <div>
          <p className={`${classes.textStatus} ${objClasses[item.status]}`}>
            {objStatus[item.status]}
          </p>
        </div>
      ),
      create_at: (
        <div>
          <p>{moment(item.created_at).format('DD-MM-YYYY HH:mm:ss')}</p>
        </div>
      ),
      updated_at: (
        <div>
          <p>{moment(item.updated_at).format('DD-MM-YYYY HH:mm:ss')}</p>
        </div>
      ),
      action: (
        <div>
          <div className={classes.bntAction}>
            <ButtonComponent handleAction={handleOpenModal(item.id)}>
              Delete
            </ButtonComponent>
          </div>
        </div>
      ),
    };
  };

  const skeletonRowData = <Skeleton variant="rect" animation="wave" />;

  const rowError = {
    id: <div>{skeletonRowData}</div>,
    userRequest: <div>{skeletonRowData}</div>,
    recipientData: <div>{skeletonRowData}</div>,
    service: <div>{skeletonRowData}</div>,
    cityData: <div>{skeletonRowData}</div>,
    full_address: <div>{skeletonRowData}</div>,
    booking_time: <div>{skeletonRowData}</div>,
    schedule: <div>{skeletonRowData}</div>,
    status: <div>{skeletonRowData}</div>,
    create_at: <div>{skeletonRowData}</div>,
    updated_at: <div>{skeletonRowData}</div>,
    action: <div>{skeletonRowData}</div>,
  };

  const pagination = (
    <div>
      <TablePagination
        className={classes.container}
        component="div"
        count={info?.total || 10}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );

  const renderRow = isRequesting
    ? new Array(10).fill(0).map((i) => rowError)
    : listBookings?.map(row);

  return (
    <div className={classes.root}>
      <DialogMui
        open={isModal}
        onClose={handleCloseModal}
        onConfirm={handleDeleteBooking}
      />
      <div className={classes.boxTable}>
        <MuiTable headers={COLUMS} rows={renderRow} pagination={pagination} />
      </div>
    </div>
  );
}

export default TableBookings;
