import { gql } from '@apollo/client';
import { client } from 'src';

export const getBookingsService = async (data) => {
  const { page, per_page, filter } = data;
  const bookings = await client.query({
    query: gql`
      query {
        bookings(page: ${page}, per_page: ${per_page}   , filter : {
          status: ${filter.status},
          date: "${filter.date}"
        }){
          data {
            type,
            id,
            userRequest{
              name,
              user_id
            },
            recipient_profile_id,
            recipientData{
              name,
              user_id
              
            }
            request_profile_id
            full_address
            booking_time
            cityData {
              name
            }
            status
            schedule
            created_at
            updated_at
            service {
              name
            }
            service_id
          }
          page
          next_page
          per_page
          total
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
  return bookings;
};

export const getCalenderBookingsService = async (data) => {
  const bookings = await client.query({
    query: gql`
      query {
        bookings( per_page : 1000 , filter : {
          from: "${data.from}",
          to: "${data.to}",
        }){
          data {
            type,
            id,
            userRequest{
              name,
              user_id
            },
            recipient_profile_id,
            recipientData{
              name,
              user_id
              
            }
            request_profile_id
            full_address
            booking_time
            cityData {
              name
            }
            status
            schedule
            created_at
            updated_at
            service {
              name
            }
            service_id
          }
          page
          next_page
          per_page
          total
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
  return bookings;
};
