import { put, takeLatest, call } from 'redux-saga/effects';

import { hideModal, hideModalUpdate } from 'src/containers/App/store/actions';
import { showSnackbar } from 'src/containers/Snackbar/store/actions';
import * as types from '../constants';
import * as actionTypes from '../actions';
import {
  addSurchargeService,
  getApiListCities,
  getListSurchargeService,
  deleteSurchargeService,
  updateSurchargeService,
} from '../services';

function* getListCitiesSaga() {
  try {
    const {
      data: { cities },
    } = yield call(getApiListCities);

    yield put(actionTypes.getListCitiesSuccess(cities));
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.getListCitiesFail(message.error.graphQLErrors[0].code)
    );
  }
}

function* createSurchargeSaga(action) {
  try {
    yield call(addSurchargeService, action.payload.data);
    yield put(actionTypes.createSurchargeSuccess(action.payload.data));
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Create Succeed',
      })
    );
    yield action.payload.data.callback();
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.getListCitiesFail(message.error.graphQLErrors[0].code)
    );
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Create Failed',
      })
    );
  }
}

function* getSurchargeSaga(action) {
  try {
    const response = yield call(getListSurchargeService, action.payload.data);
    yield put(actionTypes.getListSurchargeSuccess(response.data.surcharge));
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.getListSurchargeFail(message.error.graphQLErrors[0].code)
    );
  }
}

function* deleteSurchargeSaga(action) {
  try {
    yield call(deleteSurchargeService, action.payload);
    yield put(actionTypes.deleteSurchargeSuccess());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Delete Success',
      })
    );
    yield put(hideModal());
  } catch (error: any) {
    yield put(actionTypes.deleteSurchargeFail(error));
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Delete Failed',
      })
    );
    yield put(hideModal());
  }
}

function* updateSurchargeSaga(action) {
  try {
    yield call(updateSurchargeService, action.payload.data);
    yield put(actionTypes.updateSurchargeSuccess(action.payload));
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Update Success',
      })
    );
    yield put(hideModalUpdate());
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.updateSurchargeFail(message.error.graphQLErrors[0].code)
    );
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Update fail',
      })
    );
    yield put(hideModalUpdate());
  }
}

export default function* partnersSaga() {
  yield takeLatest(types.GET_CITIES_REQUEST, getListCitiesSaga);
  yield takeLatest(types.CREATE_SURCHARGE_REQUEST, createSurchargeSaga);
  yield takeLatest(types.GET_LIST_SURCHARGE_REQUEST, getSurchargeSaga);
  yield takeLatest(types.DELETE_SURCHARGE_REQUEST, deleteSurchargeSaga);
  yield takeLatest(types.UPDATE_SURCHARGE_REQUEST, updateSurchargeSaga);
}
