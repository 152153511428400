import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './containers/App';
import history from './utils/history';
import reportWebVitals from './reportWebVitals';
import configureStore from './store/configureStore';
import './index.css';

import Cookie from 'src/utils/cookie';

const initialState = {};
export const store = configureStore(initialState, history);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_URL_DATA,
});

const authLink = setContext((_, { headers }) => {
  const token = Cookie.getCookie('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

if(process.env.REACT_APP_SENTRY_ENV === 'PRODUCTION') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
