import { CardContent, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LaunchIcon from '@material-ui/icons/Launch';
import classnames from 'classnames';

import { ISelector } from 'src/reducers';
import ToggleComponent from 'src/components/ToggleComponent';
import TextInput from 'src/components/InputFiled';
import { IInfoBookingRequest } from '../../interface';

import { useStyles } from './styles';

function InfoBookingRequest({ infoRequest }: IInfoBookingRequest) {
  const classes = useStyles();

  const bookingDetail = useSelector((s: ISelector) => s.booking?.bookingDetail);

  const themeMode = localStorage.getItem('instalent-them-mode');

  return (
    <ToggleComponent title="Request">
      <CardContent className={classes.cardContent}>
        <Link
          to={`/user/${bookingDetail.request_profile_id}`}
          className={classnames(classes.view, [{
            [classes.viewDark]: themeMode === 'dark',
            [classes.viewLight]: themeMode === 'light'
          }])  }
        >
        <span>View more</span>
          <LaunchIcon fontSize="small" />
        </Link>
        <Grid container spacing={3}>
          {infoRequest.map((item: any, index: number) => {
            return (
              <Grid item md={4} xs={12} key={index}>
                <TextInput
                  label={item.title}
                  className={classes.textField}
                  type="text"
                  value={item.content}
                  variant="outlined"
                />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </ToggleComponent>
  );
}
export default InfoBookingRequest;
