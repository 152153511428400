import produce from 'immer';
import * as types from '../constants';

export const initialState = {
  isLoading: false,
  listCities: [],
  listSurcharge: [],
  totalSurcharge: 0,
  isFresh: false,
};

const surchargeReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case types.GET_CITIES_REQUEST:
        draft.isLoading = true;
        break;
      case types.GET_CITIES_SUCCESS:
        draft.isLoading = false;
        draft.listCities = payload.data;
        break;
      case types.GET_CITIES_FAIL:
        draft.isLoading = false;
        break;
      case types.GET_LIST_SURCHARGE_REQUEST:
        draft.isLoading = true;
        break;
      case types.GET_LIST_SURCHARGE_SUCCESS:
        draft.listSurcharge = payload.data.data;
        draft.totalSurcharge = payload.data.total;
        draft.isLoading = false;
        break;
      case types.GET_LIST_SURCHARGE_FAIL:
        draft.isLoading = true;
        break;
      case types.DELETE_SURCHARGE_REQUEST:
        draft.isFresh = false;
        break;
      case types.DELETE_SURCHARGE_SUCCESS:
        draft.isFresh = true;
        break;
      case types.DELETE_SURCHARGE_FAIL:
        draft.isFresh = true;
        break;
      case types.CREATE_SURCHARGE_REQUEST:
        draft.isFresh = false;
        break;
      case types.CREATE_SURCHARGE_SUCCESS:
        draft.isFresh = true;
        break;
      case types.CREATE_SURCHARGE_FAIL:
        draft.isFresh = true;
        break;
      case types.UPDATE_SURCHARGE_REQUEST:
        draft.isFresh = true;
        break;
      case types.UPDATE_SURCHARGE_SUCCESS:
        draft.isFresh = false;
        break;
      case types.UPDATE_SURCHARGE_FAIL:
        draft.isFresh = false;
        break;
      default:
        break;
    }
  });
export default surchargeReducer;
