import { Link } from 'react-router-dom';
import { TablePagination, Avatar } from '@material-ui/core';
import { Brightness1 as Brightness1Icon } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ISelector } from 'src/reducers';

import MuiTable from 'src/components/MuiTable';
import { COLUMNS } from '../../contants';
import { ITablePartner } from 'src/containers/ListPartner/interface';

import { useStyles } from './styles';

function TableNewPartner({
  page,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleChangePage,
}: ITablePartner) {
  const classes = useStyles();
  const { isRequesting, listNewPartners, infoPage } = useSelector(
    (s: ISelector) => s.newPartners
  );

  const row = (item) => {
    return {
      name: (
        <div>
          <Link to={`partner/${item.id}`} className={classes.rowName}>
            <Avatar alt={item.name} src={item.avatar} />
            <div>{item.name || ''}</div>
          </Link>
        </div>
      ),
      email: (
        <div>
          <p>{item.email || ''}</p>
        </div>
      ),
      phone: (
        <div>
          <p>{item.phone || ''}</p>
        </div>
      ),
      available_work_status: (
        <div>
          <p>{item.available_work_status ? 'Yes' : 'No' || ''}</p>
        </div>
      ),
      online_status: item.online_status ? (
        <div>
          <Brightness1Icon fontSize="small" className={classes.online} />
        </div>
      ) : (
        <div className={classes.online}>
          <p>Offline</p>
        </div>
      ),
      created_at: (
        <div>
          <p>{moment(item.created_at).format('YYYY-MM-DD') || ''}</p>
        </div>
      ),
    };
  };

  const skeletonRowData = <Skeleton variant="rect" animation="wave" />;

  const rowError = {
    role_id: <div>{skeletonRowData}</div>,
    name: <div>{skeletonRowData}</div>,
    email: <div>{skeletonRowData}</div>,
    phone: <div>{skeletonRowData}</div>,
    date_of_birth: <div>{skeletonRowData}</div>,
    available_work_status: <div>{skeletonRowData}</div>,
    online_status: <div>{skeletonRowData}</div>,
    created_at: <div>{skeletonRowData}</div>,
  };

  const pagination = (
    <div>
      <TablePagination
        className={classes.container}
        component="div"
        count={infoPage?.total || 0}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );

  const renderRow = isRequesting
    ? new Array(10).fill(0).map((i) => rowError)
    : listNewPartners?.map(row);

  return (
    <div className={classes.root}>
      <div className={classes.boxTable}>
        <MuiTable headers={COLUMNS} rows={renderRow} pagination={pagination} />
      </div>
    </div>
  );
}

export default TableNewPartner;
