import { FormControl, makeStyles, withStyles } from '@material-ui/core';
import styledTheme from '../../utils/theme';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: theme.palette.secondary.main,
    borderRadius: 0,
    fontSize: styledTheme.fontSize.medium,
  },
  select: {
    color: '#1a1a1a',
  },
}));
const SelectForm = withStyles((theme) => ({
  root: {
    '& .MuiSelect-select:focus': {},
    '&.MuiFormControl-root': {
      width: '150px',
      '& >label': {
        fontSize: '13px',
      },
    },
    '& .MuiInputBase-root': {
      height: 35,
      color: '#1a1a1a',
      minWidth: 150,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.text.primary,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${theme.palette.text.primary}`,
      },
    },
    '& .MuiInputLabel-outlined': {
      color: theme.palette.text.secondary,
      transform: 'translate(14px, 10px) scale(1)',
      fontSize: '12px',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.7)',
        fontSize: '12px',
      },
    },
    '& > .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '50px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '15.5px 14px',
      fontSize: styledTheme.fontSize.medium,
      color: theme.palette.text.primary,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
}))(FormControl);

const SelectFormBlackView = withStyles((theme) => ({
  root: {
    '& .MuiSelect-select:focus': {
      backgroundColor: '#363640',
    },
    '&.MuiFormControl-root': {
      width: '100% !important',
    },
    '& .MuiInputBase-root': {
      height: '32px',
      color: 'rgba(255, 255, 255, 0.87)',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        // borderColor: "rgba(255, 255, 255, 0.12)",
      },
      '&.Mui-focused fieldset': {
        border: '1px solid rgba(255, 255, 255, 0.12)',
      },
    },
    '& .MuiInputLabel-outlined': {
      color: 'rgba(255, 255, 255, 0.87)',
      transform: 'translate(14px, 17px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.7)',
      },
    },
    '& > .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '50px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '15.5px 14px',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: 'rgba(255, 255, 255, 0.87)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.12)',
    },
    '& .MuiSelect-icon': {
      color: 'rgba(255, 255, 255, 0.87)',
    },
  },
}))(FormControl);

export { SelectForm, useStyles, SelectFormBlackView };
