import { gql } from '@apollo/client';
import { client } from 'src';

export const getApiListCities = async () => {
  return await client.query({
    query: gql`
      query {
        cities(take: 100, skip: 0) {
          id
          name
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

export const addSurchargeService = async ({
  amount,
  address,
  title,
  description,
  city,
  status,
}) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      createSurcharge(payload: {
        amount: ${amount},
        address: "${address}",
        title: "${title}",
        city_id: ${city},
        status: ${status},
        description: "${description}"
      })
    }
    `,
  });
};

export const getListSurchargeService = async (data) => {
  return await client.query({
    query: gql`
    query {
      surcharge(page: ${data.page}, per_page: ${data.rowsPerPage}) {
        total
        data {
          id
          amount
          address
          title
          description
          status
          city_name
          city_id
        }
      }
    }
    `,
    fetchPolicy: 'network-only',
  });
};

export const deleteSurchargeService = async (id: string) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      deleteSurcharge(id: ${id})
    }
    `,
  });
};

export const updateSurchargeService = async (data) => {
  return await client.mutate({
    mutation: gql`
      mutation {
        updateSurcharge(
          id: ${data.id}
          payload: {
            amount: ${data.amount} ,
            address: "${data.address}",
            title: "${data.title}",
            description: "${data.description}",
            city_id: ${data.city},
            status: ${data.status},
          }
        )
      }
    `,
  });
};
