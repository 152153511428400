import { FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import * as ServiceRequestAction from '../../store/actions';
import { dataScheduleDetail } from '../../contants';
import { IDataSchedule } from '../../interface';

import { useStyles } from './styles';

const validate = Yup.object().shape({
  type: Yup.number().required('Required'),
  schedule: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
});

function InterviewDetail({ ScheduleDetail }: any) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      service_id: ScheduleDetail?.service_id,
      service_providers_id: ScheduleDetail?.service_providers_id,
      address: ScheduleDetail?.address,
      schedule: ScheduleDetail?.schedule,
      type: ScheduleDetail?.type || 0,
      description: ScheduleDetail?.description,
      created_at: ScheduleDetail?.created_at,
      updated_at: ScheduleDetail?.updated_at,
    },
    validationSchema: validate,
    onSubmit: (values) => {
      dispatch(
        ServiceRequestAction.addScheduleInterviewRequest({
          service_id: values.service_id,
          service_providers_id: values.service_providers_id,
          address: values.address,
          schedule: values.schedule,
          type: values.type,
          description: values.description,
        })
      );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.formContent}>
        <FormControl className={classes.formControl}>
          <InputLabel>Interview</InputLabel>
          <Select
            native
            value={formik.values.type}
            onChange={formik.handleChange}
            label="Interview"
            name="type"
            inputProps={{
              name: 'type',
            }}
          >
            <option aria-label="None" value="" />
            <option value={1}>Online</option>
            <option value={0}>Offline</option>
          </Select>
        </FormControl>
      </div>
      {dataScheduleDetail(formik.values).map((item: IDataSchedule) => (
        <div key={item.name} className={classes.formContent}>
          <TextField
            className={classes.textField}
            value={
              item.type === 'datetime-local'
                ? moment(item.value).format('YYYY-MM-DDThh:mm')
                : item.value
            }
            onChange={formik.handleChange}
            label={item.label}
            type={item.type}
            name={item.name}
            error={
              formik.touched[item.name] && Boolean(formik.errors[item.name])
            }
            helperText={formik.touched[item.name] && formik.errors[item.name]}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={item.disabled}
          />
        </div>
      ))}
    </form>
  );
}

export default InterviewDetail;
