import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ISelector } from 'src/reducers';
import SelectOption from 'src/components/Selection';
import ButtonComponent from 'src/components/ButtonComponent';
import TextInput from 'src/components/InputFiled';
import { hideModalUpdate } from 'src/containers/App/store/actions';
import { IListCitiesID, IListServiceArea } from '../../interface';
import { updateServiceAreaRequest } from '../../store/actions';
import { validateUpdate } from '../../constants';

import { useStyles } from './styles';

export type IUpdateType = {
  dataUpdate: IListServiceArea;
};

const CreateServiceAreaModal = ({ dataUpdate }: IUpdateType) => {
  const clx = useStyles();
  const dispatch = useDispatch();

  const acValueCity: IListCitiesID = {
    id: dataUpdate.city_id,
    name: dataUpdate.city_name,
  };

  const service_id = useSelector(
    (items: ISelector) => items.services.listServices
  );

  const ListCitiesID = useSelector(
    (items: ISelector) => items.serviceAreas.listCitiesID
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cityValue: {
        id: dataUpdate?.city_id,
        name: dataUpdate?.city_name,
      },
      serviceValue: dataUpdate?.service_id,
      price: dataUpdate?.price,
    },
    validationSchema: validateUpdate,
    onSubmit: (values) => {
      const newService = service_id.find(
        (item) => item.id === values.serviceValue
      );
      dispatch(
        updateServiceAreaRequest({
          ...values,
          city_name: values.cityValue.name,
          id: dataUpdate?.id,
          services: {
            name: newService?.name,
          },
        })
      );
    },
  });

  const handleCloseModalCreate = () => {
    dispatch(hideModalUpdate());
  };

  return (
    <form onSubmit={formik.handleSubmit} className={clx.formSubmit}>
      <div>
        <Autocomplete
          id="cityValue"
          size="small"
          options={ListCitiesID}
          getOptionLabel={(option: IListCitiesID) => option?.name}
          renderOption={(option: IListCitiesID) => <p>{option?.name}</p>}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              error={
                formik.touched.cityValue && Boolean(formik.errors.cityValue)
              }
              helperText={formik.touched.cityValue && formik.errors.cityValue}
            />
          )}
          autoComplete
          onChange={(_, newValue: any) => {
            formik.setFieldValue('cityValue', newValue);
          }}
          defaultValue={acValueCity}
        />
      </div>
      <div>
        <SelectOption
          name="service_id"
          label="Services"
          options={service_id}
          onChangeOption={formik.handleChange}
          values={formik.values.serviceValue}
          error={
            formik.touched.serviceValue && Boolean(formik.errors.serviceValue)
          }
          helperText={formik.touched.serviceValue && formik.errors.serviceValue}
          defaultValue={dataUpdate.service_id}
          style={{ width: '100%' }}
        />
      </div>
      <div>
        <TextInput
          name="price"
          label="Price"
          type="number"
          variant="outlined"
          onChange={formik.handleChange}
          value={formik.values.price}
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={formik.touched.price && formik.errors.price}
        />
      </div>
      <div className={clx.buttonForm}>
        <Button onClick={handleCloseModalCreate}>Cancel</Button>
        <ButtonComponent status="approve">Submit</ButtonComponent>
      </div>
    </form>
  );
};

export default CreateServiceAreaModal;
