import SurchargePage from 'src/pages/SurchargePage';
import { AuthenticatedRoute } from '../components/routes';

const SurchargeRoutes = [
  {
    path: '/surcharge',
    title: 'Surcharge',
    component: SurchargePage,
    route: AuthenticatedRoute,
    roles: [],
    permission: [],
    exact: true,
  },
];

export default SurchargeRoutes;
