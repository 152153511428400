import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import { useStyles } from './styles';

interface IDialogMui {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
}

function DialogMui({ title, open, onClose, onConfirm }: IDialogMui) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      className={classes.root}
    >
      <DialogContent className={classes.container}>
        <DialogContentText className={classes.title}>
          {title || 'Are you sure you want to do this'}
        </DialogContentText>
        <div className={classes.action}>
          <Button className={classes.agree} onClick={onConfirm}>
            Agree
          </Button>
          <Button
            variant="outlined"
            className={classes.disagree}
            onClick={onClose}
          >
            Disagree
          </Button>
        </div>

        <span className={classes.closeIcon} onClick={onClose}>
          ×
        </span>
      </DialogContent>
    </Dialog>
  );
}

export default DialogMui;
