import { useState } from 'react';

function useDeleteCoupon() {
  const [isDelete, setIsDelete] = useState<any>({
    open: false,
    id: '',
  });

  const [isEdit, setIsEdit] = useState<any>({
    open: false,
    detail: {},
  });

  const handleOpenModalDelete = (id) => () => {
    setIsDelete({ open: true, id });
  };

  const handleCloseModalDelete = () => {
    setIsDelete({ open: false, id: '' });
  };

  const handleOpenModalEdit = (detail) => () => {
    setIsEdit({ open: true, detail });
  };

  const handleCloseModalEdit = () => {
    setIsEdit({ ...isEdit, open: false });
  };

  return {
    isDelete,
    handleOpenModalDelete,
    handleCloseModalDelete,
    handleOpenModalEdit,
    handleCloseModalEdit,
    isEdit,
  };
}

export default useDeleteCoupon;
