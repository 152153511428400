import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

export default {
  breakpoints: ['375', '576', '768', '992', '1200'],
  colors: {
    background: '#f7f7f7',
    white: '#ffffff',
    darkBackground: '#121212',
    gray: '#d8dcf0',
    gray1: 'rgba(255, 255, 255, 0.8)',
    gray2: 'rgba(255, 255, 255, 0.87)',
    gray3: '#bdbdbd',
    gray4: '#ebebeb',
    gray5: '#f5f5f5',
    gray6: '#e5e5e5',
    black: '#202020',
    black1: '#343434',
    black2: 'rgba(0, 0, 0, 0.24)',
    textColor: '#767676',
    textColor1: '#6d6d6d',
    blue: '#3e50b4',
    darkBlue: '#3d50b4',
    lightBlue: '#317ae2',
    lighterBlue: '#3042a7',
    green: '#4caf50',
    bluishGreen: '#019587',
    red: '#f94839',
    yellow: '#ffb400',
  },
  spacing: 20,
  fontSize: {
    xsmall: '9px',
    small: '10px',
    medium: '11px',
    large: '12px',
    xlarge: '13px',
    xxlarge: '14px',
  },
  height: {
    medium: '33.06px',
  },
};

const muiTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#fff',
      secondary: '#353535',
      violet: '#3e50b4',
      yellow: '#ffb400',
      blue: '#71acff',
      green: '#66d56a',
    },
    secondary: {
      main: '#1a1a1a !important',
      secondary: '#fff !important',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    button: {
      secondary: '#fff',
    },
    text: {
      primary: '#1a1a1a',
      secondary: '#999999',
    },
    hover: {
      background: '#f7f7f7',
    },
  },
  typography: {
    button: {
      textTransform: 'capitalize',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#ffff',
      },
    },
    MuiBottomNavigation: {
      root: {
        backgroundColor: '#ffff',
      },
    },
  },
});

const muiDarkTheme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#1f1f1f !important',
      },
    },
    MuiBottomNavigation: {
      root: {
        backgroundColor: '#1f1f1f',
      },
    },
  },

  palette: {
    type: 'dark',
    primary: {
      main: '#1f1f1f',
      secondary: '#353535',
      violet: '#3e50b4',
      yellow: '#ffb400',
      blue: '#71acff',
      green: '#66d56a',
    },
    button: {
      secondary: '#747474',
    },
    secondary: {
      main: '#fff',
      secondary: '#353535',
    },
    text: {
      primary: '#fff',
      secondary: '#fcfcfc',
    },
    hover: {
      background: '#353535',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#202020',
    },
  },
});

export { muiTheme, muiDarkTheme };
