import { combineReducers } from 'redux';

import { GlobalTypes } from 'src/containers/App/interfaces';
import { IAuthReduce } from 'src/containers/auth/login';
import { IReducerBookings } from 'src/containers/Bookings/interface';
import { ITransactionReducer } from 'src/containers/HistoryTransaction/interface';
import { IReducerPartners } from 'src/containers/ListPartner/interface';
import { IServiceProviderReducer } from 'src/containers/ListServiceRequest/interface';
import { IReducerUsers } from 'src/containers/ListUsersContainer/interface';
import { IReducerNewPartners } from 'src/containers/NewPartners/interface';
import { IReducerServices } from 'src/containers/Services/interface';
import { IReducerStaff } from 'src/containers/StaffUser/interface';
import { IReducerBooking, IReducerUser } from 'src/containers/User/interface';
import { IReducerServiceArea } from 'src/containers/ServicesAreas/interface';
import globalReducer from '../containers/App/store/reducers';
import auth from '../containers/App/store/reducers';
import snack from '../containers/Snackbar/store/reducers';
import user from '../containers/User/store/reducers';
import booking from '../containers/Booking/store/reducers';
import bookings from '../containers/Bookings/store/reducers';
import serviceProvider from '../containers/ListServiceRequest/store/reducers';
import services from '../containers/Services/store/reducers';
import staff from '../containers/StaffUser/store/reducers';
import transaction from '../containers/HistoryTransaction/store/reducers';
import newPartners from '../containers/NewPartners/store/reducers';
import partners from '../containers/ListPartner/store/reducers';
import serviceRequest, {
  IReducerServiceRequest,
} from '../containers/ServiceRequest/store/reducers';
import couponRequest from 'src/containers/Coupons/store/reducers';

export interface ISelector {
  auth: IAuthReduce;
  global: GlobalTypes;
  snack: object;
  user: IReducerUser;
  booking: IReducerBooking;
  bookings: IReducerBookings;
  partners: IReducerPartners;
  users: IReducerUsers;
  serviceProvider: IServiceProviderReducer;
  services: IReducerServices;
  staff: IReducerStaff;
  transaction: ITransactionReducer;
  newPartners: IReducerNewPartners;
  serviceRequest: IReducerServiceRequest;
  couponRequest: any;
  serviceAreas: IReducerServiceArea;
}

export default function createReducer(injectedReducers = {}) {
  const appReducer = combineReducers({
    global: globalReducer,
    auth: auth,
    snack: snack,
    user: user,
    booking: booking,
    bookings: bookings,
    serviceProvider: serviceProvider,
    services: services,
    staff: staff,
    transaction: transaction,
    newPartners: newPartners,
    partners: partners,
    serviceRequest: serviceRequest,
    couponRequest: couponRequest,
    ...injectedReducers,
  });

  const rootReducer = (state: any, action: any) => {
    return appReducer(state, action);
  };

  return rootReducer;
}
