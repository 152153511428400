import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  head: {
    position: 'sticky',
    top: '-8px',
    width: '100%',
    zIndex: 100,
    '& .MuiBottomNavigationAction-root.Mui-selected': {
      color: '#3f51b5',
      '& svg': {
        color: '#3f51b5',
      },
    },
  },
  bntAction: {
    display: 'flex',
    '& >div': {
      margin: theme.spacing(0, 1),
    },
  },
  dialogContent: {
    width: 500,
  },
  dialog: {},
  scheduled: {
    display: 'flex',
    width: 160,
    alignItems: 'center',
    '& >span': {
      marginLeft: theme.spacing(1),
    },
  },
  formControl: {
    width: '100%',
  },
  textField: {
    width: '100%',
  },
  formContent: {
    paddingTop: theme.spacing(2),
  },
  actionSubmit: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: theme.spacing(5),
  },
  imageListItem: {
    height: '100% !important',
  },
  imgItem: {
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  imgList: {
    height: '100% !important',
  },
}));
export { useStyles };
