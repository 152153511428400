import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  contain: {
    padding: theme.spacing(2),
  },
  snackbar: {
    zIndex: 10000
  }
}));
export { useStyles };
