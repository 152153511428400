import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import reducerBooking from '../Booking/store/reducers';
import sagaBooking from '../Booking/store/sagas';
import reducerListService from 'src/containers/ListServiceRequest/store/reducers';
import sagaListService from 'src/containers/ListServiceRequest/store/sagas';
import reducerServiceRequest from 'src/containers/ServiceRequest/store/reducers';
import sagaServiceRequest from 'src/containers/ServiceRequest/store/sagas';
import * as ServiceRequestAction from '../ServiceRequest/store/actions';
import PartnerView from './views';
import reducer from '../User/store/reducers';
import saga from '../User/store/sagas';
import { useInjectReducer } from 'src/utils/injectReducer';
import { useInjectSaga } from 'src/utils/injectSaga';
import { ParamTypes } from '../Booking/interface';
import * as UserAction from '../../containers/User/store/actions';
import * as BookingActions from '../Booking/store/actions';
import { ISelector } from 'src/reducers';
import HistoryTransactionHook from '../HistoryTransaction/hooks/HistoryTransactionHook';
import * as TransactionAction from '../HistoryTransaction/store/actions';

function PartnerContainer() {
  useInjectReducer({ key: 'user', reducer });
  useInjectSaga({ key: 'user', saga });
  useInjectReducer({ key: 'booking', reducer: reducerBooking });
  useInjectSaga({ key: 'booking', saga: sagaBooking });
  
  useInjectReducer({ key: 'serviceProvider', reducer: reducerListService });
  useInjectSaga({ key: 'serviceProvider', saga: sagaListService });

  useInjectReducer({ key: 'serviceRequest', reducer: reducerServiceRequest });
  useInjectSaga({ key: 'serviceRequest', saga: sagaServiceRequest });

  const isAddProof = useSelector(
    (s: ISelector) => s.serviceProvider?.isAddProof || false
  );

  const dispatch = useDispatch();

  const { id } = useParams<ParamTypes>();

  const { page, rowsPerPage, search } = HistoryTransactionHook(id);

  useEffect(() => {
    dispatch(BookingActions.getServiceRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(UserAction.getUserDetailActionRequest(id));
  }, [id, dispatch, isAddProof]);

  useEffect(() => {
    dispatch(UserAction.getHistoryBookingUserRequest(id));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(ServiceRequestAction.serviceRequestPartnerAction(id));
  }, [id, dispatch]);

  useEffect(() => {
    const params = {
      id: id,
      page: page,
      rowsPerPage: rowsPerPage,
      filter: {
        date: search.date,
      },
    };
    dispatch(TransactionAction.getHistoryTransactionRequest(params));
  }, [dispatch, id, page, rowsPerPage, search.date]);

  return <PartnerView />;
}

export default PartnerContainer;
