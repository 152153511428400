import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& a': {
      textDecoration: 'none',
      color: '#3e50b4',
    },
  },
  container: {
    maxHeight: 440,
  },
  danger: {
    color: '#f94839',
  },
  active: {
    color: '#4caf50',
  },
  boxTable: {
    border: '1px none',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
  textStatus: {
    fontSize: 10,
    fontWeight: 600,
  },
  online: {
    color: '#4CAF50',
  },
  check: {
    color: '#4CAF50',
  },
  cancel: {
    color: '#ff3838',
  },
  done: {
    color: '#2dccff',
  },
  wait: {
    color: '#3788d8',
  },
  accepted: {
    color: '#56f000	',
  },
  processing: {
    color: '#ffb302',
  },
  bntAction: {
    display: 'flex',
    '& >div': {
      margin: theme.spacing(0, 1),
    },
  },
}));
export { useStyles };
