import { AuthenticatedRoute } from '../components/routes';
import StaffUserContainer  from '../containers/StaffUser'

const StaffRoutes = [
  {
    path: '/staff',
    title: 'Staff User',
    component: StaffUserContainer,
    route: AuthenticatedRoute,
    roles: [],
    permission: [],
    exact: true,
  },
];


export default StaffRoutes;