import { IBlock, IGetUsers } from '../../interface';
import * as types from '../constants';

export const getListUserRequestAction = function (data: IGetUsers) {
  return {
    type: types.GET_LIST_USER_REQUEST,
    payload: { data },
  };
};

export const getListUserSuccess = (listData: Array<any>, info: object) => {
  return {
    type: types.GET_LIST_USER_SUCCESS,
    payload: { listData },
    info,
  };
};

export const getListUserFail = (error) => {
  return {
    type: types.GET_LIST_USER_FAIL,
    payload: error,
  };
};

export const blockUserRequest = function (data: IBlock) {
  return {
    type: types.BLOCK_USER_REQUEST,
    payload: { data },
  };
};

export const blockUserSuccess = (data) => {
  return {
    type: types.BLOCK_USER_SUCCESS,
    payload: { data },
  };
};

export const blockUserFail = (error) => {
  return {
    type: types.BLOCK_USER_FAIL,
    payload: error,
  };
};
