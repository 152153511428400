export const GET_STAFF_REQUEST = 'instalent/GET_STAFF_REQUEST';
export const GET_STAFF_SUCCESS = 'instalent/GET_STAFF_SUCCESS';
export const GET_STAFF_FAIL = 'instalent/GET_STAFF_FAIL';

export const DELETE_STAFF_REQUEST = 'instalent/DELETE_STAFF_REQUEST';
export const DELETE_STAFF_SUCCESS = 'instalent/DELETE_STAFF_SUCCESS';
export const DELETE_STAFF_FAIL = 'instalent/DELETE_STAFF_FAIL';

export const UPDATE_STAFF_REQUEST = 'instalent/UPDATE_STAFF_REQUEST';
export const UPDATE_STAFF_SUCCESS = 'instalent/UPDATE_STAFF_SUCCESS';
export const UPDATE_STAFF_FAIL = 'instalent/UPDATE_STAFF_FAIL';

export const ADD_STAFF_REQUEST = 'instalent/ADD_STAFF_REQUEST';
export const ADD_STAFF_SUCCESS = 'instalent/ADD_STAFF_SUCCESS';
export const ADD_STAFF_FAIL = 'instalent/ADD_STAFF_FAIL';
