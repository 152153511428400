export const SHOW_LOADING = 'Instalent/SHOW_LOADING';
export const HIDE_LOADING = 'Instalent/HIDE_LOADING';

export const SHOW_MODAL = 'Instalent/SHOW_MODAL';
export const HIDE_MODAL = 'Instalent/HIDE_MODAL';

export const SHOW_MODAL_ADD = 'instalent/SHOW_MODAL_ADD';
export const HIDE_MODAL_ADD = 'instalent/HIDE_MODAL_ADD';

export const SHOW_MODAL_UPDATE = 'instalent/SHOW_MODAL_UPDATE';
export const HIDE_MODAL_UPDATE = 'instalent/HIDE_MODAL_UPDATE';
