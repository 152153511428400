import * as types from "../constants";
export const initialState = {
  isRequesting: false,
  thing: {
    things: {},
    thingIds: [],
  },
};

const thingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GENERATE_THING_NAME_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case types.GENERATE_THING_NAME_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        thing: action.payload.data,
      };
    case types.GENERATE_THING_NAME_FAIL:
      return {
        ...state,
        isRequesting: false,
        thing: {
          things: {},
          thingIds: [],
        },
      };
    default:
      return state;
  }
};

export default thingReducer;
