import { put, takeLatest } from 'redux-saga/effects';

import * as types from '../constants';
import * as actionTypes from '../actions';
import { blockUserService, getApiUsers } from '../services';
import { showSnackbar } from '../../../Snackbar/store/actions';

function* getListUserRequest(action) {
  try {
    const { page, rowsPerPage, filter } = action.payload.data;
    const users = yield getApiUsers({ page, rowsPerPage, filter });
    const data = users.data.users.data;
    yield put(actionTypes.getListUserSuccess(data, users.data.users));
  } catch (error) {
    const message = { error };
    yield put(actionTypes.getListUserFail(message.error.graphQLErrors[0].code));
  }
}

function* blockUserSaga(action) {
  try {
    const { data } = action.payload;
    yield blockUserService(data);
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Succeed',
      })
    );
    yield put(actionTypes.blockUserSuccess(data));
  } catch (error) {
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Failed',
      })
    );
    const message = { error };
    yield put(actionTypes.blockUserFail(message.error.graphQLErrors[0].code));
  }
}

export default function* partnerSaga() {
  yield takeLatest(types.GET_LIST_USER_REQUEST, getListUserRequest);
  yield takeLatest(types.BLOCK_USER_REQUEST, blockUserSaga);
}
