import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions';
import * as types from '../constants';
import { getWithDrawService, updateStatusWithdrawService } from '../services';
import { showSnackbar } from '../../../Snackbar/store/actions';
function* getWithdrawSaga(action) {
  try {
    const {
      data: { getRequestWithdraw },
    } = yield getWithDrawService(action.payload);
    yield put(actionTypes.getWithdrawSuccess(getRequestWithdraw));
  } catch (error: any) {
    const message = { error };
    yield put(actionTypes.getWithdrawFail(message.error.graphQLErrors[0].code));
  }
}

function* updateStatusWithdrawSaga(action) {
  try {
    yield updateStatusWithdrawService(action.payload);
    yield put(actionTypes.updateStatusWithdrawSuccess(action.payload));

    yield action.payload.callback();
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.updateStatusWithdrawFail(message.error.graphQLErrors[0].code)
    );
    yield action.payload.callback();
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message.error.message,
      })
    );
  }
}

export default function* ServicesSaga() {
  yield takeLatest(types.GET_WITHDRAW_REQUEST, getWithdrawSaga);
  yield takeLatest(
    types.UPDATE_STATUS_WITHDRAW_REQUEST,
    updateStatusWithdrawSaga
  );
}
