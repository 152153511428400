import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInjectReducer } from 'src/utils/injectReducer';
import reducer from './store/reducers';
import saga from './store/sagas';
import ServicesView from './views';
import { useInjectSaga } from 'src/utils/injectSaga';
import * as ServicesAction from './store/actions';

import { ISelector } from 'src/reducers';

function ServicesContainer() {
  useInjectReducer({ key: 'services', reducer });
  useInjectSaga({ key: 'services', saga });

  const dispatch = useDispatch();

  const isUpdate = useSelector((s: ISelector) => s.services?.isUpdate);

  const isAdd = useSelector((s: ISelector) => s.services?.isAdd);

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    const params = {
      page: page,
      rowsPerPage: rowsPerPage,
    };
    dispatch(ServicesAction.getServicesRequest(params));
  }, [dispatch, page, rowsPerPage, isUpdate, isAdd]);

  return (
    <ServicesView
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}

export default ServicesContainer;
