import { put, takeLatest } from 'redux-saga/effects';

import * as types from '../constants';
import * as actionTypes from '../actions';
import { getApiNewPartners } from '../services';

function* getNewPartnersSaga(action) {

  try {
    const { page, rowsPerPage, filter } = action.payload.data;

    const listPartner = yield getApiNewPartners({ page, rowsPerPage, filter });
    const data = listPartner.data.getPartnersNew.data;

    yield put(
      actionTypes.getNewPartnersSuccess(data, listPartner.data.getPartnersNew)
    );
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.getNewPartnersFail(message.error.graphQLErrors[0].code)
    );
  }
}

export default function* newPartnerSaga() {
  yield takeLatest(types.GET_NEW_PARTNERS_REQUEST, getNewPartnersSaga);
}
