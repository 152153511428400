import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce } from 'lodash';
import * as Yup from 'yup';

import * as CouponAction from '../../store/actions';
import ButtonComponent from 'src/components/ButtonComponent';
import TextInput from 'src/components/InputFiled';
import SelectOption from 'src/components/Selection';
import { couponData, DISCOUNT_UNIT, RESTRICTABLE_TYPE } from '../../constants';

import { useStyles } from './styles';

interface IProps {
  handleClose: () => void;
}

function CreateCoupon(props: IProps) {
  const { handleClose } = props;
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const handleChangeSelect = (event, value) => setSelectedOptions(value);

  const [isCheckedMaximum, setIsCheckedMaximum] = useState(false);

  const handleChangeChecked = (event) => {
    setIsCheckedMaximum(event.target.checked);
  };

  const dispatch = useDispatch();
  const classes = useStyles();
  const listRestrictableType = useSelector(
    (s: any) => s.couponRequest.listRestrictableType
  );

  const [state, setState] = useState<any>({
    restrictable_type: '',
    restrictable_value: '',
  });

  const validate = Yup.object().shape({
    restrictable_ids: Yup.array().min(1, 'Required'),
    coupon_code: Yup.string().required('Required'),
    minimum_order_value: Yup.number().required('Required').min(0),
    discount_unit: Yup.string().required('Required'),
    discount_value: Yup.number().when('discount_unit', {
      is: (value) => value === 'percentage',
      then: Yup.number().required('required').max(100).min(0),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      restrictable_ids: '',
      restrictable_type: '',
      coupon_code: '',
      maximum_discount_amount: null,
      minimum_order_value: '',
      discount_value: '',
      valid_before: null,
      discount_unit: '',
    },
    validationSchema: validate,
    onSubmit: (values) => {
      dispatch(
        CouponAction.addCouponRequest({
          ...values,
          discount_unit: values.discount_unit.toUpperCase(),
          restrictable_type: state.restrictable_type.toUpperCase(),
          restrictable_ids: selectedOptions.map((item) => item.id.toString()),
          callbackClose: () => handleClose(),
        })
      );
    },
  });

  useEffect(() => {}, [listRestrictableType]);

  const [search, setSearch] = useState<any>({
    role_id: null,
    email: '',
    name: '',
    phone: '',
  });
  const [dataDebounce, setDataDebounce] = useState();

  const debounceDropDown = useCallback(
    debounce((nextValue) => setDataDebounce(nextValue), 200),
    []
  );

  const handleSearch = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      ...search,
      [name]: value,
    });
    debounceDropDown(value);
  };

  useEffect(() => {
    if (state.restrictable_type === 'profile') {
      const params = {
        page: 1,
        rowsPerPage: 20,
        filter: {
          name: search.name,
          phone: search.phone,
          email: search.email,
          role_id: search.role_id,
        },
      };
      dispatch(CouponAction.getListUserRequest(params));
    }
  }, [dispatch, dataDebounce]);

  const handleChange = ({ target: { name, value } }) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const onSelectRestrictableType = (event: any) => {
    handleChange(event);
    switch (event.target.value) {
      case 'payment_method':
        dispatch(CouponAction.getPaymentMethodRequest());
        setSelectedOptions([]);
        break;
      case 'service':
        dispatch(CouponAction.getAllServiceRequest());
        setSelectedOptions([]);
        break;
      case 'profile':
        dispatch(
          CouponAction.getListUserRequest({
            page: 1,
            rowsPerPage: 20,
            filter: {
              name: search.name,
              phone: search.phone,
              email: search.email,
              role_id: search.role_id,
            },
          })
        );
        setSelectedOptions([]);
        break;
      case 'none':
        dispatch(CouponAction.deleteStateRestrict());
        setSelectedOptions([]);
        break;
      case 'new_user':
        dispatch(CouponAction.deleteStateRestrict());
        setSelectedOptions([]);
        break;
      default:
        break;
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.formContent}>
        <SelectOption
          label="Restrictable Type"
          options={RESTRICTABLE_TYPE}
          name="restrictable_type"
          onChangeOption={onSelectRestrictableType}
          value={state.restrictable_type}
        />
      </div>
      {!!listRestrictableType &&
        !isEmpty(listRestrictableType) &&
        listRestrictableType?.length > 0 && (
          <Autocomplete
            className={classes.selectContent}
            multiple
            id="tags-standard"
            options={listRestrictableType}
            onChange={handleChangeSelect}
            value={selectedOptions}
            getOptionLabel={(option: any) => option.name || option.payment_name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                type="text"
                value={search?.phone || ''}
                onChange={handleSearch}
                name="name"
                label={`${listRestrictableType[0].__typename}`}
              />
            )}
          />
        )}
      <div className={classes.formContent}>
        <TextInput
          value={formik.values.coupon_code}
          onChange={formik.handleChange}
          label={'Coupon Code'}
          type={'text'}
          name={'coupon_code'}
          variant="outlined"
          error={
            formik.touched.coupon_code && Boolean(formik.errors.coupon_code)
          }
          helperText={formik.touched.coupon_code && formik.errors.coupon_code}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div className={classes.formContent}>
        <SelectOption
          label="Discount Unit"
          options={DISCOUNT_UNIT}
          name="discount_unit"
          onChangeOption={formik.handleChange}
          value={formik.values.discount_unit}
          error={
            formik.touched.discount_unit && Boolean(formik.errors.discount_unit)
          }
          helperText={
            formik.touched.discount_unit && formik.errors.discount_unit
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div className={classes.formContent}>
        <TextInput
          value={formik.values.discount_value}
          onChange={formik.handleChange}
          label={'Discount Value'}
          type={'number'}
          name={'discount_value'}
          variant="outlined"
          error={
            formik.touched.discount_value &&
            Boolean(formik.errors.discount_value)
          }
          helperText={
            formik.touched.discount_value && formik.errors.discount_value
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>

      {couponData(formik.values).map((item: any) => (
        <div key={item.name} className={classes.formContent}>
          <TextInput
            value={item.value}
            onChange={formik.handleChange}
            label={item.label}
            type={item.type}
            name={item.name}
            variant="outlined"
            error={
              formik.touched[item.name] && Boolean(formik.errors[item.name])
            }
            helperText={formik.touched[item.name] && formik.errors[item.name]}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      ))}

      {formik.values.discount_unit === "percentage" && (
        <div className={classes.formContent}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isCheckedMaximum}
                onChange={handleChangeChecked}
                name="Maximum Discount Amount"
              />
            }
            className={classes.checkbox}
            label="Maximum Discount Amount"
          />
        </div>
      )}

      {isCheckedMaximum && (
        <div className={classes.formContent}>
          <TextInput
            value={formik.values.maximum_discount_amount}
            onChange={formik.handleChange}
            label={'Maximum Discount Amount'}
            type={'number'}
            name={'maximum_discount_amount'}
            variant="outlined"
            error={
              formik.touched.maximum_discount_amount &&
              Boolean(formik.errors.maximum_discount_amount)
            }
            helperText={
              formik.touched.maximum_discount_amount &&
              formik.errors.maximum_discount_amount
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      )}

      <div className={classes.actionSubmit}>
        <Button onClick={handleClose}>Cancel</Button>
        <ButtonComponent status="approve">Submit</ButtonComponent>
      </div>
    </form>
  );
}
export default CreateCoupon;
