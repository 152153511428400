import { AuthenticatedRoute } from '../components/routes';
import { PartnerPage } from '../pages';

const listPartnerRoutes = [
  {
    path: '/partners',
    title: 'ListPartner',
    component: PartnerPage,
    route: AuthenticatedRoute,
    roles: [],
    permission: [],
    exact: true,
  },
];


export default listPartnerRoutes;