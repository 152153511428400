import { DateRange } from '@material-ui/pickers';
import { useState } from 'react';

const useFetchWithdraw = () => {
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [selectedDate, handleDateChange] = useState<DateRange<Date>>([
    new Date(new Date().setDate(new Date().getDate() - 30)),
    new Date(),
  ]);

  const [search, setSearch] = useState<any>({
    status: '',
    date: '',
  });

  const handleSearch = ({ target: { name, value } }) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleChangDate = (date: DateRange<Date>) => {
    handleDateChange(date);
  };

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};

export default useFetchWithdraw;
