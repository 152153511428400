import * as types from '../constants';

export const getListCitiesRequestAction = function () {
  return {
    type: types.GET_CITIES_REQUEST,
  };
};

export const getListCitiesSuccess = (data) => {
  return {
    type: types.GET_CITIES_SUCCESS,
    payload: { data },
  };
};

export const getListCitiesFail = (error: Error) => {
  return {
    type: types.GET_CITIES_FAIL,
    payload: error,
  };
};

export const createSurchargeRequest = function (data) {
  return {
    type: types.CREATE_SURCHARGE_REQUEST,
    payload: { data },
  };
};

export const createSurchargeSuccess = (data) => {
  return {
    type: types.CREATE_SURCHARGE_SUCCESS,
    payload: { data },
  };
};

export const createSurchargeFail = (error: Error) => {
  return {
    type: types.CREATE_SURCHARGE_FAIL,
    payload: error,
  };
};

export const getListSurchargeRequest = (data) => {
  return {
    type: types.GET_LIST_SURCHARGE_REQUEST,
    payload: { data },
  };
};

export const getListSurchargeSuccess = (data) => {
  return {
    type: types.GET_LIST_SURCHARGE_SUCCESS,
    payload: { data },
  };
};

export const getListSurchargeFail = (error: Error) => {
  return {
    type: types.GET_LIST_SURCHARGE_FAIL,
    payload: { error },
  };
};

export const deleteSurchargeRequest = (id: string) => {
  return {
    type: types.DELETE_SURCHARGE_REQUEST,
    payload: id,
  };
};

export const deleteSurchargeSuccess = () => {
  return {
    type: types.DELETE_SURCHARGE_SUCCESS,
  };
};

export const deleteSurchargeFail = (data) => {
  return {
    type: types.DELETE_SURCHARGE_FAIL,
    payload: { data },
  };
};

export const updateSurchargeRequest = (data) => {
  return {
    type: types.UPDATE_SURCHARGE_REQUEST,
    payload: { data },
  };
};

export const updateSurchargeSuccess = (data) => {
  return {
    type: types.UPDATE_SURCHARGE_SUCCESS,
    payload: { data },
  };
};

export const updateSurchargeFail = (error: Error) => {
  return {
    type: types.UPDATE_SURCHARGE_FAIL,
    payload: { error },
  };
};
