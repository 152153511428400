export const GET_SERVICE_AREAS_REQUEST = 'instalent/GET_SERVICE_AREAS_REQUEST';
export const GET_SERVICE_AREAS_SUCCESS = 'instalent/GET_SERVICE_AREAS_SUCCESS';
export const GET_SERVICE_AREAS_FAIL = 'instalent/GET_SERVICE_AREAS_FAIL';

export const DELETE_SERVICE_AREAS_REQUEST =
  'instalent/DELETE_SERVICE_AREAS_REQUEST';
export const DELETE_SERVICE_AREAS_SUCCESS =
  'instalent/DELETE_SERVICE_AREAS_SUCCESS';
export const DELETE_SERVICE_AREAS_FAIL = 'instalent/DELETE_SERVICE_AREAS_FAIL';

export const GET_ID_CITY_REQUEST = 'instalent/GET_ID_CITY';
export const GET_ID_CITY_SUCCESS = 'instalent/GET_ID_CITY_SUCCESS';
export const GET_ID_CITY_FAIL = 'instalent/GET_ID_CITY_FAIL';

export const CREATE_SERVICE_AREA_REQUEST =
  'instalent/CREATE_SERVICE_AREA_REQUEST';
export const CREATE_SERVICE_AREA_SUCCESS =
  'instalent/CREATE_SERVICE_AREA_SUCCESS';
export const CREATE_SERVICE_AREA_FAIL = 'instalent/CREATE_SERVICE_AREA_FAIL';

export const UPDATE_SERVICE_AREA_REQUEST =
  'instalent/UPDATE_SERVICE_AREA_REQUEST';
export const UPDATE_SERVICE_AREA_SUCCESS =
  'instalent/UPDATE_SERVICE_AREA_SUCCESS';
export const UPDATE_SERVICE_AREA_FAIL = 'instalent/UPDATE_SERVICE_AREA_FAIL';
