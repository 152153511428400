import { IListServiceProvider, IServiceProviderReducer } from '../../interface';
import * as types from '../constants';

export const initialState: IServiceProviderReducer = {
  isOpenModal: false,
  isAddProof: false,
  isSchedule: false,
  isScheduleDetail: false,
  isRequesting: false,
  infoPage: {},
  ScheduleDetail: {},
  listServiceProvider: [],
  isAction: false,
  socialNetworks: [],
};

const serviceProviderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_LIST_SERVICE_PROVIDER_REQUEST:
      return {
        ...state,
        listServiceProvider: [],
        isRequesting: true,
      };
    case types.GET_LIST_SERVICE_PROVIDER_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        listServiceProvider: action.payload.listData,
        infoPage: action.info,
      };
    case types.GET_LIST_SERVICE_PROVIDER_FAIL:
      return {
        ...state,
        isRequesting: false,
        listServiceProvider: [],
      };
    case types.UPDATE_PROVIDER_REQUEST:
      return {
        ...state,
        isAction: true,
      };
    case types.UPDATE_PROVIDER_SUCCESS:
      return {
        ...state,
        listServiceProvider: state.listServiceProvider.filter(
          (item: IListServiceProvider) => item.id !== action.payload.id
        ),
        isAction: false,
      };
    case types.UPDATE_PROVIDER_FAIL:
      return {
        ...state,
        isAction: false,
      };
    case types.ADD_PROOF_REQUEST:
      return {
        ...state,
      };
    case types.ADD_PROOF_SUCCESS:
      return {
        ...state,
        isAddProof: false,
      };
    case types.ADD_PROOF_FAIL:
      return {
        ...state,
        isAddProof: false,
      };
    case types.ADD_SCHEDULE_REQUEST:
      return {
        ...state,
      };
    case types.ADD_SCHEDULE_SUCCESS:
      return {
        ...state,
        isSchedule: false,
        isScheduleDetail: false,
        listServiceProvider: state.listServiceProvider.filter(
          (item: IListServiceProvider) => item.id !== action.payload.id
        ),
      };
    case types.ADD_SCHEDULE_FAIL:
      return {
        ...state,
        isSchedule: false,
        isScheduleDetail: false,
      };
    case types.CLOSE_MODAL: {
      return {
        ...state,
        isOpenModal: false,
      };
    }
    case types.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
      };
    }
    case types.CLOSE_MODAL_ADD_PROOF: {
      return {
        ...state,
        isAddProof: false,
      };
    }
    case types.OPEN_MODAL_ADD_PROOF: {
      return {
        ...state,
        isAddProof: true,
      };
    }
    case types.CLOSE_MODAL_SCHEDULE: {
      return {
        ...state,
        isSchedule: false,
      };
    }
    case types.OPEN_MODAL_SCHEDULE: {
      return {
        ...state,
        isSchedule: true,
      };
    }

    case types.SHOW_MODAL_SCHEDULE_DETAIL: {
      return {
        ...state,
        isScheduleDetail: true,
      };
    }
    case types.HIDE_MODAL_SCHEDULE_DETAIL: {
      return {
        ...state,
        isScheduleDetail: false,
      };
    }
    case types.GET_SCHEDULE_INTERVIEW_REQUEST:
      return {
        ...state,
        ScheduleDetail: {},
      };
    case types.GET_SCHEDULE_INTERVIEW_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        ScheduleDetail: action.payload,
      };
    case types.GET_SCHEDULE_INTERVIEW_FAIL:
      return {
        ...state,
        isRequesting: false,
        ScheduleDetail: {},
      };
    case types.GET_SOCIAL_NETWORK_SUCCESS:
      return {
        ...state,
        socialNetworks: action.payload,
      };
    case types.UPDATE_STATUS_REVIEW_SOCIAL_NETWORK_REQUEST:
      return {
        ...state,
        isAction: true,
      };
    case types.UPDATE_STATUS_REVIEW_SOCIAL_NETWORK_SUCCESS:
      return {
        ...state,
        socialNetworks: state.socialNetworks.map((item: any) =>
          item.id === action.payload.id
            ? { ...item, verified: action.payload.verified }
            : item
        ),
        isAction: false,
      };
    case types.UPDATE_STATUS_REVIEW_SOCIAL_NETWORK_FAIL:
      return {
        ...state,
        isAction: false,
      };
    default:
      return state;
  }
};

export default serviceProviderReducer;
