import { put, takeLatest } from 'redux-saga/effects';

import * as types from '../constants';
import * as actionTypes from '../actions';
import * as AppAction from 'src/containers/App/store/actions';
import {
  addStaff,
  deleteStaff,
  getStaffService,
  updateStaff,
} from '../services';
import { showSnackbar } from '../../../Snackbar/store/actions';
import { hideModal } from 'src/containers/App/store/actions';

function* getStaffSaga(action) {
  try {
    const response = yield getStaffService(action.payload);
    yield put(actionTypes.getStaffSuccess(response.data.admins));
  } catch (error: any) {
    const message = { error };

    yield put(actionTypes.getStaffFail(message.error.graphQLErrors[0].code));
  }
}

function* deleteStaffSaga(action) {
  try {
    yield deleteStaff(action.payload);
    yield put(actionTypes.deleteStaffSuccess());
    yield put(hideModal());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
  } catch (error: any) {
    const message = { error };
    yield put(actionTypes.deleteStaffFail(message.error.graphQLErrors[0].code));
    yield put(hideModal());
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Updated Failed',
      })
    );
  }
}

function* updateStaffSaga(action) {
  try {
    yield updateStaff(action.payload);
    yield put(actionTypes.updateStaffSuccess());
    yield put(AppAction.hideModalUpdate());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
  } catch (error: any) {
    const message = { error };
    yield put(actionTypes.updateStaffFail(message.error.graphQLErrors[0].code));
    yield put(AppAction.hideModalUpdate());
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Updated Failed',
      })
    );
  }
}

function* addStaffSaga(action) {
  try {
    console.log('action.payload', action.payload);
    yield addStaff(action.payload);
    yield put(actionTypes.addStaffSuccess());
    yield put(AppAction.hideModalAdd());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Added Succeed',
      })
    );
  } catch (error: any) {
    const message = { error };
    yield put(actionTypes.addStaffFail(message.error.graphQLErrors[0].code));
    yield put(AppAction.hideModalAdd());
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Added Failed',
      })
    );
  }
}

export default function* StaffSaga() {
  yield takeLatest(types.GET_STAFF_REQUEST, getStaffSaga);
  yield takeLatest(types.DELETE_STAFF_REQUEST, deleteStaffSaga);
  yield takeLatest(types.UPDATE_STAFF_REQUEST, updateStaffSaga);
  yield takeLatest(types.ADD_STAFF_REQUEST, addStaffSaga);
}
