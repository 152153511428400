import FilterNewPartner from './components/FilterNewPartner';
import TableNewPartner from './components/TableNewPartner';
import NewPartnersHook from './NewPartnersHook';

import { useStyles } from './styles';

function NewPartnerContainer() {
  const classes = useStyles();
  const {
    page,
    rowsPerPage,
    search,
    handleChangeRowsPerPage,
    handleChangePage,
    handleSubmitFilter,
    onResetFilter,
    handleSearch,
  } = NewPartnersHook();

  return (
    <div className={classes.deviceContainer}>
      <FilterNewPartner
        handleSubmitFilter={handleSubmitFilter}
        search={search}
        onResetFilter={onResetFilter}
        handleSearch={handleSearch}
      />
      <TableNewPartner
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default NewPartnerContainer;
