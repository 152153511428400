import gql from 'graphql-tag';
import { client } from 'src';

export const getApiUserDetail = (id) => {
  return client.query({
    query: gql`
    query {
      getProfileById (id : ${id}) {
          id
    			images_certificate_of_vaccination {
            id
            url
            imagable_id
            imagable_type
    			}
    			images_certificate {
            id
            url
            imagable_id
            imagable_type
    			}
          images_identity_card_by_admin {
            identity_card_back_side,
            identity_card_front_side
          }
          profileSocialNetwork {
            id
            profile_id
            type
            link
            follow
            verified
          }
          name
          city_name
          avatar
          email
          role_name
          role_id
          gender
          date_of_birth
          online_status
          available_work_status
          accountRevenue
          accountBalance
          bank_account {
            id
            profile_id
            bank_account
            status
            payment_unit_id
            payment_unit {
              name
              fullname
              id
            }
            created_at
            updated_at
          }
          services {
            name
          }
          reviews {
            id
            booking_id
            profile_id
            star
            content
            created_at
            updated_at
            booking {
              service {
                name
              }
            }
          }
          status
          phone
          created_at
          updated_at
        
      }
    }
    `,
    fetchPolicy: 'network-only',
  });
};
// update Profile
export const updateProfile = async ({ id, data }) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      updateProfile(profile_id:${id} , data : {
        date_of_birth: "${data.date_of_birth}",
        name: "${data.name}",
        gender: "${data.gender}",
        avatar: "${data.avatar}"
      })
    }
    `,
  });
};

//update Social
export const updateSocial = async ({ id, data }) => {
  console.log('id,data', { id, data });
  return await client.mutate({
    mutation: gql`
    mutation {
      updateSocial(id: ${id} , data : {
        facebook: "${data.facebook}",
        twitter: "${data.twitter}",
        linkedin: "${data.linkedin}"
      })
    }
    `,
  });
};
// GET API HistoryBooking
export const getApiHistoryBooking = async (id) => {
  return client.query({
    query: gql`
    query {
      historyBookingByUser(id: ${id} ) {
        data {
          id
          qty
          city_id
          booking_time
          full_address
          schedule
          request_profile_id
          recipient_profile_id
          service {
            name
            id
          }
          status
          review {
            star
            content
          }
        }
        total
      }
    }
    `,
    fetchPolicy: 'network-only',
  });
};

export const getSocial = (id: string) => {
  return {
    facebook: 'https://www.facebook.com',
    twitter: 'https://twitter.com',
    linkedin: 'https://linkedin.com',
  };
};
// GET API Schedule
export const getApiScheduleUser = async (data) => {
  return {
    '1': {
      id: '1',
      title: 'Booking Nail',
      date: '2021-07-10T10:30:00',
      status: 'pending',
      backgroundColor: '#ffb400',
    },
    '2': {
      id: '2',
      title: 'Booking Spa',
      date: '2021-07-15T21:20:00',
      status: 'done',
      backgroundColor: '#4caf50',
    },
    '3': {
      id: '3',
      title: 'Booking Spa',
      date: '2021-07-13T21:20:00',
      status: 'done',
      backgroundColor: '#4caf50',
    },
    '4': {
      id: '4',
      title: 'Booking Mask',
      date: '2021-07-14T10:30:00',
      status: 'done',
      backgroundColor: '#4caf50',
    },
    '5': {
      id: '5',
      title: 'Booking Spa',
      date: '2021-07-15T21:10:00',
      status: 'done',
      backgroundColor: '#4caf50',
    },
    '6': {
      id: '6',
      title: 'Booking Baber',
      date: '2021-07-12T10:30:00',
      status: 'cancel',
      backgroundColor: '#f94839',
    },
  };
};
// EDIT API Schedule
export const updateApiScheduleUser = async (data) => {
  const updateSchedule = await client.mutate({
    mutation: gql`
      mutation {
      }
      `,
  });
  return updateSchedule;
};
// DELETE API Schedule
export const deleteApiScheduleUser = async (data) => {
  const deleteSchedule = await client.mutate({
    mutation: gql`
      mutation {
      }
      `,
  });
  return deleteSchedule;
};

export const addMoneyPartnerService = async ({ id, data }) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      addTransaction(amount: ${data.amount}, profile_id: ${id}, reason: "${data.content}")
    }
    `,
  });
};
