import { Grid } from '@material-ui/core';
import TableWithDraw from '../components/TableWithdraw';
import { IWithdrawPartnerView } from '../interfaces';

const WithdrawPartnerView = (props: IWithdrawPartnerView) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        {/* <FilterWithdraw />Î */}
      </Grid>
      <Grid item xs={12}>
        <TableWithDraw {...props} />
      </Grid>
    </Grid>
  );
};

export default WithdrawPartnerView;
