import { DialogActions, DialogContent, Grid } from '@material-ui/core';
import ButtonComponent from 'src/components/ButtonComponent';
import TextInput from 'src/components/InputFiled';
import { IUsers } from 'src/containers/ListServiceRequest/interface';

interface IBlockUser {
  data: IUsers | null;
  untilBlock: string;
  handleChangeUnitBlock: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlockUser: () => void;
  onClose: () => void;
  messageError: string;
}

const BlockUser = ({
  data,
  untilBlock,
  handleChangeUnitBlock,
  handleBlockUser,
  onClose,
  messageError,
}: IBlockUser) => {
  return (
    <>
      <DialogContent style={{ padding: 32 }}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            Profile
          </Grid>
          <Grid item md={6} xs={12}>
            <TextInput
              label="Name"
              value={data?.name}
              name="booking_time"
              variant="outlined"
              type="text"
              disabled
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextInput
              label="Phone"
              value={data?.phone}
              name="booking_time"
              variant="outlined"
              type="text"
              disabled
            />
          </Grid>

          <Grid item md={12} xs={12}>
            Block Unit
          </Grid>
          <Grid item md={12} xs={12}>
            <TextInput
              label="Unit"
              value={untilBlock}
              name="booking_time"
              variant="outlined"
              onChange={handleChangeUnitBlock}
              type="datetime-local"
              required
              error={messageError}
              helperText={messageError}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: new Date().toISOString().slice(0, 16),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <ButtonComponent status="cancel" handleAction={onClose}>
          Cancel
        </ButtonComponent>
        <ButtonComponent handleAction={handleBlockUser}>Block</ButtonComponent>
      </DialogActions>
    </>
  );
};

export default BlockUser;
