import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    height: 'calc(100vh - 46px)',
    overflowY: 'auto',
    borderRadius: 0,
  },
  layoutContent: {
    height: 'calc(100% - 46px)',
    padding: theme.spacing(1, 5),
  },
  root: {
    backgroundColor: '#f5f5f5dd !important',
    '& .MuiPaper-root': {
      backgroundColor: '#f5f5f5dd !important',
    },
  },
  rootDark: {
    backgroundColor: '#424242bf !important',
    '& .MuiPaper-root': {
      backgroundColor: '#424242bf !important',
    },
  },
}));

export { useStyles };
