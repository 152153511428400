import { useState } from 'react';
import { IListWithdraw } from '../interfaces';

interface IOpen {
  isOpen: boolean;
  detail: IListWithdraw;
}

const useFetchWithdraw = () => {
  const [open, setOpen] = useState<IOpen>({
    isOpen: false,
    detail: {
      id: '',
      profile_id: '',
      amount: null,
      bank_account_id: '',
      status: '',
      profiles: { name: '', phone: '' },
      bank_account: {
        id: '',
        profile_id: null,
        bank_account: '',
        status: false,
        payment_unit: {
          name: '',
          fullname: '',
        },
      },
    },
  });

  const handleClickOpen = (detail: IListWithdraw) => {
    setOpen({
      isOpen: true,
      detail,
    });
  };

  const handleClose = () => {
    setOpen({
      isOpen: false,
      detail: {
        id: '',
        profile_id: '',
        amount: null,
        bank_account_id: '',
        status: '',
        profiles: { name: '', phone: '' },
        bank_account: {
          id: '',
          profile_id: null,
          bank_account: '',
          status: false,
          payment_unit: {
            name: '',
            fullname: '',
          },
        },
      },
    });
  };

  return {
    open,
    handleClickOpen,
    handleClose,
  };
};

export default useFetchWithdraw;
