import { AuthenticatedRoute } from '../components/routes';
import ServicesContainer  from '../containers/Services'

const ServicesRoutes = [
  {
    path: '/services',
    title: 'Services',
    component: ServicesContainer,
    route: AuthenticatedRoute,
    roles: [],
    permission: [],
    exact: true,
  },
];


export default ServicesRoutes;