import { Menu, makeStyles } from '@material-ui/core';

import ButtonComponent from 'src/components/ButtonComponent';
import { StatusRequest } from 'src/containers/ServiceRequest/contants';
import { IDataLoading } from '../../interface';

type MenuActionServiceRequestProps = {
  handleActionApprove: () => void;
  handleActionDecline: () => void;
  isLoading: boolean;
  dataLoading: IDataLoading;
  item: any;
  open: boolean;
  anchorEl: null | HTMLElement;
  onClose: () => void;
};

export const MenuActionServiceRequest = (
  props: MenuActionServiceRequestProps
) => {
  const {
    handleActionApprove,
    handleActionDecline,
    isLoading,
    dataLoading,
    item,
    open,
    anchorEl,
    onClose,
  } = props;

  const classes = useStyles();

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={onClose}
    >
      <div className={classes.listMenu}>
        <ButtonComponent
          handleAction={handleActionApprove}
          status="approve"
          disable={isLoading || item.status === StatusRequest.APPROVED}
          isLoading={
            dataLoading?.id === item.id && dataLoading?.status === 'APPROVED'
          }
        >
          Approve
        </ButtonComponent>
        <ButtonComponent
          handleAction={handleActionDecline}
          status="cancel"
          disable={isLoading || item.status === StatusRequest.DECLINED}
          isLoading={
            dataLoading.id === item.id && dataLoading.status === 'DECLINED'
          }
        >
          Decline
        </ButtonComponent>
      </div>
    </Menu>
  );
};

const useStyles = makeStyles((theme) => ({
  listMenu: {
    padding: theme.spacing(0, 1),
    '& div': {
      paddingBottom: 8,
    },
  },
}));
