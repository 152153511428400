import { gql } from '@apollo/client';

import { client } from 'src';

export const getServicePartner = async (id) => {
  return await client.query({
    query: gql`
      query {
        getServiceRequest(id: ${id}) {
          id
            profile_id
            service_id
            admin {
              name
            }
            images {
              id
              url
              imagable_id
              imagable_type
              created_at
              updated_at
            }
            services {
              id
              name
              total
              avatars
              total
            }
            profile {
              name
            }
            status
            created_at
            updated_at
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};
