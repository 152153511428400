import { TablePagination } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom';
import ButtonComponent from 'src/components/ButtonComponent';
import DialogComponent from 'src/components/DialogComponent';
import MuiTable from 'src/components/MuiTable';
import { COLUMS } from '../../contants';
import useFetchWithdraw from '../../hooks/useWithDrawDetail';
import { IListWithdraw, IWithdrawPartnerView } from '../../interfaces';
import WithdrawDetail from '../WithdrawDetail/index';

const objStatus = {
  open: '#FFFF',
  inprocessing: '#ffeb3b',
  approved: '#4caf50',
  rejected: '#aa2e25',
};

const TableWithDraw = (props: IWithdrawPartnerView) => {
  const {
    listWithdraw,
    isRequesting,
    total,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;

  const { open, handleClickOpen, handleClose } = useFetchWithdraw();

  const row = (item: IListWithdraw) => {
    return {
      id: (
        <div>
          <p>{item.id}</p>
        </div>
      ),
      partner: (
        <div>
          <Link
            style={{ textDecoration: 'none', color: '#3e50b4' }}
            to={`/partner/${item.profile_id}`}
          >
            {item?.profiles?.name}
          </Link>
        </div>
      ),
      amount: (
        <div>
          <p>
            {new Intl.NumberFormat('de-DE', {
              style: 'currency',
              currency: 'VND',
            }).format(item.amount!)}
          </p>
        </div>
      ),
      status: (
        <div>
          <p style={{ color: objStatus[item.status] }}>
            {item.status.toLocaleUpperCase()}
          </p>
        </div>
      ),
      verified: (
        <div>
          <p>
            {item?.bank_account?.status ? (
              <VerifiedUserIcon style={{ color: '#4caf50' }} />
            ) : (
              <CancelIcon style={{ color: '#f44336' }} />
            )}
          </p>
        </div>
      ),
      action: (
        <div>
          <div style={{ width: '100px' }}>
            <ButtonComponent
              status="origin"
              handleAction={() => handleClickOpen(item)}
              disable={item.status === 'approved'}
            >
              Update
            </ButtonComponent>
          </div>
        </div>
      ),
    };
  };

  const skeletonRowData = <Skeleton variant="rect" animation="wave" />;

  const rowError = {
    id: <div>{skeletonRowData}</div>,
    partner: <div>{skeletonRowData}</div>,
    amount: <div>{skeletonRowData}</div>,
    status: <div>{skeletonRowData}</div>,
    verified: <div>{skeletonRowData}</div>,
    action: <div>{skeletonRowData}</div>,
  };

  const renderRow = isRequesting
    ? new Array(10).fill(0).map((i) => rowError)
    : listWithdraw?.map(row);

  const pagination = (
    <div>
      <TablePagination
        component="div"
        count={total || 10}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );

  return (
    <div>
      <MuiTable headers={COLUMS} rows={renderRow} pagination={pagination} />
      <DialogComponent
        title={open.detail?.profiles?.name}
        open={open.isOpen}
        onClose={handleClose}
      >
        <WithdrawDetail detail={open.detail} onClose={handleClose} />
      </DialogComponent>
    </div>
  );
};

export default TableWithDraw;
