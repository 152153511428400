import { useSelector } from 'react-redux';

import AvatarUser from './components/AvatarUser';
import UserInfo from './components/UserProfile';
import SocialUser from './components/Social';
import { ISelector } from 'src/reducers';

function LeftSide() {
  const userDetail = useSelector((s: ISelector) => s.user?.userDetail);
  return (
    <>
      <AvatarUser avtUrl={userDetail?.avatar} name={userDetail?.name} />
      <UserInfo />
      <SocialUser profileSocialNetwork={userDetail?.profileSocialNetwork} />
    </>
  );
}

export default LeftSide;
