import { AuthenticatedRoute } from '../components/routes';
import ListServiceRequest from 'src/containers/ListServiceRequest';

const listServiceRequestRoutes = [
  {
    path: '/partners/service-request',
    title: 'Service Provider',
    component: ListServiceRequest,
    route: AuthenticatedRoute,
    roles: [],
    permission: [],
    exact: true,
  },
];

export default listServiceRequestRoutes;
