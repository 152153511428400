import { useState, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import clsx from 'clsx';

import { muiDarkTheme, muiTheme } from '../../utils/theme';
import Header from './Header';

import { useStyles } from './styles';

function Layout(props: any) {
  const { children } = props;
  const classes = useStyles();
  const mode = localStorage.getItem('instalent-them-mode');

  const [themeMode, setThemeMode] = useState('');

  useEffect(() => {
    if (!mode) {
      let theme = window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';
      localStorage.setItem('instalent-them-mode', theme);
      setThemeMode(theme);
    } else {
      setThemeMode(mode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getMuiTheme = () => (themeMode === 'dark' ? muiDarkTheme : muiTheme);
  const onDarkMode = (status: any) => setThemeMode(status);

  return (
    <MuiThemeProvider theme={{ ...getMuiTheme() }}>
      <Header setThemeMode={onDarkMode} onDarkMode={onDarkMode} />
      <Paper
        className={clsx(classes.layoutContainer, {
          [classes.root]: themeMode === 'light',
          [classes.rootDark]: themeMode === 'dark',
        })}
      >
        <div className={classes.layoutContent}>{children}</div>
      </Paper>
    </MuiThemeProvider>
  );
}

export default Layout;
