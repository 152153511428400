import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import ButtonComponent from 'src/components/ButtonComponent';
import TextInput from 'src/components/InputFiled';
import * as StaffAction from '../../store/actions';
import * as AppAction from 'src/containers/App/store/actions';
import { dataStaffUpdate, statusSelect } from '../../contants';
import SelectOption from 'src/components/Selection';
import { IListStaff } from '../../interface';

import { useStyles } from './styles';

const validate = Yup.object().shape({
  email: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
  status: Yup.number().required('Required'),
});

interface IUpdateStaff {
  detail: IListStaff;
}

function UpdateStaff({ detail }: IUpdateStaff) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleCancel = () => dispatch(AppAction.hideModalUpdate());

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: detail.email,
      password: detail.password,
      status: detail.status,
    },
    validationSchema: validate,
    onSubmit: (values) => {
      dispatch(StaffAction.updateStaffRequest({ id: detail.id, data: values }));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {dataStaffUpdate(formik.values).map((item: any) => (
        <div key={item.name} className={classes.formContent}>
          <TextInput
            value={item.value}
            onChange={formik.handleChange}
            label={item.label}
            type={item.type}
            name={item.name}
            variant="outlined"
            required
            error={
              formik.touched[item.name] && Boolean(formik.errors[item.name])
            }
            helperText={formik.touched[item.name] && formik.errors[item.name]}
          />
        </div>
      ))}

      <div className={classes.formContent}>
        <SelectOption
          label="Status"
          options={statusSelect}
          name="status"
          onChangeOption={formik.handleChange}
          value={formik.values.status}
        />
      </div>

      <div className={classes.actionSubmit}>
        <Button onClick={handleCancel}>Cancel</Button>
        <ButtonComponent status="approve">Submit</ButtonComponent>
      </div>
    </form>
  );
}

export default UpdateStaff;
