import { gql } from '@apollo/client';
import { client } from 'src';

export const getHistoryTransactionService = async ({
  page,
  rowsPerPage,
  id,
  filter,
}) => {
  return await client.query({
    query: gql`
    query {
      historyTransactionPartner(id: ${id} , page: ${page} , per_page: ${rowsPerPage} ,filter: {
        date: "${filter.date}"
      } ) {
        amountByDateRange,
        amounts,
        data {
          id
          user_id
          booking_id
          service_id
          amount
          type
          created_at
          updated_at
          service {
            name
          }
          user {
            name
            user_id
            id
          }
          booking {
            status
            booking_time
          }
        }
      }
    }
    `,
    fetchPolicy: 'network-only',
  });
};
