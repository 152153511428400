import { Grid, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

import ToggleComponent from "src/components/ToggleComponent";
import { ISelector } from "src/reducers";

function BookingImages() {
  const classes = useStyles();

  const { bookingDetail } = useSelector((s: ISelector) => s.booking);

  return (
    <ToggleComponent title="Images booking">
      <Grid container className={classes.container}>
        {bookingDetail?.images_urls ? (
          bookingDetail?.images_urls?.map((item) => (
            <img
              className={classes.imageList}
              src={item.url}
              alt={item.imagable_type}
            />
          ))
        ) : (
          <div>No images</div>
        )}
      </Grid>
    </ToggleComponent>
  );
}

export default BookingImages;

const useStyles = makeStyles({
  imageList: {
    width: 'auto',
    height: 350,
    padding: 4,
  },
  container: {
    maxHeight: 660,
    overflow: "auto",
    dispatch: "flex",
    justifyContent: "center",
    padding: "16px 0px",
  },
});
