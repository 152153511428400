import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import { TablePagination } from '@material-ui/core';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import { COLUMS } from '../../constants';
import MuiTable from 'src/components/MuiTable';
import ButtonComponent from 'src/components/ButtonComponent';
import DialogMui from 'src/components/DialogMui';
import { ISelector } from 'src/reducers';
import DialogComponent from 'src/components/DialogComponent';
import CreateService from '../CreateService';
import * as ServicesAction from '../../store/actions';
import * as AppAction from 'src/containers/App/store/actions';
import UpdateService from '../CreateService/UpdateService';
import { IListServices, IListServiceView } from '../../interface';

import { useStyles } from './styles';

function TableServices({
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}: IListServiceView) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isRequesting = useSelector((s: ISelector) => s.services?.isRequesting);
  const listServices = useSelector((s: ISelector) => s.services?.listServices);
  const totalServices = useSelector(
    (s: ISelector) => s.services?.totalServices
  );
  const isAddService = useSelector((s: ISelector) => s.services?.isAddService);
  const isUpdateService = useSelector(
    (s: ISelector) => s.services?.isUpdateService
  );
  const isModal = useSelector((s: ISelector) => s.global?.isModal);

  const [serviceDetail, setServiceDetail] = useState<IListServices | any>();
  const [id, setId] = useState<string>('');

  const handleDeleteService = () =>
    dispatch(ServicesAction.deleteServicesRequest(id));

  const handleOpenModalAddService = () =>
    dispatch(ServicesAction.showModalAddService());

  const handleOpenModalEditService = (item) => () => {
    dispatch(ServicesAction.showModalUpdateService());
    setServiceDetail(item);
  };

  const handleCloseModalAddService = () =>
    dispatch(ServicesAction.hideModalAddService());
  const handleCloseModalEditService = () =>
    dispatch(ServicesAction.hideModalUpdateService());
  const handleCloseModal = () => dispatch(AppAction.hideModal());
  const handleOpenModal = (id) => () => {
    dispatch(AppAction.showModal());
    setId(id);
  };

  const row = (item) => {
    return {
      id: (
        <div>
          <p> {item.id}</p>
        </div>
      ),
      name: (
        <div>
          <p> {item.name}</p>
        </div>
      ),
      price: (
        <div>
          <p>
            {new Intl.NumberFormat('de-DE', {
              style: 'currency',
              currency: 'VND',
            }).format(item.price)}
          </p>
        </div>
      ),
      min: (
        <div>
          <p>{item.min}</p>
        </div>
      ),
      max: (
        <div>
          <p>{item.max}</p>
        </div>
      ),
      unit: (
        <div>
          <p>{item.unit}</p>
        </div>
      ),
      immediately_surcharge: (
        <div>
          <p>{item.immediately_surcharge}</p>
        </div>
      ),
      manual_surcharge: (
        <div>
          <p>{item.manual_surcharge}</p>
        </div>
      ),
      description: (
        <div>
          <p>{item.description}</p>
        </div>
      ),
      create_at: (
        <div>
          <p>{moment(item.created_at).format('DD-MM-YYYY HH:mm:ss')}</p>
        </div>
      ),
      updated_at: (
        <div>
          <p>{moment(item.updated_at).format('DD-MM-YYYY HH:mm:ss')}</p>
        </div>
      ),
      action: (
        <div>
          <div className={classes.bntAction}>
            <ButtonComponent
              status="approve"
              handleAction={handleOpenModalEditService(item)}
            >
              <EditIcon fontSize="small" />
            </ButtonComponent>
            <ButtonComponent
              status="cancel"
              handleAction={handleOpenModal(item.id)}
            >
              <DeleteIcon fontSize="small" />
            </ButtonComponent>
          </div>
        </div>
      ),
    };
  };

  const skeletonRowData = (
    <div>
      <Skeleton variant="rect" animation="wave" />
    </div>
  );

  const formatData = (keys) => {
    const obj = {};
    keys.forEach((key) => (obj[key] = skeletonRowData));
    return obj;
  };

  const rowError = formatData([
    'id',
    'name',
    'price',
    'min',
    'max',
    'unit',
    'immediately_surcharge',
    'manual_surcharge',
    'description',
    'create_at',
    'updated_at',
    'action',
  ]);

  const pagination = (
    <div>
      <TablePagination
        className={classes.container}
        component="div"
        count={totalServices || 10}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );

  const renderRow = isRequesting
    ? new Array(10).fill(0).map((i) => rowError)
    : listServices?.map(row);

  return (
    <div className={classes.root}>
      <DialogComponent
        title="Create a service"
        open={isAddService}
        onClose={handleCloseModalAddService}
      >
        <CreateService />
      </DialogComponent>

      <DialogComponent
        title="Edit a service"
        open={isUpdateService}
        onClose={handleCloseModalEditService}
      >
        <UpdateService detail={serviceDetail} />
      </DialogComponent>

      <DialogMui
        open={isModal}
        onClose={handleCloseModal}
        onConfirm={handleDeleteService}
      />

      <div className={classes.headContain}>
        <ButtonComponent
          status="approve"
          handleAction={handleOpenModalAddService}
          icon={<AddIcon fontSize="small" />}
        >
          Create a Service
        </ButtonComponent>
      </div>
      <div className={classes.boxTable}>
        <MuiTable headers={COLUMS} rows={renderRow} pagination={pagination} />
      </div>
    </div>
  );
}

export default TableServices;
