import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& a': {
      textDecoration: 'none',
      color: '#3e50b4',
    },
  },
  container: {
    maxHeight: 440,
  },
  listService: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    overflow: 'auto',
    '-ms-overflow-style': 'none',
  },
  danger: {
    color: '#f94839',
  },
  active: {
    color: '#4caf50',
  },
  boxTable: {
    border: '1px none',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
  online: {
    color: '#4CAF50',
  },
  check: {
    color: '#4CAF50',
  },
  cancle: {
    color: '#F94839',
  },
  rowName: {
    display: 'flex',
    alignItems: 'center',
    '& >div': {
      marginRight: theme.spacing(1),
    },
  },
}));
export { useStyles };
