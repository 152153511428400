import { IDataField } from '../interface';

export const COLUMNS = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'User ID',
    field: 'user_id',
  },
  {
    title: 'Email',
    field: 'email',
  },
  {
    title: 'City',
    field: 'city',
  },
  {
    title: 'Phone',
    field: 'phone',
  },
  {
    title: 'Day of Birth',
    field: 'date_of_birth',
  },
  {
    title: 'Online Status',
    field: 'online_status',
  },
  {
    title: 'Status',
    field: 'status',
  },
  {
    title: 'Block until',
    field: 'block_until',
  },
  {
    title: 'Deleted at',
    field: 'deleted_at',
  },
  {
    title: 'Created at',
    field: 'created_at',
  },
  {
    title: 'Updated at',
    field: 'updated_at',
  },
  {
    title: 'Action',
    field: 'action',
  },
];

export const dataField = (data: IDataField) => [
  {
    name: 'name',
    label: 'Name',
    value: data.name,
  },
  {
    name: 'email',
    label: 'Email',
    value: data.email,
  },
  {
    name: 'phone',
    label: 'Phone',
    value: data.phone,
  },
];
