import { IGetBooking, IGetCalenderBooking } from '../../interface';
import * as types from '../constants';

export const getBookings = function (params: IGetBooking) {
  return {
    type: types.GET_BOOKINGS_REQUEST,
    payload: params,
  };
};

export const getBookingsSuccess = (data) => {
  return {
    type: types.GET_BOOKINGS_SUCCESS,
    payload: { data },
  };
};

export const getBookingsFail = (error) => {
  return {
    type: types.GET_BOOKINGS_FAIL,
    payload: error,
  };
};

export const getCalenderBookings = function (params: IGetCalenderBooking) {
  return {
    type: types.GET_CALENDER_BOOKING_REQUEST,
    payload: params,
  };
};

export const getCalenderBookingsSuccess = (data) => {
  return {
    type: types.GET_CALENDER_BOOKING_SUCCESS,
    payload: { data },
  };
};

export const getCalenderBookingsFail = (error) => {
  return {
    type: types.GET_CALENDER_BOOKING_FAIL,
    payload: error,
  };
};

