import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    width: '90%',
  },
  contentSocial: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  iconSocial: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    width: '40%',
    fontSize: 14,
    '& >img': {
      marginRight: theme.spacing(1),
    },
  },
  url: {
    width: '58%',
    fontSize: 14,
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color:
      theme.palette.type === 'light'
        ? 'rgba(0, 0, 0, 0.87)'
        : 'rgba(255, 255, 255, 0.8)',
  },
  edit: {},
}));
export { useStyles };
