import * as types from '../constants';

export const getWithdrawRequest = function (params) {
  return {
    type: types.GET_WITHDRAW_REQUEST,
    payload: params,
  };
};

export const getWithdrawSuccess = (data) => {
  return {
    type: types.GET_WITHDRAW_SUCCESS,
    payload: { data },
  };
};

export const getWithdrawFail = (error) => {
  return {
    type: types.GET_WITHDRAW_FAIL,
    payload: error,
  };
};

export const updateStatusWithdrawRequest = function (params) {
  return {
    type: types.UPDATE_STATUS_WITHDRAW_REQUEST,
    payload: params,
  };
};

export const updateStatusWithdrawSuccess = (data) => {
  return {
    type: types.UPDATE_STATUS_WITHDRAW_SUCCESS,
    payload: { data },
  };
};

export const updateStatusWithdrawFail = (error) => {
  return {
    type: types.UPDATE_STATUS_WITHDRAW_FAIL,
    payload: error,
  };
};
