// import { useState } from 'react';
import TableView from '../components/TableView';
import FilterView from '../components/FilterView';
import { IUserView } from '../interface';

import { useStyles } from './styles';

function ServiceProviderView(props: IUserView) {
  const classes = useStyles();

  // const [rowSelecteds, setSelected] = useState<Array<any>>([]);

  // const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     setSelected(props.listDataProvider.map((item) => item.id));
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (id: string) => {
  //   const selectedIndex = rowSelecteds.indexOf(id);
  //   let newSelected: string[] = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(rowSelecteds, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(rowSelecteds.slice(1));
  //   } else if (selectedIndex === rowSelecteds.length - 1) {
  //     newSelected = newSelected.concat(rowSelecteds.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       rowSelecteds.slice(0, selectedIndex),
  //       rowSelecteds.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  return (
    <div className={classes.deviceContainer}>
      <FilterView
        search={props.search}
        handleSearch={props.handleSearch}
        onResetFilter={props.onResetFilter}
        handleSubmitFilter={props.handleSubmitFilter}
        // rowSelecteds={rowSelecteds}
      />
      <TableView
        page={props.page}
        rowsPerPage={props.rowsPerPage}
        handleChangeRowsPerPage={props.handleChangeRowsPerPage}
        handleChangePage={props.handleChangePage}
        handleEditListProvider={props.handleEditListProvider}
        // rowSelecteds={rowSelecteds}
        // handleSelectAllClick={handleSelectAllClick}
        // handleClick={handleClick}
      />
    </div>
  );
}
export default ServiceProviderView;
