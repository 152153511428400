import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import { useInjectReducer } from 'src/utils/injectReducer';
import { useInjectSaga } from 'src/utils/injectSaga';
import UserView from './views';
import reducer from './store/reducers';
import * as UserAction from './store/actions';
import saga from './store/sagas';
import { ParamTypes } from '../Booking/interface';
import reducerBooking from '../Booking/store/reducers';
import sagaBooking from '../Booking/store/sagas';
import * as BookingActions from '../Booking/store/actions';

function UserContainer() {
  useInjectReducer({ key: 'user', reducer });
  useInjectSaga({ key: 'user', saga });

  useInjectReducer({ key: 'booking', reducer: reducerBooking });
  useInjectSaga({ key: 'booking', saga: sagaBooking });

  const dispatch = useDispatch();
  const { id } = useParams<ParamTypes>();

  useEffect(() => {
    dispatch(BookingActions.getServiceRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(UserAction.getUserDetailActionRequest(id));
    dispatch(UserAction.getHistoryBookingUserRequest(id));
    dispatch(UserAction.getSocialRquest(id));
  }, [id, dispatch]);

  return <UserView />;
}

export default UserContainer;
