import { gql } from '@apollo/client';
import moment from 'moment';

import { client } from 'src';
import {
  IGetPageNumberResendService,
  IResendNotificationPageService,
} from '../../interface';

export const getBookingService = async (id: string) => {
  const booking = await client.query({
    query: gql`
    query {
      booking(id: ${id}) {
        id
        qty
        request_profile_id
        notes
        conversations {
          id
        }
        userRequest {
          id
          name
          email
          gender
          date_of_birth
          online_status
          available_work_status
          phone
          status
          created_at
          updated_at
          location 
            {
              coordinates
            }
        }
        recipient_profile_id
        recipientData {
          id
          role_id
          role_name
          name
          email
          gender
          date_of_birth
          online_status
          available_work_status
          phone
          status
          created_at
          updated_at
          location 
            {
              coordinates
            }
        }
        service_id
        city_id
        cityData {
          id
          name
          created_at
          updated_at
        }
        full_address
        booking_time
        schedule
        status
        location {
          type
          coordinates
        }
        created_at
        updated_at
        service {
          name
          id
          price
          min
          max
          is_new
          description
          child {
            name
            price
            description
            min
            max
            created_at
            updated_at
          }
        }
        review {
          star
          content
          created_at
          updated_at
        }
        images_urls {
          url
          imagable_id
          imagable_type
        }
        partner_join_booking {
          id
          user_id
          role_id
          avatar
          name
          email
          phone
        }
        discount_value
        discount_unit
        sub_total
        type
      }
  }
    `,
    fetchPolicy: 'network-only',
  });
  return booking;
};

export const updateBookingStatusService = async (
  id: string,
  status: string
) => {
  return await client.mutate({
    mutation: gql`
      mutation {
        updateStatusBookingByUser(id: ${id}, data: { status: ${status} })
      }
    `,
  });
};

export const updateBookingDoneStatusService = async (
  id: string,
) => {
  return await client.mutate({
    mutation: gql`
      mutation {
        updateBookingDoneAdmin(booking_id: ${id})
      }
    `,
  });
};

export const getService = async () => {
  return await client.query({
    query: gql`
      query {
        services(take: 100) {
          id
          name
        }
      }
    `,
  });
};

export const updateBookingService = async (id: string, data) => {
  return await client.mutate({
    mutation: gql`
      mutation {
        updateBooking(id: ${id} , data: {
          service_id: ${+data.service_id},
          qty: ${+data.qty} ,
          full_address: "${data.full_address}",
          booking_time: "${moment(data.booking_time).utc().toISOString()}",
          request_profile_id: ${+data.request_profile_id},
          recipient_profile_id: ${+data.recipient_profile_id}
        })
      }
    `,
  });
};

export const updateAssignBookingService = async ({
  booking_id,
  profile_id,
}) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      assignBookingRequest(booking_id: ${+booking_id} , profile_id: ${+profile_id}) 
    }
    `,
  });
};

export const deleteBookingService = async (id: string) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      deleteBooking(id :${+id})
    }
    `,
  });
};

export const getCancelBookingService = async (id: string) => {
  return await client.query({
    query: gql`
    query {
      getCancelbooking(booking_id: ${id}) {
        profile_id
        cancelation_reson
        created_at
        updated_at
        profile {
          name
          id
          phone
          
        }
      }
    }
    `,
  });
};

export const getPartnerReceiveService = async (booking_id: string) => {
  return await client.query({
    query: gql`
    query {
      getPartnerSkipOrReceiveNotificationByBookingId(booking_id: ${booking_id} , status: false ) {
        name
        email
        phone
        id
        avatar
        location {
          type
          coordinates
        }
      }
    }
    `,
  });
};

export const getPartnerSkipService = async (booking_id: string) => {
  return await client.query({
    query: gql`
    query {
      getPartnerSkipOrReceiveNotificationByBookingId(booking_id: ${booking_id} , status: true ) {
        name
        email
        phone
        id
      }
    }
    `,
  });
};

export const resendNotificationService = async (booking_id: string) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      resendNotificationPartner(booking_id: ${booking_id})
    }
    `,
  });
};

export const resendNotificationAvailableNotWorkService = async (
  booking_id: string
) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      sendNotifiPartnerNotAvailableWork(booking_id: ${booking_id})
    }
    `,
  });
};

export const getConversationService = async ({ conversationId, page }: any) => {
  return await client.query({
    query: gql`
    query {
      getMessage(data : {
        conversationId : ${conversationId},
        page: ${page}
      }) {
        page
        next_page
        per_page
        messages {
          id
          conversation_id
          profile_id
          content
          type
          is_seen
          created_at
          updated_at
        }
      }
    }
    `,
  });
};

export const getPageNumberResendService = async ({
  service_id,
  lat,
  long,
  request_profile_id,
}: IGetPageNumberResendService) => {
  return await client.query({
    query: gql`
      query {
        getPartnerAvailableWorkByServiceId(
          service_id: ${service_id},
          lat: ${lat},
          long: ${long},
          request_profile_id: ${request_profile_id}
        ) {
          pageNumber
        }
      }
    `,
  });
};

export const resendNotificationPageService = async ({
  booking_id,
  page,
}: IResendNotificationPageService) => {
  return await client.mutate({
    mutation: gql`
      mutation {
        sentNotificationAvailableWorkByPage(booking_id: ${booking_id}, page: ${page})
      }
    `,
  });
};
