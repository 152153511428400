import {
  GetConversationRequest,
  IBooking,
  IGetPageNumberResendService,
  IParamUpdateBooking,
  IResendNotificationPageService,
  IUpdateStatusBooking,
} from '../../interface';
import * as types from '../constants';

export const getBookingRequest = function (bookingId: string) {
  return {
    type: types.GET_BOOKING_REQUEST,
    payload: { bookingId },
  };
};

export const getBookingSuccess = (data: IBooking) => {
  return {
    type: types.GET_BOOKING_SUCCESS,
    payload: data,
  };
};

export const getBookingFail = (error) => {
  return {
    type: types.GET_BOOKING_FAIL,
    payload: error,
  };
};

export const updateStatusBookingRequest = function (
  data: IUpdateStatusBooking
) {
  return {
    type: types.UPDATE_STATUS_BOOKING_REQUEST,
    payload: { data },
  };
};

export const updateStatusBookingSuccess = () => {
  return {
    type: types.UPDATE_STATUS_BOOKING_SUCCESS,
  };
};

export const updateStatusBookingFail = (error) => {
  return {
    type: types.UPDATE_STATUS_BOOKING_FAIL,
    payload: error,
  };
};

export const updateBookingRequest = function (params: IParamUpdateBooking) {
  return {
    type: types.UPDATE_BOOKING_REQUEST,
    payload: { params },
  };
};

export const updateBookingSuccess = () => {
  return {
    type: types.UPDATE_BOOKING_SUCCESS,
  };
};

export const updateBookingFail = (error) => {
  return {
    type: types.UPDATE_BOOKING_FAIL,
    payload: error,
  };
};

export const getServiceRequest = function () {
  return {
    type: types.GET_SERVICE_REQUEST,
  };
};

export const getServiceSuccess = (data: IBooking) => {
  return {
    type: types.GET_SERVICE_SUCCESS,
    payload: data,
  };
};

export const getServiceFail = (error) => {
  return {
    type: types.GET_SERVICE_FAIL,
    payload: error,
  };
};

export const deleteBookingRequest = function (id: string) {
  return {
    type: types.DELETE_BOOKING_REQUEST,
    payload: id,
  };
};

export const deleteBookingSuccess = () => {
  return {
    type: types.DELETE_BOOKING_SUCCESS,
  };
};

export const deleteBookingFail = (error: any) => {
  return {
    type: types.DELETE_BOOKING_FAIL,
    payload: error,
  };
};

export const fetchBookingCancelRequest = (data: string) => {
  return {
    type: types.FETCH_CANCEL_BOOKING_REQUEST,
    payload: data,
  };
};

export const fetchBookingCancelSuccess = (data) => {
  return {
    type: types.FETCH_CANCEL_BOOKING_SUCCESS,
    payload: data,
  };
};

export const fetchBookingCancelFail = (error) => {
  return {
    type: types.FETCH_CANCEL_BOOKING_FAIL,
    payload: error,
  };
};

export const fetchPartnerReceiveNotificationRequest = (data) => {
  return {
    type: types.FETCH_PARTNER_RECEIVE_NOTIFICATION_REQUEST,
    payload: data,
  };
};

export const fetchPartnerReceiveNotificationSuccess = (data) => {
  return {
    type: types.FETCH_PARTNER_RECEIVE_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const fetchPartnerReceiveNotificationFail = (error) => {
  return {
    type: types.FETCH_PARTNER_RECEIVE_NOTIFICATION_FAIL,
    payload: error,
  };
};

export const fetchPartnerSkipNotificationRequest = (data) => {
  return {
    type: types.FETCH_PARTNER_SKIP_NOTIFICATION_REQUEST,
    payload: data,
  };
};

export const fetchPartnerSkipNotificationSuccess = (data) => {
  return {
    type: types.FETCH_PARTNER_SKIP_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const fetchPartnerSkipNotificationFail = (error) => {
  return {
    type: types.FETCH_PARTNER_SKIP_NOTIFICATION_FAIL,
    payload: error,
  };
};

export const resendNotificationRequest = function (data) {
  return {
    type: types.RESEND_NOTIFICATION_REQUEST,
    payload: data,
  };
};

export const resendNotificationSuccess = () => {
  return {
    type: types.RESEND_NOTIFICATION_SUCCESS,
  };
};

export const resendNotificationFail = (error) => {
  return {
    type: types.RESEND_NOTIFICATION_FAIL,
    payload: error,
  };
};

export const resendNotificationNotAvailableWorkRequest = function (data) {
  return {
    type: types.RESEND_NOTIFICATION_NOT_AVAILABLE_WORK_REQUEST,
    payload: data,
  };
};

export const resendNotificationNotAvailableWorkSuccess = () => {
  return {
    type: types.RESEND_NOTIFICATION_NOT_AVAILABLE_WORK_SUCCESS,
  };
};

export const resendNotificationNotAvailableWorkFail = (error) => {
  return {
    type: types.RESEND_NOTIFICATION_NOT_AVAILABLE_WORK_FAIL,
    payload: error,
  };
};

export const getConversationRequest = function ({
  conversationId,
  page,
}: GetConversationRequest) {
  return {
    type: types.GET_CONVERSATION_BOOKING_REQUEST,
    payload: { conversationId, page },
  };
};

export const getConversationSuccess = (data) => {
  return {
    type: types.GET_CONVERSATION_BOOKING_SUCCESS,
    payload: data,
  };
};

export const getConversationFail = (error) => {
  return {
    type: types.GET_CONVERSATION_BOOKING_FAIL,
    payload: error,
  };
};

export const getPageNumberResendRequest = function (
  data: IGetPageNumberResendService
) {
  return {
    type: types.GET_PAGE_NUMBER_REQUEST,
    payload: data,
  };
};

export const getPageNumberResendSuccess = (data) => {
  return {
    type: types.GET_PAGE_NUMBER_SUCCESS,
    payload: data,
  };
};

export const getPageNumberResendFail = (error) => {
  return {
    type: types.GET_PAGE_NUMBER_FAIL,
    payload: error,
  };
};

export const resendNotificationPageRequest = function (
  data: IResendNotificationPageService
) {
  return {
    type: types.RESEND_NOTIFICATION_PAGE_REQUEST,
    payload: data,
  };
};

export const resendNotificationPageSuccess = () => {
  return {
    type: types.RESEND_NOTIFICATION_PAGE_SUCCESS,
  };
};

export const resendNotificationPageFail = (error) => {
  return {
    type: types.RESEND_NOTIFICATION_PAGE_FAIL,
    payload: error,
  };
};

export const assignPartnerPageRequest = function (
  data
) {
  return {
    type: types.ASSIGN_PARTNER_REQUEST,
    payload: data,
  };
};

export const assignPartnerPageSuccess = () => {
  return {
    type: types.ASSIGN_PARTNER_SUCCESS,
  };
};

export const assignPartnerPageFail = (error) => {
  return {
    type: types.ASSIGN_PARTNER_FAIL,
    payload: error,
  };
};