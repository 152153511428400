import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    width: '100%'
  },
  search: {
    display: "flex",
    width: '100%',
    "&__box" :{
      width: '100%',
      display: "flex",
    }
  },
  box: {
    marginRight: theme.spacing(1.25),
    marginBottom: theme.spacing(1.25)
  },
  filter: {
    
    "& >div>label" : {
      background: "none !important",
      fontSize: "12px !important",
    },
    "& .MuiOutlinedInput-input" : {
      background: "none !important"
    },
    "& .MuiPickersDateRangePickerInput-root": {
      "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
        fontSize: "13px",
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        fontSize: "10px",
      },
    },
    display: "flex",
    flexWrap: "wrap",
    marginRight: theme.spacing(1.25),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2.5),
    "& >div>div>div>p": {
      display: "none",
    },
    boxSizing: "border-box",
  },
  formItem: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  btn: {
    width: "150px",
    marginRight: theme.spacing(1.25),
    "& button": {
      width: "100%",
      height: 35,
    },
  },
  btnReset: {
    boxSizing: "border-box",
    padding: theme.spacing(1,1.25),
    border: "none",
    cursor: "pointer",
    fontSize: "12px",
  },
  btnApply: {
    boxSizing: "border-box",
    padding: theme.spacing(1,1.25),
    border: "none",
    cursor: "pointer",
    fontSize: "12px",
  },
  btnColor: {
    background: "#FC4A46",
    color: "#ffff",
  },
  gridLeft: {
    display: 'flex'
  },
}));
export { useStyles };