export const COLUMS = [
  {
    title: 'ID',
    field: 'id',
  },
  {
    title: 'Request',
    field: 'userRequest',
  },
  {
    title: 'Recipient',
    field: 'recipientData',
  },
  {
    title: 'Service',
    field: 'service',
  },
  {
    title: 'City',
    field: 'cityData',
  },
  {
    title: 'Address',
    field: 'full_address',
  },
  {
    title: 'Booking Time',
    field: 'booking_time',
  },
  {
    title: 'Schedule',
    field: 'schedule',
  },
  {
    title: 'Status',
    field: 'status',
  },

  {
    title: 'Created at',
    field: 'create_at',
  },
  {
    title: 'Updated at',
    field: 'updated_at',
  },
  {
    title: 'Action',
    field: 'action',
  },
];

export const STATUS_BOOKING = [
  {
    name: 'CANCELED',
    id: 'CANCELED',
  },
  {
    name: 'WAITING TO BE PROCESSED',
    id: 'WAITINGTOBEPROCESSED',
  },
  {
    name: 'DONE',
    id: 'DONE',
  },
  {
    name: 'ACCEPTED',
    id: 'ACCEPTED',
  },
  {
    name: 'PROCESSING',
    id: 'PROCESSING',
  },
];
