import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as types from '../constants';
import * as actionTypes from '../actions';
import {
  addProofProfileService,
  addScheduleInterviewService,
  getApiProviderService,
  getProfileUserService,
  getScheduleInterviewService,
  getTopicPartnerService,
  updateProviderServicer,
  updateStatusReviewNetWorkServicer,
} from '../services';
import { showSnackbar } from '../../../Snackbar/store/actions';

function* getServiceProviderSaga(action) {
  try {
    const { page, rowsPerPage, filter } = action.payload.data;
    const providerData = yield getApiProviderService({
      page,
      rowsPerPage,
      filter,
    });
    const data = providerData.data.getServicesProvider;

    yield put(
      actionTypes.getServiceProviderSuccess(
        data,
        providerData.data.getServicesProvider.data
      )
    );
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.getServiceProviderFail(message.error.graphQLErrors[0].code)
    );
  }
}

function* getScheduleInterviewRequest(action) {
  try {
    const { data } = yield getScheduleInterviewService(action.payload);
    yield put(
      actionTypes.getScheduleInterviewSuccess(data.getApprovalFormulas)
    );
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.getScheduleInterviewFail(message.error.graphQLErrors[0].code)
    );
  }
}

function* updateProviderSaga(action) {
  try {
    const { service_id, status, id, url_images } = action.payload.data;
    yield updateProviderServicer({ service_id, status, id, url_images });
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
    yield put(actionTypes.updateProviderSuccess(action.payload.data));
  } catch (error: any) {
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Updated Failed',
      })
    );
    const message = { error };
    yield put(
      actionTypes.updateProviderFail(message.error.graphQLErrors[0].code)
    );
  }
}

function* addProofSaga(action) {
  try {
    const { profile_id, urlImages, service_provider_id } = action.payload.data;
    yield addProofProfileService({
      profile_id,
      urlImages,
      service_provider_id,
    });
    yield put(actionTypes.addProofSuccess());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
  } catch (error: any) {
    const message = { error };
    yield put(actionTypes.addProofFail(message.error.graphQLErrors[0].code));
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Updated Failed',
      })
    );
  }
}

function* addScheduleSaga(action) {
  try {
    yield addScheduleInterviewService(action.payload.data);
    yield put(actionTypes.addScheduleInterviewSuccess(action.payload.data));
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.addScheduleInterviewFail(message.error.graphQLErrors[0].code)
    );
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Updated Failed',
      })
    );
  }
}

function* getSocialNetWorkSaga(action) {
  try {
    const {
      data: {
        getProfileById: { profileSocialNetwork },
      },
    } = yield getProfileUserService(action.payload);

    const response = yield all(
      profileSocialNetwork.map((item) => call(getTopicPartnerService, item.id))
    );
    const dataTopic = response.map((item) => item.data.getTopicPartner);

    const dataInfo = profileSocialNetwork.map((item, index) => ({
      ...item,
      topic: dataTopic[index],
    }));

    yield put(actionTypes.getSocialNetworkSuccess(dataInfo));
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.getSocialNetworkFail(message.error.graphQLErrors[0].code)
    );
  }
}

function* updateStatusReviewNetworkSaga(action) {
  try {
    yield updateStatusReviewNetWorkServicer(action.payload);
    yield put(actionTypes.updateStatusReviewSocialSuccess(action.payload));

    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.updateStatusReviewSocialFail(
        message.error.graphQLErrors[0].code
      )
    );
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Updated Failed',
      })
    );
  }
}

export default function* ListServiceRequestSaga() {
  yield takeLatest(
    types.GET_LIST_SERVICE_PROVIDER_REQUEST,
    getServiceProviderSaga
  );

  yield takeLatest(types.UPDATE_PROVIDER_REQUEST, updateProviderSaga);
  yield takeLatest(types.ADD_PROOF_REQUEST, addProofSaga);
  yield takeLatest(types.ADD_SCHEDULE_REQUEST, addScheduleSaga);
  yield takeLatest(
    types.GET_SCHEDULE_INTERVIEW_REQUEST,
    getScheduleInterviewRequest
  );
  yield takeLatest(types.GET_SOCIAL_NETWORK_REQUEST, getSocialNetWorkSaga);
  yield takeLatest(
    types.UPDATE_STATUS_REVIEW_SOCIAL_NETWORK_REQUEST,
    updateStatusReviewNetworkSaga
  );
}
