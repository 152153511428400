import { call, put, takeLatest } from 'redux-saga/effects';

import * as types from '../constants';
import * as actionTypes from '../actions';
import {
  getBookingService,
  getService,
  updateBookingStatusService,
  updateBookingService,
  deleteBookingService,
  getCancelBookingService,
  getPartnerReceiveService,
  getPartnerSkipService,
  resendNotificationService,
  resendNotificationAvailableNotWorkService,
  getConversationService,
  getPageNumberResendService,
  resendNotificationPageService,
  updateAssignBookingService,
  updateBookingDoneStatusService,
} from '../services';
import { showSnackbar } from '../../../Snackbar/store/actions';
import { hideModal } from 'src/containers/App/store/actions';

function* getUserBookingSaga(action) {
  try {
    const {
      data: { booking },
    } = yield getBookingService(action.payload.bookingId);
    yield put(actionTypes.getBookingSuccess(booking));
  } catch (error) {
    const message = { error };
    yield put(actionTypes.getBookingFail(message.error.graphQLErrors[0].code));
  }
}

function* updateStatusBookingSaga(action) {
  const { id, status } = action.payload.data;

  try {
    status === 'DONE'
      ? yield updateBookingDoneStatusService(id)
      : yield updateBookingStatusService(id, status);
    yield put(actionTypes.updateStatusBookingSuccess());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.updateStatusBookingFail(message.error.graphQLErrors[0].code)
    );
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Updated Failed',
      })
    );
  }
}

function* updateBookingSaga(action) {
  const { id, data } = action.payload.params;
  try {
    yield updateBookingService(id, data);
    yield put(actionTypes.updateBookingSuccess());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
    yield put(actionTypes.getBookingRequest(id));
    yield put(hideModal());
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.updateBookingFail(message.error.graphQLErrors[0].code)
    );
    yield put(hideModal());
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Updated Failed',
      })
    );
  }
}

function* getServiceSaga() {
  try {
    const {
      data: { services },
    } = yield getService();
    yield put(actionTypes.getServiceSuccess(services));
  } catch (error) {
    const message = { error };
    yield put(actionTypes.getServiceFail(message.error.graphQLErrors[0].code));
  }
}

function* deleteBookingSaga(action) {
  try {
    yield deleteBookingService(action.payload);
    yield put(actionTypes.deleteBookingSuccess());
    yield put(hideModal());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Deleted Succeed',
      })
    );
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.deleteBookingFail(message.error.graphQLErrors[0].code)
    );
    yield put(hideModal());
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Deleted Failed',
      })
    );
  }
}

function* cancelBookingSaga(action) {
  try {
    const { data } = yield call(getCancelBookingService, action.payload);
    yield put(actionTypes.fetchBookingCancelSuccess(data.getCancelbooking));
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.fetchBookingCancelFail(message.error.graphQLErrors[0].code)
    );
  }
}

function* getPartnerReceiveNotificationSaga(action) {
  try {
    const { data } = yield call(getPartnerReceiveService, action.payload);
    yield put(
      actionTypes.fetchPartnerReceiveNotificationSuccess(
        data.getPartnerSkipOrReceiveNotificationByBookingId
      )
    );
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.fetchPartnerReceiveNotificationFail(
        message.error.graphQLErrors[0].code
      )
    );
  }
}

function* getPartnerSkipNotificationSaga(action) {
  try {
    const { data } = yield call(getPartnerSkipService, action.payload);
    yield put(
      actionTypes.fetchPartnerSkipNotificationSuccess(
        data.getPartnerSkipOrReceiveNotificationByBookingId
      )
    );
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.fetchPartnerSkipNotificationFail(
        message.error.graphQLErrors[0].code
      )
    );
  }
}

function* resendNotificationSaga(action) {
  try {
    yield call(resendNotificationService, action.payload);
    yield put(actionTypes.resendNotificationSuccess());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Resend Succeed',
      })
    );
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.resendNotificationFail(message.error.graphQLErrors[0].code)
    );
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Resend Failed',
      })
    );
  }
}

function* resendNotificationAvailableNotWorSaga(action) {
  try {
    yield call(resendNotificationAvailableNotWorkService, action.payload);
    yield put(actionTypes.resendNotificationNotAvailableWorkSuccess());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Resend Succeed',
      })
    );
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.resendNotificationNotAvailableWorkFail(
        message.error.graphQLErrors[0].code
      )
    );
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Resend Failed',
      })
    );
  }
}

function* getConversationSaga({ payload }: any) {
  try {
    const {
      data: { getMessage },
    } = yield call(getConversationService, payload);
    yield put(actionTypes.getConversationSuccess({ getMessage, payload }));
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.getConversationFail(message.error.graphQLErrors[0].code)
    );
  }
}

function* getPageNumberRequest(action) {
  try {
    const {
      data: {
        getPartnerAvailableWorkByServiceId: { pageNumber },
      },
    } = yield call(getPageNumberResendService, action.payload);

    yield put(actionTypes.getPageNumberResendSuccess(pageNumber));
  } catch (error) {
    yield put(actionTypes.getPageNumberResendFail(error));
  }
}

function* resendNotificationPageSaga(action) {
  try {
    yield call(resendNotificationPageService, action.payload);
    yield put(actionTypes.resendNotificationPageSuccess());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Resend Succeed',
      })
    );
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.resendNotificationPageFail(
        message.error.graphQLErrors[0].code
      )
    );
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Resend Failed',
      })
    );
  }
}

function* assignPartnerSaga(action) {
  try {
    yield call(updateAssignBookingService, action.payload);
    yield put(actionTypes.assignPartnerPageSuccess());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Resend Succeed',
      })
    );
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.assignPartnerPageFail(message.error.graphQLErrors[0].code)
    );
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Resend Failed',
      })
    );
  }
}

export default function* bookingSaga() {
  yield takeLatest(types.GET_BOOKING_REQUEST, getUserBookingSaga);
  yield takeLatest(
    types.UPDATE_STATUS_BOOKING_REQUEST,
    updateStatusBookingSaga
  );
  yield takeLatest(types.GET_SERVICE_REQUEST, getServiceSaga);
  yield takeLatest(types.UPDATE_BOOKING_REQUEST, updateBookingSaga);
  yield takeLatest(types.DELETE_BOOKING_REQUEST, deleteBookingSaga);
  yield takeLatest(types.FETCH_CANCEL_BOOKING_REQUEST, cancelBookingSaga);
  yield takeLatest(
    types.FETCH_PARTNER_RECEIVE_NOTIFICATION_REQUEST,
    getPartnerReceiveNotificationSaga
  );
  yield takeLatest(
    types.FETCH_PARTNER_SKIP_NOTIFICATION_REQUEST,
    getPartnerSkipNotificationSaga
  );
  yield takeLatest(types.RESEND_NOTIFICATION_REQUEST, resendNotificationSaga);
  yield takeLatest(
    types.RESEND_NOTIFICATION_NOT_AVAILABLE_WORK_REQUEST,
    resendNotificationAvailableNotWorSaga
  );

  yield takeLatest(types.GET_CONVERSATION_BOOKING_REQUEST, getConversationSaga);

  yield takeLatest(types.GET_PAGE_NUMBER_REQUEST, getPageNumberRequest);
  yield takeLatest(
    types.RESEND_NOTIFICATION_PAGE_REQUEST,
    resendNotificationPageSaga
  );
  yield takeLatest(types.ASSIGN_PARTNER_REQUEST, assignPartnerSaga);
}