export const GENERATE_THING_NAME_REQUEST = 'Instalent/GENERATE_THING_NAME_REQUEST';
export const GENERATE_THING_NAME_SUCCESS = 'Instalent/GENERATE_THING_NAME_SUCCESS';
export const GENERATE_THING_NAME_FAIL = 'Instalent/GENERATE_THING_NAME_FAIL';

export const STATUS = [
  { value: 'fail', content: 'FAIL' },
  { value: 'completed', content: 'COMPLETED' },
  { value: 'pending', content: 'PENDING' },
];
export const FIRMWARE_VERSION = [
  { value: 'v1.0', content: 'v1.0' },
  { value: 'v1.1', content: 'v1.1' },
  { value: 'v1.2', content: 'v1.2' },
];

export const TOOLS = [{ value: 'update', content: 'Update Firmware' }];
