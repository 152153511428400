import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Button from 'src/components/Button';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(50),
  },
  dialogTitle: {
    textAlign: 'center',
  },
  dialogContent: {
    textAlign: 'center',
  },
  dialogAction: {
    justifyContent: 'center',
  },
  titleIcon: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      cursor: 'default',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '8rem',
    },
  },
}));

interface IProps {
  [key: string]: any;
  confirmDialog : any;
  setConfirmDialog: any
}

export default function ConfirmDialog(props : IProps) {
  const { confirmDialog, setConfirmDialog } = props
  const classes = useStyles()
  const handleClick = () => {
  }

  const handleConfirm = () => setConfirmDialog({ ...confirmDialog, isOpen: false })
  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogContent className={classes.dialogContent}>
        <Typography>Are you sure ?</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button onClick={handleClick}>AGREE</Button>
        <Button
          onClick={handleConfirm}
        >
          DISAGREE
        </Button>
      </DialogActions>
    </Dialog>
  );
}