import produce from 'immer';

import { ActionType, GlobalTypes } from '../../interfaces';
import * as types from '../constants';

export const initialState: GlobalTypes = {
  isLoading: false,
  errors: null,
  isModal: false,
  isAdd: false,
  isUpdate: false,
};

const appReducer = (state = initialState, { type, payload }: ActionType) =>
  produce(state, (draft: GlobalTypes) => {
    switch (type) {
      case types.SHOW_LOADING:
        draft.isLoading = true;
        break;
      case types.HIDE_LOADING:
        draft.isLoading = false;
        break;

      case types.SHOW_MODAL:
        draft.isModal = true;
        break;
      case types.HIDE_MODAL:
        draft.isModal = false;
        break;
      case types.SHOW_MODAL_ADD:
        draft.isAdd = true;
        break;
      case types.HIDE_MODAL_ADD:
        draft.isAdd = false;
        break;
      case types.SHOW_MODAL_UPDATE:
        draft.isUpdate = true;
        break;
      case types.HIDE_MODAL_UPDATE:
        draft.isUpdate = false;
        break;
      default:
        break;
    }
  });

export default appReducer;
