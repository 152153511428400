import { call, put, takeLatest } from 'redux-saga/effects';

import * as types from '../constants';
import * as actionTypes from '../actions';
import { getHistoryTransactionService } from '../services';

function* getHistoryTransactionSaga(action) {
  try {
    const response = yield call(getHistoryTransactionService, action.payload);
    yield put(
      actionTypes.getHistoryTransactionSuccess(
        response.data.historyTransactionPartner
      )
    );
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.getHistoryTransactionFail(message.error.graphQLErrors[0].code)
    );
  }
}

export default function* TransactionSaga() {
  yield takeLatest(
    types.GET_HISTORY_TRANSACTION_REQUEST,
    getHistoryTransactionSaga
  );
}
