import { makeStyles, TablePagination, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styledTheme from 'src/utils/theme';

const useStyles = makeStyles((theme: Theme) => ({
  deviceContainer: {
    marginTop: theme.spacing(2)
  },
  boxContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexWrap: 'wrap',
  },
  boxIten: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 100,
    maxWidth: 250,
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100px',
    },
  },
  boxTitle: {
    margin: 0,
    marginLeft: 20,
    fontSize: styledTheme.fontSize.xlarge,
  },
  deviceTable: {
    marginTop: 20,
    padding: 15,
  },
  deviceHeader: {
    width: '100&',
  },
  searchFilter: {
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    padding: 15,
    width: 'calc(100% - 170px)',
    color: theme.palette.text.primary,
    marginRight: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  selection: {
    width: 150,
  },
  ml10: {
    marginLeft: 10,
  },
}));
const TablePaginationStyle = withStyles(theme => ({
  root: {
    marginLeft: 'auto',
    '& .MuiTablePagination-toolbar': {
      fontSize: styledTheme.fontSize.large,
    },
    '& .MuiTablePagination-caption': {
      fontSize: styledTheme.fontSize.large,
    },
  },
}))(TablePagination);

export { useStyles, TablePaginationStyle };
