import { AuthenticatedRoute } from '../components/routes';
import BookingContainer from '../containers/Booking';
const BookingRoutes = [
  {
    path: '/booking/:id',
    title: 'Booking',
    component: BookingContainer,
    route: AuthenticatedRoute,
    roles: [],
    permission: [],
    exact: true,
  },
];

export default BookingRoutes;
