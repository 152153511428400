import { AuthenticatedRoute } from '../components/routes';
import { PartnerDetailPage } from '../pages';

const partnerRoute = [
  {
    path: '/partner/:id',
    title: 'Partners',
    component: PartnerDetailPage,
    route: AuthenticatedRoute,
    roles: [],
    permission: [],
    exact: true,
  },
];


export default partnerRoute;