import { TablePagination } from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import { rowError } from '../../constants';
import ButtonComponent from 'src/components/ButtonComponent';
import MuiTable from 'src/components/MuiTable';
import { COLUMNS } from '../../constants';
import { IFormikValueType } from '../../interface';

export interface ITableSurcharge {
  totalSurcharge: number;
  isLoading: boolean;
  listSurcharge: Array<IFormikValueType>;
  page: number;
  rowsPerPage: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleOpenModalDeleteSurcharge: (id: string) => void;
  handleOpenModalUpdate: (data: IFormikValueType) => void;
}

export const TableSurcharge = ({
  totalSurcharge,
  isLoading,
  listSurcharge,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOpenModalDeleteSurcharge,
  handleOpenModalUpdate,
}: ITableSurcharge) => {
  const pagination = (
    <div>
      <TablePagination
        component="div"
        count={totalSurcharge || 10}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );

  const row = (item) => {
    return {
      id: (
        <div>
          <p> {item.id}</p>
        </div>
      ),
      title: (
        <div>
          <p> {item.title}</p>
        </div>
      ),
      amount: (
        <div>
          <p> {item.amount}</p>
        </div>
      ),
      city_name: (
        <div>
          <p>{item.city_name}</p>
        </div>
      ),
      address: (
        <div>
          <p> {item.address}</p>
        </div>
      ),
      description: (
        <div>
          <p>{item.description}</p>
        </div>
      ),
      status: (
        <div>
          {item.status ? (
            <CheckCircleIcon color="action" />
          ) : (
            <ErrorIcon color="error" />
          )}
        </div>
      ),
      actions: (
        <div>
          <ButtonComponent
            status="approve"
            handleAction={() => handleOpenModalUpdate(item)}
          >
            <EditIcon fontSize="small" />
          </ButtonComponent>
          <ButtonComponent
            status="cancel"
            handleAction={() => handleOpenModalDeleteSurcharge(item.id)}
          >
            <DeleteIcon fontSize="small" />
          </ButtonComponent>
        </div>
      ),
    };
  };

  const renderRow = isLoading
    ? new Array(10).fill(0).map((i) => rowError)
    : listSurcharge?.map(row);

  return (
    <>
      <div>
        <MuiTable headers={COLUMNS} pagination={pagination} rows={renderRow} />
      </div>
    </>
  );
};
