import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';

import { useInjectReducer } from 'src/utils/injectReducer';
import { useInjectSaga } from 'src/utils/injectSaga';
import reducer from './store/reducers';
import ServiceProviderView from './views';
import saga from './store/sagas';
import { IDataField, IGetUsers, IParamHandle } from './interface';
import * as RequestProviderAction from './store/actions/index';
import * as BookingActions from 'src/containers/Booking/store/actions';
import sagaBooking from '../Booking/store/sagas';
import reducerBooking from '../Booking/store/reducers';
import sagaUser from '../User/store/sagas';
import reducerUser from '../User/store/reducers';
import useQueryLocation from 'src/hooks/useQueryLocation';
import useLoadingButton from './hook/useLoadingButton';
import { ISelector } from 'src/reducers';

function ListServiceRequest() {
  const { isAction } = useSelector((s: ISelector) => s.serviceProvider);

  const { handleResetLoading } = useLoadingButton(isAction);
  useInjectReducer({ key: 'serviceProvider', reducer });
  useInjectSaga({ key: 'serviceProvider', saga });

  useInjectReducer({ key: 'booking', reducer: reducerBooking });
  useInjectSaga({ key: 'booking', saga: sagaBooking });

  useInjectReducer({ key: 'user', reducer: reducerUser });
  useInjectSaga({ key: 'user', saga: sagaUser });

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  let query = useQueryLocation();
  let params = useRef<IGetUsers>();

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [search, setSearch] = useState<IDataField>({
    serciveId: 0,
    status: 0,
    email: '',
    name: '',
    phone: '',
    images: null,
  });

  const handleEditListProvider = ({
    id,
    status,
    service_id,
    url_images,
  }: IParamHandle) => {
    dispatch(
      RequestProviderAction.updateProviderRequest({
        service_id,
        status,
        id,
        url_images,
      })
    );
  };

  const handleSearch = ({ target: { name, value } }) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const onResetFilter = () => {
    setSearch({
      serciveId: 0,
      status: 0,
      email: '',
      name: '',
      phone: '',
      images: null,
    });
    params.current = {
      page: 1,
      rowsPerPage: 10,
      filter: {
        serciveId: 0,
        status: 0,
        email: '',
        name: '',
        phone: '',
        images: null,
      },
    };
    history.push(`/partners/service-request`);
    dispatch(
      RequestProviderAction.getServiceProviderRequestAction(params.current)
    );
  };

  const handleSubmitFilter = (e: React.FormEvent<HTMLFormElement>) => {
    handleResetLoading();
    e.preventDefault();

    params.current = {
      rowsPerPage: rowsPerPage,
      page: page,
      filter: {
        serciveId: search.serciveId,
        status: search.status,
        email: search.email,
        name: search.name,
        phone: search.phone,
        images: search.images,
      },
    };

    dispatch(
      RequestProviderAction.getServiceProviderRequestAction(params.current)
    );
    const paramsString = queryString.stringify(params.current.filter);
    history.push(
      `/partners/service-request?page=${page}&per_page=${rowsPerPage}&${paramsString}`
    );
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+e.target.value);
    setPage(1);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };

  useEffect(() => {
    if (location.search) {
      const serciveId = query.get('serciveId');
      const status = query.get('status');
      const email = query.get('email');
      const name = query.get('name');
      const phone = query.get('phone');
      const images = query.get('search');
      params.current = {
        page: page,
        rowsPerPage: rowsPerPage,
        filter: {
          serciveId: serciveId || search.serciveId,
          status: status || search.status,
          email: email || search.email,
          name: name || search.name,
          phone: phone || search.phone,
          images: images || search.images,
        },
      };

      setSearch({
        serciveId: serciveId || search.serciveId,
        status: status || search.status,
        email: email || search.email,
        name: name || search.name,
        phone: phone || search.phone,
        images: images || search.images,
      });
    } else {
      params.current = {
        page: page,
        rowsPerPage: rowsPerPage,
        filter: {
          serciveId: search.serciveId,
          status: search.status,
          email: search.email,
          name: search.name,
          phone: search.phone,
          images: search.images,
        },
      };
    }

    dispatch(
      RequestProviderAction.getServiceProviderRequestAction(params.current)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, rowsPerPage, page]);

  useEffect(() => {
    dispatch(BookingActions.getServiceRequest());
  }, [dispatch]);

  return (
    <ServiceProviderView
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      search={search}
      onResetFilter={onResetFilter}
      handleSubmitFilter={handleSubmitFilter}
      handleSearch={handleSearch}
      handleEditListProvider={handleEditListProvider}
    />
  );
}

export default ListServiceRequest;
