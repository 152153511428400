// import { useState } from 'react';
import { useSelector } from 'react-redux';
// import SearchIcon from '@material-ui/icons/Search';
import { Grid, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

// import TextInput from 'src/components/InputFiled';
import { IFilter } from '../../interface';
import { dataFieldServiceRequest, dataFilterImages, dataStatusProvider } from '../../contants';
import { ISelector } from 'src/reducers';
import SelectOption from 'src/components/Selection';
import TextInput from 'src/components/InputFiled';
// import Button from 'src/components/Button';

import { useStyles } from './styles';

function FilterView({
  handleSubmitFilter,
  handleSearch,
  onResetFilter,
  search,
}: // rowSelecteds,
IFilter) {
  const classes = useStyles();
  const services = useSelector((s: ISelector) => s.booking?.services || []);

  // const [action, setAction] = useState(0);

  // const handleChangeAction = (event: React.ChangeEvent<{ value: number }>) =>
  //   setAction(+event.target.value);

  // const handleUpdateMulti = () => {}; // handle when have api

  return (
    <div className={classes.container}>
      <form className={classes.search} onSubmit={handleSubmitFilter}>
        <Grid container>
          <Grid item xs={12} sm={12} md={9} lg={9} className={classes.gridLeft}>
            {dataFieldServiceRequest(search).map((item, index) => {
              return (
                <div className={classes.box} key={index}>
                  <TextInput
                    value={item.value || ''}
                    name={item.name}
                    label={item.label}
                    type='search'
                    variant='outlined'
                    onChange={handleSearch}
                    inputprops={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              );
            })}
            <div className={classes.selectFilter}>
              <SelectOption
                label='Service Name'
                options={services}
                name='serciveId'
                onChangeOption={handleSearch}
                value={search.serciveId}
              />

              <SelectOption
                label='Status'
                options={dataStatusProvider}
                name='status'
                onChangeOption={handleSearch}
                value={search.status}
              />
              <SelectOption
                label='Images'
                options={dataFilterImages}
                name='images'
                onChangeOption={handleSearch}
                value={search.images}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <div className={classes.filter}>
              <div className={classes.btn}>
                <button
                  className={`${classes.btnApply} ${classes.btnColor}`}
                  type='submit'
                >
                  Apply Filter
                </button>
              </div>
              <div className={classes.btn}>
                <button
                  className={classes.btnReset}
                  type='button'
                  onClick={onResetFilter}
                >
                  Clear Filter
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>

      {/* <div>
        {rowSelecteds.length > 0 && (
          <div className={classes.action}>
            <SelectOption
              label='Action'
              options={dataStatusProvider}
              onChangeOption={handleChangeAction}
              value={action}
            />
            <Button onClick={handleUpdateMulti}>Apply</Button>
          </div>
        )}
      </div> */}
    </div>
  );
}

export default FilterView;
