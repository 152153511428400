import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  cancel: {
    color: '#ff3838',
  },
  done: {
    color: '#2dccff',
  },
  wait: {
    color: '#3788d8',
  },
  accepted: {
    color: '#56f000	',
  },
  processing: {
    color: '#ffb302',
  },
  textStatus: {
    fontSize: 10,
    fontWeight: 600,
  },
  bntAction: {
    display: 'flex',
    '& >div': {
      margin: theme.spacing(0, 1),
    },
  },
  schedule: {
    display: 'flex',
    background: '#04a0de',
    padding: '8px 15px',
    width: 100,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
    '& >span': {
      marginLeft: theme.spacing(1),
      textTransform: 'capitalize',
      color: '#ffff',
    },
    '& >svg': {
      color: '#ffff',
    },
  },
}));
export { useStyles };
