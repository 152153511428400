import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',

    padding: theme.spacing(2, 0),
    marginTop: theme.spacing(2),
  },
  rootDark: {
    backgroundColor: '#424242bf',
  },
  rootLight: {
    backgroundColor: '#FC4A46',
  },
  wallet: {
    color: '#ffff',
    fontWeight: 'bold',
    opacity: '0.7',
  },
  textMoney: {
    color: '#ffff',
    fontWeight: 'bold',
    fontSize: 16,
  },
  button: {
    border: '1px dashed #fff',
    padding: theme.spacing(1, 2),
    color: '#ffff',
  },
  mainChildren: {},
  textChildren: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: theme.spacing(4),
  },
  btn: {
    '& >div>button': {
      fontWeight: 'bold',
      fontSize: '16px !important',
    },
  },
  info: {},
  infoText: {
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: theme.spacing(2),
  },
  infoDescription: {
    '& >div': {
      marginBottom: theme.spacing(2),
    },
  },
  amountInput: {
    '& >div': {
      width: '100%',
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
    '& input': {
      fontWeight: 'bold',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#1a1a1a',
      },
      '&.Mui-focused fieldset': {
        border: `1px solid #ffff`,
      },
      '&.Mui-error fieldset': {
        border: `1px solid ${theme.palette.error.main}`,
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 17px) scale(1)',
      color: theme.palette.text.secondary,
      top: -7,
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, 0) scale(0.7)',
      },
    },
  },
}));
export { useStyles };
