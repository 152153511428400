export const objStatus = {
  0: 'WAITING_FOR_PROCESS',
  1: 'APPROVED',
  2: 'DECLINED',
  3: 'PROCESSING',
};

export const COLUMNS = [
  {
    title: 'Service Name',
    field: 'name',
  },
  {
    title: 'Status',
    field: 'status',
  },
  {
    title: 'Created',
    field: 'created_at',
  },
  {
    title: 'Approved/Declined By',
    field: 'author',
  },
  {
    title: 'Certificate',
    field: 'images',
  },
  {
    title: 'Schedule',
    field: 'schedule',
  },
  {
    title: 'Action',
    field: 'action',
  },
];

export const StatusRequest = {
  APPROVED: 1,
  DECLINED: 2,
};

export const STATUS_SERVICE_REQUEST = {
  WAITING_FOR_PROCESS: 0,
  APPROVED: 1,
  DECLINED: 2,
  PROCESSING: 3,
};
