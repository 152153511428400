export const COLUMS = [
  {
    title: 'ID',
    field: 'id',
  },
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Price',
    field: 'price',
  },
  {
    title: 'Min',
    field: 'min',
  },
  {
    title: 'Max',
    field: 'max',
  },
  {
    title: 'Unit',
    field: 'unit',
  },
  {
    title: 'Immediately Surcharge',
    field: 'immediately_surcharge',
  },
  {
    title: 'Manual Surcharge',
    field: 'manual_surcharge',
  },
  {
    title: 'Description',
    field: 'description',
  },
  {
    title: 'Created at',
    field: 'create_at',
  },
  {
    title: 'Updated at',
    field: 'updated_at',
  },
  {
    title: 'Action',
    field: 'action',
  },
];

export const textFieldService = (value) => [
  {
    label: 'Name',
    name: 'name',
    type: 'text',
    value: value.name,
  },
  {
    label: 'Min',
    name: 'min',
    type: 'number',
    value: value.min,
  },
  {
    label: 'Max',
    name: 'max',
    type: 'number',
    value: value.max,
  },
  {
    label: 'Unit',
    name: 'unit',
    type: 'text',
    value: value.unit,
  },
  {
    label: 'Immediately Surcharge',
    name: 'immediately_surcharge',
    type: 'text',
    value: value.immediately_surcharge,
  },
  {
    label: 'Manual Surcharge',
    name: 'manual_surcharge',
    type: 'text',
    value: value.manual_surcharge,
  },
  {
    label: 'Description',
    name: 'description',
    type: 'text',
    value: value.description,
  },
];
