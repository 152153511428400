import { AuthenticatedRoute } from '../components/routes';
import ServiceAreasContainer from '../containers/ServicesAreas';

const ServiceAreasRouter = [
  {
    path: '/service-areas',
    title: 'Service Areas',
    component: ServiceAreasContainer,
    route: AuthenticatedRoute,
    roles: [],
    permission: [],
    exact: true,
  },
];

export default ServiceAreasRouter;
