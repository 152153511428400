import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import { TablePagination } from '@material-ui/core';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import { COLUMS, statusAdmin } from '../../contants';
import MuiTable from 'src/components/MuiTable';
import ButtonComponent from 'src/components/ButtonComponent';
import DialogMui from 'src/components/DialogMui';
import { ISelector } from 'src/reducers';
import DialogComponent from 'src/components/DialogComponent';
import * as AppAction from 'src/containers/App/store/actions';
import * as StaffAction from 'src/containers/StaffUser/store/actions';
import UpdateStaff from '../CreateStaff/UpdateStaff';
import CreateStaff from '../CreateStaff/CreateStaff';
import { IListServiceView } from 'src/containers/Services/interface';
import { IListStaff } from '../../interface';

import { useStyles } from './styles';

function TableStaff({
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}: IListServiceView) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isRequesting = useSelector((s: ISelector) => s.staff?.isRequesting);
  const listStaff = useSelector((s: ISelector) => s.staff?.listStaff);
  const total = useSelector((s: ISelector) => s.staff?.total);
  const isAdd = useSelector((s: ISelector) => s.global?.isAdd);
  const isUpdate = useSelector((s: ISelector) => s.global?.isUpdate);
  const isModal = useSelector((s: ISelector) => s.global?.isModal);

  const [id, setId] = useState<string>('');
  const [serviceDetail, setServiceDetail] = useState<IListStaff | any>();

  const handleCloseModal = () => dispatch(AppAction.hideModal());

  const handleOpenModal = (id) => () => {
    dispatch(AppAction.showModal());
    setId(id);
  };

  const handleOpenModalAddService = () => dispatch(AppAction.showModalAdd());

  const handleDeleteService = () =>
    dispatch(StaffAction.deleteStaffRequest(id));

  const handleCloseModalEditService = () =>
    dispatch(AppAction.hideModalUpdate());

  const handleOpenModalEditService = (item) => () => {
    dispatch(AppAction.showModalUpdate());
    setServiceDetail(item);
  };

  const handleCloseModalAddService = () => dispatch(AppAction.hideModalAdd());

  const row = (item) => {
    return {
      id: (
        <div>
          <p> {item.id}</p>
        </div>
      ),
      email: (
        <div>
          <p> {item.email}</p>
        </div>
      ),
      name: (
        <div>
          <p>{item?.adminProfileData?.name}</p>
        </div>
      ),
      status: (
        <div>
          <p>{statusAdmin[item.status]}</p>
        </div>
      ),
      create_at: (
        <div>
          <p>{moment(item.created_at).format('DD-MM-YYYY HH:mm:ss')}</p>
        </div>
      ),
      updated_at: (
        <div>
          <p>{moment(item.updated_at).format('DD-MM-YYYY HH:mm:ss')}</p>
        </div>
      ),
      action: (
        <div>
          <div className={classes.bntAction}>
            <ButtonComponent
              status='approve'
              handleAction={handleOpenModalEditService(item)}
            >
              <EditIcon fontSize='small' />
            </ButtonComponent>
            <ButtonComponent
              status='cancel'
              handleAction={handleOpenModal(item.id)}
            >
              <DeleteIcon fontSize='small' />
            </ButtonComponent>
          </div>
        </div>
      ),
    };
  };

  const skeletonRowData = (
    <div>
      <Skeleton variant='rect' animation='wave' />
    </div>
  );

  const formatData = (keys) => {
    const obj = {};
    keys.forEach((key) => (obj[key] = skeletonRowData));
    return obj;
  };

  const rowError = formatData([
    'id',
    'email',
    'name',
    'status',
    'create_at',
    'updated_at',
    'action',
  ]);

  const pagination = (
    <div>
      <TablePagination
        className={classes.container}
        component='div'
        count={total || 10}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );

  const renderRow = isRequesting
    ? new Array(10).fill(0).map((i) => rowError)
    : listStaff?.map(row);

  return (
    <div className={classes.root}>
      <DialogComponent
        title='Create a Staff'
        open={isAdd}
        onClose={handleCloseModalAddService}
      >
        <CreateStaff handleClose={handleCloseModalAddService} />
      </DialogComponent>

      <DialogComponent
        title='Edit a Staff'
        open={isUpdate}
        onClose={handleCloseModalEditService}
      >
        <UpdateStaff detail={serviceDetail} />
      </DialogComponent>

      <DialogMui
        open={isModal}
        onClose={handleCloseModal}
        onConfirm={handleDeleteService}
      />

      <div className={classes.headContain}>
        <ButtonComponent
          status='approve'
          handleAction={handleOpenModalAddService}
          icon={<AddIcon fontSize='small' />}
        >
          Create a Staff
        </ButtonComponent>
      </div>
      <div className={classes.boxTable}>
        <MuiTable headers={COLUMS} rows={renderRow} pagination={pagination} />
      </div>
    </div>
  );
}

export default TableStaff;
