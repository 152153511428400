import { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core';
import clsx from 'clsx';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';

import { useStyles } from './styles';

interface IAction {
  title: string;
  iconAction: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  active?: boolean;
}
interface Props {
  title: string;
  children: React.ReactNode;
  actions?: Array<IAction>;
  isClose?: boolean;
}

function ToggleComponent(props: Props) {
  const classes = useStyles();
  const { title, actions = [], children } = props;

  // const handleStopPropagation = (event) => {
  //   event.stopPropagation();
  // };
  const [expanded, setExpand] = useState<boolean>(true);

  const handleExpand = () => setExpand(!expanded);

  return (
    <Accordion
      expanded={expanded}
      className={classes.accordion}
      defaultExpanded={true}
    >
      <AccordionSummary
        disableRipple
        className={classes.animation}
        expandIcon={
          <>
            {actions.map((item, index) => {
              return (
                <div
                  className={clsx(classes.action, {
                    [classes.active]: item.active,
                  })}
                  key={index}
                  onClick={item.onClick}
                >
                  <div className={classes.iconAction}>{item.iconAction}</div>
                  <div>{item.title} </div>
                </div>
              );
            })}
            <div className={classes.toggleAction} onClick={handleExpand}>
              {expanded ? <RemoveIcon /> : <AddIcon />}
            </div>
          </>
        }
        IconButtonProps={{
          disableRipple: true,
        }}
      >
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.detail}>{children}</AccordionDetails>
    </Accordion>
  );
}
export default ToggleComponent;
