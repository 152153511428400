import ToggleComponent from 'src/components/ToggleComponent';
import { useStyles } from './styles';

const objImageSocial = {
  facebook: '/images/facebook.png',
  tiktok: '/images/tiktok.png',
  instagram: '/images/instagram.png',
  youtube: '/images/youtube.png',
};

type ISocialUser = {
  profileSocialNetwork?: [];
};

function SocialUser({ profileSocialNetwork }: ISocialUser) {
  const classes = useStyles();

  // const [isEdit, setEdit] = useState<boolean>(false);

  // const onSaveSocial = ({ id, data }) => {
  //   dispatch(UserAction.editSocialUserActionRequest({ id, data }));
  //   setEdit(!isEdit);
  // };

  // const actions = [
  //   {
  //     title: '',
  //     iconAction: <IconPen />,
  //     onClick: onEdit,
  //   },
  // ];
  // const editActions = [
  //   {
  //     title: '',
  //     iconAction: <IconClose />,
  //     onClick: onEdit,
  //   },
  // ];

  return (
    <ToggleComponent title="Social">
      {/* {isEdit ? (
        <div className={classes.edit}>
          <EditSocial
            socialInfo={dataSocial}
            editSocialUserActionRequest={onSaveSocial}
          />
        </div>
      ) : (
        <div className={classes.info}>
          {profileSocialNetwork?.map((item: any, index) => (
            <div key={index} className={classes.contentSocial}>
              <div className={classes.iconSocial}>
                <img width="20px" src={objImageSocial[item?.type]} alt="" />
                <div style={{ fontSize: 12 }}>{item?.type.toUpperCase()}</div>
              </div>
              <a
                className={classes.url}
                target="_blank"
                href={`https://${item?.link.split("'")[1]}`}
                rel="noreferrer"
              >
                {`https://${item?.link.split("'")[1]}`}
              </a>
            </div>
          ))}
        </div>
      )} */}
      <div className={classes.info}>
        {profileSocialNetwork && profileSocialNetwork?.length > 0
          ? profileSocialNetwork?.map((item: any, index) => (
              <div key={index} className={classes.contentSocial}>
                <div className={classes.iconSocial}>
                  <img width="20px" src={objImageSocial[item?.type]} alt="" />
                  <div style={{ fontSize: 12 }}>{item?.type.toUpperCase()}</div>
                </div>
                <a
                  className={classes.url}
                  target="_blank"
                  href={`https://${item?.link}`}
                  rel="noreferrer"
                >
                  {`https://${item?.link}`}
                </a>
              </div>
            ))
          : 'No data social network'}
      </div>
    </ToggleComponent>
  );
}

export default SocialUser;
