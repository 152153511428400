import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import cookie from 'src/utils/cookie';
import * as types from '../constants';
import * as actionTypes from '../actions';
import {
  getApiLogin,
  getProfileAdminService,
  refreshTokenService,
} from '../services';

function* loginSaga(action) {
  try {
    const { data } = action.payload;
    const user = yield getApiLogin(data);
    cookie.setCookie('token', user.data.loginAdmin.token);
    cookie.setCookie('refreshToken', user.data.loginAdmin.refreshtoken);
    yield put(actionTypes.loginSuccess(user));
    yield put(push('/partners'));
  } catch (error) {
    yield put(actionTypes.loginFail(error));
  }
}

export function* logoutSaga() {
  const res = true;
  cookie.removeCookie('token');
  cookie.removeCookie('refreshToken');
  if (res) {
    yield put(actionTypes.logoutSuccess());
    window.location.replace('/');
    return;
  }
  yield put(actionTypes.logoutFail());
}

export function* refreshTokenSaga() {
  const refreshToken = cookie.getCookie('refreshToken');

  try {
    const {
      data: { refreshTokenAdmin },
    } = yield refreshTokenService(refreshToken);
    yield put(actionTypes.refreshSuccess());
    cookie.setCookie('token', refreshTokenAdmin.token);
    cookie.setCookie('refreshToken', refreshTokenAdmin.refreshtoken);
  } catch (error) {
    yield put(actionTypes.refreshFail(error));
    cookie.removeCookie('token');
    cookie.removeCookie('refreshToken');
    yield put(actionTypes.logoutRequest());
  }
  yield;
}

function* getProfileAdminSaga() {
  try {
    const { data } = yield getProfileAdminService();
    yield put(actionTypes.getProfileAdminSuccess(data.adminProfile));
  } catch (error) {
    yield put(actionTypes.getProfileAdminFail(error));
  }
}

export default function* AuthSaga() {
  yield takeLatest(types.LOGIN_REQUEST, loginSaga);
  yield takeLatest(types.LOGOUT_REQUEST, logoutSaga);
  yield takeLatest(types.REFRESH_TOKEN_REQUEST, refreshTokenSaga);
  yield takeLatest(types.GET_PROFILE_ADMIN_REQUEST, getProfileAdminSaga);
}