import { Button, CircularProgress } from '@material-ui/core';
import classnames from 'classnames';

import { useStyles } from './styles';

interface IProps {
  isLoading?: boolean;
  handleAction?: any;
  children: any;
  disable?: boolean;
  status?: string;
  icon?: React.ReactNode;
}

function ButtonComponent({
  isLoading,
  handleAction,
  children,
  disable,
  status,
  icon,
  ...props
}: IProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button
        {...props}
        type="submit"
        disabled={disable}
        variant="outlined"
        className={classnames(classes.submit, {
          [classes.disabled]: disable,
          [classes.approve]: status === 'approve',
          [classes.cancel]: status === 'cancel',
          [classes.origin]: status === 'origin',
        })}
        onClick={handleAction}
        startIcon={icon}
      >
        {children}
        {isLoading && (
          <CircularProgress color="secondary" size={18} className={classes.circularProgress} />
        )}
      </Button>
    </div>
  );
}

export default ButtonComponent;
