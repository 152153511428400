import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: "10px",
    "& a" : {
      textDecoration: "none",
      color: "#3e50b4"
    },
  },
  select :{
    paddingLeft: "5px",
    "& .MuiSelect-select.MuiSelect-select" : {
      fontSize: "12px",
      "&:focus" :  {
        background: "none"
      }
    },

  },
  dialog: {

  },
  pageContent: {
    display: "flex"
  },
  navigate: {
    padding: "10px",
    marginRight: "20px",
    "& span" : {
      fontSize: "12px",
      marginRight: "20px"
    }
  },
  page: {
    fontSize: "12px",
    paddingRight: "5px"
  },
  container: {
    maxHeight: 440,
  },
  danger: {
    color: "#f94839"
  },
  active: {
    color: "#4caf50"
  },
  boxTable: {
    border: "1px none",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
  },
  online: {
    color: '#4CAF50'
  },
  check: {
    color: '#4CAF50'
  },
  cancle: {
    color: '#F94839'
  },
  rowName: {
    display: 'flex',
    alignItems: 'center',
    '& >div' : {
      marginRight: theme.spacing(1)
    }
  }
}));
export { useStyles };