import { Grid, MenuItem, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ButtonComponent from 'src/components/ButtonComponent';
import DialogMui from 'src/components/DialogMui';
import TextInput from 'src/components/InputFiled';
import { statusData, statusWithdraw } from '../../contants';
import { IWithdrawDetail } from '../../interfaces';
import { updateStatusWithdrawRequest } from '../../store/actions';

export default function WithdrawDetail({ onClose, detail }: IWithdrawDetail) {
  const dispatch = useDispatch();
  const [stateStatus, setStateStatus] = useState(
    statusWithdraw[detail?.status] || ''
  );

  const handleChangeStatus = (e: any) => {
    setStateStatus(e.target.value);
  };

  const handleUpdateStatus = () => {
    dispatch(
      updateStatusWithdrawRequest({
        id: detail?.id,
        status: stateStatus,
        callback: () => onClose(),
      })
    );
  };

  const [confirm, setConfirm] = useState(false);

  const handleChangeConfirm = () => {
    setConfirm(!confirm);
  };

  return (
    <Grid container spacing={2} style={{ padding: '32px 16px' }}>
      <Grid item xs={12}>
        <Typography>Profile</Typography>
      </Grid>

      <Grid item xs={6}>
        <TextInput
          disabled
          label="Partner"
          value={detail?.profiles?.name}
          variant="outlined"
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          disabled
          label="Phone number"
          value={detail?.profiles?.phone}
          variant="outlined"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Bank Account</Typography>
      </Grid>

      <Grid item xs={6}>
        <TextInput
          disabled
          label="Bank Account"
          value={detail?.bank_account?.bank_account}
          name="price"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          disabled
          label="Verified"
          value={detail?.bank_account?.status}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          disabled
          label="Full Name"
          value={detail?.bank_account?.payment_unit?.fullname}
          name="price"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          disabled
          label="Name"
          value={detail?.bank_account?.payment_unit?.name}
          name="price"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography>Status</Typography>
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label="Name"
          value={stateStatus}
          variant="outlined"
          select
          onChange={handleChangeStatus}
        >
          {statusData.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextInput>
      </Grid>
      <Grid item xs={6}>
        <ButtonComponent status="origin" handleAction={handleChangeConfirm}>
          Update Status
        </ButtonComponent>
      </Grid>

      <DialogMui
        title={`Are you sure that you want to update Partner ${detail?.profiles?.name} ? `}
        open={confirm}
        onClose={handleChangeConfirm}
        onConfirm={handleUpdateStatus}
      />
    </Grid>
  );
}
