import * as types from '../constants';

import { IParamServiceAreas, IParamUpdate } from '../../interface';

export const getServiceAreasRequest = (data: IParamServiceAreas) => {
  return {
    type: types.GET_SERVICE_AREAS_REQUEST,
    payload: data,
  };
};

export const getServiceAreasSuccess = (data) => {
  return {
    type: types.GET_SERVICE_AREAS_SUCCESS,
    payload: { data },
  };
};

export const getServiceAreasFail = (data) => {
  return {
    type: types.GET_SERVICE_AREAS_FAIL,
    payload: { data },
  };
};

export const deleteServiceAreasRequest = (id: string) => {
  return {
    type: types.DELETE_SERVICE_AREAS_REQUEST,
    payload: id,
  };
};

export const deleteServiceAreasSuccess = () => {
  return {
    type: types.DELETE_SERVICE_AREAS_SUCCESS,
  };
};

export const deleteServiceAreasFail = (data) => {
  return {
    type: types.DELETE_SERVICE_AREAS_FAIL,
    payload: { data },
  };
};

export const getCitiesIdRequest = () => {
  return {
    type: types.GET_ID_CITY_REQUEST,
  };
};

export const getCitiesIdSuccess = (data) => {
  return {
    type: types.GET_ID_CITY_SUCCESS,
    payload: { data },
  };
};

export const getCitiesIdFail = (data) => {
  return {
    type: types.GET_ID_CITY_FAIL,
    payload: { data },
  };
};

export const createServiceAreaRequest = (data) => {
  return {
    type: types.CREATE_SERVICE_AREA_REQUEST,
    payload: { data },
  };
};

export const createServiceAreaSuccess = () => {
  return {
    type: types.CREATE_SERVICE_AREA_SUCCESS,
  };
};

export const createServiceAreaFail = (data) => {
  return {
    type: types.CREATE_SERVICE_AREA_FAIL,
    payload: { data },
  };
};

export const updateServiceAreaRequest = (params: IParamUpdate) => {
  return {
    type: types.UPDATE_SERVICE_AREA_REQUEST,
    payload: { params },
  };
};

export const updateServiceAreaSuccess = (data: IParamUpdate) => {
  return {
    type: types.UPDATE_SERVICE_AREA_SUCCESS,
    payload: { data },
  };
};

export const updateServiceAreaFail = (data) => {
  return {
    type: types.UPDATE_SERVICE_AREA_FAIL,
    payload: { data },
  };
};
