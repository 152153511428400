import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputLabel, MenuItem, Select } from '@material-ui/core';

import TextInput from '../../../../../../../components/InputFiled';
import ButtonComponent from 'src/components/ButtonComponent';

import { SelectForm, useStyles } from './styles';
import { IEditUser } from 'src/containers/User/interface';

const validate = Yup.object().shape({
  name: Yup.string().required('Required'),
  gender: Yup.string().required('Required').nullable(),
  date_of_birth: Yup.string().required('Required').nullable(),
});

const dataEdit = (dataForm) => [
  {
    title: 'Name',
    name: 'name',
    value: dataForm.name || '',
  },
  {
    title: 'Date of birth',
    name: 'date_of_birth',
    value: dataForm.date_of_birth || '2014-08-18',
  },
];

function EditUser(props: IEditUser) {
  const classes = useStyles();
  const { editUserActionRequest, userInfo } = props;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...userInfo },
    validationSchema: validate,
    onSubmit: (values) => {
      editUserActionRequest({ id: values.id, data: { ...values } });
    },
  });

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete='off'
      onSubmit={formik.handleSubmit}
    >
      {dataEdit(formik.values).map((item) => (
        <TextInput
          key={item.name}
          name={item.name}
          type={item.name === 'date_of_birth' ? 'date' : 'text'}
          variant='outlined'
          label={item.title}
          value={item.value || ''}
          error={formik.touched[item.name] && Boolean(formik.errors[item.name])}
          helperText={formik.touched[item.name] && formik.errors[item.name]}
          onChange={formik.handleChange}
        />
      ))}
      <div>
        <SelectForm variant='outlined'>
          <InputLabel>gender</InputLabel>
          <Select
            name='gender'
            value={formik.values.gender}
            onChange={formik.handleChange}
            label='gender'
            className={classes.menuItem}
          >
            <MenuItem value={'female'}>Female</MenuItem>
            <MenuItem value={'male'}>Male</MenuItem>
          </Select>
        </SelectForm>
      </div>
      <ButtonComponent>Update</ButtonComponent>
    </form>
  );
}
export default EditUser;
