import { useDispatch, useSelector } from 'react-redux';
import {
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import { Button, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';

import { formatNumber } from 'src/containers/ListPartner/components/TablePartner';
import DialogComponent from 'src/components/DialogComponent';
import { ISelector } from 'src/reducers';
import * as AppAction from 'src/containers/App/store/actions';
import * as UserAction from 'src/containers/User/store/actions';
import TextInput from 'src/components/InputFiled';
import ButtonComponent from 'src/components/ButtonComponent';
import NumberFormatCustom from 'src/containers/Services/components/CreateService/FormatNumber';
import DialogMui from 'src/components/DialogMui';
import { IUser } from 'src/containers/User/interface';

import { useStyles } from './styles';

interface IWalletPartner {
  isSet?: boolean;
  title: string;
  balance: number;
}

interface IFieldProfile {
  name?: string;
  value?: string | number;
  label?: string;
}

const fieldProfile = (item: IUser) => [
  {
    name: 'name',
    value: item.name,
    label: 'Name',
  },
  {
    name: 'email',
    value: item.email,
    label: 'Email',
  },
  {
    name: 'phone',
    value: item.phone,
    label: 'Phone',
  },
  {
    name: 'accountBalance',
    value: item.accountBalance,
    label: 'Account Balance',
  },
  {
    name: 'accountRevenue',
    value: item.accountRevenue,
    label: 'Account Revenue',
  },
];

const validate = Yup.object().shape({
  amount: Yup.number().required('Required'),
  content: Yup.string().required('Required'),
});

function WalletPartner({ isSet, title, balance }: IWalletPartner) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isAdd = useSelector((s: ISelector) => s.global?.isAdd);

  const handleCloseModalAddService = () => dispatch(AppAction.hideModalAdd());

  const handleOpenModalAddService = () => dispatch(AppAction.showModalAdd());

  const themeMode = localStorage.getItem('instalent-them-mode');

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootDark]: themeMode === 'dark',
        [classes.rootLight]: themeMode === 'light',
      })}
    >
      <div className={classes.wallet}>
        <AccountBalanceWalletIcon fontSize="large" />
        <div>{title}</div>
      </div>
      <p className={classes.textMoney}>{formatNumber(balance)} $ </p>
      {isSet && (
        <>
          <Button
            size="small"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={handleOpenModalAddService}
          >
            ADD MONEY
          </Button>

          <DialogComponent
            title="ADD MONEY"
            open={isAdd}
            onClose={handleCloseModalAddService}
          >
            <AddMoneyChildren />
          </DialogComponent>
        </>
      )}
    </div>
  );
}

function AddMoneyChildren() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isModal = useSelector((s: ISelector) => s.global?.isModal);
  const userDetail = useSelector((s: ISelector) => s.user?.userDetail);

  const handleCloseModal = () => dispatch(AppAction.hideModal());

  const handleConfirm = () => {
    dispatch(
      UserAction.addMoneyPartnerRequest({
        id: userDetail?.id || '',
        data: { ...formik.values, amount: +formik.values.amount },
      })
    );
    dispatch(AppAction.hideModalAdd());
  };

  const handleOpenModal = () => dispatch(AppAction.showModal());

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: 0,
      content: '',
    },
    validationSchema: validate,
    onSubmit: () => {
      handleOpenModal();
    },
  });

  return (
    <div className={classes.mainChildren}>
      <div className={classes.info}>
        <div className={classes.infoText}>Info Partner</div>
        <div className={classes.infoDescription}>
          {fieldProfile(userDetail).map((item: IFieldProfile) => (
            <TextInput
              value={item.value}
              label={item.label}
              name={item.name}
              variant="outlined"
              disabled
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
            />
          ))}
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.infoText}>Amount</div>
        <div className={classes.amountInput}>
          <TextField
            value={formik.values.amount}
            label="Amount"
            onChange={formik.handleChange}
            name="amount"
            className={classes.textField}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
            }}
            variant="outlined"
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
          />

          <TextField
            value={formik.values.content}
            label="Content"
            onChange={formik.handleChange}
            name="content"
            className={classes.textField}
            variant="outlined"
            error={formik.touched.content && Boolean(formik.errors.content)}
            helperText={formik.touched.content && formik.errors.content}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={classes.textChildren}>
          <p>You'll add money for partner</p>
          <p>{formatNumber(formik.values.amount)} $</p>
        </div>
        <div className={classes.btn}>
          <ButtonComponent>Continue</ButtonComponent>
        </div>
      </form>
      <DialogMui
        open={isModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
      />
    </div>
  );
}

export default WalletPartner;
