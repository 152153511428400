import { call, put, takeLatest } from 'redux-saga/effects';

import * as types from '../constants';
import * as actionTypes from '../actions';
import {
  addCouponService,
  deleteCouponService,
  getApiUsers,
  getCouponServices,
  getListServices,
  getPaymentMethod,
  updateCouponService,
} from '../services';
import { showSnackbar } from 'src/containers/Snackbar/store/actions';

function* getPaymentMethods() {
  try {
    const data = yield call(getPaymentMethod);
    yield put(actionTypes.getPaymentMethodSuccess(data.data.getPaymentMethods));
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.getPaymentMethodFailed(message.error.graphQLErrors[0].code)
    );
  }
}

function* getAllServices() {
  try {
    const data = yield call(getListServices);

    yield put(actionTypes.getAllServiceSuccess(data.data.services));
    console.log(data.data.services);
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.getAllServiceFailed(message.error.graphQLErrors[0].code)
    );
  }
}

function* getListUserServices(action) {
  try {
    const data = yield call(getApiUsers, action.payload.data);
    yield put(actionTypes.getListUserSuccess(data.data.users.data));
    console.log(data.data.services);
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.getListUserFailed(message.error.graphQLErrors[0].code)
    );
  }
}

function* addCouponSaga(action) {
  try {
    yield addCouponService(action.payload);
    yield put(actionTypes.addCouponSuccess());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Add Succeed',
      })
    );
    yield action.payload.callbackClose();
  } catch (error: any) {
    const message = { error };
    yield put(actionTypes.addCouponFailed(message.error.graphQLErrors[0].code));
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Added Failed',
      })
    );
  }
}

function* getAllCouponSaga(action) {
  try {
    const data = yield call(getCouponServices, action.payload.data);
    yield put(actionTypes.getListCouponSuccess(data.data.getAllCoupon));
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.getListCouponFailed(message.error.graphQLErrors[0].code)
    );
  }
}

function* deleteCouponSaga(action) {
  try {
    yield deleteCouponService(action.payload.id);
    yield put(actionTypes.deleteCouponSuccess(action.payload.id));

    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Delete Succeed',
      })
    );
    yield action.payload.closeModal();
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.deleteCouponFailed(message.error.graphQLErrors[0].code)
    );

    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Delete Failed',
      })
    );
    yield action.payload.closeModal();
  }
}

function* updateCouponSaga(action) {
  try {
    yield updateCouponService(action.payload);
    yield put(actionTypes.updateCouponSuccess(action.payload));

    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
    yield action.payload.callbackClose();
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.updateCouponFailed(message.error.graphQLErrors[0].code)
    );

    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Updated Failed',
      })
    );
    yield action.payload.callbackClose();
  }
}

export default function* CouponsSaga() {
  yield takeLatest(types.GET_PAYMENT_METHOD_REQUEST, getPaymentMethods);
  yield takeLatest(types.GET_ALL_SERVICE_REQUEST, getAllServices);
  yield takeLatest(types.GET_LIST_USER_REQUEST, getListUserServices);
  yield takeLatest(types.ADD_COUPON_REQUEST, addCouponSaga);
  yield takeLatest(types.GET_ALL_COUPON_REQUEST, getAllCouponSaga);
  yield takeLatest(types.DELETE_COUPON_REQUEST, deleteCouponSaga);
  yield takeLatest(types.UPDATE_COUPON_REQUEST, updateCouponSaga);
}
