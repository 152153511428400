export const GET_WITHDRAW_REQUEST = 'instalent/GET_WITHDRAW_REQUEST';
export const GET_WITHDRAW_SUCCESS = 'instalent/GET_WITHDRAW_SUCCESS';
export const GET_WITHDRAW_FAIL = 'instalent/GET_WITHDRAW_FAIL';

export const UPDATE_STATUS_WITHDRAW_REQUEST =
  'instalent/UPDATE_STATUS_WITHDRAW_REQUEST';
export const UPDATE_STATUS_WITHDRAW_SUCCESS =
  'instalent/UPDATE_STATUS_WITHDRAW_SUCCESS';
export const UPDATE_STATUS_WITHDRAW_FAIL =
  'instalent/UPDATE_STATUS_WITHDRAW_FAIL';
