import SearchIcon from '@material-ui/icons/Search';
import { Grid, InputAdornment } from '@material-ui/core';
import { useSelector } from 'react-redux';

import TextInput from 'src/components/InputFiled';
import SelectOption from 'src/components/Selection';
import { IFilterPartner } from '../../interface';
import { ISelector } from 'src/reducers';
import { dataFieldPartner } from '../../contants';

import { useStyles } from './styles';

function FilterPartner({
  handleSubmitFilter,
  handleSearch,
  onResetFilter,
  search,
}: IFilterPartner) {
  const classes = useStyles();
  const services = useSelector((s: ISelector) => s.booking?.services || []);

  return (
    <div className={classes.container}>
      <form className={classes.search} onSubmit={handleSubmitFilter}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridLeft}>
            {dataFieldPartner(search).map((item, index) => {
              return (
                <div className={classes.box} key={index}>
                  <TextInput
                    value={item.value || ''}
                    name={item.name}
                    label={item.label}
                    type="search"
                    variant="outlined"
                    onChange={handleSearch}
                    inputprops={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              );
            })}
            <SelectOption
              label="Service Name"
              options={services}
              name="serviceId"
              onChangeOption={handleSearch}
              value={search.serviceId}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className={classes.filter}>
              <div className={classes.btn}>
                <button
                  className={`${classes.btnApply} ${classes.btnColor}`}
                  type="submit"
                >
                  Apply Filter
                </button>
              </div>
              <div className={classes.btn}>
                <button
                  className={classes.btnReset}
                  type="button"
                  onClick={onResetFilter}
                >
                  Clear Filter
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default FilterPartner;
