import { useSelector } from 'react-redux';

import AvatarUser from 'src/containers/User/components/LeftSide/components/AvatarUser';
import SocialUser from 'src/containers/User/components/LeftSide/components/Social';
import UserInfo from 'src/containers/User/components/LeftSide/components/UserProfile';
import { ISelector } from 'src/reducers';
import PartnerCategory from './components/PartnerCategory';
import WalletPartner from './components/WalletPartner';

function LeftSide() {
  const userDetail = useSelector((s: ISelector) => s.user?.userDetail);

  return (
    <>
      <AvatarUser avtUrl={userDetail?.avatar} name={userDetail?.name} />
      <WalletPartner
        balance={userDetail?.accountRevenue || 0}
        title="Account Revenue"
      />
      <WalletPartner
        balance={userDetail?.accountBalance || 0}
        isSet={true}
        title="Account Balance"
      />
      <UserInfo />
      <SocialUser profileSocialNetwork={userDetail?.profileSocialNetwork} />
      <PartnerCategory services={userDetail?.services} />
    </>
  );
}

export default LeftSide;
