import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import * as ServicesAction from '../../store/actions';
import { ITextFieldService } from '../../interface';
import { textFieldService} from '../../constants';
import ButtonComponent from 'src/components/ButtonComponent';
import TextInput from 'src/components/InputFiled';
import NumberFormatCustom from './FormatNumber';

import { useStyles } from './styles';

const validate = Yup.object().shape({
  name: Yup.string().required('Required'),
  price: Yup.string().required('Required'),
  min: Yup.string().required('Required'),
  max: Yup.string().required('Required'),
  unit: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

function CreateService() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleCancel = () => dispatch(ServicesAction.hideModalAddService());

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      price: 0,
      min: 0,
      max: 0,
      unit: '',
      description: '',
    },
    validationSchema: validate,
    onSubmit: (values) => {
      dispatch(ServicesAction.addServicesRequest(values));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {textFieldService(formik.values).map((item: ITextFieldService) => (
        <div key={item.name} className={classes.formContent}>
          <TextInput
            value={item.value}
            onChange={formik.handleChange}
            label={item.label}
            type={item.type}
            name={item.name}
            variant="outlined"
            required
            error={
              formik.touched[item.name] && Boolean(formik.errors[item.name])
            }
            helperText={formik.touched[item.name] && formik.errors[item.name]}
            InputLabelProps={{
              shrink: true,
              inputComponent: NumberFormatCustom as any,
            }}
          />
        </div>
      ))}
      <div className={classes.formContent}>
        <TextInput
          label="Price"
          value={formik.values.price}
          name="price"
          variant="outlined"
          onChange={formik.handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
          required
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={formik.touched.price && formik.errors.price}
        />
      </div>

      <div className={classes.actionSubmit}>
        <Button onClick={handleCancel}>Cancel</Button>
        <ButtonComponent status="approve">Submit</ButtonComponent>
      </div>
    </form>
  );
}

export default CreateService;
