import { TablePagination } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MuiTable from 'src/components/MuiTable';
import ToggleComponent from 'src/components/ToggleComponent';
import { useInjectReducer } from 'src/utils/injectReducer';
import { useInjectSaga } from 'src/utils/injectSaga';
import reducer from './store/reducers';
import saga from './store/sagas';
import { ISelector } from 'src/reducers';
import TextInput from 'src/components/InputFiled';
import ButtonComponent from 'src/components/ButtonComponent';
import { formatNumber } from '../ListPartner/components/TablePartner';
import { ParamTypes } from '../Booking/interface';
import HistoryTransactionHook from './hooks/HistoryTransactionHook';
import { IListTransaction } from './interface';

import { useStyles } from './styles';

export const COLUMS = [
  {
    title: 'Transaction ID',
    field: 'id',
  },
  {
    title: 'Service',
    field: 'service',
  },
  {
    title: 'Type',
    field: 'type',
  },
  {
    title: 'Booking ID',
    field: 'booking_id',
  },
  {
    title: 'Amount',
    field: 'amount',
  },
  {
    title: 'Created',
    field: 'created_at',
  },
];

function HistoryTransaction() {
  useInjectReducer({ key: 'transaction', reducer });
  useInjectSaga({ key: 'transaction', saga });
  const { id } = useParams<ParamTypes>();

  const {
    page,
    rowsPerPage,
    search,
    handleSearch,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilter,
    handleClearFilter,
  } = HistoryTransactionHook(id);
  const {
    isRequesting,
    listTransactions = [],
    total,
  } = useSelector((s: ISelector) => s.transaction);

  const classes = useStyles();

  const row = (item: IListTransaction) => {
    return {
      id: <div>{item.id || ''}</div>,
      service: (
        <div>
          <p>{item.service?.name || ''}</p>
        </div>
      ),
      type: (
        <div>
          <p>{item.type|| ''}</p>
        </div>
      ),
      booking_id: (
        <div>
          <Link className={classes.link} to={`/booking/${item.booking_id}`}>
            {item.booking_id || ''}
          </Link>
        </div>
      ),
      amount: (
        <div>
          <p>{formatNumber(item.amount) || ''} đ</p>
        </div>
      ),
      created_at: (
        <div>
          <p>{moment(item.created_at || '').format('DD-MM-YYYY HH:mm:ss')}</p>
        </div>
      ),
    };
  };

  const skeletonRowData = (
    <div>
      <Skeleton variant="rect" animation="wave" />
    </div>
  );

  const formatData = (keys) => {
    const obj = {};
    keys.forEach((key) => (obj[key] = skeletonRowData));
    return obj;
  };

  const rowError = formatData([
    'id',
    'service',
    'type',
    'booking_id',
    'userRequest',
    'booking_time',
    'amount',
    'created_at',
  ]);

  const renderRow = isRequesting
    ? new Array(10).fill(0).map((i) => rowError)
    : listTransactions.map(row);

  const pagination = (
    <div>
      <TablePagination
        component="div"
        count={total || 1000}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );

  return (
    <ToggleComponent title="History Transaction">
        <div className={classes.filter}>
          <div className={classes.inputSearch}>
            <TextInput
              value={search.date}
              onChange={handleSearch}
              type="date"
              name="date"
              variant="outlined"
            />
          </div>
          <div className={classes.bntSearch}>
            <ButtonComponent handleAction={handleFilter}>
              Filter
            </ButtonComponent>
          </div>
          <div className={classes.bntClear}>
            <ButtonComponent status="cancel" handleAction={handleClearFilter}>
              Clear Filter
            </ButtonComponent>
          </div>
        </div>
        <MuiTable headers={COLUMS} rows={renderRow} pagination={pagination} />
    </ToggleComponent>
  );
}

export default HistoryTransaction;
