import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TablePagination } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  DeleteForever as DeleteForeverIcon,
  Create as CreateIcon,
  Add as AddIcon,
} from '@material-ui/icons';

import {
  showModal,
  hideModal,
  showModalUpdate,
  hideModalUpdate,
  showModalAdd,
  hideModalAdd,
} from 'src/containers/App/store/actions';
import ButtonComponent from 'src/components/ButtonComponent';
import { ISelector } from 'src/reducers';
import DialogMui from 'src/components/DialogMui';
import MuiTable from 'src/components/MuiTable';
import DialogComponent from 'src/components/DialogComponent';
import { COLUMNS } from '../../constants';
import { IListServiceView } from '../../../Services/interface';
import { deleteServiceAreasRequest } from '../../store/actions';
import { IListServiceArea } from '../../interface';
import CreateServiceAreaModal from '../CreateModal';
import UpdateModal from '../UpdateModal';

import { useStyles } from './styles';

function TableServiceAreas(props: IListServiceView) {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    props;
  const clx = useStyles();
  const dispatch = useDispatch();

  const [isID, setIsID] = useState('');
  const [dataUpdate, setDataUpdate] = useState<IListServiceArea | any>();

  const isLoading = useSelector(
    (area: ISelector) => area.serviceAreas?.isLoading
  );
  const servicesAreasList = useSelector(
    (area: ISelector) => area.serviceAreas?.servicesAreasList
  );
  const total = useSelector((area: ISelector) => area.serviceAreas?.total);
  const isCreateServiceArea = useSelector(
    (area: ISelector) => area.global?.isModal
  );
  const isAdd = useSelector((area: ISelector) => area.global?.isAdd);
  const isUpdateServiceArea = useSelector(
    (area: ISelector) => area.global?.isUpdate
  );

  const skeletonRowData = (
    <div>
      <Skeleton variant="rect" animation="wave" />
    </div>
  );

  const handleOpenModalDelete = (id: string) => {
    dispatch(showModal());
    setIsID(id);
  };

  const handleDeleteServiceArea = () =>
    dispatch(deleteServiceAreasRequest(isID));

  const handleOpenModalUpdateServiceArea = (items: IListServiceArea) => {
    dispatch(showModalUpdate());
    setDataUpdate(items);
  };

  const handleCloseModalDelete = () => dispatch(hideModal());

  const handleOpenCreateServiceAreaModal = () => dispatch(showModalAdd());

  const handleCloseCreateServiceAreaModal = () => dispatch(hideModalAdd());

  const handleCloseUpdateModalServiceArea = () => {
    dispatch(hideModalUpdate());
  };

  const formatData = (keys) => {
    const obj = {};
    keys.forEach((key) => (obj[key] = skeletonRowData));
    return obj;
  };

  const rowError = formatData([
    'id',
    'service',
    'city_name',
    'price',
    'action',
  ]);

  const row = (items) => {
    return {
      id: <div>{items.id}</div>,
      service: <div>{items.services.name}</div>,
      city_name: <div>{items.city_name}</div>,
      price: (
        <div>
          {new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'VND',
          }).format(items.price)}
        </div>
      ),
      action: (
        <div>
          <div className={clx.buttonAction}>
            <div>
              <ButtonComponent
                status="approve"
                handleAction={() => handleOpenModalUpdateServiceArea(items)}
              >
                <CreateIcon />
              </ButtonComponent>
            </div>
            <div>
              <ButtonComponent
                status="cancel"
                handleAction={() => handleOpenModalDelete(items?.id)}
              >
                <DeleteForeverIcon color="inherit" />
              </ButtonComponent>
            </div>
          </div>
        </div>
      ),
    };
  };

  const renderRow = isLoading
    ? new Array(10).fill(0).map((i) => rowError)
    : servicesAreasList?.map(row);

  const pagination = (
    <div>
      <TablePagination
        component="div"
        count={total || 10}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );

  return (
    <>
      <div className={clx.buttonCreate}>
        <ButtonComponent
          status="approve"
          handleAction={handleOpenCreateServiceAreaModal}
          icon={<AddIcon fontSize="small" />}
        >
          Create Service Areas
        </ButtonComponent>
      </div>
      <MuiTable headers={COLUMNS} rows={renderRow} pagination={pagination} />
      <DialogMui
        title="Delete this?"
        open={isCreateServiceArea}
        onClose={handleCloseModalDelete}
        onConfirm={handleDeleteServiceArea}
      />
      <DialogComponent
        title="Create a Service Area"
        open={isAdd}
        onClose={handleCloseCreateServiceAreaModal}
      >
        <CreateServiceAreaModal />
      </DialogComponent>
      <DialogComponent
        title="Update a Service Area"
        open={isUpdateServiceArea}
        onClose={handleCloseUpdateModalServiceArea}
      >
        <UpdateModal dataUpdate={dataUpdate} />
      </DialogComponent>
    </>
  );
}
export default TableServiceAreas;
