import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& a': {
      textDecoration: 'none',
      color: '#3e50b4',
    },
  },
  container: {
    maxHeight: 440,
  },
  boxTable: {
    border: '1px none',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
  textStatus: {
    fontSize: 10,
    fontWeight: 600,
  },
  bntAction: {
    display: 'flex',
    '& >div': {
      margin: theme.spacing(0, 1),
    },
  },
  headContain: {
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    marginBottom: theme.spacing(3),
  },
}));
export { useStyles };
