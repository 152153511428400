import TablePartner from '../../ListPartner/components/TablePartner';
import FilterPartner from '../../ListPartner/components/FilterPartner';
import { IListPartnerView } from '../interface';

import { useStyles } from './styles';

function ListPartnerView(props: IListPartnerView) {
  const classes = useStyles();

  return (
    <div className={classes.deviceContainer}>
      <FilterPartner
        search={props.search}
        handleSearch={props.handleSearch}
        onResetFilter={props.onResetFilter}
        handleSubmitFilter={props.handleSubmitFilter}
      />
      <TablePartner
        page={props.page}
        rowsPerPage={props.rowsPerPage}
        handleChangeRowsPerPage={props.handleChangeRowsPerPage}
        handleChangePage={props.handleChangePage}
      />
    </div>
  );
}
export default ListPartnerView;
