import * as Yup from 'yup';
import Skeleton from '@material-ui/lab/Skeleton';

import { IFormikValueType } from '../interface/index';

export const textFieldSurcharge = (value: IFormikValueType) => [
  {
    label: 'Title',
    name: 'title',
    type: 'text',
    value: value.title,
  },
  {
    label: 'Description',
    name: 'description',
    type: 'text',
    value: value.description,
  },
  {
    label: 'Amount',
    name: 'amount',
    value: value.amount,
  },
];

export const optionStatus = [
  {
    name: 'Active',
    id: true,
  },
  {
    name: 'UnActive',
    id: false,
  },
];

export const COLUMNS = [
  {
    title: 'ID',
    field: 'id',
  },
  {
    title: 'Title',
    field: 'title',
  },
  {
    title: 'City',
    field: 'city_name',
  },
  {
    title: 'Amount',
    field: 'amount',
  },
  {
    title: 'Address',
    field: 'address',
  },
  {
    title: 'Description',
    field: 'description',
  },
  {
    title: 'status',
    field: 'status',
  },
  {
    title: 'Actions',
    field: 'actions',
  },
];

export const validate = Yup.object().shape({
  title: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  amount: Yup.number().required('Required'),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  status: Yup.boolean().required('Required'),
});

export const skeletonRowData = (
  <div>
    <Skeleton variant="rect" animation="wave" />
  </div>
);

export const formatData = (keys) => {
  const obj = {};
  keys.forEach((key) => (obj[key] = skeletonRowData));
  return obj;
};

export const rowError = formatData([
  'id',
  'title',
  'amount',
  'address',
  'city_name',
  'description',
  'status',
  'actions',
]);
