import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  & > button {
    min-width: 200;
    height: 40px;
    text-transform: none;
  }
  .MuiCircularProgress-root {
    position: absolute;
    top: 55%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiButtonBase-root': {
      background: '#FC4A46',
      color: '#ffff',
      borderRadius: theme.spacing(0.25),
      textTransform: 'capitalize',
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      height: 35,
      fontSize: 10,
      padding: '5px 15px !important',
      width: '100%',
    },
    '& .MuiButton-root' : {
      minWidth: 120
    }
  },
  circularProgress: {
    marginLeft: 5,
    marginRight: theme.spacing(0),
    color: 'white',
  },
  submit: {},
  disabled: {
    opacity: 0.8,
    cursor: 'pointer',
    pointerEvents: 'none',
  },
  cancel: {
    background: '#ffff !important',
    color: '#000d !important',
    border: '1px solid !important',
    '&:hover': {
      background: '#ffff',
      opacity: 0.7,
      transition: 'all .3s',
    },
  },
  approve: {
    background: '#FC4A46 !important',
    color: '#ffff !important',
    '&:hover': {
      background: '#FC4A46 !important',
      opacity: 0.7,
      transition: 'all .3s',
    },
  },
  origin : {
    background: '#3f51b5 !important',
    color: '#ddddd !important',
    '&:hover': {
      background: '#3f51b5 !important',
      opacity: 0.7,
      transition: 'all .3s',
    },
  }
}));
export { useStyles, Center };
