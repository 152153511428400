import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonCreate: {
    display: 'flex',
    justifyContent: 'left',
    width: '100%',
    marginBottom: 20
  },
  buttonAction: {
    display: 'flex',
    flexDirection: 'row',
    '& div' : {
      margin: 5
    }
  }
}));

export { useStyles };
