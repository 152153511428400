export interface IParamAddService {
  coupon_code: string;
  discount_unit: string;
  discount_value: number | string;
  maximum_discount_amount: number | string | null;
  minimum_order_value: number | string;
  restrictable_ids: Array<string>;
  restrictable_type: string;
  valid_before: string | null;
  callbackClose: () => void;
}

export const discountUnit = {
  0: 'percentage',
  1: 'currency',
};
