export const GET_SERVICES_REQUEST = 'instalent/GET_SERVICES_REQUEST';
export const GET_SERVICES_SUCCESS = 'instalent/GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAIL = 'instalent/GET_SERVICES_FAIL';

export const DELETE_SERVICES_REQUEST = 'instalent/DELETE_SERVICES_REQUEST';
export const DELETE_SERVICES_SUCCESS = 'instalent/DELETE_SERVICES_SUCCESS';
export const DELETE_SERVICES_FAIL = 'instalent/DELETE_SERVICES_FAIL';

export const UPDATE_SERVICES_REQUEST = 'instalent/UPDATE_SERVICES_REQUEST';
export const UPDATE_SERVICES_SUCCESS = 'instalent/UPDATE_SERVICES_SUCCESS';
export const UPDATE_SERVICES_FAIL = 'instalent/UPDATE_SERVICES_FAIL';

export const ADD_SERVICES_REQUEST = 'instalent/ADD_SERVICES_REQUEST';
export const ADD_SERVICES_SUCCESS = 'instalent/ADD_SERVICES_SUCCESS';
export const ADD_SERVICES_FAIL = 'instalent/ADD_SERVICES_FAIL';

export const SHOW_MODAL_ADD_SERVICE = 'instalent/SHOW_MODAL_ADD_SERVICE';
export const HIDE_MODAL_ADD_SERVICE = 'instalent/HIDE_MODAL_ADD_SERVICE';

export const SHOW_MODAL_UPDATE_SERVICE = 'instalent/SHOW_MODAL_UPDATE_SERVICE';
export const HIDE_MODAL_UPDATE_SERVICE = 'instalent/HIDE_MODAL_UPDATE_SERVICE';
