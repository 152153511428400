import { FormControl, makeStyles, withStyles } from '@material-ui/core';
import styledTheme from '../../../../../../../utils/theme';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: theme.palette.secondary.main,
    borderRadius: 0,
    fontSize: styledTheme.fontSize.medium
  },
  root: {
    width: '100%',
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  submit: {
    background: '#3f51b5',
    color: '#fff',
  },
  rootText: {
    width: '100%',
    '& input:-internal-autofill-selected': {
    },
    '& .MuiInputBase-root': {
      height: styledTheme.height.medium,
      color: theme.palette.text.primary,
      borderRadius: 'inherit',
      fontSize: 14,
      '& .MuiInputBase-inputTypeSearch': {},
    },
    '& .MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputTypeSearch':
    {
      background: '#ffff',
    },
    '& .Mui-disabled': {
      color: '#bcbcbc',
      borderColor: '#bcbcbc',
      '&:hover fieldset': {
        borderColor: '#bcbcbc',
      },
    },
    '& .MuiOutlinedInput-root': {
      height: 35,
      // background: '#ffff',
      '&:hover fieldset': {
        borderColor: '#1a1a1a',
      },
      '&.Mui-focused fieldset': {
        border: `1px solid rgba(0, 0, 0, 0.23)`,
      },
      '&.Mui-error fieldset': {
        border: `1px solid ${theme.palette.error.main}`,
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 17px) scale(1)',
      color: theme.palette.text.secondary,
      top: -7,
      fontSize: styledTheme.fontSize.xxlarge,
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, 0) scale(0.7)',
      },
    },
    '& > .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '30px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '16px 14px',
    },
    '& > .MuiInputLabel-outlined.Mui-focused': {},
    '& > .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(10px, -6px) scale(0.75)',
      top: '-4px',
    },
  }
}));

const SelectForm = withStyles((theme) => ({
  root: {
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.MuiFormControl-root': {
      width: '100%',
      '& >label': {
        fontSize: '13px'
      }
    },
    '& .MuiInputBase-root': {
      height: '32px',
      color: '#1a1a1a',
      minWidth: 100,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.text.primary,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${theme.palette.text.primary}`,
      },
    },
    '& .MuiInputLabel-outlined': {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.primary.main,
      transform: 'translate(14px, 10px) scale(1)',
      fontSize: '12px',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.7)',
        fontSize: '12px',
      },

    },
    '& > .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '50px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '15.5px 14px',
      fontSize: styledTheme.fontSize.medium,
      color: theme.palette.text.primary,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.primary,
    },
  },
}))(FormControl);
export { useStyles, SelectForm };
