import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  deviceContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

export { useStyles };
