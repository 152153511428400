import { makeStyles } from '@material-ui/core';
import styledTheme from '../../../utils/theme';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: '100%',
    position: 'sticky',
    top: 0,
    zIndex: 12,
    borderRadius: 0,
  },
  bgDark: {
    backgroundColor: '#424242 !important',
  },
  bgLight: {
    backgroundColor: '#FC4A46 !important',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '10px',
    margin: '0 auto',
  },
  routeItem: {
    display: 'flex',
    alignItems: 'center',
    padding: 15,
    fontSize: 12,
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.text.primary,
    '&:hover': {
      background: 'rgba(0,0,0,.04)',
    },
  },
  closeItem: {
    padding: '8px 15px',
    '& svg': {
      fontSize: 16,
    },
    '& p': {
      fontSize: 12,
    },
  },
  text: {
    marginLeft: 10,
    fontWeight: 500,
  },
  itemIcon: {
    fontSize: 16,
  },
  isActive: {
    background: '#DB1510',
    color: '#ffffff',
    '&:hover': {
      background: '#DB1510',
    },
  },
  lightBg: {
    backgroundColor: theme.palette.primary.violet,
  },
  darkBg: {
    backgroundColor: theme.palette.primary.secondary,
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 16,
    alignItems: 'center',
    padding: '10px 16px',
    cursor: 'pointer',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    marginRight: `${styledTheme.spacing}px`,
    textDecoration: 'none',
    color: '#ffff',
  },
  linkChildren: {
    display: 'flex',
    alignItems: 'center',
    marginRight: `${styledTheme.spacing}px`,
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  title: {
    fontSize: '14px',
    margin: '10px',
    color: '#ffff',
  },
  icon: {
    color: '#ffff',
  },
  label: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',

    '& >svg': {
      width: 20,
      marginRight: 4,
    },
    '& >span': {
      // fontWeight: 'bold'
    },
  },
  red: {
    color: 'red',
  },
  textName: {
    color: '#ffff',
    fontSize: 16,
  },
  menuItem: {},
  menuItemActive: {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
}));

export { useStyles };
