import { gql } from '@apollo/client';
import { client } from 'src';

export const getStaffService = async (data) => {
  const { page, rowsPerPage } = data;
  return await client.query({
    query: gql`
    query {
      admins(page: ${page} , per_page: ${rowsPerPage}) {
        data {
          id
          email
          password
          status
          language
          created_at
          updated_at
          adminProfileData {
            id
            admin_id
            avatar
            name
            gender
            date_of_birth
            online_status
          }
        }
        total
      }
    }
    `,
    fetchPolicy: 'network-only',
  });
};

export const updateStaff = async ({ id, data }) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      updateAdmin(id: ${id} , data:{
        status : ${data.status},
        email: "${data.email}",
        password: "${data.password}"
      })
    }
    `,
  });
};

export const addStaff = async ({
  email,
  password,
  gender,
  name,
  date_of_birth,
  adminRole,
  adminPermission,
}) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      addAdmin(payload: {
        data: {
          email: "${email}"
          password: "${password}"
          status: 1
        }
        adminProfile: {
          name: "${name}"
          gender: "${gender}"
          date_of_birth: "${date_of_birth}"
        }
        adminRole: ${adminRole}
        adminPermission: ${adminPermission}
      })
    }
    `,
  });
};

export const deleteStaff = async (id) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      deleteAdmin (id: ${id})
    }
    `,
  });
};
