import { useState, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

import { muiDarkTheme, muiTheme } from '../../utils/theme';

function Layout(props: any) {
  const { children } = props;
  const mode = localStorage.getItem('instalent-them-mode');

  const [themeMode, setThemeMode] = useState('');

  useEffect(() => {
    if (!mode) {
      let theme = window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';
      localStorage.setItem('instalent-them-mode', theme);
      setThemeMode(theme);
    } else {
      setThemeMode(mode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getMuiTheme = () => (themeMode === 'dark' ? muiDarkTheme : muiTheme);

  return (
    <MuiThemeProvider theme={{ ...getMuiTheme() }}>
      <Paper square>{children}</Paper>
    </MuiThemeProvider>
  );
}

export default Layout;
