import { Grid } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ButtonComponent from 'src/components/ButtonComponent';
import DialogComponent from 'src/components/DialogComponent';
import { useInjectReducer } from 'src/utils/injectReducer';
import { useInjectSaga } from 'src/utils/injectSaga';
import DialogMui from 'src/components/DialogMui';
import { FormCreateSurcharge } from '../components/FormCreateSurcharge';
import { FormUpdateSurcharge } from '../components/FromUpdateSurcharge';
import { TableSurcharge } from '../components/TableSurcharge';
import reducer from '../store/reducers';
import saga from '../store/sagas';
import {
  makeSelectListCities,
  makeSelectLoading,
  makeSelectListSurcharge,
  makeSelectTotalSurcharge,
  makeSelectIsFresh,
} from '../store/selectors';
import useViewSurcharge from './hook';
import { ISurchargeView } from '../interface';

import { useStyles } from './styles';

const SurchargeView = (props: ISurchargeView) => {
  useInjectReducer({ key: 'surcharge', reducer });
  useInjectSaga({ key: 'surcharge', saga });
  const classes = useStyles();
  const { listCities, listSurcharge, totalSurcharge, isLoading, isFresh } =
    props;

  const {
    isOpenCreate,
    handleToggleFormCreate,
    handleCreateSurcharge,
    isModalUpdate,
    isModalDelete,
    handleCloseModalDelete,
    handleDeleteSurcharge,
    handleCloseModalUpdate,
    oldDataSurcharge,
    handleUpdateSurcharge,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleOpenModalDeleteSurcharge,
    handleOpenModalUpdate,
  } = useViewSurcharge(isFresh);

  return (
    <div className={classes.deviceContainer}>
      <div className={classes.headContain}>
        <ButtonComponent
          handleAction={handleToggleFormCreate}
          status="approve"
          icon={<AddIcon fontSize="small" />}
        >
          Create Surcharge
        </ButtonComponent>
      </div>

      <Grid container>
        <Grid xs={12}>
          <TableSurcharge
            totalSurcharge={totalSurcharge}
            isLoading={isLoading}
            listSurcharge={listSurcharge}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleOpenModalDeleteSurcharge={handleOpenModalDeleteSurcharge}
            handleOpenModalUpdate={handleOpenModalUpdate}
          />
        </Grid>
      </Grid>

      <DialogComponent
        title="New surcharge"
        open={isOpenCreate}
        onClose={handleToggleFormCreate}
      >
        <FormCreateSurcharge
          listCities={listCities}
          handleCreateSurcharge={handleCreateSurcharge}
          onClose={handleToggleFormCreate}
        />
      </DialogComponent>
      <DialogComponent
        title="Update Surcharge"
        open={isModalUpdate}
        onClose={handleCloseModalUpdate}
      >
        <FormUpdateSurcharge
          handleUpdateSurcharge={handleUpdateSurcharge}
          listCities={listCities}
          onClose={handleCloseModalUpdate}
          oldDataSurcharge={oldDataSurcharge}
        />
      </DialogComponent>
      <DialogMui
        open={isModalDelete}
        title="Delete this?"
        onClose={handleCloseModalDelete}
        onConfirm={handleDeleteSurcharge}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  listCities: makeSelectListCities(),
  isLoading: makeSelectLoading(),
  listSurcharge: makeSelectListSurcharge(),
  totalSurcharge: makeSelectTotalSurcharge(),
  isFresh: makeSelectIsFresh(),
});

export default connect(mapStateToProps)(SurchargeView);
