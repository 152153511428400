import { put, takeLatest } from 'redux-saga/effects';

import * as types from '../constants';
import * as actionTypes from '../actions';
import { getApiListPartner } from '../services';

function* getListPartner(action) {
  try {
    const { page, rowsPerPage, filter } = action.payload.data;
    const listPartner = yield getApiListPartner({ page, rowsPerPage, filter });
    const data = listPartner.data.getPartners.data;

    yield put(
      actionTypes.getListUserSuccess(data, listPartner.data.getPartners)
    );
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.getListPartnersFail(message.error.graphQLErrors[0].code)
    );
  }
}

export default function* partnersSaga() {
  yield takeLatest(types.GET_LIST_PARTNERS_REQUEST, getListPartner);
}
