import { IInfoPage } from 'src/containers/ListPartner/interface';
import { IDataPartner, IParams } from '../../interface';
import * as types from '../constants';

export const getNewPartnersRequestAction = function (data: IParams) {
  return {
    type: types.GET_NEW_PARTNERS_REQUEST,
    payload: { data },
  };
};

export const getNewPartnersSuccess = (listData: Array<IDataPartner>, info: IInfoPage) => {
  return {
    type: types.GET_NEW_PARTNERS_SUCCESS,
    payload: { listData },
    info,
  };
};

export const getNewPartnersFail = (error: any) => {
  return {
    type: types.GET_NEW_PARTNERS_FAIL,
    payload: error
  };
};
