export const LOGIN_REQUEST = 'Instalent/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'Instalent/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'Instalent/LOGIN_FAIL';

export const LOGOUT_REQUEST = 'Instalent/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'Instalent/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'Instalent/LOGOUT_FAIL';

export const REFRESH_TOKEN_REQUEST = 'Instalent/REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'Instalent/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'Instalent/REFRESH_TOKEN_FAIL';

export const GET_PROFILE_ADMIN_REQUEST = 'Instalent/GET_PROFILE_ADMIN_REQUEST';
export const GET_PROFILE_ADMIN_SUCCESS = 'Instalent/GET_PROFILE_ADMIN_SUCCESS';
export const GET_PROFILE_ADMIN_FAIL = 'Instalent/GET_PROFILE_ADMIN_FAIL';
