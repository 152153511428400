import { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as ListServiceRequestAction from 'src/containers/ListServiceRequest/store/actions';

function ServiceRequestHook() {
  const dispatch = useDispatch();
  const [detail, setDetail] = useState<any>({});

  const handleCloseModalCertificate = () =>
    dispatch(ListServiceRequestAction.closeModal());

  const handleGetDetail = (data) => () => {
    setDetail(data);
    dispatch(ListServiceRequestAction.openModal());
  };

  const handleOpenModalUpload = (item) => () => {
    dispatch(ListServiceRequestAction.openModalAdd());
    setDetail(item);
  };

  const handleOpenSchedule = (data) => () => {
    setDetail(data);
    dispatch(ListServiceRequestAction.openModalSchedule());
  };

  const handleOpenScheduleDetail = (id) => () => {
    dispatch(ListServiceRequestAction.getScheduleInterviewAction(id));
    dispatch(ListServiceRequestAction.showModalScheduleDetail());
  };

  const handleCloseModalSchedule = () =>
    dispatch(ListServiceRequestAction.closeModalSchedule());

  const handleHideModalScheduleDetail = () =>
    dispatch(ListServiceRequestAction.hideModalScheduleDetail());

  return {
    handleCloseModalCertificate,
    handleGetDetail,
    handleOpenModalUpload,
    handleOpenSchedule,
    handleOpenScheduleDetail,
    handleCloseModalSchedule,
    handleHideModalScheduleDetail,
    detail,
  };
}

export default ServiceRequestHook;
