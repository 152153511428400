import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    container: {
      maxWidth: theme.spacing(70),
      padding: theme.spacing(5),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontWeight: 600,
      fontSize: 16,
      marginTop: theme.spacing(6),
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(1, 4),
      },
    },
    closeIcon: {
      cursor: 'pointer',
      fontSize: 32,
      color: '#343535',
      position: 'absolute',
      top: 0,
      right: theme.spacing(2),
    },
    action: {
      display: 'flex',
      '& >button': {
        width: 150,
        margin: theme.spacing(0, 1),
      },
    },
    disagree: {},
    agree: {
      color: '#ffff',
      backgroundColor: '#FC4A46',
      '&:hover': {
        opacity: 0.8,
        backgroundColor: '#FC4A46',
      },
    },
  })
);

export { useStyles };
