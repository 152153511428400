import { Button, TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ISelector } from 'src/reducers';
import SelectOption from 'src/components/Selection';
import ButtonComponent from 'src/components/ButtonComponent';
import TextInput from 'src/components/InputFiled';
import { hideModalAdd } from 'src/containers/App/store/actions';
import { createServiceAreaRequest } from '../../store/actions';
import { IListCitiesID } from '../../interface';
import {validateCreate} from '../../constants'

import { useStyles } from './styles';

const CreateServiceAreaModal = () => {
  const dispatch = useDispatch();
  const clx = useStyles();

  const service_id = useSelector(
    (items: ISelector) => items.services.listServices
  );

  const ListCitiesID = useSelector(
    (items: ISelector) => items.serviceAreas.listCitiesID
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      city_id: '',
      service_id: '',
      price: '',
    },
    validationSchema: validateCreate,
    onSubmit: (values) => {
      dispatch(createServiceAreaRequest(values));
    },
  });

  const handleCloseModalCreate = () => {
    dispatch(hideModalAdd());
  };

  return (
    <form onSubmit={formik.handleSubmit} className={clx.formSubmit}>
      <Autocomplete
        id="city_id"
        size="small"
        options={ListCitiesID}
        getOptionLabel={(option: IListCitiesID) => option?.name}
        renderOption={(option: IListCitiesID) => <p>{option?.name}</p>}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Chose city"
            variant="outlined"
            error={formik.touched.city_id && Boolean(formik.errors.city_id)}
            helperText={formik.touched.city_id && formik.errors.city_id}
          />
        )}
        autoComplete
        onChange={(_, newValue: any) => {
          formik.setFieldValue('city_id', newValue?.id);
        }}
      />
      <SelectOption
        name="service_id"
        label="Services"
        options={service_id}
        onChangeOption={formik.handleChange}
        values={formik.values.service_id}
        error={formik.touched.service_id && Boolean(formik.errors.service_id)}
        helperText={formik.touched.service_id && formik.errors.service_id}
        style={{ width: '100%' }}
      />
      <TextInput
        name="price"
        label="Price"
        type="number"
        variant="outlined"
        onChange={formik.handleChange}
        value={formik.values.price}
        error={formik.touched.price && Boolean(formik.errors.price)}
        helperText={formik.touched.price && formik.errors.price}
      />

      <div className={clx.buttonForm}>
        <Button onClick={handleCloseModalCreate}>Cancel</Button>
        <ButtonComponent status="approve">Submit</ButtonComponent>
      </div>
    </form>
  );
};

export default CreateServiceAreaModal;
