import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';

import { useInjectReducer } from 'src/utils/injectReducer';
import { useInjectSaga } from 'src/utils/injectSaga';
import reducer from './store/reducers';
import UsersView from './views';
import saga from './store/sagas';
import { IGetUsers, ISearch } from './interface';
import * as UsersAction from './store/actions/index';
import useQueryLocation from 'src/hooks/useQueryLocation';
import { ISelector } from 'src/reducers';

function ListUserContainer() {
  useInjectReducer({ key: 'users', reducer });
  useInjectSaga({ key: 'users', saga });

  const isBlock = useSelector((s: ISelector) => s.users?.isBlock);

  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  let query = useQueryLocation();
  let params = useRef<IGetUsers>();

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [search, setSearch] = useState<ISearch>({
    role_id: null,
    email: '',
    name: '',
    phone: '',
  });

  const handleSearch = ({ target: { name, value } }) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const onResetFilter = () => {
    setSearch({
      role_id: '',
      email: '',
      name: '',
      phone: '',
    });
    params.current = {
      page: 1,
      rowsPerPage: 10,
      filter: {
        name: '',
        phone: '',
        email: '',
        role_id: null,
      },
    };
    history.push(`/users`);
    dispatch(UsersAction.getListUserRequestAction(params.current));
  };

  const handleSubmitFilter = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    params.current = {
      rowsPerPage: rowsPerPage,
      page: page,
      filter: {
        name: search.name,
        phone: search.phone,
        email: search.email,
        role_id: search.role_id,
      },
    };

    dispatch(UsersAction.getListUserRequestAction(params.current));

    const paramsString = queryString.stringify(params.current.filter);
    history.push(`/users?page=${page}&per_page=${rowsPerPage}&${paramsString}`);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+e.target.value);
    setPage(1);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };

  useEffect(() => {
    if (location.search) {
      const name = query.get('name');
      const phone = query.get('phone');
      const email = query.get('email');
      const role_id = query.get('role_id');

      params.current = {
        page: page,
        rowsPerPage: rowsPerPage,
        filter: {
          name: name || search.name,
          phone: phone || search.phone,
          email: email || search.email,
          role_id: role_id || search.role_id,
        },
      };
    }

    params.current = {
      page: page,
      rowsPerPage: rowsPerPage,
      filter: {
        name: search.name,
        phone: search.phone,
        email: search.email,
        role_id: search.role_id,
      },
    };
    dispatch(UsersAction.getListUserRequestAction(params.current));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, rowsPerPage, page, isBlock]);

  return (
    <UsersView
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      search={search}
      onResetFilter={onResetFilter}
      handleSubmitFilter={handleSubmitFilter}
      handleSearch={handleSearch}
    />
  );
}

export default ListUserContainer;
