import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ToggleComponent from 'src/components/ToggleComponent';

import useStyles from './styles';

const PartnerJoinings = ({ partner_join_booking }) => {
  const classes = useStyles();

  return (
    <ToggleComponent title="Partner Joining">
      <Grid spacing={2} container >
        {partner_join_booking.map((item) => (
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Link to={`/partner/${item.id}`} className={classes.content}>
              <img
                className={classes.avatar}
                src={item.avatar}
                alt={item.name}
              />
              <div className={classes.contentDescription}>
                <div>
                  <strong>Name</strong>: {item.name}{' '}
                </div>
                <div>
                  <strong>Email</strong>: {item.email}{' '}
                </div>
                <div>
                  <strong>Phone</strong>: {item.phone}{' '}
                </div>
              </div>
            </Link>
          </Grid>
        ))}
      </Grid>
    </ToggleComponent>
  );
};

export default PartnerJoinings;
