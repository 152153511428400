import { IListServiceProvider } from 'src/containers/ListServiceRequest/interface';
import { STATUS_SERVICE_REQUEST } from '../../contants';
import * as types from '../constants';

export interface IReducerServiceRequest {
  isRequesting: boolean;
  serviceRequests: Array<IListServiceProvider>;
  isUpdate: boolean;
}

export const initialState: IReducerServiceRequest= {
  isRequesting: false,
  serviceRequests: [],
  isUpdate: false,
};

const ServiceRequestPartnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SERVICE_PARTNER_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case types.SERVICE_PARTNER_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        serviceRequests: action.payload,
      };
    case types.SERVICE_PARTNER_FAIL:
      return {
        isRequesting: false,
      };
    case types.UPDATE_SERVICE_PARTNER_REQUEST:
      return {
        ...state,
        isUpdate: true,
      };
    case types.UPDATE_SERVICE_PARTNER_SUCCESS:
      return {
        ...state,
        serviceRequests: state.serviceRequests.map(
          (item: IListServiceProvider) =>
            item.id === action.payload.id
              ? { ...item, status: STATUS_SERVICE_REQUEST[action.payload.status] }
              : item
        ),
        isUpdate: false,
      };
    case types.UPDATE_SERVICE_PARTNER_FAIL:
      return {
        ...state,
        isUpdate: false,
      };
    case types.ADD_SCHEDULE_SERVICE_PARTNER_REQUEST:
      return {
        ...state,
      };
    case types.ADD_SCHEDULE_SERVICE_PARTNER_SUCCESS:
      return {
        ...state,
        serviceRequests: state.serviceRequests.map(
          (item: IListServiceProvider) =>
            item.id === action.payload.id ? { ...item, status: 3 } : item
        ),
      };
    case types.ADD_SCHEDULE_SERVICE_PARTNER_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default ServiceRequestPartnerReducer;
