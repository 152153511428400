import { AuthenticatedRoute } from '../components/routes';
import BookingsContainer  from '../containers/Bookings'

const BookingsRoutes = [
  {
    path: '/bookings',
    title: 'Bookings',
    component: BookingsContainer,
    route: AuthenticatedRoute,
    roles: [],
    permission: [],
    exact: true,
  },
];


export default BookingsRoutes;