import { IInfoPage } from 'src/containers/ListPartner/interface';
import {
  IGetUsers,
  IListServiceProvider,
  IParamHandle,
  IParamsProof,
  IScheduleDetail,
} from '../../interface';
import * as types from '../constants';

export const getServiceProviderRequestAction = function (data: IGetUsers) {
  return {
    type: types.GET_LIST_SERVICE_PROVIDER_REQUEST,
    payload: { data },
  };
};

export const getServiceProviderSuccess = (
  info: IInfoPage,
  listData: Array<IListServiceProvider>
) => {
  return {
    type: types.GET_LIST_SERVICE_PROVIDER_SUCCESS,
    payload: { listData },
    info,
  };
};

export const getServiceProviderFail = (error) => {
  return {
    type: types.GET_LIST_SERVICE_PROVIDER_FAIL,
    payload: error,
  };
};

export const updateProviderRequest = function (data: IParamHandle) {
  return {
    type: types.UPDATE_PROVIDER_REQUEST,
    payload: { data },
  };
};

export const updateProviderSuccess = (data: IParamHandle) => {
  return {
    type: types.UPDATE_PROVIDER_SUCCESS,
    payload: data,
  };
};

export const updateProviderFail = (error) => {
  return {
    type: types.UPDATE_PROVIDER_FAIL,
    payload: error,
  };
};

export const closeModal = () => {
  return {
    type: types.CLOSE_MODAL,
  };
};

export const openModal = () => {
  return {
    type: types.OPEN_MODAL,
  };
};

export const closeModalAdd = () => {
  return {
    type: types.CLOSE_MODAL_ADD_PROOF,
  };
};

export const openModalAdd = () => {
  return {
    type: types.OPEN_MODAL_ADD_PROOF,
  };
};

export const addProofRequest = function (data: IParamsProof) {
  return {
    type: types.ADD_PROOF_REQUEST,
    payload: { data },
  };
};

export const addProofSuccess = () => {
  return {
    type: types.ADD_PROOF_SUCCESS,
  };
};

export const addProofFail = (error) => {
  return {
    type: types.ADD_PROOF_FAIL,
    payload: error,
  };
};

export const addScheduleInterviewRequest = function (data: IScheduleDetail) {
  return {
    type: types.ADD_SCHEDULE_REQUEST,
    payload: { data },
  };
};

export const addScheduleInterviewSuccess = (data: IScheduleDetail) => {
  return {
    type: types.ADD_SCHEDULE_SUCCESS,
    payload: data,
  };
};

export const addScheduleInterviewFail = (error) => {
  return {
    type: types.ADD_SCHEDULE_FAIL,
    payload: error,
  };
};

export const closeModalSchedule = () => {
  return {
    type: types.CLOSE_MODAL_SCHEDULE,
  };
};

export const openModalSchedule = () => {
  return {
    type: types.OPEN_MODAL_SCHEDULE,
  };
};

export const showModalScheduleDetail = () => {
  return {
    type: types.SHOW_MODAL_SCHEDULE_DETAIL,
  };
};

export const hideModalScheduleDetail = () => {
  return {
    type: types.HIDE_MODAL_SCHEDULE_DETAIL,
  };
};

export const getScheduleInterviewAction = function (id: string) {
  return {
    type: types.GET_SCHEDULE_INTERVIEW_REQUEST,
    payload: id,
  };
};

export const getScheduleInterviewSuccess = (data: IScheduleDetail) => {
  return {
    type: types.GET_SCHEDULE_INTERVIEW_SUCCESS,
    payload: data,
  };
};

export const getScheduleInterviewFail = (error) => {
  return {
    type: types.GET_LIST_SERVICE_PROVIDER_FAIL,
    payload: error,
  };
};

export const getSocialNetworkAction = function (id: string) {
  return {
    type: types.GET_SOCIAL_NETWORK_REQUEST,
    payload: id,
  };
};

export const getSocialNetworkSuccess = (data) => {
  return {
    type: types.GET_SOCIAL_NETWORK_SUCCESS,
    payload: data,
  };
};

export const getSocialNetworkFail = (error) => {
  return {
    type: types.GET_SOCIAL_NETWORK_FAIL,
    payload: error,
  };
};

export const updateStatusReviewSocialAction = function (data) {
  return {
    type: types.UPDATE_STATUS_REVIEW_SOCIAL_NETWORK_REQUEST,
    payload: data,
  };
};

export const updateStatusReviewSocialSuccess = (data) => {
  return {
    type: types.UPDATE_STATUS_REVIEW_SOCIAL_NETWORK_SUCCESS,
    payload: data,
  };
};

export const updateStatusReviewSocialFail = (error) => {
  return {
    type: types.UPDATE_STATUS_REVIEW_SOCIAL_NETWORK_FAIL,
    payload: error,
  };
};
