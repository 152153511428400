import SearchIcon from '@material-ui/icons/Search';
import { Grid, InputAdornment } from '@material-ui/core';

import TextInput from 'src/components/InputFiled';
import { IFilter } from '../../interface';
import { dataField } from '../../contants';

import { useStyles } from './styles';

function FilterUser({
  handleSubmitFilter,
  handleSearch,
  onResetFilter,
  search,
}: IFilter) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <form className={classes.search} onSubmit={handleSubmitFilter}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classes.gridLeft}
            >
              {dataField(search).map((item, index) => {
                return (
                  <div className={classes.box} key={index}>
                    <TextInput
                      value={item.value || ''}
                      name={item.name}
                      label={item.label}
                      type="search"
                      variant="outlined"
                      onChange={handleSearch}
                      inputprops={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                );
              })}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className={classes.filter}>
                <div className={classes.btn}>
                  <button
                    className={`${classes.btnApply} ${classes.btnColor}`}
                    type="submit"
                  >
                    Apply Filter
                  </button>
                </div>
                <div className={classes.btn}>
                  <button
                    className={classes.btnReset}
                    type="button"
                    onClick={onResetFilter}
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default FilterUser;
