import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ISelector } from 'src/reducers';
import { useInjectReducer } from 'src/utils/injectReducer';
import { useInjectSaga } from 'src/utils/injectSaga';
import saga from '../store/sagas';
import reducer from '../store/reducer';
import { getServiceAreasRequest, getCitiesIdRequest } from '../store/actions';
import TableServiceAreas from '../components/TableServiceAreas';
import sagaServices from '../../Services/store/sagas';
import reducerServices from '../../Services/store/reducers';
import { getServicesRequest } from '../../Services/store/actions';

import { useStyles } from './styles';

function ServiceAreasViews() {
  const clx = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  useInjectReducer({ key: 'serviceAreas', reducer });
  useInjectSaga({ key: 'serviceAreas', saga });

  useInjectReducer({ key: 'services', reducer: reducerServices });
  useInjectSaga({ key: 'services', saga: sagaServices });

  const isRefresh = useSelector(
    (area: ISelector) => area.serviceAreas?.isRefresh
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(+(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    const data = {
      page: page,
      rowsPerPage: rowsPerPage,
    };
    dispatch(getServiceAreasRequest(data));
  }, [page, dispatch, rowsPerPage, isRefresh]);

  useEffect(() => {
    const params = {
      page: 1,
      rowsPerPage: 100,
    };
    dispatch(getServicesRequest(params));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCitiesIdRequest());
  }, []);

  return (
    <div className={clx.deviceContainer}>
      <TableServiceAreas
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default ServiceAreasViews;
