import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

import Button from 'src/components/Button';
import InfoDetail from '../components/InfoBookingDetail';
import InfoRecipient from '../components/InfoBookingRecipient';
import InfoRequest from '../components/InfoBookingRequest';
import { profileRecipient, profileRequest } from '../contants';
import { IBookingContainer } from '../interface';
import BookingConversation from '../components/BookingConversation';
import BookingImages from '../components/BookingImages';
import PartnerJoinings from '../components/PartnerJoin';
import {
  makeSelectBookingDetail,
  makeSelectIsResend,
  makeSelectIsResendNotAvailableWork,
  makeSelectPageNumberResend,
} from '../store/selectors';

import { useStyles } from './styles';

function BookingView({
  search,
  handleSearch,
  handleResendNotification,
  handleResendNotificationAvailableWork,
  handleSendNotificationPage,
}: IBookingContainer) {
  const classes = useStyles();

  const bookingDetail = useSelector(makeSelectBookingDetail());
  const isResend = useSelector(makeSelectIsResend() || false);
  const isResendNotAvailableWork = useSelector(
    makeSelectIsResendNotAvailableWork() || false
  );
  const pageNumberResend = useSelector(makeSelectPageNumberResend());
  return (
    <Grid container spacing={3} className={classes.grid}>
      {bookingDetail?.status === 1 && (
        <Grid item xs={12}>
          <div className={classes.buttonResend}>
            <Button loading={isResend} onClick={handleResendNotification}>
              Resend Notifications
            </Button>
            <Button
              loading={isResendNotAvailableWork}
              onClick={handleResendNotificationAvailableWork}
            >
              Resend Notifications Available Work Status
            </Button>
          </div>
        </Grid>
      )}
      {bookingDetail?.partner_join_booking?.length > 0 && (
        <Grid item xs={12}>
          <PartnerJoinings
            partner_join_booking={bookingDetail?.partner_join_booking}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <div className={classes.btnResend}>
          {!!pageNumberResend &&
            pageNumberResend > 0 &&
            new Array(pageNumberResend).fill(0).map((item, index) => {
              return (
                <Button
                  onClick={handleSendNotificationPage(index + 1)}
                  loading={isResend}
                >
                  {(index + 2 - 1) * 100 + 1 + '-' + (index + 2) * 100}
                </Button>
              );
            })}
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <InfoRecipient
          search={search}
          handleSearch={handleSearch}
          infoRecipient={profileRecipient(bookingDetail?.recipientData)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <InfoRequest infoRequest={profileRequest(bookingDetail?.userRequest)} />
      </Grid>
      <Grid item xs={12}>
        <InfoDetail />
      </Grid>
      <Grid item xs={6}>
        <BookingConversation />
      </Grid>
      <Grid item xs={6}>
        <BookingImages />
      </Grid>
    </Grid>
  );
}
export default BookingView;
