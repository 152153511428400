import {
  IParamAddStaff,
  IParamStaff,
  IParamUpdateStaff,
} from '../../interface';
import * as types from '../constants';

export const getStaffRequest = function (params: IParamStaff) {
  return {
    type: types.GET_STAFF_REQUEST,
    payload: params,
  };
};

export const getStaffSuccess = (data) => {
  return {
    type: types.GET_STAFF_SUCCESS,
    payload: { data },
  };
};

export const getStaffFail = (error) => {
  return {
    type: types.GET_STAFF_FAIL,
    payload: error,
  };
};

export const deleteStaffRequest = function (id: string) {
  return {
    type: types.DELETE_STAFF_REQUEST,
    payload: id,
  };
};

export const deleteStaffSuccess = () => {
  return {
    type: types.DELETE_STAFF_SUCCESS,
  };
};

export const deleteStaffFail = (error) => {
  return {
    type: types.DELETE_STAFF_FAIL,
    payload: error,
  };
};

export const updateStaffRequest = function (params: IParamUpdateStaff) {
  return {
    type: types.UPDATE_STAFF_REQUEST,
    payload: params,
  };
};

export const updateStaffSuccess = () => {
  return {
    type: types.UPDATE_STAFF_SUCCESS,
  };
};

export const updateStaffFail = (error) => {
  return {
    type: types.UPDATE_STAFF_FAIL,
    payload: error,
  };
};

export const addStaffRequest = function (params: IParamAddStaff) {
  return {
    type: types.ADD_STAFF_REQUEST,
    payload: params,
  };
};

export const addStaffSuccess = () => {
  return {
    type: types.ADD_STAFF_SUCCESS,
  };
};

export const addStaffFail = (error) => {
  return {
    type: types.ADD_STAFF_FAIL,
    payload: error,
  };
};
