import { TextField, Theme } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import styledTheme from '../../utils/theme';

// Styles
const TextInputStyle = withStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& input:-internal-autofill-selected': {},
    '& .MuiInputBase-root': {
      height: styledTheme.height.medium,
      color: theme.palette.text.primary,
      borderRadius: 'inherit',
      fontSize: 14,
      '& .MuiInputBase-inputTypeSearch': {},
    },
    '& .MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputTypeSearch':
      {
        background: '#ffff',
      },
    '& .Mui-disabled': {
      color: '#bcbcbc',
      borderColor: '#bcbcbc',
      '&:hover fieldset': {
        borderColor: '#bcbcbc',
      },
    },
    '& .MuiOutlinedInput-root': {
      height: 35,
      // background: '#ffff',
      '&:hover fieldset': {
        borderColor: '#1a1a1a',
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${theme.palette.text.secondary}`,
      },
      '&.Mui-error fieldset': {
        border: `1px solid ${theme.palette.error.main}`,
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 17px) scale(1)',
      color: theme.palette.text.secondary,
      top: -7,
      fontSize: styledTheme.fontSize.xxlarge,
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, 0) scale(0.7)',
      },
    },
    '& > .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '30px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 14px',
    },
    '& > .MuiInputLabel-outlined.Mui-focused': {},
    '& > .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(10px, -6px) scale(0.75)',
      top: '-4px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    '& .MuiIconButton-root:hover' : {
      background: 'none',
    }
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  heightAuto: {
    height: 'auto',
  },
  light: {
    backgroundColor: '#ffff',
  },
  default: {
    input: {
      '&:-webkit-autofill-active': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
      },
    },
  },
}));


export { TextInputStyle, useStyles };
