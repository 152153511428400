import { TablePagination } from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ButtonComponent from 'src/components/ButtonComponent';
import DialogComponent from 'src/components/DialogComponent';
import DialogMui from 'src/components/DialogMui';
import MuiTable from 'src/components/MuiTable';
import { getServiceRequest } from 'src/containers/Booking/store/actions';
import { ISelector } from 'src/reducers';
import { useInjectReducer } from 'src/utils/injectReducer';
import { useInjectSaga } from 'src/utils/injectSaga';
import reducerBooking from '../../Booking/store/reducers';
import sagaBooking from '../../Booking/store/sagas';
import CreateCoupon from '../components/CreateCoupon';
import UpdateCoupon from '../components/UpdateCoupon';
import { COLUM_NAME } from '../constants';
import useDeleteCoupon from '../hooks/useDeleteCoupon';
import { deleteCouponRequest, getListCouponRequest } from '../store/actions';
import reducer from '../store/reducers';
import saga from '../store/sagas';

import { useStyles } from './styles';

function CouponsView() {
  const classes = useStyles();
  useInjectReducer({ key: 'couponRequest', reducer });
  useInjectSaga({ key: 'couponRequest', saga });

  useInjectReducer({ key: 'booking', reducer: reducerBooking });
  useInjectSaga({ key: 'booking', saga: sagaBooking });

  const dispatch = useDispatch();

  const listCoupon = useSelector((s: any) => s.couponRequest.listCoupon);
  const total = useSelector((s: any) => s.couponRequest.total);
  const isRequesting = useSelector((s: any) => s.couponRequest.isRequesting);
  const isAddingCoupon = useSelector(
    (s: any) => s.couponRequest.isAddingCoupon
  );
  const services = useSelector((s: ISelector) => s.booking?.services || []);

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [isOpenCreateCoupon, setIsOpenCreateCoupon] = useState(false);

  const {
    isDelete,
    handleOpenModalDelete,
    handleCloseModalDelete,
    isEdit,
    handleOpenModalEdit,
    handleCloseModalEdit,
  } = useDeleteCoupon();

  const handleDeleteCoupon = () => {
    dispatch(
      deleteCouponRequest({
        id: isDelete.id,
        closeModal: handleCloseModalDelete,
      })
    );
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+e.target.value);
    setPage(1);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };

  const handleCloseCreateCoupon = () => {
    setIsOpenCreateCoupon(!isOpenCreateCoupon);
  };

  const skeletonRowData = <Skeleton variant="rect" animation="wave" />;

  const rowError = {
    id: <div>{skeletonRowData}</div>,
    coupon_code: <div>{skeletonRowData}</div>,
    restrictable_type: <div>{skeletonRowData}</div>,
    service: <div>{skeletonRowData}</div>,
    discount_value: <div>{skeletonRowData}</div>,
    discount_unit: <div>{skeletonRowData}</div>,
    valid_before: <div>{skeletonRowData}</div>,
    minimum_order_value: <div>{skeletonRowData}</div>,
    maximum_discount_amount: <div>{skeletonRowData}</div>,
    create_at: <div>{skeletonRowData}</div>,
    updated_at: <div>{skeletonRowData}</div>,
    action: <div>{skeletonRowData}</div>,
  };

  const listServiceCoupon = listCoupon.map((coupon) => coupon.restrictable_ids);

  const listServiceName = listServiceCoupon.map((coupon) => {
    return services.filter((item) => coupon.includes(item?.id));
  });

  const couponRow = (item, index) => {
    return {
      id: (
        <div>
          <p> {item.id}</p>
        </div>
      ),
      coupon_code: (
        <div>
          <p> {item.coupon_code}</p>
        </div>
      ),
      restrictable_type: (
        <div>
          <p>{item?.restrictable_type}</p>
        </div>
      ),
      service: (
        <div>
          <p>
            {item.restrictable_type === 'service' &&
              listServiceName[index]?.map((item: any) => (
                <option key={item.name} value={item.name}>
                  - {item.name}
                </option>
              ))}
          </p>
        </div>
      ),
      discount_value: (
        <div>
          <p>{item?.discount_value}</p>
        </div>
      ),
      discount_unit: (
        <div>
          <p>{item?.discount_unit === 1 ? 'VND' : '%'}</p>
        </div>
      ),
      valid_before: (
        <div>
          <p>{moment(item?.valid_before).format('LLLL')}</p>
        </div>
      ),
      minimum_order_value: (
        <div>
          <p>{item?.minimum_order_value}</p>
        </div>
      ),
      maximum_discount_amount: (
        <div>
          <p>{item?.maximum_discount_amount}</p>
        </div>
      ),
      create_at: (
        <div>
          <p>{moment(item.created_at).format('LLLL')}</p>
        </div>
      ),
      updated_at: (
        <div>
          <p>{moment(item.updated_at).format('LLLL')}</p>
        </div>
      ),
      action: (
        <div>
          <div className={classes.bntAction}>
            <ButtonComponent
              status="approve"
              handleAction={handleOpenModalEdit(item)}
            >
              <EditIcon fontSize="small" />
            </ButtonComponent>
            <ButtonComponent
              status="cancel"
              handleAction={handleOpenModalDelete(item.id)}
            >
              <DeleteIcon fontSize="small" />
            </ButtonComponent>
          </div>
        </div>
      ),
    };
  };

  const renderCouponRow = isRequesting
    ? new Array(11).fill(0).map((i) => rowError)
    : listCoupon?.map(couponRow);

  useEffect(() => {
    dispatch(getListCouponRequest({ page, rowsPerPage }));
  }, [dispatch, page, rowsPerPage, isAddingCoupon]);

  useEffect(() => {
    dispatch(getServiceRequest());
  }, [dispatch]);

  const pagination = (
    <div>
      <TablePagination
        component="div"
        count={total}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.headContain}>
        <ButtonComponent
          status="approve"
          handleAction={() => setIsOpenCreateCoupon(true)}
          icon={<AddIcon fontSize="small" />}
        >
          Create a Coupon
        </ButtonComponent>
      </div>
      <DialogComponent
        title="Create a Coupon"
        open={isOpenCreateCoupon}
        onClose={handleCloseCreateCoupon}
      >
        <CreateCoupon handleClose={handleCloseCreateCoupon} />
      </DialogComponent>

      <DialogComponent
        title="Update a Coupon"
        open={isEdit.open}
        onClose={handleCloseModalEdit}
      >
        <UpdateCoupon onClose={handleCloseModalEdit} detail={isEdit.detail} />
      </DialogComponent>

      <DialogMui
        open={isDelete.open}
        onClose={handleCloseModalDelete}
        onConfirm={handleDeleteCoupon}
      />
      <div className={classes.boxTable}>
        <MuiTable
          headers={COLUM_NAME}
          rows={renderCouponRow}
          pagination={pagination}
        />
      </div>
    </div>
  );
}

export default CouponsView;
