import * as types from '../constants';
export const initialState = {
  isRequesting: false,
  listBookings: [],
  info: {},
  calenderBookings: [],
};

const BookingsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_BOOKINGS_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case types.GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        listBookings: action.payload.data.data,
        info: action.payload.data,
      };
    case types.GET_BOOKINGS_FAIL:
      return {
        isRequesting: false,
        listBookings: [],
      };

    case types.GET_CALENDER_BOOKING_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case types.GET_CALENDER_BOOKING_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        calenderBookings: action.payload.data,
      };
    case types.GET_CALENDER_BOOKING_FAIL:
      return {
        isRequesting: false,
        calenderBookings: [],
      };
    default:
      return state;
  }
};

export default BookingsReducer;
