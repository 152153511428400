// Dependencies
import styled, { css } from "styled-components";
import { ArrowDropDown } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import styledTheme from "../../utils/theme";

// Button component
const Button = ({
  label,
  color,
  textColor,
  borderColor,
  shadowColor,
  width,
  height,
  fontSize,
  onClick,
  expand,
  noShadow,
  dropdown,
  small,
  large,
  disable,
  children,
  active,
  mr,
  loading,
  dropdownClick,
  ...props
}: any) => {
  const handleClick = () => {
    if (disable) return;
    if (!disable && !!onClick) onClick();
  };

  const onDropdownClick = () => {
    dropdownClick && dropdownClick();
  };
  return (
    <Container expand={expand} disable={disable} {...props}>
      <ButtonWrapper
        {...{
          color,
          textColor,
          shadowColor,
          fontSize,
          noShadow,
          expand,
          large,
          small,
          width,
          height,
          borderColor,
          mr,
        }}
        onClick={handleClick}
      >
        {loading && <LoadingStyle size={16} />}

        {!!label && label}
        {children}
      </ButtonWrapper>
      {dropdown && (
        <Dropdown>
          <ArrowDropDown onClick={onDropdownClick} />
        </Dropdown>
      )}
    </Container>
  );
};

// Styles
const Container = styled.div`
  display: flex;
  font-size: ${styledTheme.fontSize.medium};
  font-weight: 500;
  line-height: 1.15;
  cursor: pointer;
  
  ${(props: any) =>
    props.disable &&
    css`
      cursor: not-allowed;
      pointer-events: none;
    `}

  ${(props: any) =>
    !!props.expand &&
    (props.expand === "x"
      ? css`
          width: 100%;
        `
      : css`
          height: 100%;
        `)}
`;

const LoadingStyle = styled(CircularProgress)`
  color: #fff;
  margin-right: 6px;
`;


const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${styledTheme.colors.white};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  border: 0;
  outline: none;
  background-color: #FC4A46;

  svg {
    width: 14px;
    height: 14px;
  };

  &:hover {
    opacity: 0.9;
  }
  cursor: pointer;
  

  ${(props: any) =>
    !!props.width
      ? css`
          padding: ${styledTheme.spacing * 0.3}px 0;
          min-width: ${props.width}px;
        `
      : css`
          padding: ${styledTheme.spacing * 0.3}px ${styledTheme.spacing * 0.5}px;
        `}

  ${(props: any) =>
    props.noShadow &&
    css`
      box-shadow: none;
    `}

  ${(props: any) =>
    !!props.shadowColor &&
    css`
      box-shadow: 0 1px 1px 0 ${props.shadowColor};
    `}

  ${(props: any) =>
    props.large &&
    css`
      padding: ${styledTheme.spacing * 0.3}px ${styledTheme.spacing * 2}px;
    `}

  ${(props: any) =>
    props.small &&
    css`
      padding: ${styledTheme.spacing * 0.2}px ${styledTheme.spacing * 0.6}px;
    `}

  ${(props: any) =>
    !!props.textColor &&
    css`
      color: ${props.textColor};
    `}

  ${(props: any) =>
    !!props.fontSize &&
    css`
      font-size: ${props.fontSize}px;
    `}

  ${(props: any) =>
    !!props.expand &&
    (props.expand === "x"
      ? css`
          width: 100%;
        `
      : css`
          height: 100%;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        `)}

  ${(props: any) =>
    !!props.borderColor &&
    css`
      border: 0.5px solid ${props.borderColor};
    `}

  ${(props: any) =>
    !!props.height &&
    css`
      height: ${props.height}px;
    `}

  ${(props: any) =>
    props.mr &&
    css`
      margin-right: ${styledTheme.spacing * 0.2}px;
    `}
`;

const Dropdown = styled(ButtonWrapper)`
  border-left: 1px solid ${styledTheme.colors.lighterBlue};
  padding-left: ${styledTheme.spacing * 0.3}px;
  padding-right: ${styledTheme.spacing * 0.3}px;

  svg {
    transform: scale(2);
    width: 9px;
    height: 9px;
  }
`;

export default Button;
