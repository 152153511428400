import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'block',
    width: '100%',
  },
  selectFilter: {
    '& > div': {
      marginRight: 4 ,
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1, 0),
      },
    },
  },
  search: {
    display: 'flex',
    width: '100%',
    '&__box': {
      width: '100%',
      display: 'flex',
    },
  },
  box: {
    marginRight: '4px',
    marginBottom: '10px',
  },
  filter: {
    '& >div>label': {
      background: 'none !important',
      fontSize: '12px !important',
    },
    '& .MuiOutlinedInput-input': {
      background: 'none !important',
    },
    '& .MuiPickersDateRangePickerInput-root': {
      '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
        fontSize: '13px',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        fontSize: '10px',
      },
    },
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '10px',
    marginBottom: '20px',
    '& >div>div>div>p': {
      display: 'none',
    },
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  btn: {
    width: '150px',
    marginRight: '10px',
    '& button': {
      width: '100%',
      height: 35,
    },
  },
  btnReset: {
    boxSizing: 'border-box',
    padding: '8px 10px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '12px',
  },
  btnApply: {
    boxSizing: 'border-box',
    padding: '8px 10px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '12px',
  },
  btnColor: {
    background: '#FC4A46',
    color: '#ffff',
  },
  gridLeft: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  action: {
    display: 'flex',
    width: '100%',
    '& >div:first-child': {
      marginRight: theme.spacing(2),
    },
  },
}));
export { useStyles };
