import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import * as ServicesAction from '../../store/actions';
import { textFieldService } from '../../constants';
import { ITextFieldService } from '../../interface';
import ButtonComponent from 'src/components/ButtonComponent';
import TextInput from 'src/components/InputFiled';
import NumberFormatCustom from './FormatNumber';
import { IListServices } from '../../interface';

import { useStyles } from './styles';

const validate = Yup.object().shape({
  name: Yup.string().required('Required'),
  price: Yup.string().required('Required'),
  min: Yup.string().required('Required'),
  max: Yup.string().required('Required'),
  unit: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

interface IUpdateService {
  detail: IListServices;
}

function UpdateService({ detail }: IUpdateService) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleCancel = () => dispatch(ServicesAction.hideModalUpdateService());

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: detail.name,
      price: detail.price,
      min: detail.min,
      max: detail.max,
      unit: detail.unit,
      immediately_surcharge: detail.immediately_surcharge,
      manual_surcharge: detail.manual_surcharge,
      description: detail.description || '',
    },
    validationSchema: validate,
    onSubmit: (values) => {
      dispatch(
        ServicesAction.updateServicesRequest({ id: detail.id, data: values })
      );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {textFieldService(formik.values).map((item: ITextFieldService) => (
        <div key={item.name} className={classes.formContent}>
          <TextInput
            value={item.value}
            onChange={formik.handleChange}
            label={item.label}
            type={item.type}
            name={item.name}
            variant="outlined"
            required
            error={
              formik.touched[item.name] && Boolean(formik.errors[item.name])
            }
            helperText={formik.touched[item.name] && formik.errors[item.name]}
            InputLabelProps={{
              shrink: true,
              inputComponent: NumberFormatCustom as any,
            }}
          />
        </div>
      ))}
      <div className={classes.formContent}>
        <TextInput
          label="Price"
          value={formik.values.price}
          name="price"
          variant="outlined"
          onChange={formik.handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
          required
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={formik.touched.price && formik.errors.price}
        />
      </div>

      <div className={classes.actionSubmit}>
        <Button onClick={handleCancel}>Cancel</Button>
        <ButtonComponent status="approve">Submit</ButtonComponent>
      </div>
    </form>
  );
}

export default UpdateService;
