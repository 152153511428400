import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  content: {
    alignItems: 'center',
    position: 'relative',
    textDecoration: 'none'
  },
  contentDescription: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: "8px 0px",
    "& div" : {
      padding: '2px 8px',
      fontSize: "12px",
      whiteSpace: "nowrap",
      color: theme.palette.text.primary
    }
  },
  avatar: {
    width: '100%',
    borderRadius: '100%',
    minHeight: 207,
  }
}));

export default useStyles;
