import { put, takeLatest } from 'redux-saga/effects';
import * as types from '../constants';
import * as actionTypes from '../actions';
import {
  getApiUserDetail,
  updateProfile,
  getApiHistoryBooking,
  getApiScheduleUser,
  updateApiScheduleUser,
  deleteApiScheduleUser,
  addMoneyPartnerService,
  getSocial,
} from '../services';
import { showSnackbar } from '../../../Snackbar/store/actions';
import { hideModal } from 'src/containers/App/store/actions';

//user info
function* getUserDetailRequest(action) {
  try {
    const data = yield getApiUserDetail(action.payload.userID);
    yield put(actionTypes.getUserDetailSuccess(data.data.getProfileById));
  } catch (error: any) {
    const message = { error };

    yield put(
      actionTypes.getUserDetailFail(message.error.graphQLErrors[0].code)
    );
  }
}

function* editUserRequest(action) {
  try {
    const dataEditUser = action.payload.data;
    yield updateProfile(dataEditUser);
    yield put(actionTypes.editUserActionSuccess(dataEditUser.data));
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.editUserActionFail(message.error.graphQLErrors[0].code)
    );
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Updated failed',
      })
    );
  }
}

//user social
function* getSocialSaga(action) {
  try {
    const response = yield getSocial(action.payload);
    yield put(actionTypes.getSocialSuccess(response));
  } catch (error: any) {
    const message = { error };
    yield put(actionTypes.getSocialFail(message.error.graphQLErrors[0].code));
  }
}

function* editSocialRequest(action) {
  try {
    // yield updateSocial(action.payload)
    yield put(actionTypes.editSocialUserActionSuccess(action.payload.data));
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
  } catch (error) {
    const newErr = { ...error };
    console.log('-------error', newErr);
    yield put(actionTypes.editSocialUserActionFail(error));
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: newErr.message,
      })
    );
  }
}

//History Booking
function* getHistoryBookingRequest(action) {
  try {
    const historyBooking = yield getApiHistoryBooking(action.payload.userID);
    yield put(
      actionTypes.getHistoryBookingUserSuccess(
        historyBooking.data.historyBookingByUser.data
      )
    );
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.getHistoryBookingUserFail(message.error.graphQLErrors[0].code)
    );
  }
}

//Schedule User
function* getScheduleRequest(action) {
  try {
    const dataSchedule = action.payload.data;
    const Schedule = yield getApiScheduleUser(dataSchedule);
    yield put(actionTypes.getScheduleUserSuccess(Schedule));
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.getScheduleUserFail(message.error.graphQLErrors[0].code)
    );
  }
}

function* editScheduleRequest(action) {
  try {
    const dataSchedule = action.payload.data;
    const Schedule = yield updateApiScheduleUser(dataSchedule);
    yield put(actionTypes.editScheduleUserSuccess(Schedule));
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.editScheduleUserFail(message.error.graphQLErrors[0].code)
    );
  }
}

function* deleteScheduleRequest(action) {
  try {
    const dataSchedule = action.payload.data;
    const Schedule = yield deleteApiScheduleUser(dataSchedule);
    yield put(actionTypes.deleteScheduleUserSuccess(Schedule));
  } catch (error) {
    const message = { error };
    yield put(
      actionTypes.deleteScheduleUserFail(message.error.graphQLErrors[0].code)
    );
  }
}

function* addMoneyPartnerSaga(action) {
  try {
    yield addMoneyPartnerService(action.payload);
    yield put(actionTypes.addMoneyPartnerSuccess(action.payload.data));
    yield put(hideModal());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
  } catch (error: any) {
    const message = { error };
    yield put(hideModal());

    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message.error.networkError.result.errors[0].message,
      })
    );
    yield put(
      actionTypes.addMoneyPartnerFail(message.error.networkError.statusCode)
    );
  }
}

export default function* userSagaSaga() {
  yield takeLatest(types.GET_USER_DETAIL_REQUEST, getUserDetailRequest);
  yield takeLatest(types.EDIT_USER_REQUEST, editUserRequest);
  yield takeLatest(types.GET_HISTORY_BOOKING_REQUEST, getHistoryBookingRequest);
  yield takeLatest(types.GET_SCHEDULE_USER, getScheduleRequest);
  yield takeLatest(types.EDIT_SCHEDULE_USER, editScheduleRequest);
  yield takeLatest(types.DELETE_SCHEDULE_USER, deleteScheduleRequest);
  yield takeLatest(types.ADD_MONEY_PARTNER_REQUEST, addMoneyPartnerSaga);
  yield takeLatest(types.GET_SOCIAL_REQUEST, getSocialSaga);
  yield takeLatest(types.EDIT_SOCIAL_USER_REQUEST, editSocialRequest);
}
