import produce from 'immer';
import * as types from '../constants';
import { getProfileUser } from '../../contants';
import { IReducerUser } from '../../interface';

export const initialState: IReducerUser = {
  isRequesting: false,
  userDetail: {},
  userProfile: {},
  historyBooking: [],
  dataSocial: {},
};
export type userState = typeof initialState;

const UserReducer = (state = initialState, { type, payload }: any) =>
  produce(state, (draft) => {
    switch (type) {
      case types.GET_USER_DETAIL_REQUEST:
        draft.isRequesting = true;
        draft.userDetail = {};
        draft.userProfile = {};
        break;
      case types.GET_USER_DETAIL_SUCCESS:
        draft.isRequesting = false;
        draft.userDetail = payload;
        draft.userProfile = getProfileUser(payload.profile);
        break;
      case types.GET_USER_DETAIL_FAIL:
        draft.isRequesting = false;
        break;
      case types.EDIT_USER_REQUEST:
        break;
      case types.EDIT_USER_SUCCESS:
        draft.userDetail = payload.data;
        break;
      case types.EDIT_USER_FAIL:
        break;
      case types.GET_HISTORY_BOOKING_REQUEST:
        draft.isRequesting = true;
        draft.historyBooking = [];
        break;
      case types.GET_HISTORY_BOOKING_SUCCESS:
        draft.isRequesting = false;
        draft.historyBooking = payload.data;
        break;
      case types.GET_HISTORY_BOOKING_FAIL:
        draft.isRequesting = false;
        break;
      case types.ADD_MONEY_PARTNER_REQUEST:
        break;
      case types.ADD_MONEY_PARTNER_SUCCESS:
        draft.userDetail = {
          ...state.userDetail,
          accountBalance: state.userDetail.accountBalance + payload.amount,
        };
        break;
      case types.ADD_MONEY_PARTNER_FAIL:
        break;
      case types.GET_SOCIAL_SUCCESS:
        draft.dataSocial = payload.data;
        break;
      case types.GET_SOCIAL_FAIL:
        break;
      case types.EDIT_SOCIAL_USER_REQUEST:
        draft.dataSocial = payload.data;
        break;
      case types.EDIT_SOCIAL_USER_SUCCESS:
        draft.dataSocial = payload;
        break;
      case types.EDIT_SOCIAL_USER_FAIL:
        break;
      default:
        break;
    }
  });

export default UserReducer;
