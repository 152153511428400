//User Info,
export const GET_USER_DETAIL_REQUEST = 'instalent/GET_USER_DETAIL_REQUEST';
export const GET_USER_DETAIL_SUCCESS = 'instalent/GET_USER_DETAIL_SUCCESS';
export const GET_USER_DETAIL_FAIL = 'instalent/GET_USER_DETAIL_FAIL';

// edit talent info
export const EDIT_USER_REQUEST = 'instalent/EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'instalent/EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'instalent/EDIT_USER_FAIL';

// edit social user
export const EDIT_SOCIAL_USER_REQUEST = 'instalent/EDIT_SOCIAL_USER_REQUEST';
export const EDIT_SOCIAL_USER_SUCCESS = 'instalent/EDIT_SOCIAL_USER_SUCCESS';
export const EDIT_SOCIAL_USER_FAIL = 'instalent/EDIT_SOCIAL_USER_FAIL';

//History Booking
export const GET_HISTORY_BOOKING_REQUEST =
  'instalent/GET_HISTORY_BOOKING_REQUEST';
export const GET_HISTORY_BOOKING_SUCCESS =
  'instalent/GET_HISTORY_BOOKING_SUCCESS';
export const GET_HISTORY_BOOKING_FAIL = 'instalent/GET_HISTORY_BOOKING_FAIL';
//schedule user
export const GET_SCHEDULE_USER = 'instalent/GET_SCHEDULE_USER';
export const GET_SCHEDULE_USER_SUCCESS = 'instalent/GET_SCHEDULE_USER_SUCCESS';
export const GET_SCHEDULE_USER_FAIL = 'instalent/GET_SCHEDULE_USER_FAIL';
//schedule edit
export const EDIT_SCHEDULE_USER = 'instalent/EDIT_SCHEDULE_USER';
export const EDIT_SCHEDULE_USER_SUCCESS =
  'instalent/EDIT_SCHEDULE_USER_SUCCESS';
export const EDIT_SCHEDULE_USER_FAIL = 'instalent/EDIT_SCHEDULE_USER_FAIL';
//schedule delete
export const DELETE_SCHEDULE_USER = 'instalent/DELETE_SCHEDULE_USER';
export const DELETE_SCHEDULE_USER_SUCCESS =
  'instalent/DELETE_SCHEDULE_USER_SUCCESS';
export const DELETE_SCHEDULE_USER_FAIL = 'instalent/DELETE_SCHEDULE_USER_FAIL';

export const ADD_MONEY_PARTNER_REQUEST = 'instalent/ADD_MONEY_PARTNER_REQUEST';
export const ADD_MONEY_PARTNER_SUCCESS = 'instalent/ADD_MONEY_PARTNER_SUCCESS';
export const ADD_MONEY_PARTNER_FAIL = 'instalent/ADD_MONEY_PARTNER_FAIL';

//user social
export const GET_SOCIAL_REQUEST = 'instalent/GET_SOCIAL_REQUEST';
export const GET_SOCIAL_SUCCESS = 'instalent/GET_SOCIAL_SUCCESS';
export const GET_SOCIAL_FAIL = 'instalent/GET_SOCIAL_FAIL';