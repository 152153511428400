import { gql } from '@apollo/client';

import { IParams } from '../../interface';
import { client } from 'src';

export const getApiNewPartners = async ({ page, rowsPerPage, filter } : IParams) => {
  return await client.query({
    query: gql`
      query {
        getPartnersNew(page: ${page} , per_page: ${rowsPerPage} , filter : {
          username:"${filter.username}"
          phone:"${filter.phone}"
          email:"${filter.email}"
          serviceId:${filter.serviceId}
        }) {
          data {
            id
            role_id
            user_id
            role_name
            name
            email
            gender
            date_of_birth
            online_status
            available_work_status
            status
            phone
            avatar
            created_at
            updated_at
          }
          total
          page
          next_page
          per_page
        }
      }
    `,
  });
};
