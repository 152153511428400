import produce from 'immer';
import * as types from '../constants';

export const initialState: any = {
  paymentMethod: [],
  isRequesting: false,
  allServices: [],
  isRequestingGetAllService: false,
  listRestrictableType: [],
  listUser: [],
  isAddingCoupon: false,
  listCoupon: [],
  total: 0,
};

const CouponsReducer = (state = initialState, { type, payload }: any) =>
  produce(state, (draft) => {
    switch (type) {
      case types.GET_PAYMENT_METHOD_REQUEST:
        draft.isRequesting = true;
        break;
      case types.GET_PAYMENT_METHOD_SUCCESS:
        draft.paymentMethod = payload.data;
        draft.listRestrictableType = payload.data;
        draft.isRequesting = false;
        break;
      case types.GET_PAYMENT_METHOD_FAIL:
        draft.isRequesting = false;
        break;
      case types.GET_ALL_SERVICE_REQUEST:
        draft.isRequestingGetAllService = true;
        break;
      case types.GET_ALL_SERVICE_SUCCESS:
        draft.allServices = payload.data;
        draft.listRestrictableType = payload.data;
        draft.isRequestingGetAllService = false;
        break;
      case types.GET_ALL_SERVICE_FAIL:
        draft.isRequestingGetAllService = false;
        break;
      case types.GET_LIST_USER_REQUEST:
        draft.isRequestingGetAllService = true;
        break;
      case types.GET_LIST_USER_SUCCESS:
        draft.allServices = payload.data;
        draft.listRestrictableType = payload.data;
        draft.listUser = payload.data;
        draft.isRequestingGetAllService = false;
        break;
      case types.GET_LIST_USER_FAIL:
        draft.isRequestingGetAllService = false;
        break;

      case types.DELETE_RESTRICTABLE_STATE:
        draft.listRestrictableType = [];
        break;

      case types.ADD_COUPON_REQUEST:
        draft.isAddingCoupon = true;
        break;
      case types.ADD_COUPON_SUCCESS:
        draft.isAddingCoupon = false;
        break;
      case types.ADD_COUPON_FAIL:
        draft.isAddingCoupon = false;
        break;
      case types.GET_ALL_COUPON_REQUEST:
        draft.isRequesting = true;
        break;
      case types.GET_ALL_COUPON_SUCCESS:
        draft.isRequesting = false;
        draft.listCoupon = payload.data.data;
        draft.total = payload.data.total;
        break;
      case types.GET_ALL_COUPON_FAIL:
        draft.isRequesting = false;
        break;

      case types.DELETE_COUPON_REQUEST:
        break;
      case types.DELETE_COUPON_SUCCESS:
        const newList = state.listCoupon.filter(
          (coupon) => coupon.id !== payload
        );
        draft.listCoupon = newList;
        break;
      case types.DELETE_COUPON_FAIL:
        break;
      case types.UPDATE_COUPON_REQUEST:
        draft.isUpdate = true;
        break;
      case types.UPDATE_COUPON_SUCCESS:
        draft.isUpdate = false;
        draft.listCoupon = state.listCoupon.map((item) => item.id === payload.id ? payload : item)
        break;
      case types.UPDATE_COUPON_FAIL:
        draft.isUpdate = false;
        break;
      default:
        break;
    }
  });

export default CouponsReducer;
