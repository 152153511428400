import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
    fontSize: 14,
    fontWeight: 300,
  },
  date: {
    fontSize: 14,
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
    margin: 'auto',
  },
  service: {
    fontWeight: 'bold',
  },
  description: {
    width: '100%',
    wordBreak: 'break-word',
  },
  mainReview: {
    maxHeight: 400,
    overflow: 'auto',
  },
  contentRating: {
    '& >span': {
      marginRight: theme.spacing(1),
    },
    display: 'flex',
    alignItems: 'center',
  },
  noReview: {
    padding: theme.spacing(2, 0),
  },
}));
export { useStyles };
