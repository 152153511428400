import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInjectReducer } from 'src/utils/injectReducer';
import StaffUserView from './views';
import { useInjectSaga } from 'src/utils/injectSaga';
import reducer from './store/reducers';
import saga from './store/sagas';
import { ISelector } from 'src/reducers';
import * as StaffAction from './store/actions'

function StaffUserContainer() {

  useInjectReducer({ key: 'staff', reducer });
  useInjectSaga({ key: 'staff', saga });
  
  const dispatch = useDispatch()

  const isAdd = useSelector((s: ISelector) => s.staff?.isAdd )

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    const params = {
      page: page,
      rowsPerPage: rowsPerPage,
    };
    dispatch(StaffAction.getStaffRequest(params));
  }, [dispatch, page, rowsPerPage , isAdd]);

  return <StaffUserView page={page} rowsPerPage={rowsPerPage}  handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage}  />;
}

export default StaffUserContainer;
