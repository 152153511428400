import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  main: {
    position: 'relative',
    width: '100%',
    height: '345px',
  },
  media: {
    height: '100%',
    width: '100%',
    color: '#fff',
    position: 'relative',
    objectFit: 'contain',
    fontSize: '180px'
  },
}));
export { useStyles };
