import * as types from '../constants';
export const initialState = {
  isRequesting: false,
  listPartners: [],
  infoPage: {},
};

const TalentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_LIST_PARTNERS_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case types.GET_LIST_PARTNERS_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        listPartners: action.payload.listData,
        infoPage: action.info,
      };
    case types.GET_LIST_PARTNERS_FAIL:
      return {
        isRequesting: false,
        listPartners: [],
      };

    default:
      return state;
  }
};

export default TalentReducer;
