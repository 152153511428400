export const GET_BOOKING_REQUEST = 'instalent/GET_BOOKING_REQUEST';
export const GET_BOOKING_SUCCESS = 'instalent/GET_BOOKING_SUCCESS';
export const GET_BOOKING_FAIL = 'instalent/GET_BOOKING_FAIL';

export const UPDATE_STATUS_BOOKING_REQUEST =
  'instalent/UPDATE_STATUS_BOOKING_REQUEST';
export const UPDATE_STATUS_BOOKING_SUCCESS =
  'instalent/UPDATE_STATUS_BOOKING_SUCCESS';
export const UPDATE_STATUS_BOOKING_FAIL =
  'instalent/UPDATE_STATUS_BOOKING_FAIL';

export const UPDATE_BOOKING_REQUEST = 'instalent/UPDATE_BOOKING_REQUEST';
export const UPDATE_BOOKING_SUCCESS = 'instalent/UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_FAIL = 'instalent/UPDATE_BOOKING_FAIL';

export const GET_SERVICE_REQUEST = 'instalent/GET_SERVICE_REQUEST';
export const GET_SERVICE_SUCCESS = 'instalent/GET_SERVICE_SUCCESS';
export const GET_SERVICE_FAIL = 'instalent/GET_SERVICE_FAIL';

export const DELETE_BOOKING_REQUEST = 'instalent/DELETE_BOOKING_REQUEST';
export const DELETE_BOOKING_SUCCESS = 'instalent/DELETE_BOOKING_SUCCESS';
export const DELETE_BOOKING_FAIL = 'instalent/DELETE_BOOKING_FAIL';

export const FETCH_CANCEL_BOOKING_REQUEST =
  'instalent/FETCH_CANCEL_BOOKING_REQUEST';
export const FETCH_CANCEL_BOOKING_SUCCESS =
  'instalent/FETCH_CANCEL_BOOKING_SUCCESS';
export const FETCH_CANCEL_BOOKING_FAIL = 'instalent/FETCH_CANCEL_BOOKING_FAIL';

export const FETCH_PARTNER_RECEIVE_NOTIFICATION_REQUEST =
  'instalent/FETCH_PARTNER_RECEIVE_NOTIFICATION_REQUEST';

export const FETCH_PARTNER_RECEIVE_NOTIFICATION_SUCCESS =
  'instalent/FETCH_PARTNER_RECEIVE_NOTIFICATION_SUCCESS';

export const FETCH_PARTNER_RECEIVE_NOTIFICATION_FAIL =
  'instalent/FETCH_PARTNER_RECEIVE_NOTIFICATION_FAIL';

export const FETCH_PARTNER_SKIP_NOTIFICATION_REQUEST =
  'instalent/FETCH_PARTNER_SKIP_NOTIFICATION_REQUEST';

export const FETCH_PARTNER_SKIP_NOTIFICATION_SUCCESS =
  'instalent/FETCH_PARTNER_SKIP_NOTIFICATION_SUCCESS';

export const FETCH_PARTNER_SKIP_NOTIFICATION_FAIL =
  'instalent/FETCH_PARTNER_SKIP_NOTIFICATION_FAIL';

export const RESEND_NOTIFICATION_REQUEST =
  'instalent/RESEND_NOTIFICATION_REQUEST';

export const RESEND_NOTIFICATION_SUCCESS =
  'instalent/RESEND_NOTIFICATION_SUCCESS';

export const RESEND_NOTIFICATION_FAIL = 'instalent/RESEND_NOTIFICATION_FAIL';

export const RESEND_NOTIFICATION_NOT_AVAILABLE_WORK_REQUEST =
  'instalent/RESEND_NOTIFICATION_NOT_AVAILABLE_WORK_REQUEST';

export const RESEND_NOTIFICATION_NOT_AVAILABLE_WORK_SUCCESS =
  'instalent/RESEND_NOTIFICATION_NOT_AVAILABLE_WORK_SUCCESS';

export const RESEND_NOTIFICATION_NOT_AVAILABLE_WORK_FAIL =
  'instalent/RESEND_NOTIFICATION_NOT_AVAILABLE_WORK_FAIL';

export const GET_CONVERSATION_BOOKING_REQUEST =
  'instalent/GET_CONVERSATION_BOOKING_REQUEST';

export const GET_CONVERSATION_BOOKING_SUCCESS =
  'instalent/GET_CONVERSATION_BOOKING_SUCCESS';

export const GET_CONVERSATION_BOOKING_FAIL =
  'instalent/GET_CONVERSATION_BOOKING_FAIL';

export const GET_PAGE_NUMBER_REQUEST = 'instalent/GET_PAGE_NUMBER_REQUEST';

export const GET_PAGE_NUMBER_SUCCESS = 'instalent/GET_PAGE_NUMBER_SUCCESS';

export const GET_PAGE_NUMBER_FAIL = 'instalent/GET_PAGE_NUMBER_FAIL';

export const RESEND_NOTIFICATION_PAGE_REQUEST =
  'instalent/RESEND_NOTIFICATION_PAGE_REQUEST';

export const RESEND_NOTIFICATION_PAGE_SUCCESS =
  'instalent/RESEND_NOTIFICATION_PAGE_SUCCESS';

export const RESEND_NOTIFICATION_PAGE_FAIL =
  'instalent/RESEND_NOTIFICATION_PAGE_FAIL';

export const ASSIGN_PARTNER_REQUEST =
  'instalent/ASSIGN_PARTNER_REQUEST';

export const ASSIGN_PARTNER_SUCCESS =
  'instalent/ASSIGN_PARTNER_SUCCESS';

export const ASSIGN_PARTNER_FAIL =
  'instalent/ASSIGN_PARTNER_FAIL';
