import NumberFormat from 'react-number-format';

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  const handleChange = (values) =>
    onChange({
      target: {
        name: props.name,
        value: values.value,
      },
    });
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={handleChange}
      thousandSeparator
      isNumericString
    />
  );
}

export default NumberFormatCustom;
