import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  view: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    fontSize: 12,
    textDecoration: 'none',
    '& svg': {
      marginLeft: 8,
      cursor: 'pointer',
    },
    color: theme.palette.type === 'light' ? '#1a1a1a' : '#ffff',
  },
  editBooking: {
    marginBottom: theme.spacing(2),
  },
  autoComplete: {
    marginBottom: theme.spacing(2),
    width: '100% !important',
    '&>div>div': {
      '& .MuiInputBase-root': {
        '& input': {
          paddingBottom: '0px !important',
          paddingTop: '0px !important',
        },
      },
    },
  },
  tabPanel: {
    "& >div": {
      padding: '8px 0px'
    }
  },
  tabs: {
    width: '100%'
  },
  tab : {
    fontSize: '12px',
    width: '100%',
  }
}));

export { useStyles };
