import * as types from '../constants';

export const initialState = {
  authenticated: false,
  message_error: '',
  isLoading: false,
  adminInfo: {},
};

const loginReduce = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        authenticated: false,
        isLoading: true,
        message_error: '',
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        isLoading: false,
      };
    case types.LOGIN_FAIL:
      return {
        authenticated: false,
        message_error: action.payload.data,
        isLoading: false,
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        authenticated: false,
      };
    case types.LOGOUT_FAIL:
      return {
        ...state,
        authenticated: false,
      };

    case types.GET_PROFILE_ADMIN_REQUEST:
      return {
        ...state,
      };
    case types.GET_PROFILE_ADMIN_SUCCESS:
      return {
        ...state,
        adminInfo: action.payload,
      };
    case types.GET_PROFILE_ADMIN_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default loginReduce;
