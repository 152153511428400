export const COLUMNS_WAITING = [
  {
    title: 'ID',
    field: 'id',
  },
  {
    title: 'Partner',
    field: 'partner',
  },
  {
    title: 'Service Name',
    field: 'name',
  },
  {
    title: 'Status',
    field: 'status',
  },
  {
    title: 'City',
    field: 'city',
  },
  {
    title: 'Created at',
    field: 'created_at',
  },
  {
    title: 'Updated at',
    field: 'updated_at',
  },
  {
    title: 'Certificate',
    field: 'images',
  },
  {
    title: 'Schedule',
    field: 'schedule',
  },
  {
    title: 'Action',
    field: 'action',
  },
];

export const COLUMNS = [
  {
    title: 'ID',
    field: 'id',
  },
  {
    title: 'Partner',
    field: 'partner',
  },
  {
    title: 'Service Name',
    field: 'name',
  },
  {
    title: 'Status',
    field: 'status',
  },
  {
    title: 'City',
    field: 'city',
  },
  {
    title: 'Created at',
    field: 'created_at',
  },
  {
    title: 'Updated at',
    field: 'updated_at',
  },
  {
    title: 'Approved/Declined By',
    field: 'author',
  },
  {
    title: 'Certificate',
    field: 'images',
  },
  {
    title: 'Action',
    field: 'action',
  },
];

export const COLUMNS_PROCESSING = [
  {
    title: 'ID',
    field: 'id',
  },
  {
    title: 'Partner',
    field: 'partner',
  },
  {
    title: 'Service Name',
    field: 'name',
  },
  {
    title: 'Status',
    field: 'status',
  },
  {
    title: 'City',
    field: 'city',
  },
  {
    title: 'Created at',
    field: 'created_at',
  },
  {
    title: 'Updated at',
    field: 'updated_at',
  },
  {
    title: 'Certificate',
    field: 'images',
  },
  {
    title: 'Schedule',
    field: 'schedule',
  },
  {
    title: 'Action',
    field: 'action',
  },
];

export const dataStatusProvider = [
  {
    name: 'WAITING_FOR_PROCESS',
    id: 0,
  },
  {
    name: 'PROCESSING',
    id: 3,
  },
  {
    name: 'APPROVED',
    id: 1,
  },
  {
    name: 'DECLINED',
    id: 2,
  },
];

export const dataFilterImages = [
  {
    name: 'IMAGES',
    id: true,
  }
];

export const StatusRequest = {
  APPROVED: 1,
  DECLINED: 2,
};

export const dataSchedule = (value) => [
  {
    label: 'Schedule Interview',
    name: 'schedule',
    type: 'datetime-local',
    value: value.schedule,
  },
  {
    label: 'Description',
    name: 'description',
    type: 'text',
    value: value.description,
  },
  {
    label: 'Address',
    name: 'address',
    type: 'text',
    value: value.address,
  },
];

export const dataScheduleDetail = (value) => [
  {
    label: 'Schedule Interview',
    name: 'schedule',
    type: 'datetime-local',
    value: value.schedule,
  },
  {
    label: 'Description',
    name: 'description',
    type: 'text',
    value: value.description,
  },
  {
    label: 'Address',
    name: 'address',
    type: 'text',
    value: value.address,
  },
  {
    label: 'Created at',
    name: 'created_at',
    type: 'datetime-local',
    value: value.created_at,
    disabled: true,
  },
  {
    label: 'Updated at',
    name: 'updated_at',
    type: 'datetime-local',
    value: value.updated_at,
    disabled: true,
  },
];

export const STATUS_SERVICE_REQUEST = {
  WAITING_FOR_PROCESS: 0,
  APPROVED: 1,
  DECLINED: 2,
  PROCESSING: 3,
};

export const dataFieldServiceRequest = (data) => [
  {
    name: 'email',
    label: 'Email',
    value: data.email,
  },
  {
    name: 'name',
    label: 'Name',
    value: data.name,
  },

  {
    name: 'phone',
    label: 'Phone',
    value: data.phone,
  },
];