import { AuthenticatedRoute } from '../components/routes';
import CouponContainer  from '../containers/Coupons'

const CouponsRoutes = [
  {
    path: '/coupons',
    title: 'Coupons',
    component: CouponContainer,
    route: AuthenticatedRoute,
    roles: [],
    permission: [],
    exact: true,
  },
];


export default CouponsRoutes;