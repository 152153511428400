import {
  IListServiceProvider,
  IParamHandle,
  IScheduleDetail,
} from 'src/containers/ListServiceRequest/interface';
import * as types from '../constants';

// GET LIST SERVICE REQUEST
export const serviceRequestPartnerAction = function (payloadAction: string) {
  return {
    type: types.SERVICE_PARTNER_REQUEST,
    payload: payloadAction,
  };
};

export const serviceRequestPartnerSuccess = function (
  dataAction: Array<IListServiceProvider>
) {
  return {
    type: types.SERVICE_PARTNER_SUCCESS,
    payload: dataAction,
  };
};

export const serviceRequestPartnerFail = function (error) {
  return {
    type: types.SERVICE_PARTNER_FAIL,
    payload: error,
  };
};

// UPDATE SERVICE REQUEST
export const updateServiceRequest = function (data: IParamHandle) {
  return {
    type: types.UPDATE_SERVICE_PARTNER_REQUEST,
    payload: { data },
  };
};

export const updateServiceSuccess = (data: IParamHandle) => {
  return {
    type: types.UPDATE_SERVICE_PARTNER_SUCCESS,
    payload: data,
  };
};

export const updateServiceFail = (error) => {
  return {
    type: types.UPDATE_SERVICE_PARTNER_FAIL,
    payload: error,
  };
};

// ADD SCHEDULE

export const addScheduleInterviewPartnerRequest = function (
  data: IScheduleDetail
) {
  return {
    type: types.ADD_SCHEDULE_SERVICE_PARTNER_REQUEST,
    payload: { data },
  };
};

export const addScheduleInterviewPartnerSuccess = (data: IScheduleDetail) => {
  return {
    type: types.ADD_SCHEDULE_SERVICE_PARTNER_SUCCESS,
    payload: data,
  };
};

export const addScheduleInterviewPartnerFail = (error) => {
  return {
    type: types.ADD_SCHEDULE_SERVICE_PARTNER_FAIL,
    payload: error,
  };
};
