export const COLUMNS = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Email',
    field: 'email',
  },
  {
    title: 'Phone',
    field: 'phone',
  },
  {
    title: 'Available Work Status',
    field: 'available_work_status',
  },
  {
    title: 'Online Status',
    field: 'online_status',
  },
  {
    title: 'Created at',
    field: 'created_at',
  },
];

export const dataFieldPartner = (data) => [
  {
    name: 'email',
    label: 'Email',
    value: data.email,
  },
  {
    name: 'username',
    label: 'Name',
    value: data.username,
  },

  {
    name: 'phone',
    label: 'Phone',
    value: data.phone,
  },
];
