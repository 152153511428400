import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IUsers } from 'src/containers/ListServiceRequest/interface';
import { blockUserRequest } from '../store/actions';

const useBlockUser = () => {
  const dispatch = useDispatch();

  const [untilBlock, setUntilBlock] = useState<string>('');

  const [messageError, setMessageError] = useState<string>('');

  const handleChangeUnitBlock = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessageError('');
    setUntilBlock(e.target.value);
  };

  const [userProfile, setUserProfile] = useState<{
    isOpen: boolean;
    data: IUsers | null;
  }>({
    isOpen: false,
    data: null,
  });

  const handleDeleteProfile = (data: IUsers) => {
    setUserProfile({
      isOpen: true,
      data,
    });
  };

  const handleCloseDelete = () => {
    setUserProfile({
      isOpen: false,
      data: null,
    });
  };

  const handleBlockUser = () => {
    if (untilBlock) {
      if (userProfile.data) {
        dispatch(
          blockUserRequest({
            profileId: userProfile!.data.id,
            block_until: untilBlock,
          })
        );
        handleCloseDelete();
        setUntilBlock('');
        setMessageError('');
      }
    } else {
      setMessageError('Required');
    }
  };

  return {
    userProfile,
    handleDeleteProfile,
    handleCloseDelete,
    handleBlockUser,
    untilBlock,
    handleChangeUnitBlock,
    setMessageError,
    messageError,
  };
};

export default useBlockUser;
