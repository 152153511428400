import TableBookings from '../components/TableBookings';
import FilterBookings from '../components/FilterBookings';
import { IBookingsView } from '../interface';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';

import { useStyles } from './styles';
import CalenderBookings from '../components/calenderBookings';
import { useState } from 'react';
import {
  LinkTab,
  TabPanel,
} from 'src/containers/Booking/components/InfoBookingRecipient';

function BookingsView({
  search,
  handleSearch,
  handleClearFilter,
  handleFilter,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleChangeTime,
}: IBookingsView) {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const classes = useStyles();
  return (
    <div className={classes.deviceContainer}>
      <AppBar position="static">
        <Tabs variant="fullWidth" value={value} onChange={handleChange}>
          <LinkTab label="Calender" />
          <LinkTab label="Bookings" />
        </Tabs>
      </AppBar>
      <TabPanel className={classes.tabPanel} value={value} index={1}>
        <div>
          <FilterBookings
            search={search}
            handleSearch={handleSearch}
            handleClearFilter={handleClearFilter}
            handleFilter={handleFilter}
          />
          <TableBookings
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={0}>
        <CalenderBookings handleChangeTime={handleChangeTime} />
      </TabPanel>
    </div>
  );
}
export default BookingsView;
