import { gql } from '@apollo/client';
import { client } from 'src';
import moment from 'moment';

export const getPaymentMethod = async () => {
  return await client.query({
    query: gql`
      query {
        getPaymentMethods {
          id
          payment_name
          payment_type
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

export const getListServices = async () => {
  return await client.query({
    query: gql`
      query {
        services {
          id
          name
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

export const getApiUsers = async (data) => {
  const { page, rowsPerPage, filter } = data;

  const users = await client.query({
    query: gql`
        query {
          users(page: ${page} , per_page: ${rowsPerPage}, filter : { name: "${filter.name}" , email: "${filter.email}", phone : "${filter.phone}" , role_id: 2})
          {
            data {
              role_id
              user_id
              avatar
              id
              role_name
              name
              city_name
              email
              gender
              date_of_birth
              online_status
              available_work_status
              status
              phone
              created_at
              updated_at
            }
            page
            next_page
            per_page,
            totalUsers
          }
        }
      `,
  });
  return users;
};

export const getCouponServices = async (data) => {
  const { page, rowsPerPage } = data;

  const users = await client.query({
    query: gql`
          query {
            getAllCoupon(page: ${page} , per_page :${rowsPerPage}) {
              data {
                id
                coupon_code
                discount_unit
                discount_value
                discount_unit
                valid_before
                minimum_order_value
                maximum_discount_amount
                restrictable_type
                restrictable_ids
                created_at
                updated_at
              }
              total
            }
          }
        `,
    fetchPolicy: 'network-only',
  });
  return users;
};

export const addCouponService = async ({
  coupon_code,
  discount_value,
  discount_unit,
  valid_before,
  minimum_order_value,
  maximum_discount_amount,
  restrictable_type,
  restrictable_ids,
}) => {
  if (valid_before) {
    return await client.mutate({
      mutation: gql`
              mutation {
                  createCouponCode(
                      data: {
                          coupon_code: "${coupon_code}",
                          discount_value: ${+discount_value},
                          discount_unit: ${discount_unit},
                          valid_before: "${moment(valid_before)
                            .utc()
                            .toISOString()}",
                          minimum_order_value: ${+minimum_order_value},
                          maximum_discount_amount: ${+maximum_discount_amount},
                          restrictable_type: ${restrictable_type},
                          restrictable_ids: ${JSON.stringify(restrictable_ids)}
                  })
              }
        `,
    });
  }
  return await client.mutate({
    mutation: gql`
            mutation {
                createCouponCode(
                    data: {
                        coupon_code: "${coupon_code}",
                        discount_value: ${+discount_value},
                        discount_unit: ${discount_unit},
                        minimum_order_value: ${+minimum_order_value},
                        maximum_discount_amount: ${+maximum_discount_amount},
                        restrictable_type: ${restrictable_type},
                        restrictable_ids: ${JSON.stringify(restrictable_ids)}
                })
            }
      `,
  });
};

export const deleteCouponService = async (id) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      deleteCouponCode(id :${id})
    }
    `,
  });
};

export const updateCouponService = async ({
  id,
  coupon_code,
  discount_value,
  discount_unit,
  valid_before,
  minimum_order_value,
  maximum_discount_amount,
  restrictable_type,
  restrictable_ids,
}) => {
  if (valid_before) {
    return await client.mutate({
      mutation: gql`
      mutation {
        updateCouponCode (id: ${id} , data : {
        coupon_code: "${coupon_code}",
        discount_value: ${+discount_value},
        discount_unit: ${discount_unit},
        valid_before: "${moment(valid_before).utc().toISOString()}",
        minimum_order_value: ${+minimum_order_value},
        maximum_discount_amount: ${+maximum_discount_amount},
        restrictable_type: ${restrictable_type},
        restrictable_ids: ${JSON.stringify(restrictable_ids)}
        })
      }
      `,
    });
  }
  return await client.mutate({
    mutation: gql`
    mutation {
      updateCouponCode (id: ${id} , data : {
      coupon_code: "${coupon_code}",
      discount_value: ${+discount_value},
      discount_unit: ${discount_unit},
      minimum_order_value: ${+minimum_order_value},
      maximum_discount_amount: ${+maximum_discount_amount},
      restrictable_type: ${restrictable_type},
      restrictable_ids: ${JSON.stringify(restrictable_ids)}
      })
    }
    `,
  });
};
