import { AuthenticatedRoute } from '../components/routes';
import UserContainer from 'src/containers/User'

const partnerRoute = [
  {
    path: '/user/:id',
    title: 'User',
    component: UserContainer,
    route: AuthenticatedRoute,
    roles: [],
    permission: [],
    exact: true,
  },
];

export default partnerRoute;