import { createSelector } from 'reselect';
import { initialState } from '../reducers';

const selectGlobal = (state: any) => state.surcharge || initialState;

const makeSelectLoading = () =>
  createSelector(selectGlobal, (state) => state.isLoading);

const makeSelectListCities = () =>
  createSelector(selectGlobal, (state) => state.listCities);

const makeSelectListSurcharge = () =>
  createSelector(selectGlobal, (state) => state.listSurcharge);

const makeSelectTotalSurcharge = () =>
  createSelector(selectGlobal, (state) => state.totalSurcharge);

const makeSelectIsFresh = () =>
  createSelector(selectGlobal, (state) => state.isFresh);

export {
  makeSelectLoading,
  makeSelectListCities,
  makeSelectListSurcharge,
  makeSelectTotalSurcharge,
  makeSelectIsFresh,
};
