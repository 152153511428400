import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { useInjectSaga } from "src/utils/injectSaga";
import { useInjectReducer } from "src/utils/injectReducer";

import saga from "./store/sagas";
import reducer from "./store/reducers";
import HomeView from "./views";

function HomeContainer(props) {
  useInjectSaga({ key: "thing", saga });
  useInjectReducer({ key: "thing", reducer });

  return <HomeView {...props} />;
}

const mapStateToProps = (state: any) => {
  const { thing } = state;
  return { thing };
};
const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({}, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
