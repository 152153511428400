import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  inputSearch: {
    width: '30%',
    marginRight: theme.spacing(2),
  },
  bntSearch: {
    width: 100,
  },
  bntClear: {
    width: 100,
    paddingLeft: 40,
  },
  link: {
    textDecoration: 'none',
  }
}));

export { useStyles };
