import { IReducerNewPartners } from '../../interface';
import * as types from '../constants';

export const initialState : IReducerNewPartners = {
  isRequesting: false,
  listNewPartners: [],
  infoPage: {
    data: [],
    page: 1,
    per_page: 10,
    total: 10,
  },
};

const NewPartnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NEW_PARTNERS_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case types.GET_NEW_PARTNERS_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        listNewPartners: action.payload.listData,
        infoPage: action.info,
      };
    case types.GET_NEW_PARTNERS_FAIL:
      return {
        isRequesting: false,
        listNewPartners: [],
      };

    default:
      return state;
  }
};

export default NewPartnerReducer;
