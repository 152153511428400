// import { showSnackbar } from '../redux/actions/snackbar.action';

import { refreshTokenRequest } from 'src/containers/auth/login/store/actions';
import * as types from 'src/containers/auth/login/store/constants';

let previousActions: any = [];

const authenticateMiddleware = (store) => (next) => (action) => {
  const { type: actionType, payload } = action;
  if (actionType.indexOf('_REQUEST') > 0) {
    // store.dispatch(showLoading());
    previousActions.push(action);
  }

  if (actionType.indexOf('_SUCCESS') > 0 || actionType.indexOf('_FAIL') > 0) {
    // store.dispatch(hideLoading());
  }

  if (actionType.includes('_FAIL')) {
    const { error = {} } = payload || {};
    const { response = {}, code = '' } = error;
    const { status = 1 } = response;

    if (status === 401 || +code === 401 || payload === 'UNAUTHENTICATED') {
      previousActions.push(action);
      store.dispatch(refreshTokenRequest()); 
    }
  }

  if (
    actionType === types.REFRESH_TOKEN_SUCCESS &&
    previousActions.length > 0
  ) {
    previousActions.forEach((previousAction: any) => {
      const type = previousAction.type.replace('_FAIL', '_REQUEST');
      const action = previousActions.find((item: any) => item.type === type);

      if (action) store.dispatch(action);
    });
    previousActions = [];
  }
  next(action);
};

export default authenticateMiddleware;
