import TableServices from '../components/TableServices';
import { IListServiceView } from '../interface';

import { useStyles } from './styles';

function ServicesView(props: IListServiceView) {
  const classes = useStyles();
  return (
    <div className={classes.deviceContainer}>
      <TableServices {...props} />
    </div>
  );
}
export default ServicesView;
