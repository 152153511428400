import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IconClose, IconPen } from '../../../../../../components/IconAction';
import EditUser from './EditUser';
import ToggleComponent from 'src/components/ToggleComponent';
import { IUser } from 'src/containers/User/interface';
import { profileUser } from 'src/containers/User/contants';
import * as UserAction from '../../../../store/actions';
import { ISelector } from 'src/reducers';

import { useStyles } from './styles';

function UserInfo() {
  const classes = useStyles();
  const [isEdit, setEdit] = useState<boolean>(false);

  const userDetail = useSelector((s: ISelector) => s.user?.userDetail) || {};

  const dispatch = useDispatch();

  const onEdit = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setEdit(!isEdit);
  };

  const onSaveUser = ({ id, data }) => {
    dispatch(UserAction.editUserActionRequest({ id, data }));
    setEdit(!isEdit);
  };

  const actions = [
    {
      title: '',
      iconAction: <IconPen />,
      onClick: onEdit,
    },
  ];
  const editActions = [
    {
      title: '',
      iconAction: <IconClose />,
      onClick: onEdit,
    },
  ];

  return (
    <>
      <ToggleComponent title='Profile' actions={isEdit ? editActions : actions}>
        {isEdit ? (
          <div className={classes.edit}>
            <EditUser
              userInfo={userDetail}
              editUserActionRequest={onSaveUser}
            />
          </div>
        ) : (
          <div>
            <InfoChildren userDetail={userDetail} />
          </div>
        )}
      </ToggleComponent>
    </>
  );
}

interface IInfoChildren {
  userDetail: IUser;
}

function InfoChildren({ userDetail }: IInfoChildren) {
  const classes = useStyles();

  return (
    <div className={classes.info}>
      {profileUser(userDetail).map((item, index) => (
        <div key={index} className={classes.talenMain__item}>
          <div className={`${classes.item}-main`}>
            <div className={`${classes.item}-main__left`}>{item.title}:</div>
            <div className={`${classes.item}-main__right`}>
              {item.content || ''}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default UserInfo;
