import { IParamAddService, IParamUpdateService } from '../../interface';
import * as types from '../constants';

export const getServicesRequest = function (params) {
  return {
    type: types.GET_SERVICES_REQUEST,
    payload: params,
  };
};

export const getServicesSuccess = (data) => {
  return {
    type: types.GET_SERVICES_SUCCESS,
    payload: { data },
  };
};

export const getServicesFail = (error) => {
  return {
    type: types.GET_SERVICES_FAIL,
    payload: error,
  };
};

export const deleteServicesRequest = function (id) {
  return {
    type: types.DELETE_SERVICES_REQUEST,
    payload: id,
  };
};

export const deleteServicesSuccess = () => {
  return {
    type: types.DELETE_SERVICES_SUCCESS,
  };
};

export const deleteServicesFail = (error) => {
  return {
    type: types.DELETE_SERVICES_FAIL,
    payload: error,
  };
};

export const updateServicesRequest = function (params: IParamUpdateService) {
  return {
    type: types.UPDATE_SERVICES_REQUEST,
    payload: params,
  };
};

export const updateServicesSuccess = () => {
  return {
    type: types.UPDATE_SERVICES_SUCCESS,
  };
};

export const updateServicesFail = (error) => {
  return {
    type: types.UPDATE_SERVICES_FAIL,
    payload: error,
  };
};

export const addServicesRequest = function (params: IParamAddService) {
  return {
    type: types.ADD_SERVICES_REQUEST,
    payload: params,
  };
};

export const addServicesSuccess = () => {
  return {
    type: types.ADD_SERVICES_SUCCESS,
  };
};

export const addServicesFail = (error) => {
  return {
    type: types.ADD_SERVICES_FAIL,
    payload: error,
  };
};

export const showModalAddService = () => {
  return {
    type: types.SHOW_MODAL_ADD_SERVICE,
  };
};

export const hideModalAddService = () => {
  return {
    type: types.HIDE_MODAL_ADD_SERVICE,
  };
};

export const showModalUpdateService = () => {
  return {
    type: types.SHOW_MODAL_UPDATE_SERVICE,
  };
};

export const hideModalUpdateService = () => {
  return {
    type: types.HIDE_MODAL_UPDATE_SERVICE,
  };
};
