import Reviews from 'src/containers/User/components/RightSide/components/Reviews';
import Schedule from 'src/containers/User/components/RightSide/components/Schedule';
import AdditionPartnerInfo from './components/AdditionPartnerInfo';
import HistoryTransaction from '../../../HistoryTransaction';
import ServiceRequest from 'src/containers/ServiceRequest';
import BankAccount from './components/BankAccount';

function RightSide() {
  return (
    <div>
      <AdditionPartnerInfo />
      <Schedule />
      <ServiceRequest />
      <HistoryTransaction />
      <BankAccount />
      <Reviews />
    </div>
  );
}

export default RightSide;