import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom';

import MuiTable from 'src/components/MuiTable';
import { IPartnerReceive } from '../../interface';

export const COLUMNS = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Email',
    field: 'email',
  },
  {
    title: 'Phone',
    field: 'phone',
  },
];
interface PartnerReceiveProps {
  isRequesting: boolean;
  list: Array<IPartnerReceive>;
}

function PartnerReceive({ isRequesting, list }: PartnerReceiveProps) {
  const classes = useStyles();
  const row = (item) => {
    return {
      name: (
        <div>
          <Link to={`/partner/${item.id}`}>{item.name || ''}</Link>
        </div>
      ),
      email: (
        <div>
          <Link to={`/partner/${item.id}`}>{item.email || ''}</Link>
        </div>
      ),
      phone: (
        <div>
          <Link to={`/partner/${item.id}`}>{item.phone || ''}</Link>
        </div>
      ),
    };
  };

  const skeletonRowData = <Skeleton variant="rect" animation="wave" />;

  const rowError = {
    name: <div>{skeletonRowData}</div>,
    email: <div>{skeletonRowData}</div>,
    phone: <div>{skeletonRowData}</div>,
  };

  const renderRow = isRequesting
    ? new Array(10).fill(0).map((i) => rowError)
    : list?.map(row);
  return (
    <div className={classes.table}>
      <MuiTable headers={COLUMNS} rows={renderRow} />
    </div>
  );
}

export default PartnerReceive;

const useStyles = makeStyles(() => ({
  table: {
    '& a': {
      textDecoration: 'none',
      color: '#3e50b4',
    },
  },
}));
