import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  dialogContent: {
    width: 500,
  },
  dialog: {},
  scheduled: {
    display: 'flex',
    width: 160,
    alignItems: 'center',
    '& >span': {
      marginLeft: theme.spacing(1),
    },
  },
  formControl: {
    width: '100%',
  },
  textField: {
    width: '100%',
  },
  formContent: {
    paddingTop: theme.spacing(3),
    '& >div': {
      width: '100% !important',
    },
  },
  actionSubmit: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: theme.spacing(5),
    '& >div:first-child': {
      marginRight: theme.spacing(2),
    },
  },
  imageListItem: {
    height: '100% !important',
  },
  imgItem: {
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  imgList: {
    height: '100% !important',
  },
  selectContent: {
    paddingTop: 24,
  },
  checkbox: {
    '& span': {
      fontSize: '14px',
    },
  },
}));
export { useStyles };
