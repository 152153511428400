import { put, takeLatest } from 'redux-saga/effects';
import * as types from '../constants';
import * as actionTypes from '../actions';
import { ActionType } from '../../../App/interfaces/index';

function* generateThingSaga(action: ActionType) {
  try {
    const data = [
      {
        thingname: 'ESN-MLVL67S08NIPGLJRSVERDM03PLULYBJ2',
        firmware: 'v1.0',
        status: 'fail',
      },
      {
        thingname: 'ESN-MLVL67S08NIPGLJRSVERDM03PLULYBJ3',
        firmware: 'v1.0',
        status: 'fail',
      },
      {
        thingname: 'ESN-MLVL67S08NIPGLJRSVERDM03PLU3LYBJ',
        firmware: 'v1.0',
        status: 'fail',
      },
      {
        thingname: 'ESN-MLVL67S08NIPGLJRSVERDM03PLUtLYBJ',
        firmware: 'v1.0',
        status: 'fail',
      },
      {
        thingname: 'ESN-MLVL67S08NIPGLJRSVERDM0r3PLULYBJ',
        firmware: 'v1.0',
        status: 'fail',
      },
      {
        thingname: 'ESN-MLVL67S08NIPGLJRSVERDM053PLULYBJ',
        firmware: 'v1.0',
        status: 'fail',
      },
      {
        thingname: 'ESN-MLVL67S08NIPGLJRSVERDMe03PLULYBJ',
        firmware: 'v1.0',
        status: 'fail',
      },
      {
        thingname: 'ESN-MLVL67S08NIPGLJRSVERDM03PgLULYBJ',
        firmware: 'v1.0',
        status: 'fail',
      },
      {
        thingname: 'ESN-MLVL67S08NIPGLJRSVERDM03sPLULYBJ',
        firmware: 'v1.0',
        status: 'fail',
      },
    ];
    const formatData = data.reduce(
      (obj: any, item) => {
        obj.things = {
          ...obj.things,
          [item.thingname]: { id: item.thingname, ...item },
        };
        obj.thingIds.push(item.thingname);
        return obj;
      },
      {
        things: {},
        thingIds: [],
      }
    );
    yield put(actionTypes.generateThingNameSuccess(formatData));
  } catch (error) {
    yield put(actionTypes.generateThingNameFail(error));
  }
}

export default function* watchApp() {
  yield takeLatest(types.GENERATE_THING_NAME_REQUEST, generateThingSaga);
}
