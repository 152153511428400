export const COLUMS = [
  {
    title: 'ID',
    field: 'id',
  },
  {
    title: 'Email',
    field: 'email',
  },
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Status',
    field: 'status',
  },
  {
    title: 'Created at',
    field: 'create_at',
  },
  {
    title: 'Updated at',
    field: 'updated_at',
  },
  {
    title: 'Action',
    field: 'action',
  },
];

export const dataStaff = (value) => [
  {
    label: 'Name',
    name: 'name',
    type: 'text',
    value: value.name,
  },
  {
    label: 'Email',
    name: 'email',
    type: 'email',
    value: value.email,
  },
  {
    label: 'Password',
    name: 'password',
    type: 'password',
    value: value.password,
  },
  {
    label: 'Confirm Password',
    name: 'confirmPassword',
    type: 'password',
    value: value.confirmPassword,
  },

  {
    label: 'Date of birth',
    name: 'date_of_birth',
    type: 'date',
    value: value.date_of_birth,
  },
];

export const dataStaffUpdate = (value) => [
  {
    label: 'Email',
    name: 'email',
    type: 'email',
    value: value.email,
  },
  {
    label: 'Password',
    name: 'password',
    type: 'password',
    value: value.password,
  },
];

export const AdminPermissionName = [
  {
    name: 'USER MANAGER',
    id: 'USER_MANAGER',
  },
  {
    name: 'SERVICE MANAGER',
    id: 'SERVICE_MANAGER',
  },
  {
    name: 'ALL MANAGER',
    id: 'ALL_MANAGER',
  },
  {
    name: 'OTHER',
    id: 'OTHER',
  },
];

export const AdminRoleNameData = [
  {
    name: 'SYSTEM ADMIN',
    id: 'SYS_ADMIN',
  },
  {
    name: 'AGENTS',
    id: 'AGENTS',
  },
  {
    name: 'ADMINS',
    id: 'ADMINS',
  },
  {
    name: 'OTHER',
    id: 'OTHER',
  },
];

export const GENDER = [
  {
    name: 'Female',
    id: 'female',
  },
  {
    name: 'Male',
    id: 'male',
  },
];

export const statusSelect = [
  {
    name: 'INACTIVE',
    id: '0',
  },
  {
    name: 'ACTIVE',
    id: '1',
  },
];

export const statusAdmin = {
  0: 'INACTIVE',
  1: 'ACTIVE',
};
