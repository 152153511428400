import {
  Avatar,
  Drawer,
  Menu,
  MenuItem,
  Paper,
  TextField,
} from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import {
  AccountBalanceWallet as AccountBalanceWalletIcon,
  AddCircle as AddCircleIcon,
  ArrowDropDown as ArrowDropDownIcon,
  AssignmentInd as AssignmentIndIcon,
  AttachMoney as CouponIcon,
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  CalendarToday as CalendarTodayIcon,
  Category as CategoryIcon,
  Dashboard as DashboardIcon,
  Person as PersonIcon,
  Map as MapIcon,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as AuthAction from 'src/containers/auth/login/store/actions';
import reducer from 'src/containers/auth/login/store/reducer';
import saga from 'src/containers/auth/login/store/sagas';
import { ISelector } from 'src/reducers';
import { useInjectReducer } from 'src/utils/injectReducer';
import { useInjectSaga } from 'src/utils/injectSaga';
import styled from 'styled-components';
import { logoutRequest } from '../../../containers/auth/login/store/actions';
import styledTheme from '../../../utils/theme';

import { useStyles } from './styles';

// Constants
const items = [
  {
    label: 'Home',
    link: '/',
    icon: <DashboardIcon />,
  },
  {
    label: `Manage User  `,
    icon: <PersonIcon />,
    childMenu: [
      {
        label: 'Partners',
        link: '/partners',
        icon: <PersonIcon />,
      },
      {
        label: 'New Partners',
        link: '/new-partners',
        icon: <PersonIcon />,
      },
      {
        label: 'Users',
        link: '/users',
        icon: <PersonIcon />,
      },
      {
        label: 'Staff User',
        link: '/staff',
        icon: <PersonIcon />,
      },
    ],
  },
  {
    label: 'Bookings',
    link: '/bookings',
    icon: <CalendarTodayIcon />,
  },
  {
    label: 'Services',
    link: '/services',
    icon: <CategoryIcon />,
  },
  {
    label: 'Service Request',
    link: '/partners/service-request',
    icon: <AssignmentIndIcon />,
  },
  {
    label: 'Coupons',
    link: '/coupons',
    icon: <CouponIcon />,
  },
  {
    label: 'WithDraw Partner',
    link: '/withdraw',
    icon: <AccountBalanceWalletIcon />,
  },
    {
    label: 'Surcharge',
    link: '/surcharge',
    icon: <AddCircleIcon />,
  },
  {
    label: 'Services Areas',
    link: '/service-areas',
    icon: <MapIcon />,
  },
];

const ROUTES = [
  {
    path: '/',
    title: 'Devices',
  },
];
interface Props {
  logoutRequest: any;
  auth: any;
  [data: string]: any;
}
// Header component
const Header = (props: Props) => {
  useInjectSaga({ key: 'auth', saga });
  useInjectReducer({ key: 'auth', reducer });

  const dispatch = useDispatch();
  const classes = useStyles();
  const match = useRouteMatch();

  const [isOpenSideBar, setOpenSidebar] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const adminInfo = useSelector((s: ISelector) => s.auth?.adminInfo || {});

  const themeMode = localStorage.getItem('instalent-them-mode');

  const { logoutRequest = false, setThemeMode, auth = {} } = props;
  const { name = '', avatar } = auth;

  const onChangeSelect = (v: any) => console.log(v);
  const onTongleOpen = () => setOpenSidebar(true);
  const onTongleClose = () => setOpenSidebar(false);

  const onDarkMode = (e: any) => {
    if (themeMode === 'dark') {
      setThemeMode('light');
      localStorage.setItem('instalent-them-mode', 'light');
    } else {
      setThemeMode('dark');
      localStorage.setItem('instalent-them-mode', 'dark');
    }
  };

  useEffect(() => {
    const path = match.path as any;
    setSelectedRoute(path);
  }, [match]);

  useEffect(() => {
    dispatch(AuthAction.getProfileAdminRequest());
  }, [dispatch]);

  return (
    <Paper
      className={clsx(classes.wrapper, {
        [classes.bgDark]: themeMode === 'dark',
        [classes.bgLight]: themeMode === 'light',
      })}
    >
      <div className={classes.container}>
        <LogoWrapper>
          {/* <Hamburger onClick={onTongleOpen} className={classes.icon} /> */}
          <div className={classes.title}>Instalent</div>
        </LogoWrapper>
        <NavList>
          {items.map((item: any, index) => (
            <NavItem
              key={index}
              label={item.label}
              link={item.link}
              className={classes.link}
              icon={item.icon}
              childMenu={item.childMenu}
              onOpenMenu={handleClick}
              anchorEl={anchorEl}
              handleClose={handleClose}
            />
          ))}

          {/* <div className="responseNav">
            <div className="navMore">
              More <Icon.ExpandMore />{' '}
            </div>
            <ul>
              {items.map((item: any, index) => (
                <li key={index} className={`resNav${index + 1}`}>
                  <NavItem
                    label={item.label}
                    link={item.link}
                    icon={item.icon}
                  />
                </li>
              ))}
            </ul>
          </div> */}
        </NavList>
        <TopBar>
          <UserInfo>
            <UserAvatar src={avatar} />
            <span className={classes.textName}>{adminInfo.name}</span>
            <UserNameSelect select value={name} onChange={onChangeSelect}>
              {adminInfo && <MenuItem value={name}>{adminInfo.name}</MenuItem>}
              <div onClick={onDarkMode} className={classes.spaceBetween}>
                Theme: {themeMode === 'dark' ? 'Dark mode' : ' Light Mode'}
                {themeMode === 'dark' ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </div>
              <MenuItem className={classes.red} onClick={logoutRequest}>
                Logout
              </MenuItem>
            </UserNameSelect>
          </UserInfo>
        </TopBar>
      </div>
      <DrawerStyle anchor={'left'} open={isOpenSideBar} onClose={onTongleClose}>
        <div
          className={`${classes.routeItem} ${classes.closeItem}`}
          onClick={onTongleClose}
        >
          <Icon.Close />
          <p className={classes.text}>Close</p>
        </div>
        {ROUTES.map((route: any, index) => (
          <Link
            className={`${classes.routeItem} ${
              selectedRoute === route.path && classes.isActive
            }`}
            to={route.path}
            key={index}
          >
            <p className={classes.text}>{route.title}</p>
          </Link>
        ))}
      </DrawerStyle>
    </Paper>
  );
};

function NavItem(props: any) {
  const classes = useStyles();

  const { pathname } = useLocation();

  const {
    label,
    link,
    className,
    icon,
    childMenu,
    onOpenMenu,
    anchorEl,
    handleClose,
  } = props;

  return (
    <>
      {childMenu?.length > 0 ? (
        <>
          <div className={className} onClick={onOpenMenu}>
            <div className={classes.label}>
              {icon} <span>{label} </span> <ArrowDropDownIcon />
            </div>
          </div>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {childMenu?.map((menu, index) => (
              <Link to={menu.link} style={{ textDecoration: 'none' }}>
                <MenuItem
                  key={index}
                  onClick={handleClose}
                  className={clsx(classes.menuItem, {
                    [classes.menuItemActive]: pathname === menu.link,
                  })}
                >
                  <NavLink
                    activeStyle={{
                      fontWeight: 'bold',
                    }}
                    className={classes.linkChildren}
                    exact
                    to={menu.link}
                  >
                    <div className={classes.label}>
                      {menu.icon} <span>{menu.label} </span>
                    </div>
                  </NavLink>
                </MenuItem>
              </Link>
            ))}
          </Menu>
        </>
      ) : (
        <NavLink
          exact
          activeStyle={{
            fontWeight: 'bold',
          }}
          className={className}
          to={link}
        >
          <div className={classes.label}>
            {icon} <span>{label} </span>
          </div>
        </NavLink>
      )}
    </>
  );
}

// Styles

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  color: white;
`;

const Hamburger = styled(Icon.Menu)`
  cursor: pointer;
`;

const NavList = styled.div`
  display: flex;
  color: #d8dcf0;
  margin-left: 30px;
  .responseNav {
    display: none;
  }
  .navMore {
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 12px;
  }
`;

const TopBar = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  & .MuiBadge-badge {
    transform: scale(0.6) translate(20%, 15%);
    font-size: 16px;
  }

  svg {
    width: 22px;
    color: #d8dcf0;
  }

  & .MuiInput-underline {
    &:before,
    &:after,
    &:hover:not(.Mui-disabled):before {
      border-bottom: none;
    }
  }

  .MuiInputBase-root {
    font-size: 16px;
    line-height: 12px;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${styledTheme.spacing}px;

  svg {
    color: #ffffff;
  }
`;

const UserAvatar = styled(Avatar)`
  width: 20px;
  height: 20px;
  margin: 0 ${styledTheme.spacing * 0.2}px;
`;

const UserNameSelect = styled(TextField)`
  .MuiInputBase-root {
    color: #fff;
    margin-top: 1px;
    .MuiSelect-selectMenu {
      min-height: auto;
    }
  }
  .MuiSelect-selectMenu {
    max-width: 70px;
  }
`;

const DrawerStyle = withStyles(() => ({
  paper: {
    minWidth: 250,
  },
}))(Drawer);

const mapStateToProps = (store: any) => {
  const { auth } = store;
  return {
    auth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      logoutRequest: logoutRequest,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
