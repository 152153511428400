import { gql } from '@apollo/client';
import { client } from 'src';

export const getWithDrawService = async (data) => {
  const { page, rowsPerPage } = data;
  return await client.query({
    query: gql`
      query {
        getRequestWithdraw(page: ${page} , per_page:${rowsPerPage} ) {
          data {
            id
            profile_id
            profiles {
              name
              phone
            }
            amount
            bank_account_id
            bank_account {
              id
              profile_id
              bank_account
              status
              payment_unit {
                name
                fullname
              }
            }
            status
          }
          total
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

export const updateStatusWithdrawService = async ({ id, status }) => {
  return await client.mutate({
    mutation: gql`
      mutation {
        updateRequestWithdraw(id: ${id}, payload: { status: ${status} })
      }
    `,
  });
};
