import {
  Avatar,
  FormControl,
  TablePagination,
} from '@material-ui/core';
import { Brightness1 as Brightness1Icon } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ButtonComponent from 'src/components/ButtonComponent';
import DialogComponent from 'src/components/DialogComponent';
import MuiTable from 'src/components/MuiTable';
import BlockUser from 'src/containers/ListUsersContainer/components/BlockUser';
import useBlockUser from 'src/containers/ListUsersContainer/hooks/useBlockUser';
import { ISelector } from 'src/reducers';
import { COLUMNS } from '../../contants';
import { ITablePartner } from '../../interface';
import { useStyles } from './styles';

export function formatNumber(value: number) {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function TablePartner(props: ITablePartner) {
  const classes = useStyles();

  const listPartners = useSelector(
    (s: ISelector) => s.partners?.listPartners || []
  );
  const infoPage = useSelector((s: ISelector) => s.partners?.infoPage || {});
  const isRequesting = useSelector((s: ISelector) => s.partners?.isRequesting);

  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    props;

  const {
    userProfile,
    handleDeleteProfile,
    handleCloseDelete,
    handleBlockUser,
    handleChangeUnitBlock,
    untilBlock,
    messageError,
  } = useBlockUser();

  const row = (item) => {
    return {
      name: (
        <div>
          <Link to={`partner/${item.id}`} className={classes.rowName}>
            <Avatar alt={item.name} src={item.avatar} />
            <div>{item.name || ''}</div>
          </Link>
        </div>
      ),
      email: (
        <div>
          <Link to={`partner/${item.id}`}>
            <p>{item.email || ''}</p>
          </Link>
        </div>
      ),
      phone: (
        <div>
          <p>{item.phone || ''}</p>
        </div>
      ),
      city: (
        <div>
          <p>{item.city_name || ''}</p>
        </div>
      ),
      available_work_status: (
        <div>
          <p>{item.available_work_status ? 'Yes' : 'No' || ''}</p>
        </div>
      ),
      block_until: (
        <div>
          <p>
            {item.block_until ? moment(item.block_until).format('LLLL') : ''}
          </p>
        </div>
      ),
      online_status: item.online_status ? (
        <div>
          <Brightness1Icon fontSize="small" className={classes.online} />
        </div>
      ) : (
        <div className={classes.online}>
          <p>Offline</p>
        </div>
      ),
      accountBalance: (
        <div>
          <p> {formatNumber(item.accountBalance)} đ </p>
        </div>
      ),
      accountRevenue: (
        <div>
          <p> {formatNumber(item.accountRevenue)} đ </p>
        </div>
      ),
      service: (
        <div>
          <FormControl>
            <div className={classes.listService}>
              {item?.services?.map((item) => (
                <option key={item.name} value={item.name}>
                  - {item.name}
                </option>
              ))}
            </div>
          </FormControl>
        </div>
      ),
      action: (
        <div>
          <ButtonComponent handleAction={() => handleDeleteProfile(item)}>
            Block
          </ButtonComponent>
        </div>
      ),
    };
  };

  const skeletonRowData = <Skeleton variant="rect" animation="wave" />;

  const rowError = {
    role_id: <div>{skeletonRowData}</div>,
    name: <div>{skeletonRowData}</div>,
    email: <div>{skeletonRowData}</div>,
    phone: <div>{skeletonRowData}</div>,
    date_of_birth: <div>{skeletonRowData}</div>,
    city: <div>{skeletonRowData}</div>,
    available_work_status: <div>{skeletonRowData}</div>,
    block_until: <div>{skeletonRowData}</div>,
    online_status: <div>{skeletonRowData}</div>,
    service: <div>{skeletonRowData}</div>,
    accountBalance: <div>{skeletonRowData}</div>,
    accountRevenue: <div>{skeletonRowData}</div>,
    action: <div>{skeletonRowData}</div>,
  };

  const pagination = (
    <div>
      <TablePagination
        className={classes.container}
        component="div"
        count={infoPage?.total || 0}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );

  const renderRow = isRequesting
    ? new Array(10).fill(0).map((i) => rowError)
    : listPartners.map(row);

  return (
    <div className={classes.root}>
      <div className={classes.boxTable}>
        <MuiTable headers={COLUMNS} rows={renderRow} pagination={pagination} />
      </div>

      <DialogComponent
        title={`Block User ${userProfile.data?.name!}`}
        open={userProfile.isOpen}
        onClose={handleCloseDelete}
      >
        <BlockUser
          data={userProfile!.data}
          untilBlock={untilBlock}
          handleChangeUnitBlock={handleChangeUnitBlock}
          handleBlockUser={handleBlockUser}
          onClose={handleCloseDelete}
          messageError={messageError}
        />
      </DialogComponent>
    </div>
  );
}

export default connect(null, {})(TablePartner);
