import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  formSubmit: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: 20,
    width: '100%'
  },
  buttonForm: {
    display: 'flex',
    justifyContent: 'right',
  }
}));
export { useStyles };
