import { Grid } from '@material-ui/core';

import { IFilterBookings } from '../../interface';
import TextInput from 'src/components/InputFiled';
import SelectOption from 'src/components/Selection';
import { STATUS_BOOKING } from '../contants';

import { useStyles } from './styles';

function FilterBookings({
  handleFilter,
  handleClearFilter,
  search,
  handleSearch,
}: IFilterBookings) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridLeft}>
          <div className={classes.box}>
            <TextInput
              value={search.date}
              onChange={handleSearch}
              type="date"
              name="date"
              variant="outlined"
            />
          </div>
          <SelectOption
            label="Status"
            options={STATUS_BOOKING}
            name="status"
            onChangeOption={handleSearch}
            value={search.status}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className={classes.filter}>
            <div className={classes.btn}>
              <button
                className={`${classes.btnApply} ${classes.btnColor}`}
                type="submit"
                onClick={handleFilter}
              >
                Apply Filter
              </button>
            </div>
            <div className={classes.btn}>
              <button
                className={classes.btnReset}
                type="button"
                onClick={handleClearFilter}
              >
                Clear Filter
              </button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default FilterBookings;
