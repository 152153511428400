import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import useQueryLocation from 'src/hooks/useQueryLocation';
import { IParams, ISearchPartner } from './interface';
import * as NewPartnersAction from './store/actions/index';
import queryString from 'query-string';
import saga from './store/sagas';
import reducer from './store/reducers';
import { useInjectReducer } from 'src/utils/injectReducer';
import { useInjectSaga } from 'src/utils/injectSaga';

function NewPartnersHook() {
  useInjectReducer({ key: 'newPartners', reducer });
  useInjectSaga({ key: 'newPartners', saga });
  const history = useHistory();

  const location = useLocation();
  let query = useQueryLocation();
  let params = useRef<IParams>();
  const dispatch = useDispatch();

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };

  const [search, setSearch] = useState<ISearchPartner>({
    email: '',
    username: '',
    phone: '',
    serviceId: 0,
  });

  const handleSearch = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const onResetFilter = () => {
    setSearch({
      email: '',
      username: '',
      phone: '',
      serviceId: 0,
    });
    params.current = {
      page: 1,
      rowsPerPage: 10,
      filter: {
        email: '',
        username: '',
        phone: '',
        serviceId: 0,
      },
    };
    history.push(`/new-partners`);
    dispatch(NewPartnersAction.getNewPartnersRequestAction(params.current));
  };

  const handleSubmitFilter = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    params.current = {
      rowsPerPage: rowsPerPage,
      page: page,
      filter: {
        username: search.username,
        phone: search.phone,
        email: search.email,
        serviceId: search.serviceId,
      },
    };
    dispatch(NewPartnersAction.getNewPartnersRequestAction(params.current));
    const paramsString = queryString.stringify(params.current.filter);

    history.push(`/new-partners?${paramsString}`);
  };

  useEffect(() => {
    if (location.search) {
      const username = query.get('username');
      const phone = query.get('phone');
      const email = query.get('email');
      const serviceId = query.get('serviceId');

      params.current = {
        page: page,
        rowsPerPage: rowsPerPage,
        filter: {
          username: username || search.username,
          phone: phone || search.phone,
          email: email || search.email,
          serviceId: serviceId || search.serviceId,
        },
      };
    }
    params.current = {
      page: page,
      rowsPerPage: rowsPerPage,
      filter: {
        username: search.username,
        phone: search.phone,
        email: search.email,
        serviceId: search.serviceId,
      },
    };

    dispatch(NewPartnersAction.getNewPartnersRequestAction(params.current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, rowsPerPage, page]);

  return {
    page,
    rowsPerPage,
    search,
    handleChangeRowsPerPage,
    handleChangePage,
    handleSearch,
    onResetFilter,
    handleSubmitFilter,
  };
}

export default NewPartnersHook;
