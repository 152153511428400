import { Fragment } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import HelmetComponent from '../components/HelmetComponent';
import { MainLayout } from '../layouts';
import authRoute from './authRoutes';
import BookingRoutes from './BookingRoutes';
import BookingsRoutes from './BookingsRoutes';
import CouponsRoutes from './CouponsRoutes';
import dashboardRoutes from './dashboardRoutes';
import listNewPartnerRoutes from './listNewPartnerRoutes';
import listPartnerRoutes from './listPartnerRoutes';
import listServiceRequestRoutes from './listServiceRequestRoutes';
import listUserRoutes from './listUserRoutes';
import partnerRoute from './partnerRoutes';
import ServicesRoutes from './ServicesRoutes';
import StaffRoutes from './StaffUserRoutes';
import SurchargeRoutes from './SurchargeRoutes';
import userRoutes from './userRoutes';
import withDrawRoutes from './withDrawRoutes';
import ServiceAreasRouter from './ServiceAreasRouter';

const routes = [
  ...dashboardRoutes,
  ...authRoute,
  ...partnerRoute,
  ...listPartnerRoutes,
  ...listUserRoutes,
  ...userRoutes,
  ...BookingsRoutes,
  ...BookingRoutes,
  ...listServiceRequestRoutes,
  ...ServicesRoutes,
  ...StaffRoutes,
  ...listNewPartnerRoutes,
  ...CouponsRoutes,
  ...withDrawRoutes,
  ...SurchargeRoutes,
  ...ServiceAreasRouter,
];

const RenderRouter = ({
  route: Component,
  layout: Layout = Fragment,
  breadcrumb,
  title,
  ...rest
}: any) => (
  <Fragment>
    {title && <HelmetComponent title={title} />}
    <Layout breadcrumb={breadcrumb} title={title}>
      <Component {...rest} />
    </Layout>
  </Fragment>
);

const Router = (
  <BrowserRouter>
    <Switch>
      {routes.map((routeItem: any, index: number) => {
        return (
          <RenderRouter
            route={routeItem.route}
            key={index}
            path={routeItem.path}
            component={routeItem.component}
            layout={routeItem.layout || MainLayout}
            roles={routeItem.roles}
            permission={routeItem.permission}
            breadcrumb={routeItem.breadcrumb}
            title={routeItem.title}
            exact={routeItem.exact}
            tabs={routeItem.tabs ? routeItem.tabs : []}
          />
        );
      })}
    </Switch>
  </BrowserRouter>
);

export default Router;
