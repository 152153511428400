import { Avatar } from '@material-ui/core';

import { useStyles } from './styles';

function AvatarProfile({ avtUrl, name }) {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Avatar
        variant='square'
        src={avtUrl}
        className={classes.media}
        alt={name}
      />
    </div>
  );
}

export default AvatarProfile;
