import gql from 'graphql-tag';

import { client } from 'src';

export const getApiLogin = async (data) => {
  const test = await client.mutate({
    mutation: gql`
      mutation {
        loginAdmin(payload: {email: "${data.username}", password: "${data.password}"}) {
          token,
          refreshtoken
        }
      }
      `,
  });
  return test;
};

export const refreshTokenService = async (refreshToken) => {
  return await client.mutate({
    mutation: gql`
      mutation {
        refreshTokenAdmin(
          refresh: "${refreshToken}"
        ) {
          token
          refreshtoken
        }
      }
    `,
  });
};

export const getProfileAdminService = async () => {
  return await client.query({
    query: gql`
      query {
        adminProfile {
          id
          name
          avatar
          gender
          date_of_birth
        }
      }
    `,
  });
};
