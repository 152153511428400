import TableUser from '../components/TableUser';
import FilterUser from '../components/FilterUser';
import { IUserView } from '../interface';

import { useStyles } from './styles';

function UsersView(props: IUserView) {
  const classes = useStyles();
  return (
    <div className={classes.deviceContainer}>
      <FilterUser
        search={props.search}
        handleSearch={props.handleSearch}
        onResetFilter={props.onResetFilter}
        handleSubmitFilter={props.handleSubmitFilter}
      />
      <TableUser
        page={props.page}
        rowsPerPage={props.rowsPerPage}
        handleChangeRowsPerPage={props.handleChangeRowsPerPage}
        handleChangePage={props.handleChangePage}
      />
    </div>
  );
}
export default UsersView;
