import produce from 'immer';
import * as types from '../constants';

export const initialState = {
  isRequesting: false,
  listWithdraw: [],
  total: 0,
};

const withDrawReducer = (state = initialState, action: any) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case types.GET_WITHDRAW_REQUEST:
        draft.isRequesting = true;
        break;
      case types.GET_WITHDRAW_SUCCESS:
        draft.isRequesting = false;
        draft.listWithdraw = action.payload.data.data;
        draft.total = action.payload.data.total;
        break;
      case types.GET_WITHDRAW_FAIL:
        draft.isRequesting = false;
        break;
      case types.UPDATE_STATUS_WITHDRAW_REQUEST:
        draft.isRequesting = true;
        break;
      case types.UPDATE_STATUS_WITHDRAW_SUCCESS:
        draft.isRequesting = false;
        draft.listWithdraw = state.listWithdraw.map((item: any) =>
          item.id === action.payload.data.id
            ? {
                ...item,
                bank_account: { ...item.bank_account, status: true },
                status: action.payload.data.status.toLowerCase(),
              }
            : item
        );
        break;
      case types.UPDATE_STATUS_WITHDRAW_FAIL:
        draft.isRequesting = false;
        break;
      default:
        break;
    }
  });

export default withDrawReducer;
