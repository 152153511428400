import { Rating } from '@material-ui/lab';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Card, CardContent, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

import ToggleComponent from 'src/components/ToggleComponent';
import { ISelector } from 'src/reducers';
import { IReview } from 'src/containers/User/interface';

import { useStyles } from './styles';

function Reviews() {
  const classes = useStyles();

  const userDetail = useSelector((s: ISelector) => s.user?.userDetail);

  const { reviews = [] } = userDetail || {};

  return (
    <ToggleComponent title="Reviews">
      <div className={classes.mainReview}>
        {reviews.length === 0 ? (
          <div className={classes.noReview}>No review</div>
        ) : (
          reviews.map((item: IReview, index: number) => {
            return (
              <Card variant="outlined" key={index} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <div className={classes.content}>
                    <div className={classes.contentRating}>
                      <Rating readOnly value={item.star} />
                      <Link to={`/booking/${item.booking_id}`}>
                        <img width="15px" src="/images/share.png" alt="" />
                      </Link>
                    </div>
                    <Typography className={classes.service}>
                      {item && item.booking?.service?.name}
                    </Typography>
                    <div className={classes.description}>{item.content}</div>
                  </div>
                  <div className={classes.date}>
                    {moment(item.created_at).format('DD-MM-YYYY HH:mm:ss')}
                  </div>
                </CardContent>
              </Card>
            );
          })
        )}
      </div>
    </ToggleComponent>
  );
}
export default Reviews;
