import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: '800px',
    padding: 16,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
    },
  },
  dialog: {},
  scheduled: {
    display: 'flex',
    width: 160,
    alignItems: 'center',
    '& >span': {
      marginLeft: theme.spacing(1),
    },
  },
  formControl: {
    width: '100%',
  },
  textField: {
    width: '100%',
  },
  title: {
    textAlign: 'center',
    '& >h2': {
      fontWeight: 'bold',
    },
  },
}));
export { useStyles };
