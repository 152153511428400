import { gql } from '@apollo/client';
import { client } from 'src';

export const getServicesService = async (data) => {
  const { page, rowsPerPage } = data;
  return await client.query({
    query: gql`
    query {
      servicesByAdmin(page: ${page} , per_page: ${rowsPerPage} ) {
        data {
        id
        name
        max
        min
        unit
        description
        created_at
        updated_at
        price
        immediately_surcharge
        manual_surcharge
        }
        totalServices
      }
    }
    `,
    fetchPolicy: 'network-only',
  });
};

export const updateService = async ({ id, data }) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      updateServiceById(id: ${id} , data: {
        name: "${data.name}",
        price: ${+data.price} ,
        min: ${+data.min},
        max: ${+data.max},
        unit: "${data.unit}",
        description: "${data.description}"
        immediately_surcharge: ${+data.immediately_surcharge},
        manual_surcharge: ${+data.manual_surcharge}
      })
    }
    `,
  });
};

export const addService = async ({
  name,
  price,
  max,
  min,
  unit,
  description,
}) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      createService(data: {
        name: "${name}",
        price: ${price},
        max: ${max},
        min: ${min}
        unit: "${unit}",
        description: "${description}"
      })
    }
    `,
  });
};

export const deleteService = async (id) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      deleteServiceById(id :${id})
    }
    `,
  });
};
