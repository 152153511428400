import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import moment from 'moment';

import ButtonComponent from 'src/components/ButtonComponent';
import TextInput from 'src/components/InputFiled';
import {
  AdminPermissionName,
  AdminRoleNameData,
  dataStaff,
  GENDER,
} from '../../contants';
import SelectOption from 'src/components/Selection';
import * as StaffAction from '../../store/actions';

import { useStyles } from './styles';

const validate = Yup.object().shape({
  email: Yup.string().required('Required'),
  confirmPassword: Yup.string().when('password', {
    is: (val: any) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref('password')],
      'Both password need to be the same'
    ),
  }),
  password: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  gender: Yup.string().required('Required'),
  adminRole: Yup.string().required('Required'),
  adminPermission: Yup.string().required('Required'),
  date_of_birth: Yup.string().required('Required'),
});

function CreateStaff({ handleClose }: any) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
      gender: '',
      name: '',
      date_of_birth: moment(new Date()).format('YYYY-MM-DD'),
      adminRole: 'SYS_ADMIN',
      adminPermission: 'ALL_MANAGER',
    },
    validationSchema: validate,
    onSubmit: (values) => {
      dispatch(StaffAction.addStaffRequest(values));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {dataStaff(formik.values).map((item: any) => (
        <div key={item.name} className={classes.formContent}>
          <TextInput
            value={item.value}
            onChange={formik.handleChange}
            label={item.label}
            type={item.type}
            name={item.name}
            variant="outlined"
            required
            error={
              formik.touched[item.name] && Boolean(formik.errors[item.name])
            }
            helperText={formik.touched[item.name] && formik.errors[item.name]}
          />
        </div>
      ))}
      <div className={classes.formContent}>
        <SelectOption
          label="Gender"
          options={GENDER}
          name="gender"
          onChangeOption={formik.handleChange}
          value={formik.values.gender}
        />
      </div>
      <div className={classes.formContent}>
        <SelectOption
          label="Admin Permission"
          options={AdminPermissionName}
          name="adminPermission"
          onChangeOption={formik.handleChange}
          value={formik.values.adminPermission}
        />
      </div>
      <div className={classes.formContent}>
        <SelectOption
          label="Admin Role"
          options={AdminRoleNameData}
          name="adminRole"
          onChangeOption={formik.handleChange}
          value={formik.values.adminRole}
        />
      </div>
      <div className={classes.actionSubmit}>
        <Button onClick={handleClose}>Cancel</Button>
        <ButtonComponent status="approve">Submit</ButtonComponent>
      </div>
    </form>
  );
}

export default CreateStaff;
