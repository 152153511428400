import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardContent: {},
  main: {},
  textField: {
    '& >div>input': {
      padding: theme.spacing(1.8, 1.4),
    },
  },
  view: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    fontSize: 12,
    textDecoration: 'none',
    '& svg': {
      marginLeft: 8,
      cursor: 'pointer',
    },
  },
  viewDark: {
    color: '#ffff !important'
  },
  viewLight: {
    color: '#1a1a1a'
  }
}));

export { useStyles };
