import { call, put, takeLatest } from 'redux-saga/effects';

import { showSnackbar } from 'src/containers/Snackbar/store/actions';
import * as types from '../constants';
import * as actionTypes from '../actions';
import {
  getServiceAreasService,
  deleteServiceAreasService,
  getCitiesID,
  createServiceAreaService,
  updateServiceAreaService,
} from '../services';
import {
  hideModal,
  hideModalAdd,
  hideModalUpdate,
} from 'src/containers/App/store/actions';

function* getServiceAreasSaga(action) {
  try {
    const response = yield call(getServiceAreasService, action.payload);
    yield put(actionTypes.getServiceAreasSuccess(response.data.serviceArea));
  } catch (error: any) {
    yield put(actionTypes.getServiceAreasFail(error));
  }
}

function* deleteServiceAreasSaga(action) {
  try {
    yield call(deleteServiceAreasService, action.payload);
    yield put(actionTypes.deleteServiceAreasSuccess());
    yield put(hideModal());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Delete Success',
      })
    );
  } catch (error: any) {
    yield put(actionTypes.deleteServiceAreasFail(error));
    yield put(hideModal());
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Delete fail',
      })
    );
  }
}

function* getCitiesIdSaga() {
  try {
    const cityData = yield call(getCitiesID);
    yield put(actionTypes.getCitiesIdSuccess(cityData));
  } catch (error: any) {
    yield put(actionTypes.getCitiesIdFail(error));
  }
}

function* createServiceAreaSaga(action) {
  try {
    yield call(createServiceAreaService, action.payload.data);
    yield put(actionTypes.createServiceAreaSuccess());
    yield put(hideModalAdd());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Create Success',
      })
    );
  } catch (error: any) {
    yield put(actionTypes.createServiceAreaFail(error));
    yield put(hideModalAdd());
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Create fail',
      })
    );
  }
}

function* updateServiceAreaSaga(action) {
  const data = action.payload.params;
  try {
    yield call(updateServiceAreaService, data);
    yield put(actionTypes.updateServiceAreaSuccess(data));
    yield put(hideModalUpdate());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Update Success',
      })
    );
  } catch (error: any) {
    yield put(actionTypes.updateServiceAreaFail(error));
    yield put(hideModalUpdate());
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Update fail',
      })
    );
  }
}

export default function* ServiceAreasSaga() {
  yield takeLatest(types.GET_SERVICE_AREAS_REQUEST, getServiceAreasSaga);
  yield takeLatest(types.DELETE_SERVICE_AREAS_REQUEST, deleteServiceAreasSaga);
  yield takeLatest(types.GET_ID_CITY_REQUEST, getCitiesIdSaga);
  yield takeLatest(types.CREATE_SERVICE_AREA_REQUEST, createServiceAreaSaga);
  yield takeLatest(types.UPDATE_SERVICE_AREA_REQUEST, updateServiceAreaSaga);
}
