import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
  },
  buttonResend: {
    display: 'flex',
    '& >div': {
      '&:first-child': {
        marginRight: 16,
      },
    },
  },
  btnResend: {
    display: 'flex',
    '& div': {
      marginRight: 8,
    },
  },
}));

export { useStyles };
