import {
  IDataSetMoney,
  IParamsAddMoney,
  IParamUpdate,
  IUserInfo,
} from '../../interface';
import * as types from '../constants';

//User info
export const getUserDetailActionRequest = function (userID: string) {
  return {
    type: types.GET_USER_DETAIL_REQUEST,
    payload: { userID },
  };
};

export const getUserDetailSuccess = (data: any) => {
  return {
    type: types.GET_USER_DETAIL_SUCCESS,
    payload: data,
  };
};

export const getUserDetailFail = (error: any) => {
  return {
    type: types.GET_USER_DETAIL_FAIL,
    payload: error,
  };
};
// edit user info
export const editUserActionRequest = (data: IParamUpdate) => {
  return {
    type: types.EDIT_USER_REQUEST,
    payload: { data },
  };
};

export const editUserActionSuccess = (data: IUserInfo) => {
  return {
    type: types.EDIT_USER_SUCCESS,
    payload: { data },
  };
};

export const editUserActionFail = (error) => {
  return {
    type: types.EDIT_USER_FAIL,
    payload: error,
  };
};

//edit social user info
export const editSocialUserActionRequest = (data: IParamUpdate) => {
  return {
    type: types.EDIT_SOCIAL_USER_REQUEST,
    payload: data,
  };
};

export const editSocialUserActionSuccess = (data: IUserInfo) => {
  return {
    type: types.EDIT_SOCIAL_USER_SUCCESS,
    payload: data,
  };
};

export const editSocialUserActionFail = (error) => {
  return {
    type: types.EDIT_SOCIAL_USER_FAIL,
    payload: error,
  };
};

//history booking
export const getHistoryBookingUserRequest = function (userID: string) {
  return {
    type: types.GET_HISTORY_BOOKING_REQUEST,
    payload: { userID },
  };
};

export const getHistoryBookingUserSuccess = (data: any) => {
  return {
    type: types.GET_HISTORY_BOOKING_SUCCESS,
    payload: { data },
  };
};

export const getHistoryBookingUserFail = (error) => {
  return {
    type: types.GET_HISTORY_BOOKING_FAIL,
    payload: error,
  };
};
// Schedule
export const getScheduleUser = function (data) {
  return {
    type: types.GET_SCHEDULE_USER,
    payload: { data },
  };
};

export const getScheduleUserSuccess = function (data: any) {
  return {
    type: types.GET_SCHEDULE_USER_SUCCESS,
    payload: { data },
  };
};

export const getScheduleUserFail = (error) => {
  return {
    type: types.GET_SCHEDULE_USER_FAIL,
    payload: error,
  };
};

// Edit Schedule
export const editScheduleUser = function (data) {
  return {
    type: types.EDIT_SCHEDULE_USER,
    payload: { data },
  };
};

export const editScheduleUserSuccess = (data: any) => {
  return {
    type: types.EDIT_SCHEDULE_USER_SUCCESS,
    payload: { data },
  };
};

export const editScheduleUserFail = (error) => {
  return {
    type: types.EDIT_SCHEDULE_USER_FAIL,
    payload: error,
  };
};

// Delete Schedule
export const deleteScheduleUser = function (data) {
  return {
    type: types.DELETE_SCHEDULE_USER,
    payload: { data },
  };
};

export const deleteScheduleUserSuccess = (data: any) => {
  return {
    type: types.DELETE_SCHEDULE_USER_SUCCESS,
    payload: { data },
  };
};

export const deleteScheduleUserFail = (error) => {
  return {
    type: types.DELETE_SCHEDULE_USER_FAIL,
    payload: error,
  };
};

export const addMoneyPartnerRequest = (params: IParamsAddMoney) => {
  return {
    type: types.ADD_MONEY_PARTNER_REQUEST,
    payload: params,
  };
};

export const addMoneyPartnerSuccess = (data: IDataSetMoney) => {
  return {
    type: types.ADD_MONEY_PARTNER_SUCCESS,
    payload: data,
  };
};

export const addMoneyPartnerFail = (error: any) => {
  return {
    type: types.ADD_MONEY_PARTNER_FAIL,
    payload: error,
  };
};

//user social

export const getSocialRquest = function (params) {
  return {
    type: types.GET_SOCIAL_REQUEST,
    payload: { params },
  };
};

export const getSocialSuccess = function (data) {
  return {
    type: types.GET_SOCIAL_SUCCESS,
    payload: { data },
  };
};

export const getSocialFail = function (error) {
  return {
    type: types.GET_SOCIAL_FAIL,
    payload: { error },
  };
};
