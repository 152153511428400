import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  talenMain__item: {
    position: 'relative',
    marginTop: '5px',
    paddingLeft: '5px',
  },
  unverified: {
    position: 'absolute',
    right: '2%',
    bottom: '25%',
    backgroundColor: '#f94839',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      fontSize: '10px',
      fontWeight: 500,
      lineHeight: 1.17,
      color: '#ffffff',
    },
  },
  item: {
    color: 'rgba(0, 0, 0, 0.54)',
    '&-main': {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '5px',
      '&__left': {
        width: '40%',
        textAlign: 'end',
        paddingRight: '10px',
        fontWeight: '500',
        fontSize: '10px',
        color: '#b0b0b0',
      },
      '&__right': {
        width: '40%',
        textAlign: 'start',
        fontWeight: '500',
        fontSize: '10px',
        wordWrap: 'break-word',
      },
    },
  },
  payment: {
    backgroundColor: 'rgba(236, 235, 236, 0.5)',
  },
  // modify
  danger: {
    color: '#f94839',
  },
  active: {
    color: '#4caf50',
  },
  check: {
    color: '#1e74d1',
  },
  edit: {},
  info: {
    width: '90%',
    margin: 'auto',
    paddingBottom: theme.spacing(2),
  },
}));
export { useStyles };
