import {
  Checkbox,
  ImageList,
  ImageListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from 'src/components/ButtonComponent';
import { StatusRequest } from 'src/containers/ServiceRequest/contants';
import { ISelector } from 'src/reducers';
import { IDetailProfile } from '../../interface';
import * as ServiceRequestAction from '../../store/actions';
import {
  updateProviderRequest,
  updateStatusReviewSocialAction,
} from '../../store/actions';

import { useStyles } from './styles';

interface IModalInterview {
  detail: IDetailProfile | any;
  getProfileRequest?: (id: string) => void;
}

function getServiceId() {
  const host = window.location.origin;
  return host === 'http://localhost:3000' || 'https://trm.instalent.dev'
    ? 12
    : 9;
}

export const dataChangeStatus = [
  {
    name: 'Accept Review',
    id: true,
  },
  {
    name: 'Waiting Review',
    id: false,
  },
];

function Certificate({ detail, getProfileRequest }: IModalInterview) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const socialNetworks = useSelector(
    (s: ISelector) => s.serviceProvider?.socialNetworks
  );
  const isAction = useSelector((s: ISelector) => s.serviceProvider?.isAction);

  const [value, setValue] = useState(0);

  const handleUpdateProvider = () => {
    dispatch(
      updateProviderRequest({
        id: detail?.id,
        status: 'APPROVED',
        service_id: detail?.service_id,
        url_images: detail?.images?.map((item) => item.url),
      })
    );
    dispatch(ServiceRequestAction.closeModal());
  };

  const handleChangeStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    id
  ) => {
    dispatch(
      updateStatusReviewSocialAction({
        id: +id,
        verified: event.target.checked,
      })
    );
  };

  useEffect(() => {
    if (value === 1) {
      getProfileRequest && getProfileRequest(detail.profile_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const isDisabled = socialNetworks.filter((item: any) => item?.verified);

  return (
    <div className={classes.root}>
      <BottomNavigation
        className={classes.head}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
      >
        <BottomNavigationAction
          label="Images Review"
          icon={<PhotoLibraryIcon />}
        />
        {detail.service_id === 9 && ( // service id kol/koc 9
          <BottomNavigationAction
            label="KOL/KOC Review"
            icon={<SupervisedUserCircleIcon />}
          />
        )}
      </BottomNavigation>

      <div>
        {value === 0 ? (
          <ImageList
            gap={4}
            cols={1}
            rowHeight={180}
            className={classes.imgList}
          >
            {detail.images?.map((item, index) => (
              <ImageListItem className={classes.imageListItem} key={index}>
                <img
                  className={classes.imgItem}
                  src={item.url}
                  alt={item.imagable_type}
                />
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Review</TableCell>
                  <TableCell>Network</TableCell>
                  <TableCell align="center">Topic</TableCell>
                  <TableCell align="center">Follow</TableCell>
                  <TableCell align="center">Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {socialNetworks?.map((row: any) => (
                  <TableRow key={row.name}>
                    <TableCell align="center">
                      <Checkbox
                        checked={row?.verified}
                        onChange={(e) => handleChangeStatus(e, row.id)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.type}
                    </TableCell>
                    <TableCell
                      style={{ width: 300, whiteSpace: 'nowrap' }}
                      align="center"
                    >
                      {row.topic?.map((item: any) => `${item.topic.name}, `)}
                    </TableCell>
                    <TableCell align="center">{row.follow}</TableCell>
                    <TableCell align="center">
                      <a
                        style={{ color: '#3e50b4' }}
                        href={row.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {row.link}
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      {value === 1 && isDisabled.length > 0 && (
        <div style={{ paddingTop: 16 }}>
          <ButtonComponent
            handleAction={handleUpdateProvider}
            status="approve"
            disable={
              isAction ||
              detail?.status === StatusRequest.APPROVED ||
              isDisabled.length === 0
            }
            isLoading={isAction}
          >
            Approve
          </ButtonComponent>
        </div>
      )}
    </div>
  );
}

export default Certificate;
