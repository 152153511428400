import { gql } from '@apollo/client';
import moment from 'moment';

import { client } from 'src';

export const getApiProviderService = async (data) => {
  const { page, rowsPerPage, filter } = data;
  return await client.query({
    query: gql`
      query {
        getServicesProvider(page: ${page}, per_page: ${rowsPerPage}, filter: { name: "${filter.name}" , phone: "${filter.phone}" , email: "${filter.email}" , status: ${filter.status}  , images: ${filter.images} , serciveId : ${filter.serciveId}}) {
          data {
            id
            profile_id
            service_id
            admin {
              name
            }
            images {
              id
              url
              imagable_id
              imagable_type
              created_at
              updated_at
            }
            services {
              id
              name
              total
              avatars
              total
            }
            profile {
              name
              city_name
            }
            status
            created_at
            updated_at
          }
          page
          per_page
          total
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

export const updateProviderServicer = async ({
  service_id,
  status,
  id,
  url_images,
}) => {
  return await client.mutate({
    mutation: gql`
  mutation {
    updateServiceProvider(id: ${id}, data: { status: ${status}, service_id: ${service_id} , url_images : ["${url_images}"]})
  }
  `,
  });
};

export const addProofProfileService = async ({
  profile_id,
  urlImages,
  service_provider_id,
}) => {
  return await client.mutate({
    mutation: gql`
      mutation {
        uploadImageServiceProvider(
          profile_id: ${profile_id}
          service_provider_id: ${service_provider_id}
          url_images: ${JSON.stringify(urlImages)}
        )
      }
    `,
  });
};

export const addScheduleInterviewService = async ({
  service_id,
  service_providers_id,
  address,
  schedule,
  type,
  description,
}) => {
  return await client.mutate({
    mutation: gql`
    mutation {
      approvalFormulas(
        data : {
          service_id:${service_id},
          service_providers_id: ${service_providers_id},
          address: "${address}",
          schedule: "${moment(schedule).utc().toISOString()}",
          type: ${type} ,
          description: "${description}"
        }
      )
    }
    `,
  });
};

export const getScheduleInterviewService = async (id) => {
  return await client.query({
    query: gql`
    query {
      getApprovalFormulas(id: ${id} ) {
        id
        service_providers_id
        service_id
        type
        description
        address
        schedule
        created_at
        updated_at
      }
    }
    `,
    fetchPolicy: 'network-only',
  });
};

export const getProfileUserService = async (id: string) => {
  return await client.query({
    query: gql`
      query {
        getProfileById(id: ${id}) {
          id
          profileSocialNetwork {
            id
            profile_id
            type
            link
            follow
            verified
          }
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

export const getTopicPartnerService = async (id: string) => {
  return await client.query({
    query: gql`
      query {
        getTopicPartner(profileSocialNetworkId: ${id}) {
          id
          profile_social_network_id
          topic_id
          topic {
            id
            name
            created_at
            updated_at
          }
          created_at
          updated_at
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

export const updateStatusReviewNetWorkServicer = async ({ id, verified }) => {
  return await client.mutate({
    mutation: gql`
      mutation {
        verifiedRegisterSocialNetwork(id: ${id}, verified: ${verified})
      }
    `,
  });
};
