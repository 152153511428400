import { put, takeLatest } from 'redux-saga/effects';

import * as types from '../constants';
import * as actionTypes from '../actions';
import {
  addService,
  deleteService,
  getServicesService,
  updateService,
} from '../services';
import { showSnackbar } from '../../../Snackbar/store/actions';
import { hideModal } from 'src/containers/App/store/actions';

function* getServicesSaga(action) {
  try {
    const response = yield getServicesService(action.payload);
    yield put(actionTypes.getServicesSuccess(response.data.servicesByAdmin));
  } catch (error: any) {
    const message = { error };

    yield put(actionTypes.getServicesFail(message.error.graphQLErrors[0].code));
  }
}

function* deleteServicesSaga(action) {
  try {
    yield deleteService(action.payload);
    yield put(actionTypes.deleteServicesSuccess());
    yield put(hideModal());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.deleteServicesFail(message.error.graphQLErrors[0].code)
    );
    yield put(hideModal());
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Updated Failed',
      })
    );
  }
}

function* updateServicesSaga(action) {
  try {
    yield updateService(action.payload);
    yield put(actionTypes.updateServicesSuccess());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Updated Succeed',
      })
    );
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.updateServicesFail(message.error.graphQLErrors[0].code)
    );
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Updated Failed',
      })
    );
  }
}

function* addServicesSaga(action) {
  try {
    yield addService(action.payload);
    yield put(actionTypes.addServicesSuccess());
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Added Succeed',
      })
    );
  } catch (error: any) {
    const message = { error };
    yield put(
      actionTypes.addServicesFail(message.error.graphQLErrors[0].code)
    );
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Added Failed',
      })
    );
  }
}

export default function* ServicesSaga() {
  yield takeLatest(types.GET_SERVICES_REQUEST, getServicesSaga);
  yield takeLatest(types.DELETE_SERVICES_REQUEST, deleteServicesSaga);
  yield takeLatest(types.UPDATE_SERVICES_REQUEST, updateServicesSaga);
  yield takeLatest(types.ADD_SERVICES_REQUEST, addServicesSaga);
}
