import {
  Grid,
  ListItem,
  ListItemText,
  Paper,
  makeStyles,
} from "@material-ui/core";
import classnames from "classnames";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ToggleComponent from "src/components/ToggleComponent";
import { ISelector } from "src/reducers";
import * as BookingActions from "../../store/actions";

function BookingConversation() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { messageConversation, bookingDetail, dataConversation } = useSelector(
    (s: ISelector) => s.booking
  );
  const ref = React.useRef<HTMLDivElement>(null);

  const handleScroll = (e) => {
    let element = e.target;
    if (element.scrollTop === 0) {
      if (dataConversation?.next_page) {
        dispatch(
          BookingActions.getConversationRequest({
            conversationId: bookingDetail.conversations.id,
            page: +dataConversation?.page + 1,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (ref.current) {
      const offsetBottom = ref.current.offsetTop + ref.current.offsetHeight;
      ref.current.scrollTo({ top: offsetBottom });
    }
  }, [dataConversation?.per_page]);

  return (
    <ToggleComponent title="Conversation">
      {messageConversation.length > 0 ? (
        <Grid container component={Paper} className={classes.chatSection}>
          {
            <Grid item xs={12}>
              <div
                ref={ref}
                className={classes.messageArea}
                onScroll={handleScroll}
              >
                {[...messageConversation].reverse().map((item: any, index) => {
                  return (
                    <ListItem
                      key={index}
                      className={classnames(classes.list, {
                        [classes.listItemTextRight]:
                          item?.profile_id === bookingDetail.request_profile_id,
                        [classes.listItemTextLeft]:
                          item?.profile_id ===
                          bookingDetail.recipient_profile_id,
                      })}
                    >
                      <Grid
                        container
                        className={classnames(classes.container, {
                          [classes.containerRight]:
                            item?.profile_id ===
                            bookingDetail.request_profile_id,
                          [classes.containerLeft]:
                            item?.profile_id ===
                            bookingDetail.recipient_profile_id,
                        })}
                      >
                        <Grid item xs={12}>
                          {item.type === "image" ? (
                            <img
                              className={classes.images}
                              src={item?.content}
                              alt=""
                            />
                          ) : (
                            <ListItemText
                              primary={item?.content}
                            ></ListItemText>
                          )}

                          <ListItemText
                            className={classes.textTime}
                            secondary={moment(item?.created_at).format("LLL")}
                          ></ListItemText>
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                })}
              </div>
            </Grid>
          }
        </Grid>
      ) : (
        <div className={classes.noMessage}>No message</div>
      )}
    </ToggleComponent>
  );
}

export default BookingConversation;

const useStyles = makeStyles({
  noMessage: {
    textAlign: "center",
    padding: "16px 0px",
  },
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "80vh",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "70vh",
    overflowY: "auto",
  },
  alignRight: {
    textAlign: "right",
  },
  listItemTextRight: {
    justifyContent: "flex-end",
    "& span": {
      textAlign: "right",
      // color: '#ffff'
    },
    "& p": {
      textAlign: "right",
      // color: '#ffff'
    },
  },
  listItemTextLeft: {
    justifyContent: "flex-start",
    "& span": {
      textAlign: "left",
      // color: '#050505'
    },
    "& p": {
      textAlign: "left",
      // color: '#050505'
    },
  },
  list: {
    width: "100%",
    display: "flex",
    "& span": {
      color: "#ffff",
    },
    "& p": {
      color: "#ffff",
    },
  },
  container: {
    width: "auto",
    borderRadius: 16,
    padding: "14px 8px",
  },
  containerLeft: {
    backgroundColor: "#3e4042",
  },
  containerRight: {
    backgroundColor: "rgb(0, 132, 255)",
  },
  textTime: {
    "& p": {
      fontSize: 12,
    },
  },
  images: {
    maxHeight: 200,
    maxWidth: "100%",
  },
});
