import { createSelector } from 'reselect';

import { initialState } from '../reducers';

const bookingState = (state: any) => state.booking || initialState;

const makeSelectIsRequesting = () =>
  createSelector(bookingState, (state) => state.isRequesting);

const makeSelectBookingDetail = () =>
  createSelector(bookingState, (state) => state.bookingDetail);

const makeSelectServices = () =>
  createSelector(bookingState, (state) => state.services);

const makeSelectIsUpdate = () =>
  createSelector(bookingState, (state) => state.isUpdate);

const makeSelectPartnerReceive = () =>
  createSelector(bookingState, (state) => state.partnerReceive);

const makeSelectPartnerSkip = () =>
  createSelector(bookingState, (state) => state.partnerSkip);

const makeSelectIsResend = () =>
  createSelector(bookingState, (state) => state.isResend);

const makeSelectIsResendNotAvailableWork = () =>
  createSelector(bookingState, (state) => state.isResendNotAvailableWork);

const makeSelectMessageConversation = () =>
  createSelector(bookingState, (state) => state.messageConversation);

const makeSelectDataConversation = () =>
  createSelector(bookingState, (state) => state.dataConversation);

const makeSelectPageNumberResend = () =>
  createSelector(bookingState, (state) => state.pageNumberResend);
  
export {
  makeSelectBookingDetail,
  makeSelectIsRequesting,
  makeSelectServices,
  makeSelectIsUpdate,
  makeSelectPartnerReceive,
  makeSelectPartnerSkip,
  makeSelectIsResend,
  makeSelectIsResendNotAvailableWork,
  makeSelectMessageConversation,
  makeSelectDataConversation,
  makeSelectPageNumberResend,
};
