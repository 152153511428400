import { useEffect, useState } from 'react';

export interface IDataLoading {
  id: number;
  status: string;
}

function useLoadingButton(isReset: boolean) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState<IDataLoading>({
    id: 0,
    status: '',
  });

  const handleChangeLoading = (newData) => {
    setDataLoading(newData);
    setIsLoading(true);
  };

  const handleResetLoading = () => {
    setDataLoading({
      id: 0,
      status: '',
    });
    setIsLoading(false);
  };
  useEffect(() => {
    if (isReset === false) {
      handleResetLoading();
      setIsLoading(false);
    }
  }, [isReset]);

  return {
    dataLoading,
    handleChangeLoading,
    handleResetLoading,
    isLoading,
  };
}

export default useLoadingButton;
