import { IUsers } from 'src/containers/ListUsersContainer/interface';
import { IInfoPage, IParams } from '../../interface';
import * as types from '../constants';

export const getListPartnersRequestAction = function (data: IParams) {
  return {
    type: types.GET_LIST_PARTNERS_REQUEST,
    payload: { data },
  };
};
// define Type when have api
export const getListUserSuccess = (listData: Array<IUsers>, info: IInfoPage) => {
  return {
    type: types.GET_LIST_PARTNERS_SUCCESS,
    payload: { listData },
    info,
  };
};

export const getListPartnersFail = (error: any) => {
  return {
    type: types.GET_LIST_PARTNERS_FAIL,
    payload: error
  };
};
