import * as types from '../constants';
export const initialState = {
  isRequesting: false,
  listServices: [],
  totalServices: 0,
  isAddService: false,
  isUpdateService: false,
  isUpdate: false,
  isAdd: false,
};

const ServicesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_SERVICES_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case types.GET_SERVICES_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        listServices: action.payload.data.data,
        totalServices: action.payload.data.totalServices,
      };
    case types.GET_SERVICES_FAIL:
      return {
        ...state,
        isRequesting: false,
        listServices: [],
      };

    case types.UPDATE_SERVICES_REQUEST:
      return {
        ...state,
        isUpdate: false,
      };

    case types.UPDATE_SERVICES_SUCCESS:
      return {
        ...state,
        isUpdateService: false,
        isUpdate: true,
      };
    case types.UPDATE_SERVICES_FAIL:
      return {
        ...state,
        isUpdateService: false,
        isUpdate: true,
      };

    case types.ADD_SERVICES_REQUEST:
      return {
        ...state,
        isAdd: false,
      };

    case types.ADD_SERVICES_SUCCESS:
      return {
        ...state,
        isAddService: false,
        isAdd: true,
      };
    case types.ADD_SERVICES_FAIL:
      return {
        ...state,
        isAddService: false,
        isAdd: true,
      };

    case types.DELETE_SERVICES_REQUEST:
      return {
        ...state,
        isUpdate: false,
      };

    case types.DELETE_SERVICES_SUCCESS:
      return {
        ...state,
        isUpdate: true,
      };
    case types.DELETE_SERVICES_FAIL:
      return {
        ...state,
        isUpdate: true,
      };

    case types.HIDE_MODAL_ADD_SERVICE:
      return {
        ...state,
        isAddService: false,
      };
    case types.SHOW_MODAL_ADD_SERVICE:
      return {
        ...state,
        isAddService: true,
      };

    case types.HIDE_MODAL_UPDATE_SERVICE:
      return {
        ...state,
        isUpdateService: false,
      };
    case types.SHOW_MODAL_UPDATE_SERVICE:
      return {
        ...state,
        isUpdateService: true,
      };
    default:
      return state;
  }
};

export default ServicesReducer;
