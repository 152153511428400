import Schedule from './components/Schedule';
import Reviews from './components/Reviews';

function RightSide() {
  return (
    <>
      <Schedule />
      <Reviews />
    </>
  );
}

export default RightSide;
