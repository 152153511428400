import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  deviceContainer: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  partnerContainn: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  partner__left: {
    width: '20%',
  },
  partner__right: {
    flex: 1,
    marginLeft: '15px',
  },
}));

export { useStyles };
