import ToggleComponent from 'src/components/ToggleComponent';

import { useStyles } from './styles';

function PartnerCategory({ services }: any) {
  const classes = useStyles();

  return (
    <ToggleComponent title="Talen Category">
      {services?.length === 0 ? (
        <div className={classes.noReview}>No Result</div>
      ) : (
        services?.map((item, index) => (
          <div key={index} className={classes.talenMain__item}>
            <div className={`${classes.item}-main`}>
              <div className={`${classes.item}-main__left`}>{item.name}</div>
            </div>
          </div>
        ))
      )}
    </ToggleComponent>
  );
}

export default PartnerCategory;
