export const SERVICE_PARTNER_REQUEST = 'instalent/SERVICE_PARTNER_REQUEST';
export const SERVICE_PARTNER_SUCCESS = 'instalent/SERVICE_PARTNER_SUCCESS';
export const SERVICE_PARTNER_FAIL = 'instalent/SERVICE_PARTNER_FAIL';

export const UPDATE_SERVICE_PARTNER_REQUEST =
  'instalent/UPDATE_SERVICE_PARTNER_REQUEST';
export const UPDATE_SERVICE_PARTNER_SUCCESS =
  'instalent/UPDATE_SERVICE_PARTNER_SUCCESS';
export const UPDATE_SERVICE_PARTNER_FAIL =
  'instalent/UPDATE_SERVICE_PARTNER_FAIL';

export const ADD_SCHEDULE_SERVICE_PARTNER_REQUEST =
  'instalent/ADD_SCHEDULE__SERVICE_PARTNER_REQUEST';
export const ADD_SCHEDULE_SERVICE_PARTNER_SUCCESS =
  'instalent/ADD_SCHEDULE_SERVICE_PARTNER_SUCCESS';
export const ADD_SCHEDULE_SERVICE_PARTNER_FAIL =
  'instalent/ADD_SCHEDULE_SERVICE_PARTNER_FAIL';
