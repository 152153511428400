import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import clsx from 'clsx';
import {
  BrandingWatermark as BrandingWatermarkIcon,
  LocalHospital as LocalHospitalIcon,
  EventAvailable as EventAvailableIcon,
} from '@material-ui/icons';

import IconImage from 'src/components/Icon/IconImage';
import ToggleComponent from 'src/components/ToggleComponent';
import { ISelector } from 'src/reducers';
import * as ServiceRequestAction from 'src/containers/ListServiceRequest/store/actions';
import ModalUploadImages from 'src/containers/ListServiceRequest/components/TableView/ModalUploadImages';

import { useStyles } from './styles';

function AdditionPartnerInfo() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const userDetail = useSelector((s: ISelector) => s.user?.userDetail);

  const [isOpen, setOpen] = useState({
    photo: false,
    vaccination: false,
    certificate: true,
    identify: false,
  });

  const onCertificate = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpen({
      ...isOpen,
      identify: false,
      vaccination: false,
      photo: false,
      certificate: !isOpen.certificate,
    });
  };

  const onVaccination = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpen({
      ...isOpen,
      identify: false,
      vaccination: !isOpen.vaccination,
      photo: false,
      certificate: false,
    });
  };

  const onIdentify = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpen({
      ...isOpen,
      identify: !isOpen.identify,
      photo: false,
      certificate: false,
      vaccination: false,
    });
  };

  const actions = [
    {
      title: 'Photo Certificate',
      iconAction: <EventAvailableIcon />,
      onClick: onCertificate,
      active: isOpen.certificate,
    },
    {
      title: 'Photo Vaccination',
      iconAction: <LocalHospitalIcon />,
      onClick: onVaccination,
      active: isOpen.vaccination,
    },
    {
      title: 'Photo Identity card',
      iconAction: <BrandingWatermarkIcon />,
      onClick: onIdentify,
      active: isOpen.identify,
    },
  ];

  const handleOpenModalUpload = () => {
    dispatch(ServiceRequestAction.openModalAdd());
  };

  return (
    <ToggleComponent title='Additional Talent Info' actions={actions}>
      <div>
        {isOpen.photo && (
          <>
            <Button
              variant='contained'
              color='primary'
              startIcon={<IconImage />}
              className={classes.btnUpload}
              onClick={handleOpenModalUpload}
            >
              Upload Photo
            </Button>
            <ModalUploadImages title='Upload Images' detail={userDetail} />

            <p className={classes.textPhoto}>
              All Photo ({userDetail?.images_proof?.length || 0})
            </p>
            <Grid container spacing={1} className={classes.gridImages}>
              {userDetail?.images_proof?.map((item, index) => (
                <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                  <img
                    className={classes.images}
                    src={item.url}
                    alt={item.imagable_type}
                  />
                  <div className={classes.boxBottom}>Approved</div>
                </Grid>
              ))}
            </Grid>
            <div></div>
          </>
        )}
        {isOpen.certificate && (
          <div>
            <Grid container spacing={1} className={classes.gridImages}>
              {userDetail?.images_certificate?.map((item, index) => (
                <Grid item xs={12} sm={12} md={6} lg={3} key={index}>
                  <img
                    className={classes.images}
                    src={item.url}
                    alt={item.imagable_type}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
        {isOpen.vaccination && (
          <Grid container spacing={1} className={classes.gridImages}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img
                className={classes.images}
                src={userDetail?.images_certificate_of_vaccination?.url}
                alt={
                  userDetail?.images_certificate_of_vaccination?.imagable_type
                }
              />
            </Grid>
          </Grid>
        )}
        {isOpen.identify && (
          <Grid container spacing={1} className={classes.gridImages}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img
                className={classes.images}
                src={
                  userDetail.images_identity_card_by_admin
                    ?.identity_card_back_side
                }
                alt={''}
              />
              <div className={clsx(classes.boxBottom, classes.boxBlack)}>
                Back side
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img
                className={classes.images}
                src={
                  userDetail.images_identity_card_by_admin
                    ?.identity_card_front_side
                }
                alt={''}
              />
              <div className={clsx(classes.boxBottom, classes.boxBlack)}>
                Front side
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </ToggleComponent>
  );
}

export default AdditionPartnerInfo;
