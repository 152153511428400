import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  icon__log: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    '& :hover': {
      cursor: 'pointer',
    },
    '& svg': {},
  },
  infoMap: {},
  header__time: {},
  text: {
    fontSize: '10px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& p': {
      margin: '5px',
    },
  },
  infoWindow: {
    display: 'flex',
    alignItems: 'center',
    color: '#070707',
  },
  locationPartner: {
    marginLeft: 8,
    '& div': {
      fontSize: '10px',
    },
    '& a': {
      textDecoration: 'none',
      color: '#3e50b4',
      fontWeight: 'bold',
    },
  },
}));
export { useStyles };
