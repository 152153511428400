import { Grid } from '@material-ui/core';

import LeftSide from '../components/LeftSide';
import RightSide from '../components/RightSide';

import { useStyles } from './styles';

function UserView() {
  const classes = useStyles();
  return (
    <Grid spacing={1} container className={classes.container}>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <LeftSide />
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9}>
        <RightSide />
      </Grid>
    </Grid>
  );
}
export default UserView;
