/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

import reducerBooking from 'src/containers/Booking/store/reducers';
import sagaBooking from 'src/containers/Booking/store/sagas';
import { useInjectReducer } from 'src/utils/injectReducer';
import reducer from './store/reducers';
import BookingsView from './views';
import { useInjectSaga } from 'src/utils/injectSaga';
import saga from './store/sagas';
import { IFilter, IParams } from './interface';
import { ISelector } from 'src/reducers';
import * as BookingsAction from './store/actions';
import useQueryLocation from 'src/hooks/useQueryLocation';

interface ITime {
  from : string;
  to: string;
}

function BookingsContainer() {
  useInjectReducer({ key: 'bookings', reducer });
  useInjectSaga({ key: 'bookings', saga });

  useInjectReducer({ key: 'booking', reducer: reducerBooking });
  useInjectSaga({ key: 'booking', saga: sagaBooking });

  let params = useRef<IParams>();

  const location = useLocation();
  const query = useQueryLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isDelete = useSelector((s: ISelector) => s.booking?.isDelete || false);

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);


  const [time , setTime] = useState<ITime>({
    from: '',
    to: ''
  });
  const handleChangeTime = (from, to) => {
    setTime({
      from,
      to,
    });
  };

  const [search, setSearch] = useState<IFilter>({
    status: 'WAITINGTOBEPROCESSED',
    date: '',
  });

  const handleSearch = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFilter = () => {
    params.current = {
      page: page,
      per_page: rowsPerPage,
      filter: {
        date: search.date,
        status: search.status,
      },
    };

    dispatch(BookingsAction.getBookings(params.current));
    const paramsString = queryString.stringify(params.current.filter);

    history.push(
      `/bookings?${paramsString}`
    );
  };

  const handleClearFilter = () => {
    setSearch({
      status: 'WAITINGTOBEPROCESSED',
      date: '',
    });
    params.current = {
      page: 1,
      per_page: rowsPerPage,
      filter: {
        date: '',
        status: 'WAITINGTOBEPROCESSED',
      },
    };
    dispatch(BookingsAction.getBookings(params.current));
    history.push(`/bookings`);
  };

  useEffect(() => {
    if (location.search) {
      const date = query.get('date');
      const status = query.get('status');

      params.current = {
        page,
        per_page: rowsPerPage,
        filter: {
          date: date || search.date,
          status: status || search.status,
        },
      };
    }
    params.current = {
      page,
      per_page: rowsPerPage,
      filter: {
        date: search.date,
        status: search.status,
      },
    };
    dispatch(BookingsAction.getBookings(params.current));
  }, [dispatch, page, rowsPerPage, isDelete]);

  useEffect(() => {
    dispatch(BookingsAction.getCalenderBookings({
      from : time.from,
      to : time.to
    }))
  },[dispatch,time.from ,time.to])

  return (
    <BookingsView
      search={search}
      handleSearch={handleSearch}
      handleClearFilter={handleClearFilter}
      handleFilter={handleFilter}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangeTime={handleChangeTime}
    />
  );
}

export default BookingsContainer;
