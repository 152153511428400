import * as types from '../constants';

export const showModal = function () {
  return {
    type: types.SHOW_MODAL,
  };
};

export const hideModal = () => {
  return {
    type: types.HIDE_MODAL,
  };
};

export const showModalAdd = () => {
  return {
    type: types.SHOW_MODAL_ADD,
  };
};

export const hideModalAdd = () => {
  return {
    type: types.HIDE_MODAL_ADD,
  };
};

export const showModalUpdate = () => {
  return {
    type: types.SHOW_MODAL_UPDATE,
  };
};

export const hideModalUpdate = () => {
  return {
    type: types.HIDE_MODAL_UPDATE,
  };
};
