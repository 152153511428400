import * as types from '../constants';

export const initialState = {
  isLoading: false,
  servicesAreasList: [],
  total: 0,
  isRefresh: false,
  listCitiesID: [],
};

const ServiceAreasReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_SERVICE_AREAS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_SERVICE_AREAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        servicesAreasList: action.payload.data.data,
        total: action.payload.data.total,
      };
    case types.GET_SERVICE_AREAS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case types.DELETE_SERVICE_AREAS_REQUEST:
      return {
        ...state,
        isRefresh: false,
      };
    case types.DELETE_SERVICE_AREAS_SUCCESS:
      return {
        ...state,
        isRefresh: true,
      };
    case types.DELETE_SERVICE_AREAS_FAIL:
      return {
        ...state,
        isRefresh: true,
      };
    case types.GET_ID_CITY_SUCCESS:
      return {
        ...state,
        listCitiesID: action.payload.data.data.cities,
      };
    case types.CREATE_SERVICE_AREA_REQUEST:
      return {
        ...state,
        isRefresh: false,
      };
    case types.CREATE_SERVICE_AREA_SUCCESS:
      return {
        ...state,
        isRefresh: true,
      };
    case types.CREATE_SERVICE_AREA_FAIL:
      return {
        ...state,
        isRefresh: true,
      };
    case types.UPDATE_SERVICE_AREA_REQUEST:
      return {
        ...state,
        isRefresh: false,
      };
    case types.UPDATE_SERVICE_AREA_SUCCESS:
      const data = action.payload.data;      
      const newValue: any = state.servicesAreasList.map((item: any) =>
        item?.id === data.id ? data : item
      );
      return {
        ...state,
        servicesAreasList: newValue,
      };
    case types.UPDATE_SERVICE_AREA_FAIL:
      return {
        ...state,
        isRefresh: true,
      };
    default:
      return state;
  }
};

export default ServiceAreasReducer;
