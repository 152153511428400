import moment from 'moment';
import { IBookingCancel, IUser } from 'src/containers/User/interface';

import { IBooking } from '../interface';

export const infoDetail = (values: IBooking) => [
  {
    title: 'Booking Time',
    content: moment(values?.booking_time).format('LLL') || '',
    type: 'datetime-local',
    name: 'booking_time',
  },
  {
    title: 'Quantity',
    content: values?.qty || '',
    type: 'text',
    name: 'qty',
  },
];

export const infoDetailDescription = (values: IBooking) => [
  {
    title: 'Type',
    content: values?.type || '',
  },
  {
    title: 'Discount value',
    content: values?.discount_value || '',
  },
  {
    title: 'Discount unit',
    content: values?.discount_unit || '',
  },
  {
    title: 'Notes',
    content: values?.notes || '',
  },
  { title: 'Address', content: values?.full_address || '' },
  { title: 'Schedule', content: values?.schedule ? 'Yes' : 'No' },
  { title: 'Price service', content: values?.service?.price || '' },
  { title: 'Description service', content: values?.service?.description || '' },
  { title: 'City', content: values?.cityData?.name || '' },
  {
    title: 'Created at',
    content: moment(values?.created_at).format('LLL') || '',
  },
  {
    title: 'Updated at',
    content: moment(values?.updated_at).format('LLL') || '',
  },
];

export const bookingCancel = (values: IBookingCancel) => [
  { title: 'Reason Cancel', content: values?.cancelation_reson || '' },
  {
    title: 'Time Cancel',
    content: moment(values?.created_at).format('LLL') || '',
  },
  { title: 'Canceler', content: values?.profile?.name || '' },
];

export const profileRecipient = (values: IUser) => [
  { title: 'Name', content: `${values?.name || ''}` },
  { title: 'Email', content: `${values?.email || ''}` },
  { title: 'Gender', content: `${values?.gender || ''}` },
  { title: 'Phone', content: `${values?.phone || ''}` },
  {
    title: 'Available work status',
    content: values?.available_work_status ? 'Yes' : 'No',
  },
];

export const profileRequest = (values: IUser) => [
  { title: 'Name', content: values?.name || '' },
  { title: 'Email', content: values?.email || '' },
  { title: 'Gender', content: values?.gender || '' },
  { title: 'Phone', content: values?.phone || '' },
  {
    title: 'Available work status',
    content: values?.available_work_status ? 'Yes' : 'No',
  },
];

export const statusBooking = [
  {
    name: 'CANCELED',
    id: 0,
  },
  {
    name: 'WAITINGTOBEPROCESSED',
    id: 1,
  },
  {
    name: 'DONE',
    id: 2,
  },
  {
    name: 'ACCEPTED',
    id: 3,
  },
  {
    name: 'PROCESSING',
    id: 4,
  },
];

export const newStatusBooking = [
  {
    name: 'CANCELED',
    id: 0,
  },
  {
    name: 'DONE',
    id: 2,
  },
  {
    name: 'ACCEPTED',
    id: 3,
  },
  {
    name: 'PROCESSING',
    id: 4,
  },
];

export const statusConvert = (value) => {
  if (value === 0) return 'CANCELED';
  if (value === 1) return 'WAITINGTOBEPROCESSED';
  if (value === 2) return 'DONE';
  if (value === 3) return 'ACCEPTED';
  if (value === 4) return 'PROCESSING';
  return '';
};
