import { AuthenticatedRoute } from '../components/routes';
import { UsersPage } from '../pages';

const listUserRoutes = [
  {
    path: '/users',
    title: 'ListUser',
    component: UsersPage,
    route: AuthenticatedRoute,
    roles: [],
    permission: [],
    exact: true,
  },
];

export default listUserRoutes;