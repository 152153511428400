import { useState } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import {
  Visibility,
  VisibilityOff,
  Search as SearchIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import styled from 'styled-components';

import styledTheme from '../../utils/theme';
import { TextInputStyle, useStyles } from './styles';

interface Props {
  value?: string | number | boolean | any;
  lable?: string | number;
  onChange?: (e) => void;
  errorInput?: string;
  isSearch?: boolean;
  icon?: any;
  onClickSearch?: () => void;
  onClear?: () => void;
  type?: string;
  [data: string]: any;
}

// TextInput component
function TextInput(props: Props) {
  const {
    type = 'text',
    label,
    onChange,
    value,
    onClickSearch,
    errorInput,
    icon,
    isSearch,
    onClear,
    ...rest
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const mode = localStorage.getItem('instalent-them-mode');
  const classes = useStyles();

  const togglePassword = () => {
    setShowPassword((s) => !s);
  };

  return (
    <div>
      {!!icon && <IconWrapper>{icon}</IconWrapper>}
      {isSearch && (
        <Search onClick={value ? onClear : onClickSearch}>
          {value ? <CloseIcon /> : <SearchIcon />}
        </Search>
      )}
      {type === 'password' ? (
        <TextInputStyle
          {...rest}
          label={label}
          value={value}
          onChange={onChange}
          type={showPassword ? 'text' : type}
          className={clsx(classes.default, {
            [classes.light]: mode === 'light',
          })}
          InputProps={{
            endAdornment:
              type === 'password' ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ) : undefined,
          }}
        />
      ) : (
        <TextInputStyle
          {...rest}
          label={label}
          value={value}
          onChange={onChange}
          type={type}
          className={clsx(classes.default, {
            [classes.light]: mode === 'light',
          })}
        />
      )}
    </div>
  );
}

const IconWrapper = styled.div`
  position: absolute;
  align-self: center;
  left: ${styledTheme.spacing * 0.4}px;

  svg {
    width: 15px;
    height: 15px;
    color: #9f9f9f;
  }
`;

const Search = styled.div`
  position: absolute;
  align-self: center;
  right: ${styledTheme.spacing * 0.4}px;
  z-index: 2;
  cursor: pointer;
  svg {
    width: 15px;
    height: 15px;
    color: #9f9f9f;
  }
`;
export default TextInput;
