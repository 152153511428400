import { useEffect, useState } from 'react';
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import { compose, withProps } from 'recompose';
import { IMapLocation } from 'src/containers/Booking/interface';
import { useStyles } from './styles';

const MapLocation = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=' +
      process.env.REACT_APP_API_KEY_MAP +
      '&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(
  ({
    icon,
    positionPartner,
    path,
    bookingDetail,
    partnerReceive,
  }: IMapLocation) => {
    const classes = useStyles();

    const [directions, setDirections] = useState<
      google.maps.DirectionsResult | any
    >(undefined);
    const [distance, setDistance] = useState<number>(0);
    const [midpoint, setMidpoint] = useState<any>(null);
    const [isOpenStartAdress, setIsOpenStartAddress] = useState<boolean>(false);
    const [isOpenEndAddress, setIsOpenEndAddress] = useState<boolean>(false);
    const [startAddress, setStartAddress] = useState<string>();
    const [endAddress, setEndAddress] = useState<string>();

    useEffect(() => {
      const waypoints = path.map((p) => ({
        location: { lat: p.latitude, lng: p.longitude },
        stopover: true,
      }));
      const origin = waypoints.shift().location;
      const destination = waypoints.pop().location;
      const directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
          waypoints: waypoints,
        },
        (result: any, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);
            setDistance(+(result.routes[0].legs[0].distance.value / 1000));
            if (result.routes[0].legs[0].steps.length > 2) {
              const midIndex = Math.floor(
                result.routes[0].legs[0].steps.length / 2
              );
              const point = result.routes[0].legs[0].steps[midIndex];
              const mid2 = point.path[Math.floor(point.path.length / 2)];
              const loc = {
                lat: mid2.lat(),
                lng: mid2.lng(),
              };
              setMidpoint(loc);
            }
            const startAddress = result.routes[0].legs[0].start_address;
            const endAddress = result.routes[0].legs[0].end_address;
            setEndAddress(endAddress);
            setStartAddress(startAddress);
          }
        }
      );
    });

    const originalMarkerClick = (e) => {
      setIsOpenStartAddress(!isOpenStartAdress);
    };

    const destinationMarkerClick = (e) => {
      setIsOpenEndAddress(!isOpenEndAddress);
    };

    const renderMarker = (
      text,
      position,
      scaledSize,
      anchor,
      iconUrl,
      onClick,
      isOpen
    ) => (
      <Marker
        position={position}
        icon={{
          scaledSize: scaledSize,
          anchor: anchor,
          url: iconUrl,
        }}
        onClick={onClick}
      >
        {isOpen && (
          <InfoWindow>
            <div className={classes.infoWindow}>
              <h6>{text}</h6>
            </div>
          </InfoWindow>
        )}
      </Marker>
    );

    const [selectPartner, setSelectPartner] = useState('');

    const steps = directions?.routes[0].legs[0].steps;

    return (
      <GoogleMap
        defaultCenter={positionPartner}
        defaultZoom={18}
        options={{ streetViewControl: false }}
      >
        {bookingDetail.recipientData === null ? (
          <>
            {partnerReceive?.map((item: any, index) => (
              <Marker
                key={index}
                position={{
                  lat: +item?.location?.coordinates[1] || 0,
                  lng: +item?.location?.coordinates[0] || 0,
                }}
                onClick={() => setSelectPartner(item.id)}
                icon={{
                  scaledSize: new google.maps.Size(30, 30),
                  url: '/images/location.png',
                }}
              >
                {selectPartner === item.id && (
                  <InfoWindow>
                    <div className={classes.infoWindow}>
                      <div className={classes.locationPartner}>
                        <a
                          target="_blank"
                          href={`${window.location.origin}/partner/${item.id}`}
                          rel="noreferrer"
                        >
                          {item.name}
                        </a>
                        <div>{item.phone}</div>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            ))}
            <Marker position={positionPartner}>
              <InfoWindow>
                <div className={classes.infoWindow}>
                  {icon} {bookingDetail?.full_address.split(',')[0]}
                </div>
              </InfoWindow>
            </Marker>
          </>
        ) : (
          <>
            <DirectionsRenderer
              directions={directions}
              options={{
                polylineOptions: {
                  strokeColor: '#FF0000',
                  strokeOpacity: 0.5,
                  strokeWeight: 10,
                },
                suppressMarkers: true,
              }}
            />
            {steps && (
              <>
                {renderMarker(
                  endAddress,
                  steps[steps.length - 1].end_point,
                  new google.maps.Size(32, 32),
                  new google.maps.Point(22, 15),
                  '/images/destination.png',
                  destinationMarkerClick,
                  isOpenEndAddress
                )}
                {renderMarker(
                  startAddress,
                  steps[0].start_point,
                  new google.maps.Size(32, 32),
                  new google.maps.Point(15, 30),
                  '/images/original.png',
                  originalMarkerClick,
                  isOpenStartAdress
                )}
              </>
            )}
            {midpoint && (
              <Marker position={midpoint} options={{ visible: false }}>
                <InfoWindow>
                  <div className={classes.infoWindow}>
                    {icon} Distance: {distance} km
                  </div>
                </InfoWindow>
              </Marker>
            )}
          </>
        )}
      </GoogleMap>
    );
  }
);

export default MapLocation;
