import {
  Checkbox,
  makeStyles,
  TableCell,
  TableFooter,
  TableHead,
  TableSortLabel,
  withStyles,
} from '@material-ui/core';
import styledTheme from '../../utils/theme';

const TableHeadStyle = withStyles(() => ({
  root: {
    background: '#f7f7f7',
  },
}))(TableHead);

const TableCellStyle = withStyles(theme => ({
  root: {
    padding: 10,
    fontSize: styledTheme.fontSize.large,
    minWidth: 100,
    maxWidth: 250,
    wordBreak: 'break-word',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap'
  },
}))(TableCell);

const TableFooterStyle = withStyles(() => ({
  root: {
    padding: 10,
    fontSize: 16,
  },
}))(TableFooter);

const CheckBoxStyle = withStyles(() => ({
  root: {
    padding: 0,
    width: 24,
  },
  checked: {
    color: '#4caf50 !important',
  },
}))(Checkbox);

const useStyles = makeStyles(theme => ({
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    background: theme.palette.primary.main,
  },
  container: {
    scrolbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar' : {
      display: 'none'
    },
    maxHeight: 'calc(100vh - 250px)',
    '& > table': {
      borderCollapse: 'collapse',
    },
  },
  paper: {
    boxShadow: 'none',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  rowTable: {
    background: theme.palette.primary.main,
    '& >td': {
      fontSize: 12,
      whiteSpace: 'nowrap',
      maxWidth: '100%',
      "&:first-child": {
        position: 'sticky',
        left: 0,
        zIndex: 100,
       }
    },
    '&.completed': {
      background: '#edf7ed',
    },
    '&.error': {
      background: '#feeceb',
    },
    '&.pending': {
      backgroundColor: '#fef7ec',
    },
    '&.resolved': {
      backgroundColor: '#f1f9f1',
    },
  },
  rowDark: {
    '& >td': {
      "&:first-child": {
        backgroundColor: '#202020'
       }
    },
  },
  rowLight: {
    '& >td': {
      "&:first-child": {
        backgroundColor: '#ffff'
       }
    },
  },
  checkbox: {
    '&:first-child': {
      width: 28,
    },
  },
  rowSelected: {
    borderLeft: '5px solid #4059b4',
    background: '#e2e5f4',
  },
  sortIcon: {
    display: 'flex',
    flexDirection: 'column',
  },
  arrowIcon: {
    padding: 0,
    fontSize: 16,
    marginLeft: 5,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  border: {
    border: '1px solid #e0e0e0',
  },
  borderRight: {
    borderRight: '1px solid #e0e0e0',
  },
  borderBottom: {
    borderBottom: '1px solid #e0e0e0',
  },
  empty: {
    textAlign: 'center',
  },
  dark: {
    color: '#1a1a1a',
  },
  light: {
    color: '#fff',
    '& td' : {
      color: '#fff',
    },
    '&:hover td': {
      color: '#fff',
    },
  },
  fixedCheckbox: {},
  tableRow: {
    position: 'relative',
    "&>th": {
     "&:first-child": {
      position: 'sticky',
      left: 0,
      zIndex: 100,
     }
    }
  },
  tableRowDark: {
    "&>th": {
     "&:first-child": {
      backgroundColor: '#ffff'
     }
    }
  },
  tableRowLight: {
    "&>th": {
      "&:first-child": {
       backgroundColor: '#202020'
      }
     }
  }
}));

const TableSortLabelStyle = withStyles(() => ({
  icon: {
    fontSize: 17,
    marginLeft: 5,
    position: 'relative',
    bottom: 1,
  },
}))(TableSortLabel);

export {
  TableSortLabelStyle,
  TableHeadStyle,
  useStyles,
  TableCellStyle,
  TableFooterStyle,
  CheckBoxStyle,
};
